.mainContainer_div {
  // background-color: #ffffff;
  //   height: 75%;
    // border-radius: 6px;
    // position: -webkit-sticky;
    //   position: absolute;
    //   overflow-y: scroll;
    //   z-index: 9999;
    //   width: 90%;
    //   top: 16%;
  background-color: #ffffff;
  height: 70vh;
    padding: 15px 25px 30px 25px;
    overflow-y: scroll;
  border-radius: 6px;
  position: -webkit-sticky;
  overflow-y: scroll;
  z-index: 9999;
}

.header {
  // border-bottom: 2px solid #eaecf1;
  margin-bottom: 10px;
}

.lineImg {
  margin-top: 10px;
}

.header-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #eaecf1;

  .div_one {
    width: 45%;
    text-align: center;
    height: 5vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .header_text {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      margin-left: 10%;
    }
  }

  .div_two {
    width: 18%;
    text-align: center;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .header_text {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }
}

.headertext_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 10px 20px 10px;
  padding: 0px 10px 0px 10px;
  height: 5vh;

  .download_list-title {
    color: #000;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 24px;
  }

  p {
    color: #000;
    font-family: sans-serif;
    font-size: 15px;
  }
}

.back_arrow {
  width: 9vw;
  margin: 10px;
  padding-top: 4px;
  align-items: center;
    display: flex;
    justify-content: space-around;
    align-content: center;

  a {
    color: black !important;
img {
    margin-right: 10px;
  }
}

span {
  margin-right: 6px;
}
}

.back_arrow a:hover {
  color: black !important;
}

.back_arrow:hover {
  background-color: #edeef8;
  color: black !important;
}

.bottom_div {
  // height: 68%;
  // padding-bottom: 25px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
    align-items: stretch;

  .table_Body {
    padding-top: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;

    .div_one {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 45%;
        min-height: 5vh;
      img {
        margin-right: 5px;
        height: 20px;
        width: 20px;
      }

      .details-text {
        font-size: 15px;
        margin: 0;
        padding: 0;
        margin-left: 8px;
      }
    }

    .div_two {
      width: 18%;
      text-align: center;
      height: 5vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .description-text {
        font-size: 15px;
        margin: 0;
        padding: 0;
        margin-left: 8px;
      }
    }
  }
}

.loadmore_div_Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .loadmore_div {
    background-color: #4287f5;
    width: 100px;
    padding: 3px;
    padding-left: 13px;
    outline: none;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    padding-right: 13px;
    margin-bottom: 4vw;
  }
}

.loader_divContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-btn {
  height: 3.5vh;
  width: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  border-radius: 25px;
  background-color: #0075ff;
  color: white;
  border: 1px solid #5711e2;
  align-items: center;
  margin: 0;

  p {
    margin: 0;
    font-size: 14px;
  }
}

.retry-btn {
  height: 3.5vh;
  width: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  border-radius: 25px;
  color: #6c832c;
  background: rgba(108, 131, 44, 0.1);
  border: 1.0076px solid #6c832c;
  align-items: center;
  margin: 0;

  p {
    margin: 0;
    font-size: 14px;
  }
}

.cancel-btn {
  height: 3.5vh;
  width: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  border-radius: 25px;
  color: #e56129;
  background: #fff;
  border: 1.0076px solid #e56129;
  align-items: center;
  margin: 0;

  p {
    margin: 0;
    font-size: 14px;
  }
}

.buttonView {
  display: flex;
  justify-content: center;
}

.bottom_div_flex {}

//media for tab responsive
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .requestList {
    width: 25%;
  }

  .statusList {
    width: 17%;
  }

  .actionList {
    width: 11%;
  }

  .download-btn {
    height: 3vh;
  }

  .retry-btn {
    height: 3vh;
  }

  .cancel-btn {
    height: 3vh;
  }

  .header-title .nameList {
    width: 36%;
    margin-left: 25px;
  }
}

.downloadListLoadMore{
  width: 100%;
  display: flex;
  justify-content: center;
}

.mainContainer_div::-webkit-scrollbar{
  width: 8px;
}