.centerDisplayNote {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerShowMoreContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;

    button {
        outline: none;
        background-color: #fff;
        border: none;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 1.3rem;
    }

    p {
        font-size: 1rem;
        color: #000000;
        font-family: sans-serif;
        margin: 0;
        padding: 0;
    }
}

.sortingIcons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        font-size: 0.8rem;
        color: #000000;
        font-family: sans-serif;
        margin: 0;
        padding: 0;
        margin-right: 10px;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .upArrow {
            width: 0;
            height: 0;
            border: solid 5px transparent;
            background: transparent;
            border-bottom: solid 7px black;
            border-top-width: 0;
            cursor: pointer;
        }
        .downArrow {
            width: 0;
            height: 0;
            margin-top: 2px;
            border: solid 5px transparent;
            background: transparent;
            border-top: solid 7px black;
            border-bottom-width: 0;
            cursor: pointer;
        }
    }
}

.allFacilities {
    margin: 10px;
    font-weight: 500;
}

.facilitiesText {
    cursor: pointer;
    padding: 7px;
    font-size: 1rem;
    color: #000000;
    margin: 0;
    min-width: 7vw;
    margin: 10px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;

    img {
        margin-right: 10px;
    }
}

.facilitiesText:hover {
    background-color: #edeef8;
}

.eventSummaryScss {
    .eventTableHeader {
        thead th {
            position: -webkit-sticky !important;
            position: sticky !important;
            top: 0px !important;
            z-index: 6 !important;
            height: 10vh;
            font-size: 12px;
            background-color: white !important;
            min-width: 14vw;
        }

        th:nth-child(1),
        td:nth-child(1) {
            height: 6vh !important;
            min-width: 14vw;
            border: 0px solid #ccc !important;
            justify-content: center;
            position: -webkit-sticky;
            position: sticky;
            z-index: 7 !important;
            left: 0 !important;
            background-color: #e6e9f4;
        }
    }
}