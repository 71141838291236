.profileModalmainContainer {
  z-index: 99999 !important;
}
.profileModalmainContainerHeader {
  z-index: 99999 !important;
}
.profileModalmainContainerBody {
  z-index: 99999 !important;
}
.container-div {
  margin-left: 48px;
  margin-right: 48px;
}
.profileModalmainContainerHeader {
  border-bottom-width: 0;
  margin-left: 57px;
  margin-right: 56px;
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  justify-content: space-between;
}
.profileModalmainContainerHeader-title h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #1e1e1e;
}
.profileModalmainContainerBody {
  margin-top: 0;
  display: flex;
  flex-direction: column;
}
.userDetails_container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 16px;
}
.userDetailsContainer-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 8px;
}
.userDetailsContainer-form p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin: 0;
}
.userDetailsContainer-form input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  border: 1px solid #bdcbfb;
  border-radius: 6px;
}
.profileImage_div {
  margin-top: 24px;
}

.button1 {
  background-color: #fcf2da;
  border: 1.04598px solid #cfc8b8;
  color: #000;
  font-size: 13px;
  padding: 9px 17px 9px 17px;
  border-radius: 4px;
  font-size: 13px;
}
.button2 {
  background-color: #0f43f9;
  border: 1.04598px solid #5711e2;
  color: #fff;
  font-size: 13px;
  padding: 9px 17px 9px 17px;
  border-radius: 4px;
  font-size: 13px;
}
.profileModalmainContainerFooter {
  border-top-width: 0;
  margin-bottom: 20px;
  margin-right: 48px;
}

@media only screen and (max-width: 767px) {
  .userDetails_container {
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }
  .userDetailsContainer-form {
    width: 100%;
    gap: 2px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .userDetails_container {
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }
  .userDetailsContainer-form {
    width: 100%;
    gap: 2px;
  }
}

input::-webkit-inner-spin-button {
  display: none;
}
input[readonly] {
  pointer-events: none;
}
.noneEditableInputText {
  color: #6c6262;
}
