.mainContainer {
  padding: 36px;
}

.header-div {
  border-bottom-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #1e1e1e;
  }
}

.modalmainContainerBody {
  margin-top: 0;
}

.requestDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.lightText {
  color: #707070;
  font-size: 12px;
  font-weight: 500;
}

.blackText {
  color: #000000;
  font-size: 12px;
  margin-left: auto;
  margin-right: 36px;
  font-weight: 400;
}

.qrChevron-range {
  width: 7%;
  text-align: center;
  align-items: center;
  margin-right: 10px;

  .chevronImg {
    height: 8px;
  }

  .rotate-90-deg-icon {
    transform: rotate(0deg);
    transition: transform 0.1s ease-in-out;
  }
  .rotate-0-deg-icon {
    transform: rotate(-90deg);
    transition: transform 0.1s ease-in-out;
  }
}

.dataInRows {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.changePointer {
  cursor: pointer;
}

.machineDetailsList {
  display: flex;
  flex-direction: row;
}

.bodyContainer {
  overflow-y: scroll;
  height: 70vh;
}

.machineDetailsDiv {
  background: #fbfcff;
  border: 1px solid #9ad2ff;
  border-radius: 6px;
  margin: 16px;
  padding: 16px;

  .machineDetailsText {
    width: 50%;
    text-align: center;
    color: #000000;
    font-size: 14px;
    margin-left: auto;
    margin-right: 16px;
    font-weight: 400;
  }
}
