.incidentSummarySubCardHeadCont {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  .incidentTimeTxt {
    font-size: 15px !important;
    font-weight: 700 !important;
    position: relative;
    left: 35px;
    color: black;
    padding-top: 10px;
  }
  .incidentTimeType {
    font-size: 15px !important;
    font-weight: 700 !important;
    position: relative;
    left: 40px;
    color: black;
    padding-top: 10px;
  }
  .incidentOccurenceTxt {
    font-size: 15px !important;
    font-weight: 700 !important;
    position: relative;
    color: black;
    padding-top: 10px;
    right: 25px;
  }
  .incidentLocationTxt {
    font-size: 15px !important;
    font-weight: 700 !important;
    padding-top: 10px;
    position: relative;
    right: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .incidentSummarySubCardHeadCont .incidentTimeTxt {
    font-size: 6px !important;
    font-weight: 700 !important;
    position: relative;
    left: 30px;
    top: 18px;
    color: black;
    padding-top: 10px;
  }
  .incidentSummarySubCardHeadCont .incidentTimeType {
    font-size: 5px !important;
    font-weight: 700 !important;
    position: relative;
    left: 27px;
    color: black;
    padding-top: 10px;
    top: 18px;
  }
  .incidentSummarySubCardHeadCont .incidentOccurenceTxt {
    font-size: 7px !important;
    font-weight: 700 !important;
    position: relative;
    color: black;
    padding-top: 10px;
    left: 4px;
    top: 18px;
  }
  .incidentSummarySubCardHeadCont .incidentLocationTxt {
    font-size: 5px !important;
    font-weight: 700 !important;
    padding-top: 10px;
    position: relative;
    right: 10px;
    top: 18px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .incidentSummarySubCardHeadCont {
    .incidentTimeTxt {
      font-size: 10px !important;
      top: 10px;
      left: 75px;
    }
    .incidentTimeType {
      font-size: 10px !important;
      left: 80px;
      top: 10px;
    }
    .incidentOccurenceTxt {
      font-size: 10px !important;
      left: 38px;
      top: 10px;
    }
    .incidentLocationTxt {
      font-size: 10px !important;
      position: relative;
      right: 10px;
      top: 10px;
    }
  }
}
