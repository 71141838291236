.cardWrapperOutlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-wrappers {
  background-color: white !important;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  // max-width: 90%;
  // max-height: 90%;
  // overflow: auto;
  min-width: 37vw !important;
  min-height: 30vh;
  /* height: 50vh; */
  border: 1.3px solid #3793ff;
  border-radius: 20px;
  margin: 0 auto 0;
  transition: all 0.8s;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  top: 8vh;
  width: 40vw;
  left: auto !important;
  right: auto;
  bottom: auto;

  .modal-header-style {
    display: flex;
    justify-content: space-between;
    height: 12vh;
    // background-color: #eff2ff;
    padding: 6px;
    align-items: flex-end;

    .details {
      display: flex;
      align-items: start;
      margin-top: 10px;

      img {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 3px;
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .close-modal-btn {
      color: rgb(0, 0, 0);
      cursor: pointer;
      float: right;
      font-size: 30px;
      margin: 0;
      margin-right: 2%;
    }

    .close-modal-btn:hover {
      color: rgb(167, 0, 0);
    }
  }

  .modal-bodyD {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px 10px 10px;

    p {
      margin-top: 0;
      margin-bottom: 0rem;
    }

    .leftCnt {
      .inputBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 11vw;
        // padding: 13px;

        .inputBox-label {
          input[type="radio" i] {
            margin-right: 10px;
            accent-color: #3c88f7;
          }

          input[type="radio"]:after {
            background-color: #426bfa;
          }
        }
      }
    }

    .rightCnt {

      // padding: 3%;
      // margin-left: 15%;
      .inputBox {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .typeDownload {
          label {
            margin-right: 20px;
            margin-top: 11px;
          }
        }

        .inputDateRange-asset {
          border: 1px solid #000000;
          border-radius: 4px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-top: 3px;
          width: 16vw !important;
          padding: 3px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }

  .selectDropDownFfrequency {
    width: 230px;
    border: 1px solid #9AD2FF;
    outline: 1px solid #9AD2FF;
    margin-left: 3.5%;
    margin-top: 2%;
    padding: 5px;
    border-radius: 6px;
  }

  :focus::after {
    border: 1px solid #9AD2FF;
    outline: 1px solid #9AD2FF;
  }

  .inputTextSpace {
    padding: 8px;
    margin-left: 16px;
    margin-top: 10px;

    .inputBox {
      input {
        width: 90%;
        border: 1px solid #A7B8F4;
        border-radius: 4px;
        padding: 5px;
      }

      :focus::after::before {
        border: 1px solid #9AD2FF;
        outline: 1px solid #9AD2FF;
      }
    }
  }

  .modal-footer-asset {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 10vh;
    padding: 0px;
    position: sticky;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 0px solid #dee2e6;
    padding-right: 32px;
    align-items: center;

    .email-Report {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 3%;

      img {
        margin-left: 5px;
        cursor: pointer;
        height: 20px;
        width: 20px;
      }

      h1 {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-top: 10%;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    button {
      img {
        color: white;
        margin-right: 13px;
        height: 14px;
        filter: invert(100%) contrast(200%) brightness(100%);
      }
    }

    .btn-Action {
      width: 9rem;
      border-radius: 2px !important;
      padding: 0px;
      background-color: #0075ff !important;
      color: #5711e2;
      background-color: none !important;
      color: white !important;
      border: 1px solid #5711e2;
      content: "▼" !important;
      margin-left: 20px;
    }

    .btn-Submit {
      background-color: #fcf2da;
      color: #000000;
      border: 1px solid #70707052;
    }
  }

  .btn-cancel {
    background-color: #b71c1c;
    float: left;
  }

  .btn-continue {
    background-color: #1b5e20;
    float: right;
  }
}

//   .modal-footer {
//     // background: #ffffff 0% 0% no-repeat padding-box;
//     // height: 54px;
//     // padding: 0px;
//     // position: sticky;
//     // display: flex !important;
//     // border-top: 0px solid #dee2e6;

//     // padding-right: 32px;
//     background: #ffffff 0% 0% no-repeat padding-box;
//     height: 10vh;
//     padding: 0px;
//     position: sticky;
//     display: flex !important;
//     flex-direction: row;
//     justify-content: space-between;
//     border-top: 0px solid #dee2e6;
//     padding-right: 32px;
//     align-items: center;
//     button {
//       img {
//         color: white;
//         margin-right: 13px;
//         height: 14px;
//       }
//     }

//     .btn-Action {
//       width: 9rem;
//       border-radius: 2px !important;
//       padding: 0px;
//       background-color: #674aff !important;
//       color: #5711e2;
//       background-color: none !important;
//       color: white !important;
//       border: 1px solid #5711e2;
//       content: "▼" !important;
//       display: flex;
//     }

//     .btn-Submit {
//       background-color: #fcf2da;
//       color: #000000;
//       border: 1px solid #70707052;
//     }
//   }

//   .btn-cancel {
//     background-color: #b71c1c;
//     float: left;
//   }

//   .btn-continue {
//     background-color: #1b5e20;
//     float: right;
//   }
// }

@media only screen and (max-width: 600px) {
  .modal-wrappers {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 10px #00000029;
    border: 1px solid #d7dbec;
    border-radius: 6px;
    // margin: 0 auto 0;
    transition: all 0.8s;
    width: 100vw;
    height: 100vh;
    // position: absolute;
    z-index: 9999;
    display: flex !important;
    flex-direction: column;
    // justify-content: space-between;
    top: 0%;
    left: 0%;
    right: 0px;
    position: fixed;

    .modal-bodyD {
      height: 36vh !important;
    }

    .inputTextSpace {
      padding: 10px;
      margin-left: 16px;
      margin-top: 0px !important;

      .inputBox {
        input {
          width: 90%;
        }
      }
    }
  }

  .modal-wrappers .modal-bodyD .leftCnt {
    height: 26vh !important;

    .inputBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 13px;

      .inputBox-label {
        margin: 6px;

        input[type="radio" i] {
          margin-right: 10px;
          accent-color: #3c88f7;
        }

        input[type="radio"]:after {
          background-color: #426bfa;
        }
      }
    }
  }

  .modal-wrappers .modal-bodyD .rightCnt {
    padding: 3%;
    margin-left: 2%;

    .inputBox {
      .typeDownload {
        label {
          margin-right: 20px;
          margin-top: 11px;
        }
      }

      .inputDateRange-asset {
        border: 1px solid #000000;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 3px;
        width: 50vw !important;
        height: 5vh;
        padding: 3px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .dateDivActive-asset {
      border: none;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px;

      .text {
        color: #ffffff;
        font-size: 11px;
        font-family: "Inter", sans-serif;
        margin-left: 5px;
        margin-right: 8px;
      }
    }
  }

  .modal-wrappers .modal-footer-asset {
    margin-bottom: 16vh;
  }

  .modal-wrappers .modal-footer-asset .btn-Action {
    width: 9rem;
    border-radius: 2px !important;
    padding: 0px;
    background-color: #674aff !important;
    color: #5711e2;
    background-color: none !important;
    color: white !important;
    border: 1px solid #5711e2;
    content: "▼" !important;
    margin-right: 62px;
    height: 36px;
  }

  .modal-wrappers .modal-header-style {
    display: flex;
    justify-content: space-between;
    height: 10vh;
    align-items: center;
    padding: 5px;

    .details h3 {
      font-size: 18px !important;
      font-weight: 600;
    }
  }

  .modal-wrappers .modal-footer-asset .btn-Submit {
    background-color: #fcf2da;
    color: #000000;
    border: 1px solid #70707052;
    height: 36px;
    width: 20vw;
    // margin-right: 56px;
    // width: 145px;
    // padding: 2px;
    // margin-right: 62px;
  }

  .modal-wrappers .modal-footer-asset {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 54px;
    padding: 0px;
    position: sticky;
    display: flex !important;
    border-top: 0px solid #dee2e6;
    padding-right: 32px;
    height: 93px;
  }

  .modal-bodyD {
    text-align: start;
    height: auto;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .modal-wrappers {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 10px #00000029;
    border: 1px solid #d7dbec;
    border-radius: 6px;
    margin: 0 auto 0;
    transition: all 0.8s;
    width: 75vw;
    position: absolute;
    z-index: 9999;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    top: 37%;
    left: 6%;

    .modal-bodyD {
      padding: 10px 0px 0px 14px;
      text-align: start;
      display: flex !important;
      flex-direction: row;
      height: 20vh !important;
      align-items: flex-start !important;
      justify-content: space-between !important;
    }
  }

  .modal-wrappers .modal-bodyD .rightCnt {
    padding: 3%;
    margin-left: 7%;

    .inputBox {
      .typeDownload {
        label {
          margin-right: 20px;
          margin-top: 11px;
        }
      }

      .inputDateRange-asset {
        border: 1px solid #000000;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 3px;
        width: 25vw !important;
        padding: 3px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .dateDivActive-asset {
      border: none;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px;

      .text {
        color: #ffffff;
        font-size: 11px;
        font-family: "Inter", sans-serif;
        margin-left: 5px;
        margin-right: 8px;
      }
    }
  }

  .modal-wrappers .modal-header-style {
    display: flex;
    justify-content: space-between;
    height: 7vh;
    padding: 6px;
  }
}

.dateDiv-asset {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  cursor: pointer;

  p {
    color: #000000;
    font-size: 12px;
    font-family: "Inter", sans-serif;
  }
}

.dateDivActive-asset {
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;

  .text {
    color: #ffffff;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    margin-left: 5px;
    margin-right: 8px;
  }
}

.warningText {
  color: #f50404;
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
}

.loaderStyle {
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: "center",
}