.mainContainerDiv {
    background-color: #ffffff;
    height: 75vh;
    padding: 15px 25px 30px 25px;
    overflow-y: scroll;
    border-radius: 6px;
    position: -webkit-sticky;
    overflow-y: scroll;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .viewReportHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        p {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
            padding: 0;
        }

        .searchView {
            border: 1px solid rgb(189, 203, 251);
            border-radius: 5px;
            width: 20vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px;
            padding: 4px 8px;
            margin: 0;
            padding: 3px 16px;

            input {
                border: none;
                outline: none;
            }

            .searchImg {
                width: 2vw;
                height: 2vh;
                align-self: center;
            }
        }
    }

    .reportDataMainView {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        background-color: rgba(240, 246, 254, 1);

        .viewReportCard {
            border: 1px solid rgba(189, 203, 251, 1);
            background-color: rgba(240, 246, 254, 1);
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px 10px 10px;


            .reportDetailsView {
                width: 80%;
                display: flex;
                flex-direction: column;
                font-size: 14px;
                margin: 0;
                padding: 0;

                .reportListHeader {
                    font-size: 14px;
                    color: #000;
                    font-weight: 500;
                    margin: 0;
                    padding: 0;
                    margin-left: 5px;
                }

                .reportListSubHeader {
                    font-size: 12px;
                    color: rgba(112, 112, 112, 1);
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                }
            }

            .downloadActionView {
                width: 20%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                align-self: center;

                .fileFormatSelectorDiv {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;
                    cursor: pointer;

                    p {
                        margin: 0;
                        padding: 0;
                        margin-left: 8px;
                    }
                }

                .selectedFileFormatDiv {
                    display: flex;
                    align-items: center;
                    grid-gap: 5px;
                    gap: 15px;
                    background-color: none;
                    border-radius: 4px;
                    padding: 3px 7px 3px 7px;
                    border: none;
                    cursor: pointer;

                    .fileFormatText {
                        margin: 0px;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }

                .activeSelected {
                    background-color: #ffffff;
                    border: 1px solid #000000;
                }


            }

            .reportDataFileType {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 40px;
            }
        }

        .downloadReportPopUp {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border: 1px solid rgb(191, 214, 250);
            background-color: rgb(255, 255, 255);
            width: 100%;
            padding: 8px 8px 8px 8px;

            .columnRightDiv {
                display: flex;
                flex-direction: row;

                // .inputMainContainer {
                //     display: flex;
                //     flex-direction: column;
                //     margin-right: 16px;

                //     .inputHeading {
                //         padding: 0;
                //         margin: 0;
                //         font-size: 14px;
                //         font-weight: 500;
                //         color: rgba(27, 27, 27, 1);
                //     }

                //     .textInputCont {
                //         display: flex;
                //         flex-direction: row;
                //         background-color: rgba(255, 255, 255, 1);
                //         border: 1px solid rgba(167, 184, 244, 1);
                //         max-width: fit-content;
                //         padding: 4px 16px;
                //         margin-top: 4px;
                //         border-radius: 4px;

                //         .textInputStyle {
                //             border: 0px;
                //             outline: none;
                //         }

                //         .temperatureSymbol {
                //             margin: 0;
                //             padding: 0;
                //             margin-left: 8px;
                //         }
                //     }
                // }

            }

            .columnLeftDiv {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0;

                .downloadButton {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgba(87, 17, 226, 1);
                    background-color: rgba(0, 117, 255, 1);
                    padding: 3px 10px;
                    height: fit-content;
                    border-radius: 4px;
                    margin-right: 16px;
                    cursor: pointer;

                    p {
                        margin: 0;
                        padding: 0;
                        color: rgba(255, 255, 255, 1);
                        font-size: 14px;
                        font-weight: 400;
                        margin-left: 8px;
                    }
                }

                .inActivedownloadButton{
                    opacity: 0.3;
                    cursor: not-allowed ;
                }

                .closeIcon {
                    cursor: pointer;
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }
}

.pagination_value {
    position: sticky;
    bottom: -1px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 25%;

    button {
        min-width: 5vw;
        border: navajowhite;
        font-size: 10px;
        font-weight: 400;
        margin: 0px 0px 0px 0px;
        font-family: "Inter", sans-serif !important;
        height: 6vh;
        border-radius: 30px;
        background-color: rgba(4, 112, 223, 0);
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        span {
            font-size: 10px;
            margin-left: 8px;
            font-weight: 500;
            margin-right: 8px;
        }
    }

    h3 {
        font-size: 20px;
        margin-left: 0px;
        font-weight: 500;
    }
}


.centerDivCss {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContainerDiv::-webkit-scrollbar{
    width: 8px;
}