.changePasswordContainer {
  padding: 4%;
  .headerDivContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p{
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      font-family: bold;
    }img {
      margin: 0;
      padding: 0;
      height: 5%;
      width: 5%;
    }
  }
  .bodyDivContaier {
    margin-top: 2%;
    margin-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    .userTextInput {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      margin-top: 10px;
    p{
      font-size: 1.1rem;
      color: rgb(9, 9, 9);
      margin: 0;
      padding: 0;
    }
    div{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 60%;
    div{
        display: flex;
        flex-direction: row;
        border: 2px solid #BDCBFB;
        border-radius: 4px;
        width: 100%;
        height: 80%;
      input {
        border: none;
        outline: none;  
        width: 90%;
        height: 100%;
        padding-left: 1%;
      }:focus {
        outline: none;
      }
      .visiblePasswordIcon{
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        img {
          width: 60%;
          height: 50%;
        }
      }
      
    }
    p{
      color: #6D7073;
      height: 20%;
      margin: 0;
      padding: 0;
      font-size: 0.7rem;
      margin-top: 1%;
    }
  }
  }
  .validateWarningMessage {
    display: flex;
    flex-direction: row;
    padding-left: 40%;
    p{
      font-size: 0.8rem;
      color: #fa0505;
      margin: 0;
      padding: 0;
      margin-top: 3px;
    }
  }
  }
  .footerDivContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 2%;
    div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .closeButton {
        font-size: 0.8rem;
        color: #000000;
        border-radius: 4px;
        border: 1.046px solid #CFC8B8;
        background: #FCF2DA;
        padding: 5px;
      }
      .changePasswordButton {
        font-size: 0.8rem;
        color: #FFFFFF;
        border-radius: 4px;
        border: 1.046px solid #5711E2;
        background: #0075FF;
        padding: 5px;
        margin-left: 8px;
      }
      .invalidPassword {
        opacity: 0.3;
      }
    }
  }
}


@media only screen and (min-width: 281px) and (max-width: 600px) {
  .changePasswordContainer {
    .headerDivContainer {
      p{
        font-size: 0.9rem;
      }
      img {
        height: 8%;
        width: 8%;
      }
    }
    .bodyDivContaier {
      margin-top: 3px;
      .userTextInput {
        height: 33px;
        margin-top: 8px;
      p{
        font-size: 0.8rem;
      }
      div{  
      div{  
        input {}
        .visiblePasswordIcon{ 
          img {}
        }
      }
      p{
        font-size: 0.6rem;
      }
    }
    }
    .validateWarningMessage {
      p{
        font-size: 0.6rem;
        margin-top: 1px;
      }
    }
    }
    .footerDivContainer {
      div{
        .closeButton {
          font-size: 0.6rem;
        }
        .changePasswordButton {
          font-size: 0.6rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 280px) {
  .changePasswordContainer {
    .headerDivContainer {
      p{
        font-size: 0.8rem;
      }
    }
    .bodyDivContaier {
      margin-top: 3px;
      .userTextInput {
        height: 30px;
        margin-top: 6px;
      p{
        font-size: 0.6rem;
      }
      div{
      p{
        font-size: 0.5rem;
      }
    }
    }
    .validateWarningMessage {
      p{
        font-size: 0.6rem;
        margin-top: 0;
      }
    }
    }
    .footerDivContainer {
      div{
        .closeButton {
          font-size: 0.6rem;
          padding: 3px;
        }
        .changePasswordButton {
          font-size: 0.6rem;
          padding: 3px;
        }
      }
    }
  }
}