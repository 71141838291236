.alertsContainer_style {
    min-height: 16vh;
    border: 1px solid #ccc;
    margin: 0px;
    // border-left: 5px solid #fa0808;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
        margin-right: 10px;
   
    .icon{
        img{
            width: 30px;
            margin-right: 12px;
        }
    }

    .left_alerts {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;

        h5 {
            margin: 0;
            font-size: 10px;
            font-weight: 600;
           
        }

        p {
            margin: 0;
            font-size: 10px;
            margin-top: 5px;
            margin-bottom: 2px;
            span{
                padding: 2px;
                background-color: #ffd2cb;
                border: 1px solid #ffd2cb;
                border-radius: 7px;
                font-size: 8px;
                margin-left: 8px;
                color: #000000;
                padding-left: 10px;
                padding-right: 10px;
            }

        }
    }

    .right_alerts {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        p {
            margin: 0;
            font-size: 10px;
            font-weight: 600;
        }

        .faEyeIcon {
            font-size: 0.6rem !important;
            margin: auto;
            width: 30px;
            height: 10px;
            padding: 10px;
        }

        .hoverData {
            position: absolute;
            margin-top: 15%;

            .h5 {
                font-size: 9px;
                font-weight: 500;
                background-color: white;

            }
        }
    }
}


.image-container {
    position: relative;
    overflow: hidden;
  }
  
  .image-container:hover .faEyeIcon {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  .image-container:hover .image-text {
    opacity: 1 !important;
  }
  
  .image-container .faEyeIcon {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .image-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #000000;
    font-size: 16px;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: #6d9ac9;
    width: auto;
    color: white;
  }

  abbr {
    font-style: italic;
    // color: chocolate;
}


.assetCart__scss_H {
    border-left: 5px solid rgb(250, 8, 8);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: auto;
    cursor: default;
    border-left: 5px solid rgb(250, 8, 8);
    border-top: 1px solid #e3e9ff;
    border-right: 1px solid #e3e9ff;
    border-bottom: 1px solid #e3e9ff;
    margin: 10px;

    div {
        .flex_row_H1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            font-family: sans-serif !important;
            margin-left: 10px;

            .row_1 {
                h5 {
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin: 0;
                    margin-top: 5px;
                }

                h6 {
                    margin: 0;
                    font-size: 11px;
                    font-weight: 500;
                    color: #949494;

                    span {
                        padding: 2px;
                        background-color: #ffd2cb;
                        border: 1px solid #ffd2cb;
                        border-radius: 7px;
                        font-size: 8px;
                        margin-left: 8px;
                        color: #000000;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                h4 {
                    margin: 0;
                    font-size: 11px !important;
                    font-weight: 400 !important;
                    color: #000000 !important;
                    min-width: 7vw;
                    text-align: center;
                }

                h2 {
                    color: #5b6066;
                    text-align: left;
                    font-size: 10px !important;
                    line-height: 0.8rem !important;
                    margin: 0 !important;
                    font-weight: 400 !important;
                }
            }
        }

        img {
            width: 3vw;
        }
    }


}