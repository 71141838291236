.errorSignText {
  color: "red";
  font-size: 14px;
}

.lottieContainer {
  height: 200px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.alert {
  color: #ffffff;
  background-color: #3a76f4cf;
  border-color: #e5e7f9;
}

.alert-warnings {
  color: #ffffff;
  background-color: #5a8cf5;
  border-color: #5a8cf5;
  position: absolute !important;
  top: 20%;
  left: 7%;
  padding: 0.8rem !important;
  width: 20%;

  button {
    margin-left: 10px;
    border: 1px solid #fff;
    background: #5a8cf5;
    color: white;
    border-radius: 7px;
  }
}

.container {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 100vw !important;
  min-height: 100vh !important;

  .row {
    margin: 0px;
    padding: 0px;
    height: 100vh;
    width: 100%;
    background-color: #e5e7f9;
    display: flex;
    flex-direction: row;

    .subclassOne {
      text-align: center;
      flex-direction: row;
      justify-content: center;
      border-color: #1f2c47;
      padding: 0rem;

      .selectClassname {
        font-size: 15px;
        padding: 15px;
        display: flex;
        flex-direction: row;
        margin-right: 1.5rem;
        margin: 1.5rem;
        justify-content: end;

        select {
          padding: 6px;
          font-size: 10px;
          border: 2px solid #ccc;
        }
      }

      .subContent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 15%;
        margin-right: 15%;
        .loginContent {
          margin-top: 20vh;
          text-align: left;
          h1 {
            color: #1f2c47;
            font-size: 0.8rem;
            font-weight: bold;
          }

          h6 {
            color: #3a76f4;
            font-size: 1.3rem;
            font-weight: 600;
          }

          p {
            color: #1f2c47;
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: 0px;
          }

          .userInput {
            margin-top: 4%;
            width: 100%;
            background-color: #FFFFFF;
            box-shadow: 1px 1px 1px 1px #dde1e7;
            border-radius: 2px;
            padding: 8px;
            border: none;
            outline: none;
            padding-left: 3%;
            margin-bottom: 5%;
          }

          .radioMainContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding: 5%;
            width: 20vw;
            .radioInputContainer {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              input {
                margin-right: 15%;
              }
              p{
                font-size: 0.8rem;
                margin: 0;
                padding: 0;
                color: #182F43;
              }
            }
          }

          .otpTextSelection {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 5%;
            text-align: center;
            p{
              margin: 0;
              padding: 0;
              font-size: 0.9rem;
              text-align: center;
              font-family: sans-serif;
              color: #182F43;;
            }
          }

          .emailListContainerMsg {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            border-radius: 3px;
            background: #E6FEC8;
            padding: 3%;
            text-align: center;
            margin-bottom: 4%;
            p{
              margin: 0;
              padding: 0;
              font-size: 0.8rem;
              color: #2d2b2b;
              font-family: sans-serif;
            }
          }

          .counterDivContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3%;
            height: 5vh;
            .resendotpText {
              color: #3a76f4;
              text-decoration: underline;
              font-size: 0.9rem;
              cursor: pointer;
              margin: 0;
              padding: 0;
            }
            p {
              color: #fc0000;
              font-size: 0.8rem;
              margin: 0;
              padding: 0;
            }
          }

          .otpInputContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-top: 3%;
            padding-bottom: 5%;
            input {
              background-color: #FFFFFF;
              height: 45px;
              width: 45px;
              border: none;
              outline: none;
              margin-right: 5%;
              font-size: 1rem;
              font-family: sans-serif;
              border-radius: 3px;
              padding-left: 18px;
              box-shadow: 1px 1px 1px 1px #dde1e7;
            }:focus {
              outline: none;
            }
          }

          .passwordInputPageContainer {
            .passwordInputField {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              background-color: #FFFFFF;
              border: 2px solid #D1D1D1;
              padding: 2%;
              margin-top: 2%;
              margin-bottom: 3%;
              border-radius: 3px;
              box-shadow: 1px 1px 1px 1px #dde1e7;
              input {
                width: 80%;
                border: none;
                background-color: #FFFFFF;
                outline: none;
              }:focus{
                outline: none;
              }
              .passwordIcon {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }

          .inputText {
            border: 1px solid #426bfa;
            padding: 8px;
            border-left: 8px solid #426bfa;
            width: 100%;
            color: -internal-light-dark(#426bfa, white) !important;
            margin-top: 10px;
            box-shadow: 1px 1px 1px 1px #dde1e7;
            outline: none;
          }

          .inputTextOne {
            border: 1px solid #426bfa;
            background-color: #fff;
            border-left: 8px solid #426bfa;
            width: 100%;
            color: -internal-light-dark(#426bfa, white) !important;
            margin-top: 10px;
            box-shadow: 1px 1px 1px 1px #dde1e7;
            .inputTextInside {
              padding: 8px;
              border-width: 0px;
              border-color: #fff;
              width: 90%;
              outline: none;
            }
            div {
              width: 10%;
              margin-top: 3%;
              .eyeOn {
                margin-top: 10%;
                height: 18px;
                width: 20px;
              }
              .eyeOff {
                margin-top: 10%;
                height: 12px;
                width: 20px;
              }
            }
          }

          .bottomClass {
            .changePassword {
              background: none;
              border: none;
              font-size: 14px;
              font-weight: 600;
              position: relative;
              bottom: 15px;
            }

            .forgotPasswordContainer {
              margin-top: 2%;
              margin-bottom: 2%;
              text-align: right;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              margin-right: 18%;
              p{
                font-size: 0.8rem;
                font-weight: 500;
                cursor: pointer;
                color: #3a76f4;
                margin: 0;
                padding: 0;
              }
            }
            
            input {
              margin-top: 8px;
            }

            label {
              font-size: 12px;
              margin-left: 2px;
            }

            a {
              font-size: 12px;
              position: relative;
              left: 43px;
              text-decoration: none;
            }
          }
        }

        .signInbutton {
          margin-top: 1rem;
          color: white;
          background-color: #3a76f4;
          width: 100%;
          height: 38px;
          border-radius: 2px;
          border-width: 0px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .signInbuttonOpacity {
          color: white;
          background-color: #3a76f4;
          width: 100%;
          height: 38px;
          border-radius: 2px;
          border-width: 0px;
          margin-bottom: 9px;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.5;
          cursor: not-allowed;
        }
        .errorMessageContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 3.5vh;
          p{
            margin: 0;
            padding: 0;
            font-size: 0.8rem;
            font-family: 500;
            color: #fc0000;
            font-family: sans-serif;
          }
        }
        .redirectLoginDiv {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-top: 8%;
          p {
            margin: 0;
            padding: 0;
            font-size: 0.9rem;
            color: #426bfa;
            font-family: sans-serif;
            text-decoration: underline;
          }
        }
        a {
          font-size: 13px;
          font-weight: 700;
          text-decoration: none;
        }
      }

      .redirectLoginPage {
        margin-top: 30vh;
        .msgDicContainer {
          display: flex;
          flex-direction: row;
          text-align: center;
          p{
            margin: 0;
            padding: 0;
            text-decoration: underline;
            color: #42AD46;
            font-weight: 700;
            font-size: 0.9rem;
            margin-left: 5px;
          }
        }
        .msgDicContainerOne{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-top: 5vh;
          p{
            margin: 0;
            padding: 0;
            font-size: 0.8rem;
            color: #707070;
          }
        }
      }

      .atsuyaClass {
        font-size: 12px;
        position: absolute;
        bottom: 0px;
        left: 3%;
      }
    }

    .subclassTwo {
      background-image: url("../../assets/icons/login-bg.png");
      padding: 2rem;
      border-left: 3px solid #d7d9db;
      background-position-x: 94%;
      background-position-y: 86%;
      background-size: 60%;
      background-repeat: no-repeat;
      border-left: 3px solid #d7d9db;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .headContainer {
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15vh;

        img {
          height: 5vh;
          width: 12vw;
        }
      }

      .middleContainer {
        margin-top: -30vh;
        display: flex;
        flex-direction: row;

        div {
          overflow: hidden;
          flex-direction: row;
          justify-content: left;
          height: auto;
          width: 45vh;

          h2 {
            font-size: 2rem !important;
          }

          p {
          }
        }

        .imgdivContainer {
          //background-position: 50% 50%;
          // height: 63vh;
          // width: 80vw;
          // background-size: cover;
          // margin-left: 0rem;
        }
      }

      .buttomContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: -9rem;
        justify-content: space-between;

        .sosimgClss {
          justify-content: start;

          img {
            height: 60px;
            width: 100px;
          }
        }

        .atsuyaClass {
          justify-content: space-around;
          display: flex;

          div {
            flex-direction: row;
            display: flex;
            padding: 0rem 1.2rem;
            align-items: flex-end;

            .privacyClass {
              font-size: 0.8rem;
              margin-left: 1rem;
              opacity: 0.5;
            }

            .privacyClass:hover {
              opacity: 1;
              text-decoration: underline;
              text-decoration-color: #3a76f4;
            }

            .termsClass {
              font-size: 0.8rem;
              margin-left: 1rem;
              opacity: 0.5;
            }

            .termsClass:hover {
              opacity: 1;
              text-decoration: underline;
              text-decoration-color: #3a76f4;
            }
          }

          img {
            height: 60px;
            width: 100px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    overflow: scroll !important;

    .row {
      .subclassOne {
        .subContent {
            margin-left: 5%;
            margin-right: 5%;
          .loginContent{
            text-align: center;
            h6{
              font-size: 1rem;
            }
            p{
              font-size: 0.9rem;
            }
            //#777777
            .inputText {
              padding: 5px;
            }
  
            .inputTextOne {
             
              .inputTextInside {
                padding: 5px;
              }
              div {
                .eyeOn {}
                .eyeOff {}
              }
            }

            .radioMainContainer {
              padding: 5%;
              width: 60vw;
              .radioInputContainer {
                input {}
                p{}
              }
            }

            .otpTextSelection {
              padding-top: 3%;
              padding-bottom: 3%;
              p{
                font-size: 0.9rem;
              }
            }
  
            .counterDivContainer {
              .resendotpText {
                font-size: 0.9rem;
              }
              p {
                font-size: 0.8rem;
              }
            }
  
            .otpInputContainer {
              input {
                background-color: #FFFFFF;
                height: 35px;
                width: 35px;
                padding-left: 15px;
                font-size: 0.8rem;
              }
            }

          }
          .signInbutton {
            height: 30px;
            p{
              font-size: 0.6rem;
            }
          }
          .signInbuttonOpacity {
            height: 30px;
            p{
              font-size: 0.6rem;
            }
          }
        }
        .atsuyaClass {
          font-size: 12px;
          bottom: 0%;
        }
      }

      .subclassTwo {
        display: none;
      }
    }
  }

  .alert-warnings {
    position: absolute !important;
    top: 20%;
    left: 7%;
    padding: 0.8rem !important;
    width: 85%;
  }
}

@media screen and (max-width: 961px) and (min-width: 641px) {
  .container {
    overflow: scroll !important;

    .row {
      .subclassOne {
        .subContent {
            margin-left: 5%;
            margin-right: 5%;
          .loginContent{
            h6{
              font-size: 1rem;
            }
            p{
              font-size: 0.9rem;
              text-align: start;
            }
            //#777777
            .inputText {
              padding: 5px;
            }
  
            .inputTextOne {
             
              .inputTextInside {
                padding: 5px;
              }
              div {
                .eyeOn {}
                .eyeOff {}
              }
            }

            .radioMainContainer {
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 5%;
              //width: 60vw;
              .radioInputContainer {
                input {}
                p{}
              }
            }
          }
        }
        .atsuyaClass {
          font-size: 12px;
          bottom: 0%;
        }
      }

      .subclassTwo {
        display: none;
      }
    }
  }
}
