.incidentLoadMoreCardContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  .incidentLoadMoreBtnCont {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .incidentLoadMoreBtn {
      background-color: #0075ff;
      padding: 3px 15px 3px 15px;
      border-radius: 25px;
      border: none;
      color: #ffffff;
      font-size: 13px;
    }
  }
}
