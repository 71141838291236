.loaderStyle {
    display: flex;
    align-items: center;
    height: 20vh;
    justify-content: center;
}

.energyDistributionMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .selectorContainer {
        display: flex;
        flex-direction: column;
        margin-top: 18px;
        position: relative;

        .selectInputDiv {
            border: 1px solid #BDCBFB;
            width: 30%;
            padding: 2px 5px 2px 5px;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .selectedList {
                margin: 0px;
                font-size: 14px;
                font-weight: 500;
            }

            .listIcon {
                height: 10px;
                width: 10px;
            }
        }

        .selectorList {
            border: 1px solid #BDCBFB;
            width: 30%;
            list-style-type: none;
            padding: 0px;
            border-radius: 5px;
            position: absolute;
            top: 30px;
            background-color: white;
            z-index: 1;
            overflow-y: scroll;
            height: 13vh;

            li {
                font-size: 14px;
                font-weight: 500;
                padding: 2px 0px 2px 5px;
                border-bottom: 1px solid #BDCBFB;
            }
        }

        .selectorList::-webkit-scrollbar {
            display: none;
        }
    }

    .backButtonCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        grid-gap: 10px;
        gap: 5px;
        padding-right: 15px;
        margin-top: 20px;

        .shankiHeaders {
            margin: 0px;
            font-size: 16px;
            font-weight: 600;
            padding-left: 25px;
        }

        .arrowBtnCont {
            display: flex;
            flex-direction: "row";
            align-items: center;

            .backChartIcon {
                height: 15;
                width: 15;
            }

            .backText {
                margin: 0px;
                font-size: 14px;
                font-weight: 500;
            }
        }


    }

    .chartContainer {
        width: 100%;
        transition: width 0.5s ease;
        height: 40vh;
    }
}


//Energy Balance Popup

.energyBalanceSettingsPopup {
    div {
        // width: 40vw;
        border: none;
        // margin-right: 200px;

    }

    .energyBalancePopupHeader {
        display: flex;
        flex-direction: row;
        // background-color: #0075FF;
        align-items: center;
        justify-content: space-between !important;
        // padding: 10px 20px 10px 20px;
        padding: 15px 30px 10px 30px;
        // border: 1px solid black;

        .popupHeaderTextCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            // border: 1px solid black;
            .settingsIcon {
                height: 15px;
                width: 15px;
            }

            .energyBalanceHeaderText {
                font-size: 18px;
                font-weight: 700 !important;
                color: black;
                margin: 0px;
            }
        }

        .closeIconDiv {
            margin: 0;
            height: 30px;
            // width: 63px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            img {
                cursor: pointer;
                height: 12px;
                width: 15px;
            }
        }

        .closeIconDiv:hover {
            background-color: #d3d3d3;
            cursor: pointer;
        }
    }

    .energyBalancePopupBody {
        // border: 1px solid green !important;
        display: flex;
        flex-direction: column;

        .selectorContainer {
            margin: 0px;
            display: flex;
            flex-direction: column;
            grid-gap: 15px !important;
            gap: 15px !important;
            border-bottom: 1px solid rgb(202, 236, 250) !important;
            padding: 12px 122px 12px 40px;

            .selectOptionContainer {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin: 0;
                box-sizing: border-box;


                .readingsText {
                    margin: 0px;
                    font-size: 16px;
                    font-weight: 500;
                    color: black;
                }

                .selectOption {
                    border: 1px solid #5038F5;
                    color: #5038F5;
                    font-size: 12px;
                    width: 12%;
                    border-radius: 14px;
                    background-color: transparent;
                    margin-right: 12.8%;
                }

                .popupSelectorContainer {
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    align-items: flex-end;
                    justify-content: center;
                    min-width: 50%;

                    .selectInputDiv {
                        margin: 0;
                        border: 1px solid #BDCBFB;
                        width: 69%;
                        padding: 2px 5px 2px 5px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .selectedList {
                            min-width: 50%;
                            margin: 0px;
                            font-size: 14px;
                            font-weight: 500;
                        }

                        .listIcon {
                            height: 10px;
                            width: 10px;
                        }
                    }

                    .selectorList {
                        border: 1px solid #BDCBFB;
                        width: 69%;
                        list-style-type: none;
                        padding: 0px;
                        border-radius: 5px;
                        position: absolute;
                        top: 30px;
                        background-color: white;
                        z-index: 1;
                        overflow-y: scroll;
                        height: 8.9vh;

                        li {
                            font-size: 14px;
                            font-weight: 500;
                            padding: 2px 0px 2px 5px;
                            border-bottom: 1px solid #BDCBFB;
                        }
                    }

                    .selectorList::-webkit-scrollbar {
                        display: none;
                    }

                    .defaultSelectorList {
                        border: 1px solid #BDCBFB;
                        width: 69%;
                        list-style-type: none;
                        padding: 0px;
                        border-radius: 5px;
                        position: absolute;
                        top: 30px;
                        background-color: white;
                        z-index: 1;
                        overflow-y: scroll;
                        height: 17vh;

                        li {
                            font-size: 14px;
                            font-weight: 500;
                            padding: 2px 0px 2px 5px;
                            border-bottom: 1px solid #BDCBFB;
                        }
                    }

                    .defaultSelectorList::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }

    .energyBalancePopupFooter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 14px 30px 20px 30px;

        .resetButtonCont {
            margin: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;

            .resetIcon {
                height: 15px;
                width: 15px;
            }

            .resetText {
                margin: 0px;
                font-size: 14px;
                font-weight: 400;
                color: gray;
            }
        }

        .buttonsCont {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 0px;
            gap: 10px;

            .cancelBtn {
                border: 1px solid #CFC8B8;
                padding: 5px 15px 5px 15px;
                background-color: #FCF2DA;
                font-size: 14px;
                font-weight: 500;
                color: black;
                border-radius: 3px;
            }

            .applyBtn {
                border: 1px solid #0075FF;
                padding: 5px 15px 5px 15px;
                background-color: #0075FF;
                font-size: 14px;
                font-weight: 500;
                color: white;
                border-radius: 3px;
            }
        }
    }

}

li {
    cursor: pointer;
}

li:hover {
    background-color: #e5f0f8;
}

.energyBalanceSettingsPopup{
    .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto;
        min-width: 50vw;
    }
}