.organisationDiv {
  display: flex;
  //margin-right: 25px;
  flex-wrap: wrap;
}

.orgCardCont {
  height: 60%;
  overflow-y: scroll;
}

.orgCardCont::-webkit-scrollbar {
  display: none;
}

.orgFilterDiv {
  height: 13% !important;
}

.organisationCard:hover {
  border: 1px solid #2680eb;
  box-shadow: 2px 2px 8px rgba(229, 229, 229, 0.56);
  cursor: pointer;
}

.height100 {
  height: 100%;
}

.displayGrid {
  display: grid;
}

.organisationCard {
  width: 12%;
  position: relative;
  display: block;
  min-width: 250px;
  margin: 0 5px 10px 5px;
  background: #ffffff;
  border: 1px solid #bdcbfb;
  border-radius: 12px;
  padding-bottom: 6px;
  cursor: pointer;
}

.orgFooterContentDiv {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadMoreBtn {
    border: none;
    background-color: #0075ff;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 40px 3px 40px;
    border-radius: 20px;
  }
  .loadingBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: #0075ff;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 40px 3px 40px;
    border-radius: 20px;
    .loaderAnimation {
      position: relative;
      border: 1px solid #f3f3f3;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border-top: 1px solid #3498db;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      left: 10px;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media (max-width: 2000px) {
  .organisationCard {
    width: 19%;
  }
}

@media (max-width: 1440px) {
  .organisationCard {
    width: 23%;
  }
}

@media (max-width: 1110px) {
  .organisationCard {
    width: 30%;
  }
}

@media (max-width: 850px) {
  .organisationCard {
    width: 46%;
  }
}

@media (max-width: 550px) {
  .organisationCard {
    width: 96%;
  }
}

.imageCard {
  background: #ffffff;
  border: 1px solid #bdcbfb;
  width: 2.792rem;
  height: 2.792rem;
  //margin-left: 20px;
  border-radius: 0;
}

.logoImage {
  width: 2.792rem;
  height: 2.792rem;
}
.organisationsCheckbox {
  margin: 15px 0 0 20px !important;
  width: 0.958rem !important;
  height: 0.958rem !important;
  border-radius: 2em !important;
  cursor: pointer;
}

.enabledDiv {
  margin-left: auto;
  display: flex;
  flex-direction: row;

  .enabledText {
    margin-top: 15px;
    margin-right: 3px;
    width: 3.33rem;
    height: 0.917rem;
    font-size: 0.521rem;
    padding-top: 0.146rem;
    font-weight: 600;
    padding-left: 7.5px;
    background: #aadeac;
    border-radius: 40px;
  }
}

.userExportBtn {
  background-color: #fcf2da;
  border: 0.5px solid #cfc8b8;
  font-size: 13px;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;

  img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}

.partnerDiv {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.083rem 0.417rem;
  background: #dfe6ff;
  border-radius: 5px;
}

.orgNameDetails {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: -7px;
}

.orgCardTop {
  display: flex;
  flex-direction: row;
}
.orgCardTop .organisationsCheckbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  position: relative; 
  outline: none;
  cursor: pointer;
}

.orgCardTop .organisationsCheckbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #fff; 
}
.orgCardTop .organisationsCheckbox:checked {
  background-color: #007bff;
  border-color: #007bff;
  
}


.organisationCardsOptions {
  margin-top: 14px;
  margin-right: 20px;
  width: 25px;
}

.orgCardMiddle {
  display: flex;
  flex-direction: row;
  //  margin-top: 8px;
  padding-left: 20px;
  width: 100%;
}

.addOrgCardMiddle {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;
}

.orgMiddleContent {
  margin-top: 10px;
  margin-left: 10px;
}

.orgName {
  margin: 0;
  font-size: 0.708rem;
  text-transform: uppercase;
  color: #2e8ece;
  font-weight: 600;
  word-break: break-all;
}

.orgEmail {
  font-size: 0.583rem;
  color: #131523;
  font-weight: 400;
}

.orgBottomContent {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 6px;

  img {
    width: 0.467rem;
    height: 0.549;
  }
}

.orgType {
  margin-left: 4px;
  margin-top: 8px;
  font-size: 0.583rem;
  color: #707070;
  font-weight: 400;
}

.popOver {
  margin-right: 10px !important;
  margin-top: -20px;
  width: 140px;
  padding-top: 2px;
  padding-left: 8px;
  border: 1px solid gray !important;

  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    margin-left: -8px;

    li {
      font-size: 11px;
      border-bottom: 1px solid gray;
      padding: 3px 0px 3px 15px;
      cursor: pointer;
    }
    li:hover {
      background-color: #e7ecff;
      cursor: pointer;
    }
  }
}

.viewOrgDetailsBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.viewOrgDetailsParentRow {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.viewOrgDetailsRow {
  width: 46%;
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  align-items: flex-start;
}

.viewOrgDetailsKey {
  width: 40%;
  color: gray;
  font-size: 0.833rem;
}

.viewOrgDetailsValue {
  color: #000000;
  width: 55%;
  font: inherit;
  font-weight: 450;
  font-size: 0.833rem;
}

.adminInfoHeader {
  font-weight: 500;
  font-size: 0.833rem;
  line-height: 27px;
}

.orgDeleteButton {
  background-color: #ff5539;
  border: 1px solid #ff5539;
  font-size: 12px;
  border-radius: 3px;
  padding: 7px 15px 7px 15px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 40px !important;
  margin-bottom: 20px !important;

  img {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }
}

.editBtnOrg {
  background-color: #ffffff;
  border: 1px solid #000000;
  cursor: pointer;
  font-size: 12px;
  border-radius: 3px;
  padding: 7px 15px 7px 15px;
  font-weight: 400;
  margin-bottom: 20px !important;

  img {
    height: 14px;
    width: 14px;
    margin-right: 7px;
  }
}

.popUpCloseButton {
  margin-left: auto;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: #d3d3d3;
    cursor: pointer;
    border-radius: 50%;
  }

  .closeIcon {
    width: 15px;
    height: 15px;
  }
}

.orgCardHeader {
  width: 100%;
  border-bottom: 1px solid lightgrey;
  margin-left: 0;
  margin-right: 25px;
  padding-bottom: 10px;
  border-radius: 0px !important;
}

.noBorder {
  margin-left: 35px;
  margin-right: 10px;
  border-bottom: 0 none !important;
  border-top: 0 none !important;
}

.noBorderNoMargin {
  border: none !important;
}

.viewOrgModalPop {
  div {
    border-radius: 15px;
  }
}

.cursorPointer {
  cursor: pointer;
}

.shrinkMarginTop30 {
  // margin-top: -10px;
  height: 10vh;
  padding-top: 0% !important;
  // background-color: #0075ff;
}

.shrinkMarginTop10 {
  margin-top: -10px;
}

.deletedOrgModal {
  padding: 20px;
}

.deleteOrgBody {
  padding-left: 30px;
  padding-right: 30px;
}

.deleteOrgHeader {
  padding-top: 20px;
}

.deleteOrgFooter {
  padding-bottom: 20px !important;
  padding-right: 25px !important;
}

.facilitySettingsCardsCont1 {
  height: 7%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  .facilitySettingsCardsNav {
    display: flex;
    flex-direction: row;
    height: 100%;
    border: 1px solid #bdcbfb;
    width: 94%;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
    position: relative;
    left: 15px;
    border-radius: 5px;

    p {
      font-size: 12px;
      margin: 0;
      font-weight: 500;
    }

    .facilitySettingsNavBtnsCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .selectAllBtn {
        padding: 3px 10px 3px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
        color: #000000;
      }

      .deselectAllBtn {
        padding: 3px 10px 3px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
        color: #000000;
      }

      .addUserBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #017aff;
        color: #ffffff;
        border-radius: 3px;
        font-size: 12px;
      }

      .disableBtn {
        padding: 3px 10px 3px 10px;
        background-color: #fbe6b5;
        border: 1px solid #cfc8b8;
        border-radius: 3px;
        font-size: 12px;
        color: #000000;

        img {
          margin-top: -4px;
          height: 13px;
          width: 13px;
        }
      }

      .deleteBtn {
        padding: 3px 10px 3px 10px;
        background-color: #ed381b;
        border: 1px solid #ea5037;
        border-radius: 3px;
        font-size: 12px;
        color: #ffffff;

        img {
          margin-top: -4px;
          height: 15px;
          width: 13px;
        }
      }
    }
  }
}
