.tileTypeOne {
  background-color: #f0f3ff;
  border-radius: 5px;
  min-height: 90px;
  padding: 10px 5px 0px 6px;
  margin-bottom: 6px;
  &-img {
    background-color: #fff;
    width: 26px;
    height: 26px;
    padding: 1px 8px;
    border-radius: 26px;
    margin-right: 4px;
    img {
      width: 11px;
    }
  }
  &-row {
    display: flex;
  }
  h2 {
    font-weight: 700 !important;
    font-size: 16px !important;
    margin-bottom: 0px;
    line-height: 20px !important;
  }
  h4 {
    margin-bottom: 3px;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  p {
    font-size: 10px !important;
    font-weight: 500 !important;
    margin-bottom: 0px;
    &.less {
      svg {
        background-color: #4caf50;
      }
    }
    &.more {
      svg {
        background-color: #ff6b2d;
      }
    }
    svg {
      color: #fff;
      padding: 1px 2px;
      border-radius: 6px;
      font-size: 8px;
      margin-right: 2px;
    }
  }
}
