.CustomSelect {
    .custom-select {
        outline: none;
        border-width: 2px;
        border: 2px solid #dde2fc;
        width: 25vw;
        margin: 0px 0px 10px 0px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 4px;
    }
    .options-container {
        position: absolute;
    border: 1px solid #a7b8f4;
        background-color: #ffffff;
        width: 25vw;
        max-height: 200px;
        overflow-y: scroll;
        .option {
            margin: 0;
            padding: 5px;
            border-bottom: 1px solid #a7b8f4;
                border-left: 1px solid #a7b8f4;
                border-right: 1px solid #a7b8f4;
            .optionText {
                font-size: 14px;
                margin: 0px;
                padding: 0px;
                margin-left: 1vw;
                cursor: pointer;
            }
        }
    }
}