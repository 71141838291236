.a1DatePicker {
  display: inline-block;
  position: relative;
  &-drawer {
    background-color: #fff;
    position: absolute;
    top: 33px;
    left: 10px;
    z-index: 99999;
  }
  &-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    > .btn {
      background-color: transparent;
      border: 0px !important;
      margin-left: 10px;
      &:not(&:first-child) {
        border: 1px solid #038aff !important;
      }
    }
  }
}
.downloadDatePicker {
  background-color: #fff;
  position: absolute;
  top: -148px !important;
  left: 10px;
  z-index: 9999;
}
.a1DatePicker-drawerclone {
  background-color: #fff;
  position: absolute;
  top: -65px !important;
  left: -6px;
  z-index: 99999;
}

.btnRow {
  .btn {
    margin-right: 10px;
    font-weight: 550;
  }
}
.btnRow-click {
  margin-top: 58px;
  margin-left: -75%;
}

@media only screen and (max-width: 600px) {
  .a1DatePicker {
    display: inline-block;
    position: relative;
    &-drawer {
      background-color: #fff;
      position: absolute;
      top: 38px;
      left: -17px !important;
      z-index: 999;
    }
    &-button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      > .btn {
        background-color: transparent;
        border: 0px !important;
        margin-left: 10px;
        &:not(&:first-child) {
          border: 1px solid #038aff !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .a1DatePicker {
    display: inline-block;
    position: relative;
    &-drawer {
      background-color: #fff;
      position: absolute;
      top: 38px;
      left: -112px !important;
      z-index: 999;
    }
    &-button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      > .btn {
        background-color: transparent;
        border: 0px !important;
        margin-left: 10px;
        &:not(&:first-child) {
          border: 1px solid #038aff !important;
        }
      }
    }
  }

  .btnRow {
    .btn {
      margin-right: 7px;
      line-height: 1;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  // .a1DatePicker {
  //   display: inline-block;
  //   position: relative;
  //   left: 0px;
  //   bottom: 0px;
  // }
  .a1DatePicker {
    display: inline-block;
    position: relative;
    &-drawer {
      background-color: #fff;
      position: absolute;
      top: 33px;
      left: -50px;
      z-index: 99999;
    }
    &-button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      > .btn {
        background-color: transparent;
        border: 0px !important;
        margin-left: 10px;
        &:not(&:first-child) {
          border: 1px solid #038aff !important;
        }
      }
    }
  }
}
