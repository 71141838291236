.filterLabelDiv {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    padding: 2px 0 2px 5px;

    .checkboxImage {
        width: 16px;
        height: 16px;
    }

    .filterLabelText {
        font-size: 14px;
        font-weight: 400;
        color: rgba(27, 27, 27, 1);
        margin: 0;
    }
}