.infoContent {
  table {
    tr {
      background-color: white !important;
    }
  }
}
.assetInfoDetails {
  .accordion-item {
    border-bottom: 1px solid #d7dbec;
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-radius: 0;
  }
  .accordion-title {
    display: flex;
    padding: 10px;
    div {
      margin-right: 10px;
    }
    .asset-table-name {
      color: #131523;
    }
  }
  .accordion-content {
    padding-left: 29px;
    padding-bottom: 10px;
    position: relative;
    z-index: 0;
  }
  .Asset-Name {
    color: #777777;
    font-size: 12px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .Asset-Details {
    padding-left: 25%;
    color: #131523;
    font-size: 12px;
    text-align: inherit;
    a {
      color: #000;
      margin-left: 10px;
    }
  }

  .battery-percentage {
    display: flex;
    flex-direction: row;
    .battery-img {
      margin-left: 30px;
      width: 20px;
      height: 20px;
      position: relative;
      top: 1px;
    }
    span {
      font-size: 13px;
      margin-left: 4px;
      font-weight: 500;
      position: relative;
      top: 1px;
    }

    .battery-chip {
      border: 1px solid #fae6b6;
      background-color: #fcf2da;
      border-radius: 5px;
      text-align: center;
      height: 23px;
      position: relative;
      left: 10px;
      .chip-content {
        font-size: 10px;
        color: black;
        font-weight: 500;
        padding: 3px;
      }
    }
  }
}
.assetInfoErroMsg {
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    text-align: center;
    margin: 0;
    padding: 1px;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
}
