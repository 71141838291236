.scrollingMainDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.scrollingText {
    width: 85%;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }

    .textScroll {
        font-size: 12px;
        font-weight: 600;
        color: rgb(27, 27, 27);
        margin: 0;

        &:hover {
            animation: scrollLeftRight 10s linear infinite;
        }

        @keyframes scrollLeftRight {
            0% {
                transform: translateX(0%);
            }

            100% {
                transform: translateX(-80%);
            }
        }
    }

}

.minMaxData {
    font-size: 12px;
    font-weight: 600;
    color: rgb(27, 27, 27);
    margin: 0;
}

.minMaxValue {
    font-size: 12px;
    font-weight: 400;
    color: rgb(27, 27, 27);
    margin: 0;
}