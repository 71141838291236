.assetListFilterMainDiv {
  display: flex;
  flex-direction: column;
  padding: 8px 0px 0px 10px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .assetFilterRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: baseline;
    background-color: rgb(225, 227, 240);

    .assetFilterHeading {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      margin: 0;
    }

    .filterApplyButton {
      width: fit-content;
      font-weight: 500;
      border-radius: 25px;
      padding: 4px 16px 4px 16px;
      border: 1px solid;
      margin: 0px 0px 0px 8px;
    }

    .enableFilterApplyButton {
      background-color: rgba(15, 67, 249, 1);
      border-color: rgba(15, 67, 249, 1);
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
      font-size: 12px;
    }

    .disableFilterApplyButton {
      border-color: #999;
      background-color: #ccc;
      color: #666;
      cursor: default;
      font-size: 12px;
    }

    .clearAllButton {
      color: rgba(220, 53, 69, 1);
      font-size: 14px;
      cursor: pointer;
      width: 100px !important;
      padding: 0 !important;
      text-decoration-color: rgba(220, 53, 69, 1);
      margin: 0px 0px 0px 16px;
    }

  }

  .assetCountStatusDiv {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    margin: 0;
    white-space: nowrap;
    text-align: right;
    padding: 0px 12px 0px 0px;
  }

}

.assetMainDiv {
  display: flex;
  margin-top: 5px;
  height: 70vh;
  gap: 8px;

  .assetMainRow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: transparent;
    padding-right: 16px;

  }

  @media only screen and (max-width: 767px) {
    .assetListContainer {
      padding-bottom: 10rem;
    }
  }

}

/* Media query for screens smaller than 576px (smaller mobile phones) */
@media only screen and (max-width: 575px) {

  /* Additional adjustments for smaller screens */
  .assetMainRow {
    padding-bottom: 10rem;
  }
}

.loadingBox2 {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadMoreAssets {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px;

}

.assetCardColumnClass {
  padding-right: 0.1rem !important;
  padding-left: 0.1rem !important;
}

@media only screen and (max-width: 1280px) and (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 33.33%;
  }
}

@media only screen and (min-width: 2560px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}

@media only screen and (max-width: 768px) {
  .assetMainDiv {
  height: 42vh;
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .assetMainDiv {
  height: 53vh;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 992px) {
  .assetMainDiv {
  height: 63vh;
  }
}

.assetMainDiv::-webkit-scrollbar{
  width: 8px;
}

.assetMainRow::-webkit-scrollbar{
  width: 8px;
}