.FilterChips_scss {
    padding: 20px;
    border-bottom: 2px solid #cfcfcf;

    .seletedList {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .compare {
            display: flex;
            flex-direction: row;
            margin-right: 10px;

            h4 {
                color: #843939;
                border: 1px solid #843939;
                font-size: 0.6rem !important;
                border-radius: 30px;
                text-align: center;
                padding: 1px 10px 1px 10px;
                //cursor: pointer;


                span {
                    img {

                        color: #843939;
                        filter: grayscale(1);
                        width: 1.2vw;



                    }

                    .vectorX {
                        color: #843939;
                        filter: grayscale(1);
                        width: 0.6vw;
                        margin-left: 6px;
                        filter: invert(22%) sepia(157%) saturate(552%) hue-rotate(314deg) brightness(89%) contrast(86%);
                    }
                }
            }

        }
    }
}

.FilterChips_scss {
    .selectDropDownBlock {
        width: 130px;
        padding: 3px;
        border-radius: 5px;
        border-color: #BDCBFB;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 8px;
        font-size: 13px;
    }

    .selectDropDown {
        //width: 130px;
        padding: 3px;
        border-radius: 20px;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 8px;
        font-size: 13px;
        color: #843939;
        border-color: #843939;
    }

    :focus {
        // font-size: 13px;
        // color: black;
        outline: none;
        //width: 130px;
        // padding-right: 5px;
        // padding-left: 5px;
        // border-radius: 20px;
        // padding-right: 3px;
        // margin-right: 8px;

    }
}