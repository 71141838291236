.doughnut-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart {
  transform: rotate(0deg);
  margin-left: 24px;
}

.background {
  fill: none;
  stroke: #eee;
}

.progress {
  fill: none;
  stroke-linecap: round;
}

.percentage {
  font-size: 11px;
  font-weight: 800;
}
.percentageName {
  font-size: 20px;
  font-weight: 500;
  span {
    background-color: #4caf50;
  }
}

.progress {
  stroke: url(#gradient);
}
.linearGradient stop:first-child {
  stop-color: green;
}

.linearGradient stop:nth-child(2) {
  stop-color: orange;
}

.linearGradient stop:last-child {
  stop-color: red;
}
.descriptionText {
  font-size: 12px;
  font-weight: 300;
}
