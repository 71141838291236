.assetCardContainer {
    border-radius: 6px;
    margin-top: 8px;
    margin-left: 8px;
    background-color: #FAFAFA;
    padding: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 5px 0 rgba(0, 0, 0, .19);
    }

    .assetCardHeaderCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .assetIcon {
            width: 28px;
            height: 28px;
            object-fit: contain;
            padding: 4px;
        }

        .assetNameDiv {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 3px;

            .assetName {
                font-size: 0.818rem;
                font-weight: 700;
                color: rgba(112, 112, 112, 1);
                margin: 0;
            }

            .assetType {
                font-size: 0.818rem;
                font-weight: 400;
                color: rgba(27, 27, 27, 1);
                margin: 0;
                margin-top: -2px;
            }
        }
    }

    .facilityDetailsDiv {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-top: 3px;

        .locationIcon {
            width: 14px;
            height: 14px;
        }

        .facilityData {
            font-size: 0.818rem;
            font-weight: 400;
            color: rgba(27, 27, 27, 1);
            margin: 0;
        }
    }

    .dividerLine {
        height: 1px;
        border-radius: 25px;
        color: rgba(207, 217, 254, 1);
        margin-top: 3px;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .assetStatus {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            padding: 2px 8px;

            .assetStatusText {
                font-size: 0.835rem;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                margin: 0;
            }
        }

        .assetLastUpdatedTime {
            font-size: 0.7017rem;
            font-weight: 400;
            color: rgba(27, 27, 27, 1);
            margin: 0;
        }
    }
}

.nonCompliancetext {
    color: rgba(244, 67, 54, 1);
}

.compliancetext {
    color: rgba(27, 27, 27, 1);
}