.customerSuccess {
  margin-top: 56px;
}

.customersuccess-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #dae2ff;
  border-radius: 5px;
  margin: 4px;
  align-items: center;
  padding: 10px;

  .customer-avatar {
    border-radius: 20px;
    width: 41px;
    height: 41px;
    max-width: 100%;
    object-fit: cover;
    margin-right: 10px;
  }
  .customer-details {
    display: flex;
    flex-direction: row;
    align-items: center;

    h6 {
      color: #000000;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
      font-weight: 700;
    }

    p {
      color: #3325b0;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
    }
  }
}
.ticket-details {
  display: flex;
  .ringGaugeChart {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  img {
    margin-right: 6px;
    filter: invert(44%) sepia(281%) saturate(325%) hue-rotate(683deg)
      brightness(90%) contrast(124%);
    width: 20px;
    height: 20px;
  }
  h6 {
    color: #0b0b0c;
    font-size: 16px;
    margin-bottom: 0px !important;
    font-weight: 700;
  }
}
