.breadCrumbRow {
  background-color: #fff;
  border-radius: 6px;
  display: inline-block;
  padding: 0px 8px 2px;
  a {
    text-decoration: none;
    font-size: 12px;
    color: #707070 !important;
    font-weight: 500;
    &:not(:last-child) {
      cursor: pointer;
    }
  }
  svg {
    font-size: 12px;
    color: #707070;
    margin: 0 8px;
  }
  div {
    &:last-child {
      svg {
        display: none;
      }
    }
  }
}
