.headingRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.heading {
  font-weight: 600;
  text-align: center;
  font-size: 13px;
  color: #000000;
}

.dataSortIconDiv {
  display: flex;
  flex-direction: column;
}

.sortIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
