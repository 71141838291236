.serviceTicketsCards {
  h3 {
    color: #131523;
    font-size: 20px;
    font-weight: 600;
  }
}

.ticketCards {
  margin: 10px;

  .ticketCards_header {
    background-color: #f0f3ff;
    border: 1px solid #bdcbfb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 4px 8px;

    h3 {
      font-size: 12px;
      margin-bottom: 0rem;
    }
  }

  .ticketCards_watchListLeft {
    border: 1px solid #bdcbfb;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .ticketCards_watchListIcon {
      display: flex;
      align-items: center;

      .ticketCards_atsuyaLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 2rem;
        width: 2rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        margin-top: 0px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .watchListData {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;

      .ticketAssigned {
        display: flex;
        align-items: center;

        .borderRight {
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid #e3e1e0;
          margin-bottom: 0px;
        }

        p {
          font-family: "Roboto";
          font-size: 12px;
          margin-bottom: 0px !important;
        }
      }

      .alertNotif {
        display: flex;

        h3 {
          font-size: 14px;
          font-weight: 600;
          color: #182f43;
          margin-bottom: 0rem;
        }
      }

      h5 {
        font-size: 11px;
        font-weight: 600;
      }

      h6 {
        font-size: 10px;
        color: #426bfa;
      }
    }
  }
}
