.assetSettingsCard {
  border: 1px solid #bdcbfb;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 24%;
  cursor: pointer;

  .assetSettingsCardInputCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px 5px 15px;

    .settingsCardInput {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 50%;
      border: 2px solid #bbb;
      width: 16px;
      height: 16px;
      outline: none;
      cursor: pointer;
      // visibility: hidden;
    }

    .settingsCardStatusCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .statusEnabledTxt {
        margin: 0px;
        background-color: #aadeac;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }

      .statusDisabledxt {
        margin: 0px;
        background-color: #ff967e;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }

      .settingsImgHoverCont {
        position: relative;

        img {
          height: 12px;
          width: 25px;
        }

        .settingsCardHoverCont {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0px;
          background-color: white;
          border-radius: 5px;
          position: absolute;
          border: 1px solid gray;
          top: 20px;
          right: 0px;
          width: 126px;
          transition: all 0.2s ease-in;
          display: none;
          z-index: 1;

          li {
            margin: 0px;
            font-size: 11px;
            font-weight: 500;
            border-bottom: 1px solid gray;
            width: 100%;
            padding: 3px 15px 3px 15px;
            cursor: pointer;
          }

          li:hover {
            background-color: #e7ecff;
          }
        }
      }

      .settingsImgHoverCont:hover {
        .settingsCardHoverCont {
          display: block;
        }
      }
    }
  }

  .assetSettingsCardDetailsCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 5px 15px;
    grid-gap: 10px;
    gap: 10px;

    .assetProfileCont {
      height: 60px;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 60px;
        width: 70px;
        object-fit: cover;
      }
    }

    .assetSettingsCardDetails {
      width: 65%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .assetIdTxt {
        margin: 0px;
        font-size: 10px;
        background-color: #ffc4ab;
        color: #131523;
        font-weight: 500;
        padding: 1px 3px 1px 3px;
        border-radius: 3px;
        font-weight: 500;
      }

      .settingsImgAndDetailsCont {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;

        img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }

        .detailsContainer {
          width: 98%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .assetNameTextCont {
            width: 90%;
            overflow-x: scroll;
            .assetNameTxt {
              margin: 0px;
              font-size: 12px;
              white-space: nowrap;
              font-weight: 600;
              text-transform: uppercase;
              color: #9f9f9f;
              word-break: break-word;
              transform: translateX(0);
              transition: 1s;
            }
            .assetNameTxt:hover {
              transform: translateX(calc(115px - 100%));
            }
          }
          .assetNameTextCont::-webkit-scrollbar {
            display: none;
          }
          .assetPositionTxt {
            margin: 0px;
            font-size: 11px;
            font-weight: 500;
            width: 85%;
            overflow-x: scroll;
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .assetPositionTxt::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  .assetAreaTxt {
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 0 5px 0;
    width: 90%;
    margin: 0 0 0 15px;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .assetAreaTxt::-webkit-scrollbar {
    display: none;
  }

  .assetAddressContainer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #bdcbfb;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    padding: 5px 0px 5px 15px;
    align-items: center;
    background-color: #f8faff;
    justify-content: flex-start;

    img {
      height: 11px;
      width: 10px;
    }

    .assetAddressTxt {
      margin: 0px;
      font-size: 10px;
      font-weight: 600;
    }
  }
}

.assetSettingsCard:hover {
  border: 1px solid #2680eb;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
}

.activeAssetSettingsCard {
  border: 1px solid #2680eb;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 24%;
  cursor: pointer;

  .assetSettingsCardInputCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px 5px 15px;

    .settingsCardInput:checked {
      clip-path: circle(46% at 50% 50%);
      width: 16px;
      height: 16px;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
    }

    .settingsCardStatusCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .statusEnabledTxt {
        margin: 0px;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }

      .statusDisabledxt {
        margin: 0px;
        background-color: #ff967e;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }

      .settingsImgHoverCont {
        position: relative;

        img {
          height: 12px;
          width: 25px;
        }

        .settingsCardHoverCont {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0px;
          background-color: white;
          border-radius: 5px;
          position: absolute;
          border: 1px solid gray;
          top: 20px;
          right: 0px;
          width: 126px;
          transition: all 0.2s ease-in;
          display: none;
          z-index: 1;
          li {
            margin: 0px;
            font-size: 11px;
            font-weight: 500;
            border-bottom: 1px solid gray;
            width: 100%;
            padding: 3px 15px 3px 15px;
            cursor: pointer;
          }

          li:hover {
            background-color: #e7ecff;
          }
        }
      }

      .settingsImgHoverCont:hover {
        .settingsCardHoverCont {
          display: block;
        }
      }
    }
  }

  .assetSettingsCardDetailsCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 5px 15px;
    grid-gap: 10px;
    gap: 10px;

    .assetProfileCont {
      height: 60px;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 60px;
        width: 70px;
        object-fit: cover;
      }
    }

    .assetSettingsCardDetails {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .assetIdTxt {
        margin: 0px;
        font-size: 10px;
        background-color: #ffc4ab;
        color: #131523;
        font-weight: 500;
        padding: 1px 3px 1px 3px;
        border-radius: 3px;
        font-weight: 500;
      }

      .settingsImgAndDetailsCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;

        img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }

        .detailsContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .assetNameTextCont {
            width: 90%;
            overflow-x: scroll;
            .assetNameTxt {
              margin: 0px;
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              color: #9f9f9f;
              word-break: break-word;
            }
          }

          .assetPositionTxt {
            margin: 0px;
            font-size: 11px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .assetAreaTxt {
    margin: 0px;
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0px 0px 5px 15px;
  }

  .assetAddressContainer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #bdcbfb;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    padding: 5px 0px 5px 15px;
    align-items: center;
    background-color: #f8faff;
    justify-content: flex-start;

    img {
      height: 11px;
      width: 10px;
    }

    .assetAddressTxt {
      margin: 0px;
      font-size: 10px;
      font-weight: 600;
    }
  }
}

// Delete Asset Popup

.deleteAssetPopupModal {
  div {
    border: none;
    border-radius: 20px;
  }
}

.deleteAssetPopHeaderCont {
  padding: 40px 40px 10px 40px !important;

  .deleteAssetPopHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }

  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}

.deleteAssetPopBody {
  padding: 10px 40px 10px 40px !important;

  .deleteAssetBodyCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    .deleteAssetContentTxt {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
    }

    .deleteAssetWarningCont {
      background-color: #f4433659;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 5px;
      border-left: 5px solid #f44336 !important;
      padding: 10px 15px 10px 15px;
      border-radius: 5px;

      .warningIconCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;

        img {
          height: 13px;
          width: 13px;
        }

        p {
          margin: 0px;
          font-size: 13px;
          font-weight: 500;
        }
      }

      p {
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.deleteAssetFooter {
  padding: 0px 35px 20px 0px !important;

  .deleteAssetCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }

  .deleteAssetDeleteBtn {
    border: 1px solid #ea5037;
    background-color: #ff5539;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 300;
  }
}

// View Asset Popup
.viewAssetPopupModal {
  z-index: 1050 !important;

  div {
    border: none;
    border-radius: 20px;
  }
}

.viewAssetPopupHeader {
  border-bottom: 1px solid #bdcbfb !important;
  padding: 30px 30px 10px 30px !important;
  border-radius: 0px !important;

  .viewAssetPopupHeaderCont {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .viewAssetImgCont {
      height: 80px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
        height: 80px;
        width: 80px;
      }
    }

    .viewAssetProfileDetails {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .viewAssetIdTxt {
        margin: 0px;
        font-size: 12px;
        background-color: #ffc4ab;
        color: #131523;
        font-weight: 500;
        padding: 1px 3px 1px 3px;
        border-radius: 3px;
        font-weight: 500;
      }

      .viewAssetImgAndDetailsCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;

        img {
          height: 25px;
          width: 25px;
          margin-right: 5px;
        }

        .detailsContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .viewAssetNameTxt {
            margin: 0px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            color: #9f9f9f;
          }

          .viewAssetPositionTxt {
            margin: 0px;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }

  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}

.viewAssetPopupBody {
  margin-top: 0;
  padding: 15px 35px 15px 35px !important;

  .viewAssetPopupBodyCont {
    display: flex;
    flex-direction: column;
    height: 45vh;
    overflow-y: scroll;
    border-radius: 0px;

    .AssetInfoContainer {
      display: flex;
      flex-direction: column;

      .AssetInfoHeader {
        margin: 0px;
        font-size: 16px;
        font-weight: 600;
      }

      .AssetInfoHeaderBorder {
        border: 1px solid black !important;
        width: 68px;
      }

      .AssetInfoDetailsCont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 5px;

        .AssetInfoDetails {
          width: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding-bottom: 5px;
          padding-top: 5px;

          .AssetInfoHeaderDiv {
            width: 50%;

            .AssetInfoHeader {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 400;
              color: #707070;
            }
          }

          .AssetInfoParaDiv {
            width: 50%;

            .AssetInfoPara {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .AssetAttributeCont {
      display: flex;
      flex-direction: column;

      .AssetAttributeHeader {
        margin: 0px;
        font-size: 16px;
        font-weight: 600;
      }

      .AssetAttributeHeaderBorder {
        border: 1px solid black !important;
        width: 68px;
      }

      .AssetAttributeDetailsCont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 5px;

        .AssetAttributeDetails {
          width: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding-bottom: 5px;
          padding-top: 5px;

          .AssetAttributeHeaderDiv {
            width: 50%;

            .AssetAttributeHeader {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 400;
              color: #707070;
            }
          }

          .AssetAttributeParaDev {
            width: 48%;
            box-sizing: border-box;
            padding: 10px;

            .AssetAttributePara {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}

.viewAssetPopupFooter {
  padding: 10px 35px 25px 0px !important;

  .viewAssetEditBtn {
    background-color: #ffffff;
    border: 1px solid #000000;
    font-size: 11px;
    border-radius: 2px;
    padding: 5px 12px 5px 12px;
    font-weight: 500;

    img {
      height: 12px;
      width: 12px;
      margin-right: 5px;
    }
  }

  .viewAssetDeleteBtn {
    background-color: #ea5037;
    border: 1px solid #ea5037;
    font-size: 11px;
    border-radius: 2px;
    padding: 5px 12px 5px 12px;
    font-weight: 200;
    color: #ffffff;

    img {
      height: 12px;
      width: 12px;
      margin-right: 5px;
    }
  }
}

// Relocate Popup
.relocatePopupModal {
  div {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 80% !important;

    div {
      width: 80%;

      div {
        width: 100% !important;
        border-radius: 4px !important;
        border: none !important;
        display: flex;
        flex-direction: column;

        .relocateAssetHeaderCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between !important;
          padding: 30px 30px 10px 30px !important;

          .relocateAssetHeader {
            margin: 0px !important;
            font-size: 18px !important;
            font-weight: 600 !important;
          }

          .closeIconDiv {
            height: 40px;
            width: 40px !important;
            border-radius: 50% !important;
            display: flex;
            align-items: center;
            justify-content: center;

            .closeIcon {
              height: 15px !important;
              width: 15px !important;
            }
          }

          .closeIconDiv:hover {
            background-color: #d3d3d3;
            cursor: pointer;
          }
        }

        .selectorTagContainer {
          display: flex !important;
          flex-direction: column;
          align-items: flex-start !important;
          padding: 10px 30px 10px 30px;
          grid-gap: 5px;
          gap: 5px;
          position: relative;
          width: 100% !important;

          // background-color: #0075ff;
          input {
            outline: none;
            padding-left: 1%;
            border: 1px solid lightblue;
            border-radius: 3px;
          }

          .countOfAssets {
            font-size: 12px !important;
            font-weight: 500;
            color: #0075ff;
            margin: 0px !important;
          }

          // .assetSelector {
          //   width: 65%;
          //   padding: 8px;
          //   outline: none;
          //   border: 1px solid gray;
          //   border-radius: 5px;
          // }
          .selectSearchInput {
            padding: 4px 10px 4px 10px;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            width: 70%;
          }

          .selectSearchListCont {
            border: 1px solid gray;
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 5px;
            overflow-y: scroll;
            max-height: 20vh;
            min-height: 5vh;
            background-color: #ffffff;
            position: absolute;
            top: 45px;
            width: 63%;

            .selectSearchList {
              font-size: 14px;
              font-weight: 500;
              padding: 5px;
              width: 100%;
            }

            .selectSearchList:hover {
              background-color: #d3eef2;
              cursor: pointer;
              border-radius: 5px;
            }

            .selectedList {
              font-size: 14px;
              font-weight: 500;
              padding: 5px;
              width: 100%;
              background-color: #2680eb;
              color: #ffffff;
            }
          }

          .selectSearchListCont::-webkit-scrollbar {
            display: none;
          }
        }

        .relocateAssetFooterCont {
          display: flex !important;
          flex-direction: row !important;
          justify-content: flex-end !important;
          align-items: center !important;
          grid-gap: 5px;
          gap: 10px;
          padding: 10px 30px 25px 10px;

          .assetCancelBtn {
            border: 1px solid #cfc8b8;
            background-color: #fcf2da;
            font-size: 15px;
            padding: 5px 10px 5px 10px;
            border-radius: 3px;
            font-weight: 500;
          }

          .assetRelocateBtn {
            border: 1px solid #5711e2;
            background-color: #0075ff;
            font-size: 15px;
            padding: 5px 10px 5px 10px;
            border-radius: 3px;
            font-weight: 300;
            color: #ffffff;
            width: 120px;
            height: 36px;
          }
        }
      }
    }
  }
}

// Update Attributes Popup
.updateAttributePopupModal {
  div {
    //width: 43vw;
    border: none;
    border-radius: 15px;
    margin-top: 0;
  }
}

.updateAttributePopupHeader {
  padding: 30px 35px 0px 35px !important;

  .updateAttributeHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }

  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}

.updateAttributePopupBody {
  padding: 10px 0px 10px 35px !important;

  .updateAttributePopupBodyCont {
    display: flex;
    flex-direction: column;

    .updateAttributeProfileCont {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .updateAttributeImgCont {
        height: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: cover;
          height: 70px;
          width: 70px;
        }
      }

      .updateAttributeDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .updateAttributeIdTxt {
          margin: 0px;
          font-size: 11px;
          background-color: #ffc4ab;
          color: #131523;
          font-weight: 500;
          padding: 1px 3px 1px 3px;
          border-radius: 3px;
          font-weight: 500;
        }

        .updateAttributeImgAndDetailsCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 5px;

          img {
            height: 22px;
            width: 22px;
            margin-right: 5px;
          }

          .detailsContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .updateAttributeNameTxt {
              margin: 0px;
              font-size: 13px;
              font-weight: 600;
              text-transform: uppercase;
              color: #9f9f9f;
            }

            .updateAttributePositionTxt {
              margin: 0px;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .updateAttributesInputsCont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-right: 25px;

      .updateAttributesErrorLayout {
        width: 100%;
        margin-left: 30%;
      }

      .textInputsCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        padding-top: 10px;

        p {
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
          padding-bottom: 3px;
        }

        input {
          outline: none;
          border: 1px solid #bdcbfb;
          border-radius: 5px;
          padding: 5px;
          font-size: 13px;
          width: 85%;
        }
      }

      .selectAndInputCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        padding-top: 10px;

        p {
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
          padding-bottom: 3px;
        }

        .selectInputCont {
          display: flex;
          flex-direction: row;
          width: 100%;

          input {
            outline: none;
            border: 1px solid #bdcbfb;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 5px;
            font-size: 13px;
            width: 65%;
            border-right: none;
          }

          select {
            outline: none;
            border: 1px solid #bdcbfb;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 5px;
            font-size: 13px;
            width: 20%;
          }
        }
      }

      .selectorCont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        padding-top: 10px;

        p {
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
          padding-bottom: 3px;
        }

        select {
          outline: none;
          border: 1px solid #bdcbfb;
          border-radius: 5px;
          padding: 5px;
          font-size: 13px;
          width: 85%;
        }
      }
    }
  }
}

.booleanParentCont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  border-radius: 0 !important;

  div {
    border: 1px solid rgba(189, 203, 251, 1) !important;
    padding: 5px 24px;
    cursor: pointer;
  }

  .booleanLeftCont {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
  }

  .booleanRightCont {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
  }
}

.textInputUnitConst {
  width: 85%;
  display: flex;
  flex-direction: row;
  border: 1px solid #bdcbfb !important;
  border-radius: 5px !important;
  padding: 5px;
  gap: 8px;
  align-items: center;

  .horizontalLine {
    height: 20px;
  }

  input {
    outline: none;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
}

.attributeDropdown {
  border: 1px solid rgba(189, 203, 251, 1);
  padding: 5px;
  width: 85%;
  border-radius: 5px;
}

.textArea {
  width: 85%;
  border: 1px solid #bdcbfb;
  border-radius: 5px;
}

.invalidMessage {
  color: red;
  font-size: 10px;
}

.updateAttributePopupFooter {
  padding: 5px 35px 20px 0px !important;

  .updateAttributeCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }

  .updateAttributeUpdateBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 300;
    color: #ffffff;
  }

  .disabledButton {
    border: 1px solid #6a6a6a;
    background: #a6a6a6 !important;
  }
}

@media (max-width: 2600px) {
  .assetSettingsCard {
    width: 16%;
  }

  .activeAssetSettingsCard {
    width: 16%;
  }
}

@media (max-width: 2000px) {
  .assetSettingsCard {
    width: 19%;
  }

  .activeAssetSettingsCard {
    width: 19%;
  }
}

@media (max-width: 1440px) {
  .assetSettingsCard {
    width: 24%;
  }

  .activeAssetSettingsCard {
    width: 24%;
  }
}

@media (max-width: 1110px) {
  .assetSettingsCard {
    width: 30%;
  }

  .activeAssetSettingsCard {
    width: 30%;
  }
}

@media (max-width: 850px) {
  .assetSettingsCard {
    width: 46%;
  }

  .activeAssetSettingsCard {
    width: 46%;
  }
}

@media (max-width: 550px) {
  .assetSettingsCard {
    width: 96%;
  }

  .activeAssetSettingsCard {
    width: 96%;
  }
}

.bulkRelocateSelectorDropdown {
  width: 70%;
  border: 1px solid black !important;
  border-radius: 8px !important;
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .updateAttributePopupModal {
    div {
      width: 72vw;
    }
  }
}

@media only screen and (max-width: 600px) {
  // View Asset Popup
  .viewAssetPopupHeader {
    padding: 20px 0px 10px 0px !important;
    margin: 0px 20px 0px 20px !important;

    .viewAssetPopupHeaderCont {
      .viewAssetImgCont {
        height: 60px;
        width: 60px;

        img {
          height: 60px;
          width: 60px;
        }
      }

      .viewAssetProfileDetails {
        .viewAssetIdTxt {
          font-size: 10px;
        }

        .viewAssetImgAndDetailsCont {
          img {
            height: 20px;
            width: 20px;
          }

          .detailsContainer {
            .viewAssetNameTxt {
              font-size: 10px;
            }

            .viewAssetPositionTxt {
              font-size: 10px;
            }
          }
        }
      }
    }

    .closeIconDiv {
      height: 30px;
      width: 30px;

      img {
        height: 12px;
        width: 12px;
      }
    }

    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }

  .viewAssetPopupBody {
    padding: 5px 20px 5px 20px !important;

    .viewAssetPopupBodyCont {
      .AssetInfoContainer {
        .AssetInfoHeader {
          font-size: 13px;
        }

        .AssetInfoHeaderBorder {
          width: 55px;
        }

        .AssetInfoDetailsCont {
          .AssetInfoDetails {
            .AssetInfoHeaderDiv {
              .AssetInfoHeader {
                font-size: 9px;
              }
            }

            .AssetInfoParaDiv {
              .AssetInfoPara {
                font-size: 9px;
                word-break: break-all;
              }
            }
          }
        }
      }

      .AssetAttributeCont {
        .AssetAttributeHeader {
          font-size: 13px;
        }

        .AssetAttributeHeaderBorder {
          width: 64px;
        }

        .AssetAttributeDetailsCont {
          .AssetAttributeDetails {
            .AssetAttributeHeaderDiv {
              .AssetAttributeHeader {
                font-size: 9px;
              }
            }

            .AssetAttributeParaDev {
              .AssetAttributePara {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  }

  .viewAssetPopupFooter {
    padding: 10px 20px 15px 0px !important;

    .viewAssetEditBtn {
      padding: 3px 10px 3px 10px;
    }

    .viewAssetDeleteBtn {
      padding: 3px 10px 3px 10px;
    }
  }

  .updateAttributePopupModal {
    div {
      width: 96vw;
    }
  }

  .updateAttributePopupHeader {
    padding: 15px 20px 0px 20px !important;

    .updateAttributeHeader {
      font-size: 16px !important;
    }

    .closeIconDiv {
      height: 30px;
      width: 30px;

      img {
        cursor: pointer;
        height: 12px;
        width: 12px;
      }
    }

    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }

  .updateAttributePopupBody {
    padding: 5px 0px 5px 20px !important;

    .updateAttributePopupBodyCont {
      .updateAttributeProfileCont {
        .updateAttributeImgCont {
          height: 60px;
          width: 60px;

          img {
            object-fit: cover;
            height: 60px;
            width: 60px;
          }
        }

        .updateAttributeDetails {
          .updateAttributeIdTxt {
            font-size: 9px;
          }

          .updateAttributeImgAndDetailsCont {
            img {
              height: 18px;
              width: 18px;
            }

            .detailsContainer {
              .updateAttributeNameTxt {
                font-size: 11px;
              }

              .updateAttributePositionTxt {
                font-size: 10px;
              }
            }
          }
        }
      }

      .updateAttributesInputsCont {
        .textInputsCont {
          p {
            font-size: 12px;
          }

          input {
            padding: 2px;
            font-size: 10px;
          }
        }

        .selectAndInputCont {
          p {
            font-size: 12px;
          }

          .selectInputCont {
            input {
              padding: 2px;
              font-size: 10px;
              width: 60%;
            }

            select {
              padding: 2px;
              font-size: 10px;
              width: 25%;
            }
          }
        }

        .selectorCont {
          p {
            font-size: 13px;
          }

          select {
            padding: 2px;
            font-size: 10px;
          }
        }
      }
    }
  }

  .updateAttributePopupFooter {
    padding: 5px 20px 10px 0px !important;

    .updateAttributeCancelBtn {
      font-size: 12px;
      padding: 3px 8px 3px 8px;
    }

    .updateAttributeUpdateBtn {
      font-size: 12px;
      padding: 3px 8px 3px 8px;
    }
  }
}

// .changeStatusSelector {
//   width: 80% !important;
//   outline: none !important;
//   border: 1px solid #2953dd !important;
//   border-radius: 3px !important;
//   padding: 0px !important;
//   font-size: 13px !important;
//   font-weight: 400 !important;

//   option {
//     font-size: 13px !important;
//     font-weight: 500 !important;
//   }
// }

// .css-1pahdxg-control {
//   box-shadow: none !important;
//   border-color: none !important;
// }
