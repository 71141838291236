.flexBox {
  .ahuDetailsLayout {
    overflow-y: auto;
    display: flex;
    width: 75%;
    cursor: default;
  }

  .scrollToNextIcon {
    height: 40px;
    left: 70%;
    position: absolute;
    top: 50%;
    width: 40px;
  }
}

.loadingBox1{
  width: 100%;
  display: flex;
  height: 15rem;
  justify-content: center;
}
.element1 {
  width: 100px;
  height: 50px;
  background-color: #3498db;
  position: relative;
  margin: 20px;
}

.element2 {
  width: 100px;
  height: 50px;
  background-color: #db3474;
  position: relative;
  margin-left: 220px;
  margin-bottom: 20px;
}

.element3 {
  width: 100px;
  height: 50px;
  background-color: #34db3c;
  position: relative;
  margin-left: 420px;
  top: -70px;
}

.parentParent{
  width: 100%;
  height: 300px;
  position: relative;
}

.elementSvg{
position: absolute;
  top:0;
  left: 0;
}

.line {
  position: absolute;
  width: 10px; /* Line thickness */
  background-color: #e74c3c; /* Line color */
  top: 0;
  left: calc(50% - 5px); /* Adjust the positioning of the line */
  height: 100%; /* Full height */
  z-index: -1;
}

#line {
  top: 50%;
}








.frame {
  height: 100vw;
  overflow: scroll;
  position: relative;
  width: 100%;
}

.frame .overlap-group {
  height: 415px;
  left: 0px;
  position: absolute;
  top: 0;
  width: 1537px;
}

.frame .rectangle {
  background-color: #e6e6e6;
  border: 2px solid #a7a7a7;
  border-radius: 5px;
  height: 316px;
  left: 42px;
  position: absolute;
  top: 99px;
  width: 173px;
}

.frame .div {
  background-color: #e6e6e6;
  border: 2px solid #a7a7a7;
  border-radius: 5px;
  height: 316px;
  left: 432px;
  position: absolute;
  top: 99px;
  width: 453px;
}

.frame .rectangle-2 {
  background-color: #e6e6e6;
  border: 2px solid #a7a7a7;
  border-radius: 5px;
  height: 316px;
  left: 903px;
  position: absolute;
  top: 99px;
  width: 369px;
}

.frame .rectangle-3 {
  background-color: #e6e6e6;
  border: 2px solid #a7a7a7;
  border-radius: 5px;
  height: 316px;
  left: 1293px;
  position: absolute;
  top: 99px;
  width: 244px;
}

.frame .rectangle-4 {
  background-color: #e6e6e6;
  border: 2px solid #a7a7a7;
  border-radius: 5px;
  height: 315px;
  left: 237px;
  position: absolute;
  top: 99px;
  width: 172px;
}

.frame .grad {
  height: 415px;
  left: 42px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1493px;
}

.frame .div-2 {
  align-items: center;
  display: inline-flex;
  gap: 37px;
  justify-content: center;
  left: 989px;
  position: absolute;
  top: 444px;
}

.frame .div-3 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 14px;
  position: relative;
}

.frame .rectangle-7 {
  background-color: #f1a1b3;
  height: 23px;
  position: relative;
  width: 28px;
}

.frame .text-wrapper {
  color: #000000;
  font-family: "Inter-Regular", Helvetica, serif;
  font-size: 18.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.53px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .rectangle-8 {
  background-color: #94ccea;
  height: 23px;
  position: relative;
  width: 28px;
}

.elementSvg{
  position: absolute;
  top:0;
  left: 0;
  z-index: 1000;
}

.elementSvg1{
  position: absolute;
  top:0;
  left: 0;
  z-index: 1000;
  webkit-clip-path: url(#svgPath);
  clip-path: url(#clip);
}


.path1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}


.line1 {
  stroke-dasharray: 340;
  stroke-dashoffset: 40;
  animation: dash 3s linear infinite;
}

.line2 {
  stroke-dasharray: 2;
  stroke-dashoffset: 320;
  animation: dash2 10s linear infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 40;
  }
}

@keyframes dash2 {
  from {
    stroke-dashoffset: 580;
  }
  to {
    stroke-dashoffset: -40;
  }
}
