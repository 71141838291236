.toggleSwitchCont {
    width: 9vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 4px 4px 4px 4px;

    .toggleText {
        color: #1B1B1B;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }

    .toggleSwitchMainDiv {
        display: flex;
        /* padding: 4px 16px; */
        border-radius: 25px;
        width: 46%;
        padding: 5px 6px;
        cursor: pointer;
        transition: background-color 0.5s ease-in-out;

        .toggleOffStyle{
            width: 10px;
            height: 10px;
            background-color: #fff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
            animation: none;

            .toggleOffInnerStyle{
                width: 4px;
                height: 4px;
                border-radius: 50px;
                background-color: rgba(244, 67, 54, 1);
            }
        }

        .toggleOnStyle{
            width: 4px;
            height: 10px;
            background-color: #fff;
            border-radius: 90px;
            transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
    }
}

@keyframes bounce {
  0%, 25%, 50%, 75%, 100% {
    transform: translateY(0);
  }
  12.5%, 37.5%, 62.5%, 87.5% {
    transform: translateY(-8px); // Adjust the bounce height as needed
  }
}

.toggleOffStyle.visible {
  animation: slideIn 0.5s ease-in-out forwards, bounce 0.3s ease-in-out 0.5s forwards;
}

.toggleOnStyle.visible {
  animation: bounce 0.3s ease-in-out forwards;
}


@media only screen and (min-width: 1441px) and (max-width: 2560px) {
  .toggleSwitchMainDiv{
    width: 28% !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .toggleSwitchMainDiv{
    width: 46% !important;
  }
}

@media only screen and (max-width: 600px) {
  .toggleSwitchMainDiv{
    width: 28% !important;
  }
}