// component Name: rightToggle
// path - a1Components/rightToggle/index.tsx
// scss start
.sidebarRight {
  border-radius: 6px 0px 0px 6px;
  transform: translate3d(0px, 0px, 100%);
  transition: transform 0.1s;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  transform: translateX(8.33333333%);
  z-index: 9999;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  transition: 'background-color 0.3s ease';
  &.col-xl-4 {
    width: 36%;
    transition: 'background-color 0.3s ease',
  }
  &-closed {
    transform: translateX(100%);
  }
  &-light {
    background: transparent linear-gradient(1deg, #021b79 0%, #0575e6 100%) 0%
      0% no-repeat padding-box;
  }
  &-dark {
    background: transparent
      linear-gradient(1deg, #bdc3c7 0%, #314254 38%, #2c3e50 100%) 0% 0%
      no-repeat padding-box;
  }
  .faChevronRight {
    font-size: 1.3rem;
    color: white;
  }
}

.centerState {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
}

.closeArrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.rightToggleBtn {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  .cta {
    background: transparent linear-gradient(0deg, #021b79 0%, #0575e6 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    width: 21px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin: 0;
    }
    &.ctaDark {
      background: transparent
        linear-gradient(
          0deg,
          #bdc3c7 0%,
          #314254 36%,
          #304153 53%,
          #2c3e50 100%
        )
        0% 0% no-repeat padding-box;
    }
  }

  .faChevronLeft {
    font-size: 1.3rem;
    color: white;
  }
}

.sideHeader {
  width: 80%;
  display: flex;
  justify-content: space-between;
  color: white;
  padding-top: 1px;
  margin-right: 26px;
  margin-top: 0px;
    padding-top: 5px;
}

.sideContentArea {
  width: 80%;
  padding: 0px;
  min-height: 75vh;
  background-color: white;
  border-radius: 0.375rem;
  overflow: hidden;
  margin: 0px;
  margin-top: 0px;
  margin-right: 25px;
  min-height: 88vh;
  .errStype{
    margin-left: 76px;
  }
}
.sideContentArea button h5 {
  font-weight: 800 !important;
}

.sideFooter {
  display: none !important;
  width: 80%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.sideHeaderSecondDiv {
  width: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  .externalIconColor,
  .closeIconColor {
    cursor: pointer;
  }
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.tabs__label {
  padding: 10px 16px;
  cursor: pointer;
  background-color: #f5f6fa;
  width: 7rem;
  height: 10.125rem;
  margin: 0 2px;
}
.tabs__label h2 {
  font-weight: bolder !important;
}
.tabs__label p {
  font-weight: 500 !important;
}

.tabs__radio {
  display: none;
}

.tabs__content {
  order: 1;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #dddddd;
  line-height: 1.5;
  font-size: 0.9em;
  display: none;
}

.tabs__radio:checked + .tabs__label {
  font-weight: bold;
  border-bottom: 10px solid #426bfa;
  border-top-left-radius: 20px;

  background-color: white !important;
}

.tabs__radio:checked + .tabs__label + .tabs__content {
  display: initial;
}

.stackedHorozontalBar {
  .react-EChartsCore {
    height: "280px" !important;
    bottom: "20px" !important;
  }
}

//Date selector
.live__Css {
  margin-left: 10px;
  margin-right: 10px;
}
.toggle_date {
  border-radius: 22px !important;
  // height: 10px;
  top: 5px;
  border-color: blue !important;
  height: 30px;
  img {
    padding: 10px;
  }
}
.toggleButton__Class {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
}
.live__Css__Status h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #7d7d7d;
}
// Date range button
.range_date {
  z-index: 999;
  position: absolute;
  top: 10vh;
  margin-left: 87px;
  right: 121px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.range_date_Dash {
  z-index: 1000;
  position: absolute;
  top: 18vh;
  margin-left: 87px;
  right: 121px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.bgWhite {
  background-color: white;
  padding: 1vmax;
}
.rdrMonth {
  padding: 0 0 1.666em 0.833em;
}
.bgWhiteDash {
  background-color: white;
  padding: 1vmax;
}
// @media only screen and (max-width: 1266px) {
//   .temp  .btns{
//     position: relative;
//     top: 40px;
//     right: 150px;
//     min-width: 2vw;

//   }
// }
@media only screen and (max-width: 1199px) {
  .sidebarRight {
    transform: translateX(0%);
    &.col-xl-4 {
      width: 100%;
    }
  }
}
// component Name: rightToggle
// path - a1Components/rightToggle/index.tsx
// scss end

// component Name: TabGroup
// path - a1Components/TabGroup/index.tsx
// scss start

.tabClass::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.TabBtn:enabled {
  padding: 0.4rem !important;
  width: 100%;
  background-color: #f5f6fa;
  border-radius: 0px;
  text-align: center;
  min-width: 5.25rem !important;
  min-height: 4vh;
  &:hover {
    //background-color: white;
    //border: 1px solid #D3DCE4;
  }
}

.TabBtn h5 {
  // font-weight: 800 !important;
}

.TabBtn {
  // padding-right: 5px;
  // margin-right: 0.125rem;
  min-width: 6.25rem;

  position: relative;
  &:after {
    content: "";
    width: 92%;
    height: 4px;
    position: absolute;
    bottom: 0px;
    left: 4px;
    background-color: transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &.active {
    &:after {
      // background-color: blue;
    }
  }
}

.tabBody_active {
  padding: 0 0.625rem;
}
.tabBody {
  padding: 0 0;
}
.seeMore {
  width: 100%;
  text-align: center;
}

.seeMoreDiv {
  width: 30%;
  // border: 1px solid red;
  margin: auto;
}

.tabStatus {
  background: #d10000 0% 0% no-repeat padding-box;
  border-radius: 0.188rem;
  font-size: 0.5rem;
  padding: 2px 5px;
  width: 65px;
  height: 20px;
  padding: 4px;
  margin-left: 8px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 2px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.tabStatus1 {
  border-radius: 0.188rem;
  font-size: 0.6rem;
  padding: 2px 5px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  visibility: hidden;
  // min-height: 1rem;
}

.blockAlertDiv {
  width: 86%;
  margin: auto;
  padding-top: 2%;
}

// component Name: TabGroup
// path - a1Components/TabGroup/index.tsx
// scss end

// component Name: Activity Alerts
// path - a1Components/activityAlerts/index.tsx
// scss start

.AlertTypesMain {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -4px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
  margin: 1rem 0;
  padding: 0 0.625rem;
}

.AlertTypesMainHeader {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 10px;
}

.AlertTypesMainChips {
  width: 88%;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.AlertTypesMainChips::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.AlertChipsBtn {
  border-radius: 20px;
  color: white;
  font-size: 10px;
  margin: 2px 2px;
  padding: 2px 5px;
}
.AlertChipsBtn_active {
  background-color: #4533b3;
  border-radius: 20px;
  color: white;
  font-size: 10px;
  padding: 0px 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.AlertChipsBtn_inactive {
  background-color: rgba(160, 147, 243, 0.28);
  border-radius: 20px;
  color: #462e65;
  font-size: 10px;
  margin: 2px 2px;
  padding: 2px 5px;
}

.alertTypes {
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  max-height: 50vh;
  margin: 1rem 0;
}

.alertTypes::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.alertTypes > *:last-child {
  .subAlertContainerSecondDiv {
    .overLayMsg {
      position: absolute;
      z-index: 9999;
      top: -50%;
      right: 4%;
    }
  }
}

.subAlertContainer {
  border: 0.125rem solid #dae2ff;
  border-left: 0.4rem solid #f51f1f;
  margin: auto;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0.125rem;
  position: relative;
  width: 25vw;
  min-height: 10vh;

  .hoverData {
    position: absolute;
    left: 34%;
    top: 17%;
    background-color: beige;
    min-height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 11px;
    width: 16vw;
    flex-direction: row;
    h5 {
      font-size: 10px;
      color: #262626;
      margin: 0;
    }
  }
  h6 {
    font-size: 10px;
  }
  p {
    font-size: 12px;
    margin: 0;
  }
  h5 {
    font-size: 12px;
    color: #262626;
  }
}

.subAlertContainerFirstDiv {
  width: 95%;
  padding: 0 10px;
  p {
    margin: 0.2rem;
    padding: 0;
    font-size: 12px;
  }

  .subAlertContainer p {
    font-size: 0.7rem !important;
  }
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // align-items: flex-start;
}

.subAlertContainerFirstDivFirstRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  :first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    .repeatedAlert {
      color: #ff4c30;
      font-size: 0.7rem;
      border-radius: 11px;
      text-align: center;
      padding: 0px 10px;
      background-color: rgba(255, 76, 48, 0.13);
      margin-left: 0.3125rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      div {
        padding-left: 0.3125rem;
      }
    }
  }
}

.subAlertContainerFirstDivFirstRow h6 {
  font-size: 0.85rem;
  font-weight: 600;
}

.subAlertContainerFirstDivSecondRow {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}

.subAlertContainerSecondDiv {
  width: 30%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: right;
  align-items: center;
  justify-content: space-evenly;
  .overLayMsg {
    width: 80%;
    display: none;
    border: 1px solid #707070;
    position: absolute;
    z-index: 9999;
    top: 50%;
    right: 4%;
    background-color: white;
    border-radius: 0.6875rem;
    padding: 1rem;
    font-size: 0.7rem;
    font-weight: 700;
    text-align: center;
  }
}

.faEyeIcon {
  font-size: 0.6rem !important;
  margin: auto;
}

.subAlertContainerSecondDiv:hover .overLayMsg {
  display: block;
}

.AlertTypesMainCloseBtn {
  width: 10%;
  cursor: pointer;
}

.activitySubAlertCloseBtn {
  display: flex;
  justify-content: flex-end;
  .faXmarkClass {
    background-color: #462e65;
    font-size: 1rem;
    color: white;
    padding: 5px 8px;
    border-radius: 4px;
  }
}

.alertcontainer {
  width: 65%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.AlertCountcontainer {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #0457c2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.6rem;
  font-size: 0.9rem;
  font-weight: 700;
}
.AlertCountcontainer + div {
  font-weight: 700;
  font-size: 0.6rem;
}

.alertEachRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  margin: 6px 0;
}

.alertEachRow:hover {
  background: rgba(4, 87, 194, 0.15);
  border-radius: 6px;
  .AlertCountcontainer {
    background: #0457c2 0% 0% no-repeat padding-box;
    color: white;
    border: 2px solid #0457c2;
  }
}

.alertEachRow > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.alertEachRow > div + div {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 0.7rem;
  flex-direction: row;
  width: 20%;
  margin-bottom: 4px;
}

.activitySubAlertFirstDiv {
  width: 92%;
  margin: auto;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.activitySubAlertChips {
  width: 85%;
  background-color: rgba(160, 147, 243, 0.28);
  border: px solid rgba(160, 147, 243, 0.28);
  border-radius: 20px;
  padding: 5px;
}

// component Name: Activity Alerts
// path - a1Components/activityAlerts/index.tsx
// scss end

// component Name: TrendData
// path - a1Components/TrendData/index.tsx
// scss start
.trendIcon {
  font-size: 0.9rem !important;
  padding-right: 0.5rem;
}

// component Name: TrendData
// path - a1Components/TrendData/index.tsx
// scss end

// component Name: horizontalCard
// path - a1Components/horizontalCard/index.tsx
// scss start

// .horizontalClassRow {
//   background-color: red;
//   &:nth-child(2n + 1) {
//     &:last-child {
//       background-color: green;
//     }
//   }
// }
.horizontalClassRow:last-child:nth-child(odd) {
  background-color: red;
}
// .horizontalClassRow:last-child:not(:nth-child(odd)) {
//   background-color: green;
// }
.horizontalClassRow {
  min-height: 0;
  overflow: scroll;
  max-height: 23vh;
}
.horizontalCard {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  margin: auto;
  display: flex;
  direction: column;
  justify-content: start;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dae2ff;
  border-radius: 0.375rem;
  opacity: 1;
  padding: 12px;
  height: 80px;
  //display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  //align-content: flex-start;
  .horizontalCard-resolution {
    display: flex;
    justify-content: start;
    flex-direction: column;
    h6 {
      font-size: 12px;
      font-weight: 700;
      padding-top: 0px;
      margin-top: 0px;
    }
  }
  // div{
  //   display: flex;
  //   justify-content: start;
  //   flex-direction: row;
  //   align-items: center;

  // }
}

.horizontalCardTime {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  flex-direction: row;
  padding-bottom: 5px;
  align-items: center;

  //justify-content: flex-start;
  h2 {
    font-size: 18px !important;
    font-weight: 1000 !important;
    margin-top: 0px;
    padding: 0px;
    margin-bottom: 0rem;
  }
}

.horizontalCardTime > p {
  padding-left: 1rem;
  //padding-top: 10px;
  font-weight: 600;
  display: flex;
  display: row;
  font-size: 10px !important;
  margin-bottom: 0rem;
}

// component Name: horizontalCard
// path - a1Components/horizontalCard/index.tsx
// scss end

// component Name: INSIGHTS
// path - a1Components/INSIGHTS/index.tsx
// scss START

.insights {
  padding: 6px;
  border: 1px solid #dae2ff;
  border-radius: 4px;
  border-left: 5px solid #426bfa;
  //padding: 5px 2rem 5px 5px;
  position: relative;
  margin: 0 1rem;
  box-shadow: 0px 3px 6px #00000029;
  h6 {
    font-size: 13px;
    margin-top: 5px;
    font-weight: 600;
  }

  .closeInsight {
    color: black;
    font-size: 1.2rem;
    position: absolute;
    right: 7px;
    opacity: 0px;
    top: 0px;
    padding: 0 5px;
    .fa-xmark {
      cursor: pointer;
    }
  }

  p {
    font-size: 0.6rem;
    font-weight: 500 !important;
    //font-size: 1px;
  }

  .insightsBtn {
    width: auto !important;
    color: white;
    border-radius: 0.1875rem;
    padding: 0.125rem 0.3125rem 0.125rem 0.3125rem;
    display: block;
    border: none;
    margin-bottom: 0.3125rem;
    font-size: 0.75rem;
    &.first {
      background-color: #51aeff;
      font-size: 10px;
    }
    &.last {
      background-image: linear-gradient(#302b63, #24243e);
      font-size: 10px;
    }
  }
}

.insightsRecommendation {
  border: 1px solid #dae2ff;
  border-radius: 4px;
  border-left: 3px solid #426bfa;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  position: relative;
  margin: 1rem 1rem 1rem 2rem;
  box-shadow: 0px 3px 6px #00000029;
  :div:first-child {
    display: flex;
  }
  h6 {
    font-size: 0.9rem;
  }
  .closeRecommendation {
    padding: 0 5px;
    position: absolute;
    right: 0;
    top: 0;
    .fa-xmark {
      cursor: pointer;
    }
  }

  .recommendationText {
    display: block;
    background-color: #dae2ff;
    font-size: 0.8rem;
    border-radius: 3px;
    padding: 2px 10px;
  }
}

// component Name: INSIGHTS
// path - a1Components/INSIGHTS/index.tsx
// scss end

// component Name:  CHATBOT
// path - a1Components/CHATBOT/index.tsx
// scss START

.chatBot {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 6px;
  display: none !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  h6 {
    font-size: 0.8rem !important;
  }
}

// component Name:  CHATBOT
// path - a1Components/CHATBOT/index.tsx
// scss END

// component Name: blockAlerts
// path - a1Components/blockAlerts/index.tsx
// scss start
.blockAlert_alert {
  background: #fddada 0% 0% no-repeat padding-box;
  border: 2px solid #f64747;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #f64747;
  padding: 9px;
  height: 60px;
  margin: auto;
  //padding-top: 20px;
  margin-top: 20px;
  p {
    color: #f64747;
    font-size: 12px;
    width: 90%;
  }
  .blockAlertBtn {
    text-align: center;
  }
  .contextIcon,
  .faXmark {
    color: #f64747;
    font-size: 27px;
    padding-left: 6px;
    padding-bottom: 14px;
    background-color: transparent;
  }
  .faXmark {
    cursor: pointer;
  }
  div {
  }
}

.blockAlert_info {
  background: #e6d5ff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  align-items: center;
  color: #0442a8;
  padding: 0.6rem;
  margin: auto;
  margin-top: 1rem;
  flex-direction: row;
  p {
    color: #0442a8;
    font-size: 12px !important;
    //padding: 1rem;
    padding-top: 8px;
    padding-left: 0px;
  }
  .blockAlertBtn {
    text-align: center;
  }
  .contextIcon,
  .faXmark {
    color: #0442a8;
    font-size: 1.5rem;
    padding-right: 0px;
    margin-right: 0px;
  }
  .faXmark {
    cursor: pointer;
  }
}

.blockAlertClose {
  display: none;
}

// component Name: blockAlerts
// path - a1Components/blockAlerts/index.tsx
// scss end
@media only screen and (max-width: 600px) {
  .closeArrow {
    display: none;
  }
  .rightToggleBtn {
    display: none;
  }

  .alertEachRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
    margin: 6px -19px;
  }

  .sideContentArea {
    width: 93%;
    padding: 0px;
    min-height: 75vh;
    background-color: white;
    border-radius: 0.375rem;
    overflow: hidden;
    margin: 0px;
    margin-top: 0px;
    margin-right: 0px;
    height: 80vh;
  }
}

@media only screen and (max-width: 1024px) {
  .sideContentArea {
    width: 93%;
    padding: 0px;
    min-height: 75vh;
    background-color: white;
    border-radius: 0.375rem;
    overflow: hidden;
    margin: 0px;
    margin-top: 0px;
    margin-right: 2px;
    height: 80vh;
  }
}
