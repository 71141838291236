//Create New Facility Modal Popup
.createAndEditFacilityPopup {
  div {
    border: none;
  }
}
.createAndEditFacilityHeaderCont {
  .createAndEditFacilityHeader {
    margin: 0px;
    font-weight: 600;
    font-size: 18px;
  }
  img {
    height: 15px;
    width: 13px;
    cursor: pointer;
  }
}
.createFacilityBody {
  display: flex;
  flex-direction: column;
  .createFacilityNavbar {
    display: flex;
    flex-direction: row;
    gap: 15px;
    border-bottom: 1px solid #d7dbec;
    .createFacilityNavTxt {
      margin: 0px;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      color: #373f6d;
    }
    .activeCreateFacilityNavTxt {
      margin: 0px;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      color: #0075ff;
      border-bottom: 1px solid #0075ff;
    }
  }
  .createFacilityBasicInfoCont {
    overflow-y: scroll;
    height: 50vh;
    .createFacilityInputs {
      height: 70vh;
      display: flex;
      flex-direction: column;
      .facilityInputs {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding-top: 10px;
        .createFacilityInputsleft {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .inputsCont {
            display: flex;
            flex-direction: column;
            height: 10vh;
            position: relative;
            p {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 5px;
            }
            .setPurposeTxt {
              cursor: pointer;
              margin: 0px;
              font-size: 10px;
              color: #4e23d9;
              text-decoration: underline;
            }
            .facilityInputs {
              outline: none;
              border: 1px solid #bdcbfb;
              border-radius: 5px;
              font-size: 14px;
              padding: 4px;
              width: 95%;
            }
            .numberInputCont {
              display: flex;
              flex-direction: row;
              align-items: center;
              .numberInputImgCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 30%;
                padding: 5px;
                border: 1px solid #bdcbfb;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                font-size: 14px;
                img {
                  height: 10px;
                  width: 15px;
                }
                .numberSelector {
                  border: none;
                  outline: none;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .numberInput {
                width: 65%;
                outline: none;
                border: 1px solid #bdcbfb;
                font-size: 14px;
                padding: 4px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
          }
        }
        .createFacilityInputsRight {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .inputsCont {
            display: flex;
            flex-direction: column;
            height: 10vh;
            position: relative;
            p {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 5px;
            }
            .facilityInputs {
              outline: none;
              border: 1px solid #bdcbfb;
              border-radius: 5px;
              font-size: 14px;
              padding: 4px;
              width: 97%;
            }
            .sizeInputCont {
              display: flex;
              flex-direction: row;
              align-items: center;
              .sizeInput {
                width: 70%;
                outline: none;
                border: 1px solid #bdcbfb;
                font-size: 14px;
                padding: 4px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }
              .sizeInputSelectCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 27%;
                padding: 5px;
                border: 1px solid #bdcbfb;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                font-size: 14px;
                .sizeSelector {
                  border: none;
                  outline: none;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }
            .iconAdd {
              position: absolute;
              right: 15px;
              bottom: 12px;
              height: 15px;
              width: 20px;
            }
            .facilityTypeSelector {
              outline: none;
              border: 1px solid #bdcbfb;
              border-radius: 5px;
              font-size: 14px;
              padding: 5px;
              width: 97%;
            }
            .facilityGroupSelector {
              outline: none;
              border: 1px solid #bdcbfb;
              border-radius: 5px;
              font-size: 14px;
              width: 97%;
            }
          }
        }
      }
      .mapContainer {
        height: 25vh;
        background-color: wheat;
        margin: 5px 0px 5px 0px;
      }
      .dircetionInputsCont {
        display: flex;
        flex-direction: row;
        .latitudeInputCont {
          width: 50%;
          .inputsCont {
            display: flex;
            flex-direction: column;
            p {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 5px;
            }
            input {
              outline: none;
              border: 1px solid #bdcbfb;
              border-radius: 5px;
              font-size: 14px;
              padding: 4px;
              width: 95%;
            }
          }
        }
        .longitudeInputCont {
          width: 50%;
          .inputsCont {
            display: flex;
            flex-direction: column;
            p {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 5px;
            }
            input {
              outline: none;
              border: 1px solid #bdcbfb;
              border-radius: 5px;
              font-size: 14px;
              padding: 4px;
              width: 97%;
            }
          }
        }
      }
    }
  }
  .createFacilityBasicInfoCont::-webkit-scrollbar {
    display: none;
  }
}
.createFacilityPopFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .editSwitchCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin: 0px;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .createFacilityFooterBtns {
    display: flex;
    flex-direction: row;
    gap: 5px;
    .cancelBtn {
      border: 1px solid gray;
      background-color: #fcf2da;
      color: black;
      font-size: 13px;
      padding: 5px 10px 5px 10px;
      border-radius: 2px;
      font-weight: 500;
    }
    .nextBtn {
      color: white;
      font-size: 13px;
      padding: 5px 15px 5px 15px;
      background-color: #0f43f9;
      border-radius: 2px;
      border: none;
      font-weight: 400;
    }
  }
}


//spinner styles
.spinnerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    border: 4px solid rgba(4, 20, 247, 0.1);
    border-top: 4px solid #3905f7;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toastTextContainer {
  .toastText {
    font-size: 12px;
    color: red;
    margin-top: 5px;
    // margin-left: 10px;
    // margin-right: 10px;
  }
}

.respone {
  font-size: 15px;
  color: #000;
}


