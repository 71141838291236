.facilityGroupsFilterBtnCont {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-left: 15px;
  //   border: 1px solid yellowgreen;
  .facilityGroupsBtnsContainer {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 40px;
    gap: 5px;
    position: relative;
    .collapseAndExpandBtnsCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .collapseBtn {
        font-size: 13px;
        padding: 3px 10px 3px 10px;
        border: 1px solid #707070;
        background-color: #ffffff;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-right: none;
        font-weight: 500;
        img {
          height: 12px;
          width: 12px;
          margin-right: 3px;
        }
      }
      .expandBtn {
        font-size: 13px;
        padding: 3px 10px 3px 10px;
        border: 1px solid #707070;
        background-color: #ffffff;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        font-weight: 500;
        img {
          height: 12px;
          width: 12px;
          margin-right: 3px;
        }
      }
    }
    .addNewGroupBtn {
      border: none;
      background-color: #0f43f9;
      color: #ffffff;
      font-size: 14px;
      padding: 5px 10px 5px 10px;
      border-radius: 2px;
      font-weight: 300;
      img {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }
  }
}
.facilityGroupsComponentCont {
  height: 60%;
  //   border: 1px solid yellow;
}
.facilityGroupsFooterCont {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-right: 30px;
  gap: 10px;
  .discardBtn {
    font-size: 13px;
    background-color: #fcf2da;
    border: 1px solid #cfc8b8;
    border-radius: 3px;
    padding: 4px 12px 4px 12px;
  }
  .updateBtn {
    font-size: 13px;
    background-color: #0f43f9;
    border-radius: 3px;
    color: #ffffff;
    padding: 4px 12px 4px 12px;
    border: 1px solid #0f43f9;
  }
}
.facilityAddNewGroupPopupCont {
  background-color: white;
  border: 1px solid #00000029;
  position: absolute;
  right: 0px;
  top: 35px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  box-shadow: 1px 5px 7.5px #00000029;
  width: 15vw;
  z-index: 100;
  .facilityAddGroupPopHeaderCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .facilityAddGroupPopHeader {
      margin: 0px;
      font-size: 15px !important;
      font-weight: 000 !important;
    }
    .closeIconDiv {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 10px;
        width: 10px;
        cursor: pointer;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .facilityAddGroupInputCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5px;
    p {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
      padding-bottom: 3px;
    }
    input {
      outline: none;
      border: 1px solid #bdcbfb;
      border-radius: 5px;
      width: 100%;
      padding: 4px;
      font-size: 13px;
    }
  }
  .facilityAddGroupSelectCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5px;
    p {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
      padding-bottom: 3px;
    }
    select {
      outline: none;
      border: 1px solid #bdcbfb;
      border-radius: 5px;
      outline: none;
      border: 1px solid #bdcbfb;
      border-radius: 5px;
      width: 100%;
      padding: 5px;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .facilityAddGroupBtnCont {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
    .facilityAddGroupBtn {
      border: none;
      background-color: #0f43f9;
      color: #ffffff;
      font-size: 12px;
      padding: 5px 10px 5px 10px;
      border-radius: 2px;
      font-weight: 300;
      img {
        height: 12px;
        width: 12px;
        margin-right: 5px;
      }
    }
  }
}

// Update Popup
.facilityGroupsUpdatePopup {
  div {
    border: none;
    border-radius: 15px;
  }
}
.updatePopupHeaderCont {
  padding: 30px 35px 10px 35px !important;
  .updatePopupHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.updateFacilityGroupPopupBody {
  padding: 0px 35px 0px 35px !important;
  .updatePopupBodyContainer {
    display: flex;
    flex-direction: column;
    .updatePopupBodyHeader {
      margin: 0px;
      font-size: 14px;
      font-weight: 500;
    }
    .facilityGroupingContainer {
      border-left: 5px solid #6f8efb;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 10px;
      padding: 5px 10px 5px 20px;
      background-color: #6f8efb4f;
      border-radius: 5px;
      .facilityGrouping {
        display: flex;
        flex-direction: row;
        align-items: center;
        .facilityGroupCityCont {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 30%;
          .facilityGroupCity {
            margin: 0px;
            font-size: 15px;
            font-weight: 500;
          }
          img {
            height: 15px;
            width: 20px;
          }
        }
        .facilityGroupRegionsCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 70%;
          justify-content: flex-start;
          gap: 15px;
          padding-left: 10px;
          .facilityGroupRegion1 {
            margin: 0px;
            font-size: 15px;
            font-weight: 500;
            text-decoration: line-through;
          }
          .facilityGroupRegion2 {
            margin: 0px;
            font-size: 15px;
            font-weight: 500;
          }
          img {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
}
.updateFacilityGroupPopupFooter {
  padding: 10px 35px 30px 0px !important;
  .updatePopupCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: black;
    font-weight: 500;
  }
  .updatePopupConfirmBtn {
    border: none;
    background-color: #055cc7;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 300;
  }
}
