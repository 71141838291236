.horizontalData {
  padding: 20px 10px;
  margin-bottom: 20px;
  .radio-input-warpper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
    margin-right: 25px;
    label {
      font-size: 12px;
      padding-left: 5px;
      line-height: 16px;
      padding-right: 10px;
    }
  }
  .echarts-for-react {
    height: 230px !important;
  }
  .table > :not(caption) > * > * {
    padding: 0 !important;
    border-bottom-width: 0px !important;
  }
  .table > :not(:first-child) {
    border: 0 !important;
  }
  table {
    margin-bottom: 0px;
  }
  tbody {
    td {
      &:first-child {
        width: 70%;
      }
    }
  }
  .first-th {
    display: flex;
  }
  ul {
    width: 100%;
    list-style-type: none;
    margin: 10px 0px 0px;
    padding: 0;
  }
  li {
    font-size: 0.875rem;
    margin-bottom: 19px;
    color: #182f43;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  svg {
    font-size: 12px;
  }
  h6 {
    line-height: 14px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    img {
      width: 8px;
      margin-left: 3px;
    }
  }
}
.mt-10 {
  margin-top: 10px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-0 {
  margin-bottom: 0px;
}
.dataPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  svg {
    &:not(.inactive) {
      cursor: pointer;
    }
    &.inactive {
      opacity: 0.3;
    }
    color: #3c3434;
    font-size: 1.125rem;
    padding: 0 10px;
  }
}
.horizontalScroll {
  overflow-x: auto;
  white-space: nowrap;
}
.form-check {
  margin-right: 10px;
}
.cursPointer {
  cursor: pointer;
}
.tabTitle {
  display: flex;
  padding-left: 5px;
  h6 {
    margin: 0px 5px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
    &.active {
      font-weight: 700;
      border-bottom: 2px solid #050505;
    }
  }
}

@media only screen and (max-width: 640px) {
  .xsScroll-wrapper {
    overflow-x: auto;
    width: 280px;
  }
  .dataPagination {
    left: 50%;
  }
  .first-th {
    width: 280px;
  }
  .horizontalData {
    .radio-input-warpper {
      margin-bottom: 10px;
      justify-content: flex-start;
      label {
        font-size: 10px;
      }
    }
  }
}
