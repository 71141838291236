.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(545px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 22px;
}
.facility_name{
  font-size: 18px !important;
  margin-left: 30px;
  font-weight: 500 !important;
}

.loaderStyleClass{
  height: 35vh;
  display: flex;
  justify-content: center
} 

.tempCardContainer {
  padding: 15px;
   padding-top: 20px
}
.tempBarCardContainer {
  display: flex;
  width: 96%;
  flex-direction: row;
  justify-content: center;
  align-items: center 
}

.tempBarCardSubContainer {
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
  justify-content: center;
  align-items: center,
}
.tempBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.tempBarContainerOne {
  display: flex;
  flex-direction: row;
  align-items: center,
}
.tempHeadingOne {
  font-size: 8px;
  transform: rotate(-90deg);
  margin: 0px;
  width: 1vw;
}
.previousButton {
  font-weight: bold;
  font-size: 20px;
  color: #000;
  background-color: #FFFFFF;
  outline: none;
  border: none;
  margin: 0% 1% 3% 1%;;
  z-index: 9999;
}
.paginationText {
  font-size: 13px;
  color: #000;
  margin-left: 8;
  margin-right: 8;
  background-color: #FFFFFF;
  outline: none;
  border: none
}

.tempBarCardContainerOne {
  width: 30%
}

.textHeading {
  font-weight: bold;
  margin-top: 20px;
  margin-left: 10px;
  }
.tempSubHeading {
  font-size: 12px;
  margin-top: 2px;
  color: #000; 
}

.tempheading {
  margin-bottom: 5%;
  margin-Top: 5%
}

.d-flex-titleCard {
  display: flex;
}
.facility_Name{
  font-size: 20px !important;
  padding: 20px;
  font-weight: 400 !important;
}
.dowCust{
  background: rgba(0, 117, 255, 0.2);
  padding: 5px 10px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 6px;
  border: 0px solid rgba(0, 117, 255, 0.2);
  height: 4vh;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 15px;
  img {
    height: 18;
    width: 12
  }
}

.eventSummaryBtn {
  border: none;
  outline: none;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 2%;
  padding: 3px;
  width: 30%;
  margin-top: 4vh;
  opacity: 0.8;
  border-width: 0px;
  transition: opacity 0.5 ease;
  transition: border-color 0.5 ease;
  transition: color 0.5s border-width 1s ease;
  img {
      filter: grayscale(1);
      margin-right: 5%;
  }
}.active {
  border: 2px solid #007bff;
  color: #007bff;
  opacity: 1;
  img {
      filter: sepia(100%) hue-rotate(190deg) saturate(900%);
  }
}

.loderStyleContainer {
  height: 35vh;
  display: flex;
  justify-content: center
}

.cardWrapper-distTab {
  .tabTitleBox-wrapper {
    .border-sty {
      border-bottom: 0px solid #ffffff;
      padding: 5px;
      cursor: pointer;
      min-width: 20vh;
      text-align: center;
      .distributionTab-active {
        background: #0f43f9 0% 0% no-repeat padding-box;
        border-radius: 3px;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 7px;
      }
      .distributionTab {
        color: #000000;
        padding: 7px;
        &:hover {
          background: #0f43f9;
          border-radius: 3px;
          color: #ffffff;
        }
      }
    }
    .active-Border {
      cursor: pointer;
      border-bottom: 2px solid #0f43f9;
      padding: 5px;
      min-width: 20vh;
      text-align: center;
      .distributionTab-active {
        background: #0f43f9 0% 0% no-repeat padding-box;
        border-radius: 3px;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 7px;
      }
      .distributionTab {
        color: #000000;
        padding: 7px;
      }
    }
    .tabTitleBox-wrapper-active {
      border-bottom: 1px solid #0f43f9;
    }
  }
}

.dDSStyleScsss {
  display: flex;
  flex-direction: row;
  margin-top: 4%;
  justify-content: flex-start;
  margin-bottom: 5%;
  flex-wrap: wrap;
}

.CardWrapper {
  .snackchart-card {
    .SnackCard {
      min-height: 40vh;
      height: 38vh;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0%;
      align-items: center;
      margin-top: 8%;
    }
  }
}

.border-hr {
  margin: 0.2rem 0;
  opacity: 0.1;
}

.arrow-link {
  .arrowStatus {
    .viewAll {
      position: absolute;
      left: 420px;
      bottom: 8px;
      font-size: 12px;
    }
  }
}

.arrow-icon-button {
  border: none;
  background-color: #ffffff;
}

.backAndCoutnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .backImgBtnCont {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .backBtnTxt {
      position: relative;
      top: 7px;
      left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .incidentsCount {
    font-size: 13px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 600px) {
  .d-flex-titleCard {
    display: flex;
    flex-direction: column;
  }

  .border-hr {
    display: none;
  }

  .dDSStyleScsss {
    flex-direction: column;
  }

  .arrow-link .arrowStatus .viewAll {
    left: 175px;
  }

  .actual_view {
    display: flex;
    flex-direction: row;
    .actual_view_pf {
      position: relative;
      left: 58px;
    }
  }

  .arrow-icon-button {
    position: relative;
    bottom: 30px;
  }
  .dowCust{
    background: rgba(0, 117, 255, 0.2);
    padding: 5px 16px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 6px;
    border: 0px solid rgba(0, 117, 255, 0.2);
    height: 6vh;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .facility_name{
    font-size: 18px !important;
    margin-left: 10px;
    font-weight: 500 !important;
  }
}


// @media only screen and (max-width: 600px) {
//   .d-flex-titleCard {
//     display: flex;
//     flex-direction: column;
//   }

//   .border-hr {
//     display: none;
//   }

//   .dDSStyleScsss {
//     flex-direction: column;
//   }

//   .arrow-link .arrowStatus .viewAll {
//     left: 175px;
//   }

//   .actual_view {
//     display: flex;
//     flex-direction: row;
//     .actual_view_pf {
//       position: relative;
//       left: 58px;
//     }
//   }

//   .arrow-icon-button {
//     position: relative;
//     bottom: 30px;
//   }
// }
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .arrow-link {
    .arrowStatus {
      .viewAll {
        left: 580px;
      }
    }
  }
}

// .testchartAnovi {
//   background-color: #0f43f9;
//   box-sizing: border-box;
//   padding-top: 10%;
// }