.assetFlex{
  display: flex;
  margin-top: 4px;
  height: 65vh;
}

.rrr{
  padding-right: 0.1rem !important;
  padding-left: 0.1rem !important;
}

.sss{
  width: 100%;
  margin-left: 0 !important;
  overflow-y: scroll;
  cursor: pointer !important;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: #C5C8ED ;
    border-radius: 180px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C5C8ED ;
    border-radius: 180px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #999FDD;
  }
}

.loadMoreAssets{
  display: flex;
  justify-content: center;
  align-items: center;

  .loadMore{
    color: #ffffff !important;
    width: 100px;
  }
}

.dropDown_scss{
  display: flex;
  align-items: baseline;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 40px;
  z-index: 999;
  background-color: rgb(225, 227, 240);
}

.filterBy {

  color: #000;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 1.2rem;

}

.apply {
  width: 100px !important;
  background-color: #0575e6;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-family: Inter-Bold, sans-serif;
  font-size: 14px;
  margin-right: 10px;
  margin-left: 6px;
  padding: 3px 18px;
  border: none;
  &:hover {
    width: 100px !important;
    color: white;
    background-color: #1a8bfd;
    border: 0px solid #0575e6;
  }

  &:disabled,
  &[disabled]{
    width: 100px !important;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
}

.link-danger{
  font-size: 14px;
  cursor: pointer;
  width: 100px !important;
  padding: 0 0 !important;
}


.custom-placeholder{
  display: flex;
  flex-direction: row;
  .count{
    padding: 0 6px;
    margin-left: 6px;
    border-radius: 4px;
    font-size: 12px;
    background: #e1e3f0;
    color: #000000;
  }
}

.loadingBox2 {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
