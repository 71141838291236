.eventCardCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .eventHeaderCont {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #d8dbed;
    height: 5vh;
    width: 100%;

    p {
      font-size: 13px;
      font-weight: 600;
      color: black;
      position: relative;
      top: 8px;
    }
  }

  .cardDetails {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    height: 5vh;
    width: 100%;
    border: 1px solid #e6e6e6;

    .sNoTxt {
      font-size: 10px;
      font-weight: 500;
      color: black;
      position: relative;
      top: 8px;
      left: 10px;
    }

    .startTimeTxt {
      font-size: 10px;
      font-weight: 500;
      color: black;
      position: relative;
      top: 8px;
      left: 16px;
    }

    .endTimeTxt {
      font-size: 10px;
      font-weight: 500;
      color: black;
      position: relative;
      top: 8px;
      right: 10px;
    }

    .durationTxt {
      font-size: 10px;
      font-weight: 500;
      color: black;
      position: relative;
      top: 8px;
      right: 10px;
    }
  }

  .viewAllCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .viewAllTxt {
      font-size: 10px;
      color: #426bfa;
      font-weight: 600;
      padding: 10px 10px 0px 2px;
      cursor: pointer;
    }
  }
}

.typeModalHeaderCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .typeModalHeader {
    font-size: 20px;
    color: black;
    font-weight: 600;
  }

  .typeModalPara {
    font-size: 15px;
    color: black;
    font-weight: 500;
  }
}

.typeModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .eventCardContPopup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .eventHeaderContPoup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      background-color: #d8dbed;
      height: 5vh;
      width: 100%;

      .tableTabOne {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 10%;
        height: 5vh;
        p {
          margin-top: 16%;
          margin-right: 6%;
          font-size: 13px;
          font-weight: 600;
          color: black;
          text-align: center;
        }
      }

      .tableTab {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 30%;
        height: 5vh;
        p {
          margin-top: 6%;
          margin-right: 2%;
          font-size: 13px;
          font-weight: 600;
          color: black;
          text-align: center;
        }
      }
    }

    .cardDetailsPopup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      background-color: white;
      height: 5vh;
      width: 100%;
      border: 1px solid #e6e6e6;

      .tableTabOne {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 10%;
        padding: 5px;
        padding-top: 2%;
        height: 5vh;

        p {
          font-size: 12px;
          margin-top: 7%;
          color: black;
          text-align: center;
        }
      }

      .tableTab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        width: 30%;
        padding: 5px;
        height: 5vh;

        p {
          font-size: 12px;
          margin-top: 7%;
          color: black;
          text-align: center;
        }
      }

      // .startTimeTxtPopup {
      //   font-size: 10px;
      //   font-weight: 500;
      //   color: black;
      //   position: relative;
      //   top: 8px;
      //   left: 16px;
      // }
      // .endTimeTxtPopup {
      //   font-size: 10px;
      //   font-weight: 500;
      //   color: black;
      //   position: relative;
      //   top: 8px;
      //   right: 10px;
      // }
      .durationTxtPopup {
        font-size: 10px;
        font-weight: 500;
        color: black;
        position: relative;
        top: 8px;
        right: 10px;
      }
    }
  }

  .modalBtnCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .modalBtn {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      padding: 3px 10px 3px 10px;
      border-radius: 5px;
      border: none;
      background-color: Blue;
    }
  }
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  min-width: 50vw !important;
}

.modal-body {
  min-width: 50vw;
}

//.modal-content {
//  position: relative;
//  display: flex;
//  flex-direction: column;
//  //width: 100%;
//  color: var(--bs-modal-color);
//  pointer-events: auto;
//  background-color: var(--bs-modal-bg);
//  background-clip: padding-box;
//  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
//  border-radius: var(--bs-modal-border-radius);
//  outline: 0;
//  //align-items: center;
//  //justify-content: center;
//  //height: 82vh !important;
//}

///table header sorting icon css 
.up-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-bottom: solid 7px black;
  border-top-width: 0;
  cursor: pointer;
}

.down-arrow {
  width: 0;
  height: 0;
  margin-top: 2px;
  border: solid 5px transparent;
  background: transparent;
  border-top: solid 7px black;
  border-bottom-width: 0;
  cursor: pointer;
}


.modal-body {
  overflow-y: auto;
  margin-top: -30px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Modal.Header .close {
  border: 0 !important;
  background-color: #faebd700 !important;
}

.modalBtn {
  border: 0 !important;
  background-color: white !important;
}

.close {
  border: 0 !important;
  background-color: #ffffff00 !important;
  font-size: 30px;
}

.bodytag {
  width: 100%;
  height: 55vh;
  overflow-y: scroll;
}

// //mode popup


// .model {
//   height: 100% !important;
//   display: block;
// }

// .modal.show .modal-dialog {
//   -webkit-transform: none;
//   transform: none;
//   background-color: white !important;
//   padding: 10px !important;
// }