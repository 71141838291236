.dateInputCardContainer {
    min-width: 10vw;
    margin-left: 10px;
    .tempInputTitleText {
        font-size: 0.8rem;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }

    .styleInp {
        border: 2px solid #a7b8f4;
        display: flex;
        flex-direction: row;
        //justify-content: space-between;
        align-items: center;
        height: 4.5vh;
        border-radius: 3px;

        .inputTempTextValue {
            width: 85%;
            border-left: 0;
            border-top: 0;
            border-bottom: 0;
            border-right: 2px solid #a7b8f4;
            font-size: 0.8rem;
            color: #000000;
            outline: none;
            padding: 0px 0px 0px 5px;
        }

        ::-moz-focus-inner {
            outline: none;
        }
        .calenderIcon {
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .applyButton {
            background-color: transparent;
            border-width: 2px;
            border: 1px solid #038aff;
            border-radius: 30px;
            font-size: 12px;
            padding: 3px;
            padding-left: 15px;
            padding-right: 15px;
            font-weight: 550;
            outline: none;
            z-index: 9999;
        }
    }
}

.warningMsgClass {
    display: flex;
    justify-content: center;
    align-items: center;
    .warningMsg {
        color: #ff0000;
        font-size: 0.7rem;
        font-family: sans-serif;
        margin: 0;
        padding: 0;
    }
}

.selectCustomDate {
    position: absolute;
    left: 0;
    border: 1px solid #a1a1a1;
    border-radius: 4px;
    left: 23% !important;
}

.applyButtonOpacity{
    opacity: 0.3;
    cursor: not-allowed;
    background-color: transparent;
    border-width: 2px;
    border: 1px solid #038aff;
    border-radius: 30px;
    font-size: 12px;
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 550;
    outline: none;
    z-index: 9999;
}


@media only screen and  (min-width: 768px) { 
    .selectCustomDate {
        bottom: 29% !important;
        left: 41% !important;
    }
}


@media only screen and  (min-width: 992px) and (max-width: 1024px) {
    .selectCustomDate {
        bottom: 29% !important;
        left: 32% !important;
    }
 }

@media only screen and  (min-width: 1025px) and (max-width: 1279px)  { 
    .selectCustomDate {
        bottom: 32% !important;
        left: 24% !important;
    }
}


@media only screen and  (min-width: 1280px)  { 
    .selectCustomDate {
        bottom: 0% !important;
        left: 26% !important;
    }
}

@media only screen and  (min-width: 1281px) and  (max-width: 1440px) { 
    .selectCustomDate {
        bottom: 32% !important;
        left: 24% !important;
    }
}

@media only screen and  (min-width: 1441px) { 
    .selectCustomDate {
        bottom: 32% !important;
        left: 18% !important;
    }
}