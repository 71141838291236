.facilityCardContainer {
  border: 1px solid #bdcbfb;
  display: flex;
  flex-direction: column;
  width: 24%;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  .facilityCardInputCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    .facilityCheckbox {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 50%;
      border: 2px solid #bbb;
      width: 16px;
      height: 16px;
      outline: none;
    }
    .facilityCardStatusCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .enableTxt {
        margin: 0px;
        background-color: #aadeac;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }
      .diseableTxt {
        margin: 0px;
        background-color: #ff967e;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }
      .facilityCardHoverImgCont {
        position: relative;
        img {
          height: 12px;
          width: 25px;
        }
        .facilityCardHoverCont {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0px;
          background-color: white;
          border-radius: 5px;
          position: absolute;
          border: 1px solid gray;
          top: 20px;
          right: 0px;
          width: 115px;
          transition: all 0.2s ease-in;
          display: none;
          li {
            margin: 0px;
            font-size: 11px;
            font-weight: 500;
            border-bottom: 1px solid gray;
            width: 100%;
            padding: 3px 15px 3px 15px;
            cursor: pointer;
          }
          li:hover {
            background-color: #e7ecff;
            border-radius: 2px;
          }
        }
      }
      .facilityCardHoverImgCont:hover {
        .facilityCardHoverCont {
          display: block;
        }
      }
    }
  }
  .facilityCardDetailsCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 15px;
    padding: 5px 0px 10px 10px;
    .facilityIdTxt {
      margin: 0px;
      font-size: 10px;
      background-color: #ffc4ab;
      color: #131523;
      font-weight: 500;
      padding: 1px 3px 1px 3px;
      border-radius: 3px;
      font-weight: 500;
    }
    .facilityNameTxt {
      margin: 0px;
    font-size: 12px;
    text-transform: uppercase;
    color: #2e8ece;
    font-weight: 700;
    word-break: break-all;
    }
    .facilityAddressTxt {
      margin: 0px;
      font-size: 12px;
      color: #131523;
      font-weight: 500;
    }
    .facilityCardTypeDetailsCont {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 95%;
      margin-top: 2px;
      .facilityCardTypeDetails {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #425560;
        grid-gap: 6px;
        gap: 6px;
        padding: 0px 10px 0px 5px;
        border-radius: 3px;
        img {
          height: 11px;
          width: 13px;
        }
        p {
          margin: 0px;
          font-size: 11px;
          font-weight: 400;
          color: #425560;
        }
      }
      .facilityCardCountCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 3px;
        p {
          margin: 0px;
          font-size: 12px;
          font-weight: 500;
        }
        img {
          height: 15px;
          width: 20px;
        }
      }
    }
  }
  .facilityCardFotterCont {
    border-top: 1px solid #bdcbfb;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 3px 0px 3px 10px;
    .assetsIconCont {
      height: 15px;
      width: 15px;
      background-color: #e4f6ff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      img {
        height: 13px;
        width: 15px;
      }
    }

    p {
      font-size: 12px;
      margin: 0px;
      font-weight: 500;
    }
  }
}
.facilityCardContainer:hover {
  border: 1px solid #2680eb;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
}
.facilityCardContainerActive {
  border: 1px solid #bdcbfb;
  display: flex;
  flex-direction: column;
  width: 24%;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  .facilityCardInputCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    .facilityCheckbox:checked {
      clip-path: circle(46% at 50% 50%);
      border-radius: 50%;
      width: 16px;
      height: 16px;
      outline: none;
      cursor: pointer;
    }
    .facilityCardStatusCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .enableTxt {
        margin: 0px;
        background-color: #aadeac;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }
      .diseableTxt {
        margin: 0px;
        background-color: #ff967e;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }
      .facilityCardHoverImgCont {
        position: relative;
        img {
          height: 12px;
          width: 25px;
        }
        .facilityCardHoverCont {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0px;
          background-color: white;
          border-radius: 5px;
          position: absolute;
          border: 1px solid gray;
          top: 20px;
          right: 0px;
          width: 115px;
          transition: all 0.2s ease-in;
          display: none;
          li {
            margin: 0px;
            font-size: 11px;
            font-weight: 500;
            border-bottom: 1px solid gray;
            width: 100%;
            padding: 3px 15px 3px 15px;
            cursor: pointer;
          }
          li:hover {
            background-color: #e7ecff;
          }
        }
      }
      .facilityCardHoverImgCont:hover {
        .facilityCardHoverCont {
          display: block;
        }
      }
    }
  }
  .facilityCardDetailsCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 15px;
    padding: 5px 0px 10px 10px;
    .facilityIdTxt {
      margin: 0px;
      font-size: 10px;
      background-color: #f5b8b8;
      color: #131523;
      font-weight: 500;
      padding: 1px 3px 1px 3px;
      border-radius: 3px;
      font-weight: 500;
    }
    .facilityNameTxt {
      margin: 0px;
      font-size: 12px;
      text-transform: uppercase;
      color: #2e8ece;
      font-weight: 700;
      word-break: break-all;
    }
    .facilityAddressTxt {
      margin: 0px;
      font-size: 12px;
      color: #131523;
      font-weight: 500;
    }
    .facilityCardTypeDetailsCont {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 95%;
      margin-top: 2px;
      .facilityCardTypeDetails {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid #425560;
        grid-gap: 6px;
        gap: 6px;
        padding: 0px 10px 0px 5px;
        border-radius: 3px;
        img {
          height: 11px;
          width: 13px;
        }
        p {
          margin: 0px;
          font-size: 11px;
          font-weight: 400;
          color: #425560;
        }
      }
      .facilityCardCountCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 3px;
        p {
          margin: 0px;
          font-size: 12px;
          font-weight: 500;
        }
        img {
          height: 15px;
          width: 20px;
        }
      }
    }
  }
  .facilityCardFotterCont {
    border-top: 1px solid #bdcbfb;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 3px 0px 3px 10px;
    .assetsIconCont {
      height: 15px;
      width: 15px;
      background-color: #e4f6ff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      img {
        height: 13px;
        width: 15px;
      }
    }

    p {
      font-size: 12px;
      margin: 0px;
      font-weight: 500;
    }
  }
}

//View Facility Popup
.viewFacilityPopupModal {
  z-index: 1050 !important;
  div {
    border: none;
    border-radius: 15px;
  }
}
.viewFacilityHeaderCont {
  padding: 30px 0px 5px 0px !important;
  border-bottom: 1px solid #bdcbfb !important;
  border-radius: 0px !important;
  margin: 0px 50px 0px 50px;
  .facilityDetailsHeaderCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-bottom: 5px;
    .facilityIcon {
      height: 20px;
      width: 20px;
    }
    .facilityDetailsContainer {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .facilityDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .facilityNameTxt {
          margin: 0px;
          font-size: 20px;
          font-weight: 600;
        }
        .facilityAddressTxt {
          margin: 0px;
          font-size: 13px;
          font-weight: 400;
        }
      }
      .facilityUseTxt {
        font-size: 13px;
        text-transform: uppercase;
        margin: 0px;
        padding-top: 8px;
      }
    }
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .viewFacilityCloseIcon {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.viewFacilityPopupBody {
  padding: 15px 0px 5px 50px !important;
  .viewFacilityBody {
    display: flex;
    flex-direction: column;
    .facilityInfoCont {
      display: flex;
      flex-direction: column;
      .facilityInfoHeader {
        margin: 0px;
        font-size: 16px;
        font-weight: 600;
      }
      .facilityInfoHeaderBorder {
        border: 1px solid black;
        width: 78px;
      }

      .facilityInformationDetailsCont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 15px;

        .facilityInfoDetails {
          width: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding-bottom: 5px;
          padding-top: 5px;
          .facilityInfoHeaderDiv {
            width: 50%;
            .facilityInfoHeader {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 400;
              color: #707070;
            }
          }
          .facilityInfoParaDiv {
            width: 50%;
            .facilityInfoPara {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              word-break: break-word;
            }
          }
        }
      }
    }

    .attributesInfoCont {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      .attributesInfoHeader {
        margin: 0px;
        font-size: 16px;
        font-weight: 500;
      }
      .attributesInfoHeaderBorder {
        border: 1px solid black;
        width: 78px;
      }

      .attributesInfoDetailsCont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 15px;
        .attributeInfoDetails {
          width: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding-bottom: 5px;
          padding-top: 5px;
          .attributeInfoHeaderDiv {
            width: 50%;
            .attributeInfoHeader {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 400;
              color: #707070;
            }
          }
          .attributeInfoParaDiv {
            width: 50%;
            .attributeInfoPara {
              width: 100%;
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
.viewFacilityPopupFooter {
  padding: 0px 50px 30px 0px !important;
  .viewFacilityEditBtn {
    border: 1px solid #5f5b5b;
    background-color: #ffffff;
    font-size: 12px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    color: #000000;
    img {
      height: 12px;
      width: 12px;
      margin-right: 10px;
    }
  }
  .viewFacilityDelete {
    border: 1px solid #ea5037;
    background-color: #ff5539;
    font-size: 12px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    color: #ffffff;
    font-weight: 200;
    img {
      height: 12px;
      width: 12px;
      margin-right: 5px;
    }
  }
}

// Edit Facility Popup
.editFacilityPopupModal {
  margin-left: -50px !important;
  z-index: 1050 !important;
  div {
    width: 46vw;
    border: none;
    border-radius: 15px;
  }
}
.editFacilityPopHeader {
  padding: 35px 35px 10px 35px !important;
  .editFacilityHeader {
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.editFacilityPopBody {
  padding: 10px 35px 0px 35px !important;
  .editFacilityBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .editFacilityHeaderNav {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 25px;
      border-bottom: 1px solid #d7dbec;
      width: 100%;
      border-radius: 0px !important;
      .editFacilityNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #373f6d;
      }
      .activeEditFacilityNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #0075ff;
        border-bottom: 1px solid #0075ff;
      }
    }
    .facilityBasicInfoCont {
      height: 50vh;
      width: 100%;
      overflow-y: scroll;
      border-radius: 0px !important;
      padding-top: 10px;
      .basicInfoCont {
        height: 70vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        .basicInfoInputsCont {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          .textInputsCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            input {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 5px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              width: 90%;
            }

            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .emailSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              left: 90px;
              top: 0px;
              z-index: 1000;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                img {
                  height: 10px;
                  width: 10px;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .selectorInputCont {
            width: 50%;
            // border: 1px solid brown;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            select {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 6px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              // color: #cccccc;
              width: 90%;
              option {
                font-size: 13px;
                font-weight: 500;
                color: #000;
              }
            }
          }
          .phoneNumberInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .selectAndInputCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              .imgAndSelectCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #bdcbfb;
                outline: none;
                width: 25%;
                padding: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                img {
                  height: 10px;
                  width: 15px;
                }
                select {
                  border: none;
                  outline: none;
                  font-size: 13px;
                  font-weight: 500;
                  border-radius: 0px !important;
                  option {
                    font-size: 13px;
                    font-weight: 500;
                    color: #000;
                  }
                }
              }
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-left: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .phoneNumberSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              left: 90px;
              top: 0px;
              z-index: 1000;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                img {
                  height: 10px;
                  width: 10px;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .sizeFacilityInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .inputAndSelectCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: none;
              }
              select {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 6px;
                font-size: 13px;
                font-weight: 500;
                width: 25%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                option {
                  font-size: 13px;
                  font-weight: 500;
                  color: #000;
                }
              }
            }
          }
          .addressInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .addressInputIconCont {
              display: flex;
              flex-direction: row;
              align-items: center;
              position: relative;
              width: 100%;
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 90%;
              }
              .editAddressIcon {
                height: 11px;
                width: 20px;
                cursor: pointer;
                position: absolute;
                right: 30px;
                background-color: white;
              }
            }
          }
        }
        .mapContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid grey;
          height: 15vh;
        }
        .latAndLongInputsCont {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          padding-top: 10px;
          .textInputsCont {
            width: 50%;
            // border: 1px solid brown;
            display: flex;
            flex-direction: column;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
            }
            input {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 5px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              width: 90%;
            }
          }
        }
      }
    }
    .facilityBasicInfoCont::-webkit-scrollbar {
      display: none;
    }
    .facilityAttributesCont {
      height: 50vh;
      width: 100%;
      border-radius: 0px !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 10px;
      .textInputsCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        input {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 5px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 90%;
        }
      }
      .unitOfEnergyInputCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        .selectAndInputCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          .imgAndSelectCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid #bdcbfb;
            outline: none;
            width: 27%;
            padding: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            img {
              height: 10px;
              width: 15px;
            }
            select {
              border: none;
              outline: none;
              font-size: 13px;
              font-weight: 500;
              border-radius: 0px !important;
              option {
                font-size: 13px;
                font-weight: 500;
                color: #000;
              }
            }
          }
          input {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 63%;
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
      .selectorInputCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        select {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 6px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 90%;
          option {
            font-size: 13px;
            font-weight: 500;
            color: #000;
          }
        }
      }
      .contractDemandInputCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        .inputAndSelectCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          input {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 65%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right: none;
          }
          select {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 6px;
            font-size: 13px;
            font-weight: 500;
            width: 25%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            option {
              font-size: 13px;
              font-weight: 500;
              color: #000;
            }
          }
        }
      }
    }
    .facilityUsersCont {
      width: 100%;
      height: 50vh;
      border-radius: 0px !important;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      .facilityUsersHeightCont {
        width: 100%;
        overflow-y: scroll;
        border-radius: 0px;
        .facilityUsersAddBtnCont {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          .facilityUsersAddBtn {
            background-color: #0f43f9;
            color: #ffffff;
            border: none;
            border-radius: 3px;
            padding: 3px 10px 3px 10px;
            font-size: 15px;
            font-weight: 300;
            img {
              height: 12px;
              width: 12px;
              margin-right: 8px;
            }
          }
        }
        .facilityUsersAndRoleContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          .facilityUsersAndRoleHeaders {
            display: flex;
            flex-direction: row;
            width: 100%;
            p {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              width: 48%;
              padding-bottom: 5px;
            }
          }
          .facilityUsersAndRoleSelectorsCont {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 20px;
            align-items: center;
            margin-bottom: 10px;
            .facilityUsersAndRoleSelector {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 6px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              width: 44%;
            }
            img {
              height: 20px;
              width: 15px;
              cursor: pointer;
            }
          }
        }
      }
      .facilityUsersHeightCont::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.editFacilityPopFooter {
  padding: 25px 30px 30px 30px !important;

  .editFacilityFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .enableAndDisableCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      p {
        margin: 0px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .editFacilityBtnsCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: 100%;
      justify-content: end;
      .facilityCancelBtn {
        border: 1px solid gray;
        background-color: #fcf2da;
        color: black;
        font-size: 13px;
        padding: 5px 10px 5px 10px;
        border-radius: 3px;
        font-weight: 500;
      }
      .facilityNextBtn {
        color: white;
        font-size: 13px;
        padding: 5px 15px 5px 15px;
        background-color: #0f43f9;
        border: 1px solid #5711e2;
        border-radius: 3px;
        border: none;
        font-weight: 300;
      }
      .facilityUpdateBtn {
        color: white;
        font-size: 13px;
        padding: 5px 15px 5px 15px;
        background-color: #0f43f9;
        border: 1px solid #5711e2;
        border-radius: 3px;
        border: none;
        font-weight: 300;
      }
    }
  }
  // .editFacilityFooter{
  //   display: flex;
  //   flex-direction: row;
  //   border: 1px solid yellow !important;
  //   align-items: center;
  //   justify-content: space-between;
  // }
}

// Add User Popup
.facilityAddUserPopup {
  div {
    border: none;
    border-radius: 15px;
    width: 44vw;
  }
}
.facilityAddUserHeaderCont {
  padding: 30px 40px 10px 40px !important;
  .facilityAddUserHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.facilityAddUserPopupBody {
  padding: 15px 40px 10px 40px !important;
  .facilityAddUserBodyCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    .facilityAddUserBodyHeader {
      margin: 0px;
      font-size: 16px !important;
      font-weight: 500 !important;
      padding-bottom: 15px;
    }
    .facilityAddUserDetailsCont {
      display: flex;
      flex-direction: column;
      width: 100%;
      .facilityAddUserDetails {
        display: flex;
        flex-direction: row;
        align-items: start;
        margin-bottom: 10px;
        width: 100%;
        .popUserTxt {
          width: 48%;
          margin: 0px;
          font-size: 16px;
          font-weight: 500;
        }
        .popRoleTxt {
          width: 48%;
          margin: 0px;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .facilityAddUserSelectorsDiv {
        width: 100%;
        height: 20vh;
        overflow-y: scroll;
        border-radius: 0px !important;
        .facilityAddUserSelectorsCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          width: 100%;
          .popUserSelect {
            width: 45%;
            outline: none;
            border: none;
            border: 1px solid #bdcbfb;
            padding: 5px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 500;
            color: black;
            option {
              font-size: 13px;
              font-weight: 400;
              color: black;
            }
          }
          .popRoleSelect {
            width: 45%;
            outline: none;
            border: none;
            border: 1px solid #bdcbfb;
            padding: 5px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 500;
            color: black;
            option {
              font-size: 13px;
              font-weight: 400;
              color: black;
            }
          }
          img {
            cursor: pointer;
            height: 20px;
            width: 14px;
            position: relative;
            right: 5px;
          }
        }
      }
      // .facilityAddUserSelectorsDiv::-webkit-scrollbar {
      //   //        display: none;
      // }

      .facilityAddUserDetailsBtnCont {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 5px;
        .facilityAddUserDetailsBtn {
          background-color: #0f43f9;
          margin: 0px;
          color: #ffffff;
          border: none;
          padding: 3px 10px 3px 10px;
          border-radius: 2px;
          font-weight: 400;
          font-size: 13px;
          img {
            height: 11px;
            width: 11px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.facilityAddUserFooterCont {
  padding: 5px 40px 30px 0px !important;
  .facilityAddUserCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 13px;
    padding: 5px 12px 5px 12px;
    border-radius: 2px;
    font-weight: 500;
  }
  .facilityAddUserMapUsersBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 13px;
    color: #ffffff;
    padding: 5px 12px 5px 12px;
    border-radius: 2px;
    font-weight: 400;
  }
}

//Delete Facility Popup
.deleteFacilityPopupContainer {
  div {
    border: none;
    border-radius: 15px;
  }
}
.deleteFacilityPopHeader {
  padding: 35px 35px 10px 35px !important;
  .deleteFacilityHeader {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin: 0px;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .deleteFacilityCloseIcon {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.deleteFacilityPopBody {
  padding: 10px 35px 10px 35px !important;

  .deleteFacilityBody {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    padding: 0px;
    .deleteFacilityBodyTxt {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.deleteFacilityFooterCont {
  padding: 0px 35px 25px 0px !important;

  .deleteFacilityBtn {
    background-color: #ff5539;
    border: 1px solid #ea5037;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 400;
    border: none;
  }
  .deleteFacilitycancelButton {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: black;
    font-weight: 500;
  }
}

//Disable Facility Popup
.disableFacilityPopupContainer {
  div {
    border: none;
    border-radius: 15px;
  }
}
.disableFacilityHeaderCont {
  padding: 35px 35px 10px 35px !important;
  .disableFacilityHeader {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin: 0px;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .disableFacilityCloseIcon {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.disableFacilityPopupBody {
  padding: 10px 35px 10px 35px !important;
  .disableFacilityBody {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    padding: 0px;
    .disableFacilityBodyTxt {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.disableFacilityPopupFooter {
  position: relative;
  padding: 0px 35px 25px 0px !important;
  .disableFacilityBtn {
    background-color: #ff5539;
    border: 1px solid #ea5037;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 300;
    border: none;
    img {
      height: 12px;
      width: 12px;
      margin-left: 10px;
    }
  }
  .disableFacilityCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px !important;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: black;
    font-weight: 500;
  }
  .disableFacilityFeaturesCont {
    position: absolute;
    background-color: white;
    top: 40px;
    left: 185px;
    box-shadow: 2px 2px 4px gray;
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    width: 20vw;
    .disableFacilityFeatures {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 5px 10px 5px 10px;
      cursor: pointer;

      .disableFacilityFeaturesHeader {
        margin: 0px;
        font-size: 13px;
        font-weight: 600;
      }
      .disableFacilityFeatureTxt {
        margin: 0px;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .disableFacilityFeatures:hover {
      background-color: #d4ecff;
      border: 1px solid #9ad2ff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

//Shift Assets Popup
.shiftAssetsModalPopup {
  div {
    border: none;
    border-radius: 15px;
    padding: 10px 15px 8px 10px;
  }
}
.shiftAssetsHeaderContainer {
  .shiftAssetsImgAndHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .shiftHoverImgCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px;
      transition: all 0.5s;
      .shiftAssetIcon {
        height: 15px;
        width: 15px;
        cursor: pointer;
      }
      .shiftAssetHoverIcon {
        display: none;
      }
    }
    .shiftHoverImgCont:hover {
      .shiftAssetHoverIcon {
        display: block !important;
        height: 13px;
        width: 15px;
        cursor: pointer;
      }
      .shiftAssetIcon {
        display: none !important;
      }
    }

    .shiftAssetsHeader {
      font-size: 20px !important;
      font-weight: 600 !important;
      margin: 0px;
    }
  }
  .closeIconDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
      width: 15px;
      height: 15px;
      cursor: pointer;
      margin-left: 5px;
      margin-bottom: 2px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.shiftAssetsBodyCont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .shiftAssetsBodyHeader {
    margin: 0px;
    font-size: 14px;
    font-weight: 700;
  }
  .shiftAssetSelect {
    outline: none;
    border: 1px solid #bdcbfb;
    width: 65%;
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
    color: #707070;
    font-weight: 500;
    option {
      height: 20vh;
    }
  }
  .shiftAssetSelect::-webkit-scrollbar {
    width: 8px;
  }
  .shiftAssetSelect::-webkit-scrollbar-thumb {
    background-color: #bdcbfb;
  }
  p {
    margin: 0px;
    font-size: 11px;
    font-weight: 600;
    span {
      color: #5711e2;
      text-decoration: underline;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
.shiftAssetsFooterCont {
  .shiftAssetsCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: black;
    font-weight: 500;
  }
  .shiftAssetsBtn {
    border: 1px solid #0075ff;
    background-color: #0075ff;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 400;
  }
}

// Edit Address Popup
.editAddressPopup {
  z-index: 1060 !important;
  div {
    border-radius: 15px;
    border: none;
  }
}
.editAddressHeaderCont {
  padding: 35px 35px 20px 35px !important;
  .editAddressTxt {
    margin: 0px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.editAddressBody {
  padding: 0px 35px 0px 35px !important;
  .editAddressBodyCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .editAddressInputsCont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // width: 65%;
      .addressTextInputsCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 5px;
          padding-top: 5px;
        }
        input {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 5px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 75%;
        }
      }
      .addressSelectorCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 5px;
          padding-top: 5px;
        }
        select {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 6px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 75%;
        }
      }
    }
    .editAddressPurposeCont {
      flex-direction: column;
      display: flex;
      width: 35%;
      justify-content: center;
      padding-left: 25px;
      gap: 5px;
      .addressPurposeCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .purposeCheckbox {
          height: 12px;
          width: 12px;
          cursor: pointer;
          border: 2px solid #275efe !important;
        }
        label {
          font-size: 14px;
          font-weight: 500;
          margin: 0px;
        }
      }
    }
  }
}
.editAddressFooter {
  padding: 5px 35px 30px 35px !important;
  .cancelBtn {
    border: 1px solid gray;
    background-color: #fcf2da;
    color: black;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  .updateAddressBtn {
    color: white;
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    background-color: #0f43f9;
    border: 1px solid #5711e2;
    border-radius: 3px;
    border: none;
    font-weight: 300;
  }
}

@media (max-width: 2600px) {
  .facilityCardContainer {
    width: 16%;
  }
  .facilityCardContainerActive {
    width: 16%;
  }
}

@media (max-width: 2000px) {
  .facilityCardContainer {
    width: 19%;
  }
  .facilityCardContainerActive {
    width: 19%;
  }
}

@media (max-width: 1440px) {
  .facilityCardContainer {
    width: 24%;
  }
  .facilityCardContainerActive {
    width: 24%;
  }
}

@media (max-width: 1110px) {
  .facilityCardContainer {
    width: 30%;
  }
  .facilityCardContainerActive {
    width: 30%;
  }
}

@media (max-width: 850px) {
  .facilityCardContainer {
    width: 46%;
  }
  .facilityCardContainerActive {
    width: 46%;
  }
}

@media (max-width: 550px) {
  .facilityCardContainer {
    width: 96%;
  }
  .facilityCardContainerActive {
    width: 96%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  //Disable Facility Popup
  .disableFacilityPopupFooter {
    .disableFacilityFeaturesCont {
      top: 40px;
      left: 190px;
      width: 35vw;
    }
  }

  // Add User Popup
  .facilityAddUserPopup {
    div {
      width: 55vw;
    }
  }
  .facilityAddUserHeaderCont {
    padding: 20px 20px 10px 20px !important;
    .facilityAddUserHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .facilityAddUserPopupBody {
    padding: 10px 20px 10px 20px !important;
    .facilityAddUserBodyCont {
      .facilityAddUserBodyHeader {
        font-size: 14px !important;
      }
      .facilityAddUserDetailsCont {
        .facilityAddUserDetails {
          .popUserTxt {
            font-size: 14px;
          }
          .popRoleTxt {
            font-size: 14px;
          }
        }
        .facilityAddUserSelectorsDiv {
          height: 10vh;
          .facilityAddUserSelectorsCont {
            .popUserSelect {
              font-size: 12px;
              option {
                font-size: 12px;
              }
            }
            .popRoleSelect {
              font-size: 12px;
              option {
                font-size: 12px;
              }
            }
            img {
              height: 16px;
              width: 10px;
            }
          }
        }

        .facilityAddUserDetailsBtnCont {
          .facilityAddUserDetailsBtn {
            padding: 2px 8px 2px 8px;
            font-size: 10px;
            img {
              height: 10px;
              width: 10px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .facilityAddUserFooterCont {
    padding: 5px 20px 15px 0px !important;
    .facilityAddUserCancelBtn {
      font-size: 10px;
      padding: 3px 10px 3px 10px;
    }
    .facilityAddUserMapUsersBtn {
      font-size: 10px;
      padding: 3px 10px 3px 10px;
    }
  }

  // Edit Facility Popup
  .editFacilityPopupModal {
    div {
      width: 80vw;
    }
  }
  .editFacilityPopBody {
    .editFacilityBody {
      .facilityBasicInfoCont {
        height: 40vh;
      }
      .facilityAttributesCont {
        height: 40vh;
      }
      .facilityUsersCont {
        height: 40vh;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  //View Facility Popup
  .viewFacilityHeaderCont {
    padding: 15px 0px 10px 0px !important;
    margin: 0px 20px 0px 20px;
    .facilityDetailsHeaderCont {
      .facilityIcon {
        height: 16px;
        width: 16px;
      }
      .facilityDetailsContainer {
        .facilityDetails {
          .facilityNameTxt {
            margin: 0px;
            font-size: 16px;
            font-weight: 600;
          }
          .facilityAddressTxt {
            margin: 0px;
            font-size: 10px;
            font-weight: 400;
          }
        }
        .facilityUseTxt {
          font-size: 10px;
          text-transform: uppercase;
          margin: 0px;
          padding-top: 8px;
        }
      }
    }
  }
  .viewFacilityPopupBody {
    padding: 15px 0px 15px 20px !important;
    .viewFacilityBody {
      .facilityInfoCont {
        .facilityInfoHeader {
          margin: 0px;
          font-size: 13px;
          font-weight: 600;
        }
        .facilityInfoHeaderBorder {
          border: 1px solid black;
          width: 63px;
        }

        .facilityInformationDetailsCont {
          .facilityInfoDetails {
            .facilityInfoHeaderDiv {
              .facilityInfoHeader {
                width: 100%;
                margin: 0px;
                font-size: 10px;
                font-weight: 400;
                color: #707070;
              }
            }
            .facilityInfoParaDiv {
              .facilityInfoPara {
                width: 100%;
                margin: 0px;
                font-size: 10px;
                font-weight: 500;
                word-break: break-word;
              }
            }
          }
        }
      }

      .attributesInfoCont {
        .attributesInfoHeader {
          margin: 0px;
          font-size: 13px;
          font-weight: 500;
        }
        .attributesInfoHeaderBorder {
          border: 1px solid black;
          width: 63px;
        }

        .attributesInfoDetailsCont {
          .attributeInfoDetails {
            .attributeInfoHeaderDiv {
              .attributeInfoHeader {
                width: 100%;
                margin: 0px;
                font-size: 10px;
                font-weight: 400;
                color: #707070;
              }
            }
            .attributeInfoParaDiv {
              .attributeInfoPara {
                width: 100%;
                margin: 0px;
                font-size: 10px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
  .viewFacilityPopupFooter {
    padding: 0px 20px 15px 0px !important;
    .viewFacilityEditBtn {
      border: 1px solid #5f5b5b;
      background-color: #ffffff;
      font-size: 10px;
      padding: 3px 8px 3px 8px;
      border-radius: 3px;
      color: #000000;
      img {
        height: 10px;
        width: 10px;
        margin-right: 10px;
      }
    }
    .viewFacilityDelete {
      border: 1px solid #ea5037;
      background-color: #ff5539;
      font-size: 10px;
      padding: 3px 8px 3px 8px;
      border-radius: 3px;
      color: #ffffff;
      font-weight: 200;
      img {
        height: 10px;
        width: 10px;
        margin-right: 5px;
      }
    }
  }

  //Disable Facility Popup
  .disableFacilityHeaderCont {
    padding: 20px 20px 10px 20px !important;
    .disableFacilityHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      .disableFacilityCloseIcon {
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .disableFacilityPopupBody {
    padding: 10px 20px 10px 20px !important;
    .disableFacilityBody {
      .disableFacilityBodyTxt {
        font-size: 10px;
      }
    }
  }
  .disableFacilityPopupFooter {
    padding: 0px 20px 15px 0px !important;
    .disableFacilityBtn {
      font-size: 12px !important;
      padding: 3px 8px 3px 8px;
      img {
        height: 10px;
        width: 10px;
        margin-left: 8px;
      }
    }
    .disableFacilityCancelBtn {
      font-size: 12px !important;
      padding: 3px 8px 3px 8px;
    }
    .disableFacilityFeaturesCont {
      width: 46vw;
      .disableFacilityFeatures {
        .disableFacilityFeaturesHeader {
          font-size: 12px;
        }
        .disableFacilityFeatureTxt {
          font-size: 9px;
        }
      }
      .disableFacilityFeatures:hover {
        background-color: #d4ecff;
        border: 1px solid #9ad2ff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 5px 10px 5px 10px;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }

  // Add User Popup
  .facilityAddUserPopup {
    div {
      width: 95vw;
    }
  }
  .facilityAddUserHeaderCont {
    padding: 20px 20px 10px 20px !important;
    .facilityAddUserHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .facilityAddUserPopupBody {
    padding: 10px 20px 10px 20px !important;
    .facilityAddUserBodyCont {
      .facilityAddUserBodyHeader {
        font-size: 14px !important;
      }
      .facilityAddUserDetailsCont {
        .facilityAddUserDetails {
          .popUserTxt {
            font-size: 14px;
          }
          .popRoleTxt {
            font-size: 14px;
          }
        }
        .facilityAddUserSelectorsDiv {
          height: 10vh;
          .facilityAddUserSelectorsCont {
            .popUserSelect {
              font-size: 12px;
              option {
                font-size: 12px;
              }
            }
            .popRoleSelect {
              font-size: 12px;
              option {
                font-size: 12px;
              }
            }
            img {
              height: 16px;
              width: 10px;
            }
          }
        }

        .facilityAddUserDetailsBtnCont {
          .facilityAddUserDetailsBtn {
            padding: 2px 8px 2px 8px;
            font-size: 10px;
            img {
              height: 10px;
              width: 10px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .facilityAddUserFooterCont {
    padding: 5px 20px 15px 0px !important;
    .facilityAddUserCancelBtn {
      font-size: 10px;
      padding: 3px 10px 3px 10px;
    }
    .facilityAddUserMapUsersBtn {
      font-size: 10px;
      padding: 3px 10px 3px 10px;
    }
  }

  //Delete Facility Popup
  .deleteFacilityPopHeader {
    padding: 20px 20px 10px 20px !important;
    .deleteFacilityHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      .deleteFacilityCloseIcon {
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .deleteFacilityPopBody {
    padding: 10px 20px 10px 20px !important;
    .deleteFacilityBody {
      .deleteFacilityBodyTxt {
        font-size: 10px;
      }
    }
  }
  .deleteFacilityFooterCont {
    padding: 0px 20px 15px 0px !important;

    .deleteFacilityBtn {
      font-size: 12px;
      padding: 3px 8px 3px 8px;
    }
    .deleteFacilitycancelButton {
      font-size: 12px;
      padding: 3px 8px 3px 8px;
    }
  }

  // Edit Facility Popup
  .editFacilityPopupModal {
    margin-left: 0px !important;
    div {
      width: 96vw;
    }
  }
  .editFacilityPopHeader {
    padding: 20px 20px 10px 20px !important;
    .editFacilityHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .editFacilityPopBody {
    padding: 10px 20px 0px 20px !important;
    .editFacilityBody {
      .editFacilityHeaderNav {
        gap: 15px;
        .editFacilityNavTxt {
          font-size: 13px;
        }
        .activeEditFacilityNavTxt {
          font-size: 13px;
        }
      }
      .facilityBasicInfoCont {
        height: 40vh;
        .basicInfoCont {
          height: 55vh;
          .basicInfoInputsCont {
            .textInputsCont {
              label {
                font-size: 13px;
              }
              input {
                padding: 4px;
                font-size: 10px;
              }

              // .setPurpose {
              //   margin: 0px;
              //   font-size: 14px;
              //   font-weight: 500;
              //   color: #4e23d9;
              //   text-decoration: underline;
              //   padding-top: 3px;
              //   cursor: pointer;
              //   // width: 50%;
              // }
              // .emailSetPurpose {
              //   width: 18vw;
              //   border: 1px solid #bdcbfb;
              //   background-color: white;
              //   position: absolute;
              //   display: flex;
              //   flex-direction: column;
              //   border-radius: 5px !important;
              //   padding: 10px 10px 10px 10px;
              //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
              //   left: 90px;
              //   top: 0px;
              //   z-index: 1000;
              //   .setPurposeHeader {
              //     display: flex;
              //     flex-direction: row;
              //     align-items: center;
              //     justify-content: flex-end;
              //     width: 100%;
              //     img {
              //       height: 10px;
              //       width: 10px;
              //       cursor: pointer;
              //     }
              //   }
              //   .setPurposeDataCont {
              //     display: flex;
              //     flex-direction: column;
              //     align-items: center;
              //     justify-content: flex-start;
              //     width: 100%;
              //     .setPurposeData {
              //       display: flex;
              //       flex-direction: row;
              //       align-items: center;
              //       grid-gap: 5px;
              //       grid-gap: 5px;
              //       gap: 5px;
              //       width: 100%;
              //       input {
              //         height: 11px;
              //         width: 11px;
              //         cursor: pointer;
              //         border: 1px solid #275efe !important;
              //       }
              //       label {
              //         font-size: 12px;
              //         font-weight: 500;
              //         margin: 0px;
              //         cursor: pointer;
              //       }
              //     }
              //   }
              // }
            }
            .selectorInputCont {
              label {
                font-size: 13px;
              }
              select {
                padding: 5px;
                font-size: 10px;
                option {
                  font-size: 10px;
                }
              }
            }
            .phoneNumberInputCont {
              label {
                font-size: 13px;
              }
              .selectAndInputCont {
                .imgAndSelectCont {
                  padding: 5px;
                  img {
                    height: 8px;
                    width: 10px;
                  }
                  select {
                    font-size: 10px;
                    option {
                      font-size: 10px;
                    }
                  }
                }
                input {
                  padding: 4px;
                  font-size: 10px;
                }
              }
              // .setPurpose {
              //   margin: 0px;
              //   font-size: 14px;
              //   font-weight: 500;
              //   color: #4e23d9;
              //   text-decoration: underline;
              //   padding-top: 3px;
              //   cursor: pointer;
              //   // width: 50%;
              // }
              // .phoneNumberSetPurpose {
              //   width: 18vw;
              //   border: 1px solid #bdcbfb;
              //   background-color: white;
              //   position: absolute;
              //   display: flex;
              //   flex-direction: column;
              //   border-radius: 5px !important;
              //   padding: 10px 10px 10px 10px;
              //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
              //   left: 90px;
              //   top: 0px;
              //   z-index: 1000;
              //   .setPurposeHeader {
              //     display: flex;
              //     flex-direction: row;
              //     align-items: center;
              //     justify-content: flex-end;
              //     width: 100%;
              //     img {
              //       height: 10px;
              //       width: 10px;
              //       cursor: pointer;
              //     }
              //   }
              //   .setPurposeDataCont {
              //     display: flex;
              //     flex-direction: column;
              //     align-items: center;
              //     justify-content: flex-start;
              //     width: 100%;
              //     .setPurposeData {
              //       display: flex;
              //       flex-direction: row;
              //       align-items: center;
              //       grid-gap: 5px;
              //       grid-gap: 5px;
              //       gap: 5px;
              //       width: 100%;
              //       input {
              //         height: 11px;
              //         width: 11px;
              //         cursor: pointer;
              //         border: 1px solid #275efe !important;
              //       }
              //       label {
              //         font-size: 12px;
              //         font-weight: 500;
              //         margin: 0px;
              //         cursor: pointer;
              //       }
              //     }
              //   }
              // }
            }
            .sizeFacilityInputCont {
              label {
                font-size: 13px;
              }
              .inputAndSelectCont {
                input {
                  padding: 4px;
                  font-size: 10px;
                }
                select {
                  padding: 5px;
                  font-size: 10px;
                  option {
                    font-size: 10px;
                  }
                }
              }
            }
            .addressInputCont {
              width: 50%;
              display: flex;
              flex-direction: column;
              label {
                font-size: 15px;
                margin: 0px;
                font-weight: 500;
                padding-bottom: 4px;
                padding-top: 5px;
              }
              .addressInputIconCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
                width: 100%;
                input {
                  border: 1px solid #bdcbfb;
                  outline: none;
                  padding: 5px;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 500;
                  width: 90%;
                }
                .editAddressIcon {
                  height: 11px;
                  width: 20px;
                  cursor: pointer;
                  position: absolute;
                  right: 30px;
                  background-color: white;
                }
              }
            }
          }
          .mapContainer {
            height: 15vh;
          }
          .latAndLongInputsCont {
            .textInputsCont {
              label {
                font-size: 13px;
              }
              input {
                padding: 4px;
                font-size: 10px;
              }
            }
          }
        }
      }
      .facilityBasicInfoCont::-webkit-scrollbar {
        display: none;
      }
      .facilityAttributesCont {
        height: 40vh;
        .textInputsCont {
          label {
            font-size: 13px;
          }
          input {
            padding: 4px;
            font-size: 10px;
          }
        }
        .unitOfEnergyInputCont {
          label {
            font-size: 13px;
          }
          .selectAndInputCont {
            .imgAndSelectCont {
              padding: 5px;
              img {
                height: 8px;
                width: 10px;
              }
              select {
                font-size: 10px;
                option {
                  font-size: 10px;
                }
              }
            }
            input {
              padding: 4px;
              font-size: 10px;
            }
          }
        }
        .selectorInputCont {
          label {
            font-size: 13px;
          }
          select {
            padding: 5px;
            font-size: 10px;
            option {
              font-size: 10px;
            }
          }
        }
        .contractDemandInputCont {
          label {
            font-size: 13px;
          }
          .inputAndSelectCont {
            input {
              padding: 4px;
              font-size: 10px;
            }
            select {
              padding: 5px;
              font-size: 10px;
              option {
                font-size: 10px;
              }
            }
          }
        }
      }
      .facilityUsersCont {
        height: 40vh;
        .facilityUsersHeightCont {
          .facilityUsersAddBtnCont {
            .facilityUsersAddBtn {
              padding: 2px 8px 2px 8px;
              font-size: 13px;
              img {
                height: 10px;
                width: 10px;
                margin-right: 8px;
              }
            }
          }
          .facilityUsersAndRoleContainer {
            .facilityUsersAndRoleHeaders {
              p {
                font-size: 13px;
              }
            }
            .facilityUsersAndRoleSelectorsCont {
              .facilityUsersAndRoleSelector {
                padding: 5px;
                font-size: 10px;
              }
              img {
                height: 15px;
                width: 12px;
                cursor: pointer;
              }
            }
          }
        }
        .facilityUsersHeightCont::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .editFacilityPopFooter {
    padding: 15px 20px 15px 20px !important;
    .editFacilityFooter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .enableAndDisableCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        p {
          margin: 0px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .editFacilityBtnsCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 100%;
        justify-content: end;
        .facilityCancelBtn {
          font-size: 10px;
          padding: 3px 8px 3px 8px;
        }
        .facilityNextBtn {
          font-size: 10px;
          padding: 4px 8px 4px 8px;
        }
        .facilityUpdateBtn {
          font-size: 10px;
          padding: 4px 8px 4px 8px;
        }
      }
    }
  }

  // Edit Address Popup
  .editAddressHeaderCont {
    padding: 20px 20px 10px 20px !important;
    .editAddressTxt {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        cursor: pointer;
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .editAddressBody {
    padding: 0px 20px 0px 20px !important;
    .editAddressBodyCont {
      .editAddressInputsCont {
        .addressTextInputsCont {
          label {
            font-size: 13px;
          }
          input {
            padding: 4px;
            font-size: 10px;
            width: 85%;
          }
        }
        .addressSelectorCont {
          label {
            font-size: 13px;
          }
          select {
            padding: 5px;
            font-size: 10px;
            width: 85%;
          }
        }
      }
    }
  }
  .editAddressFooter {
    padding: 10px 20px 20px 20px !important;
    .cancelBtn {
      font-size: 10px;
      padding: 3px 8px 3px 8px;
    }
    .updateAddressBtn {
      font-size: 10px;
      padding: 4px 8px 4px 8px;
    }
  }
}
