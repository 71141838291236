.display-container {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  height: 20vh;
  flex-direction: row;
    li {
      font-size: 12px;
      font-weight: 400;
      list-style: none;
      font-size: 13px;
      padding: 0%;
      background: none !important;
    }
  }
  