.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(545px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 22px;
}

.d-flex-titleCard {
  display: flex;
}

.cardWrapper-distTab {
  .tabTitleBox-wrapper {
    .border-sty {
      border-bottom: 0px solid #ffffff;
      padding: 5px;
      cursor: pointer;
      min-width: 20vh;
      text-align: center;
      .distributionTab-active {
        background: #0f43f9 0% 0% no-repeat padding-box;
        border-radius: 3px;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 7px;
      }
      .distributionTab {
        color: #000000;
        padding: 7px;
        &:hover {
          background: #0f43f9;
          border-radius: 3px;
          color: #ffffff;
        }
      }
    }
    .active-Border {
      cursor: pointer;
      border-bottom: 2px solid #0f43f9;
      padding: 5px;
      min-width: 20vh;
      text-align: center;
      .distributionTab-active {
        background: #0f43f9 0% 0% no-repeat padding-box;
        border-radius: 3px;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 7px;
      }
      .distributionTab {
        color: #000000;
        padding: 7px;
      }
    }
    .tabTitleBox-wrapper-active {
      border-bottom: 1px solid #0f43f9;
    }
  }
}

.CardWrapper {
  .snackchart-card {
    .SnackCard {
      min-height: 40vh;
      height: 38vh;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0%;
      align-items: center;
      margin-top: 8%;
    }
  }
}

.border-hr {
  margin: 0.2rem 0;
  opacity: 0.1;
}

@media only screen and (max-width: 600px) {
  .d-flex-titleCard {
    display: flex;
    flex-direction: column;
  }

  .border-hr {
    display: none;
  }
}
