.assetSettingsCardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .assetSettingsCardsNav {
    display: flex;
    flex-direction: row;
    height: 13%;
    border: 1px solid #bdcbfb;
    width: 98.5%;
    align-items: center;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
    margin-right: 16px;
    margin-top: 10px;
    position: relative;
    border-radius: 5px;

    p {
      font-size: 12px;
      margin: 0px;
      font-weight: 500;
    }

    .assetSettingsNavBtnsCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .assetsSelectAllBtn {
        border: none;
        padding: 1px 10px 1px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
      }

      .assetsDeselectAllBtn {
        border: none;
        padding: 1px 10px 1px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
      }

      .assetsUpdateAttrributesBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #0077ff;
        border-radius: 3px;
        font-size: 12px;
        color: #ffffff;
      }

      .assetsRelocateBtn {
        border: none;
        padding: 1px 10px 1px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;

        img {
          height: 13px;
          width: 13px;
          margin-right: 5px;
        }
      }

      .assetsChangeStatusBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #fbe6b5;
        border: 1px solid #cfc8b8;
        border-radius: 3px;
        font-size: 12px;

        img {
          height: 13px;
          width: 13px;
          margin-right: 5px;
        }
      }

      .assetsDeleteBtn {
        padding: 3px 10px 3px 10px;
        background-color: #ed381b;
        border: 1px solid #ea5037;
        color: #ffffff;
        border-radius: 3px;
        font-size: 12px;

        img {
          height: 13px;
          width: 13px;
          margin-right: 5px;
        }
      }
    }
  }

  .assetSettingsCardsCont {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 10px 0px 0px 0px;
    gap: 10px;
    position: relative;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .assetSettingsCardsCont::-webkit-scrollbar {
    width: 8px;
  }
}

.assetListSearchEmpty {
  width: 100%;
  padding-left: 10%;

  .display-container {
    height: 100% !important;
  }
}

// Update Attributes Specific Popup
.specificAttributePopupModal {
  div {
    width: 45vw;
    border: none;
    border-radius: 15px;
  }
}

.specificAttributePopupHeader {
  padding: 30px 35px 0px 35px !important;

  .specificAttributeHeaderCont {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #bdcbfb;
    gap: 20px;
    border-radius: 0px !important;

    .specificAttributeHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .specificAttributeHeaderTxt {
        margin: 0px !important;
        font-weight: 600 !important;
        font-size: 18px !important;
      }

      .closeIconDiv {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        img {
          cursor: pointer;
          height: 15px;
          width: 15px;
        }
      }

      .closeIconDiv:hover {
        background-color: #d3d3d3;
        cursor: pointer;
      }
    }

    .headerSelectCont {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;

      .headerSelectTxt {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
      }

      .specificAttributeHeaderSelect {
        width: 47%;
        outline: none;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;

        option {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

.specificAttributePopupBody {
  padding: 10px 20px 10px 35px !important;

  .specificAttributeBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 0px !important;
    flex-wrap: wrap;

    .inputTextCont {
      display: flex;
      flex-direction: column;
      width: 50%;
      position: relative;
      padding-top: 10px;

      label {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
        padding-bottom: 5px;
      }

      input {
        outline: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid #bdcbfb;
        width: 90%;
      }
    }

    .inputAndSelectCont {
      display: flex;
      flex-direction: column;
      width: 50%;
      position: relative;
      padding-top: 10px;

      label {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
        padding-bottom: 5px;
      }

      .inputSelect {
        display: flex;
        flex-direction: row;
        width: 100%;

        input {
          outline: none;
          border-right: none;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          padding: 5px;
          font-size: 13px;
          font-weight: 500;
          border: 1px solid #bdcbfb;
          width: 70%;
        }

        select {
          outline: none;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          padding: 5px;
          font-size: 13px;
          font-weight: 500;
          border: 1px solid #bdcbfb;
          width: 20%;
          border-left: none;
        }
      }
    }

    .selectInputCont {
      display: flex;
      flex-direction: column;
      width: 50%;
      position: relative;
      padding-top: 10px;

      label {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
        padding-bottom: 5px;
      }

      select {
        outline: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid #bdcbfb;
        width: 90%;

        option {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

.specificAttributePopupFooter {
  padding: 5px 35px 20px 0px !important;

  .specificAttributeCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }

  .specificAttributeUpdateBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 300;
    color: #ffffff;
  }
}

// Bulk Delete Popup
.bulkDeleteAssetPopupModal {
  div {
    border: none;
    border-radius: 20px;
  }
}

.bulkDeleteAssetPopHeaderCont {
  padding: 40px 40px 10px 40px !important;

  .bulkDeleteAssetPopHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }

  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}

.bulkDeleteAssetPopBody {
  padding: 10px 40px 10px 40px !important;

  .bulkDeleteAssetBodyCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    .bulkDeleteAssetContentTxt {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
    }

    .bulkDeleteAssetWarningCont {
      background-color: #f4433659;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 5px;
      border-left: 5px solid #f44336 !important;
      padding: 10px 15px 10px 15px;
      border-radius: 5px;

      .warningIconCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;

        img {
          height: 13px;
          width: 13px;
        }

        p {
          margin: 0px;
          font-size: 13px;
          font-weight: 500;
        }
      }

      p {
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.bulkDeleteAssetFooter {
  padding: 0px 35px 20px 0px !important;

  .bulkDeleteAssetCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }

  .bulkDeleteAssetDeleteBtn {
    border: 1px solid #ea5037;
    background-color: #ff5539;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 300;
  }
}

// Bulk Relocate Popup
.relocatePopupModal {
  div {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 80% !important;

    div {
      width: 80%;

      div {
        width: 100% !important;
        border-radius: 4px !important;
        border: none !important;
        display: flex;
        flex-direction: column;

        .relocateAssetHeaderCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between !important;
          padding: 30px 30px 10px 30px !important;

          .relocateAssetHeader {
            margin: 0px !important;
            font-size: 18px !important;
            font-weight: 600 !important;
          }

          .closeIconDiv {
            height: 40px;
            width: 40px !important;
            border-radius: 50% !important;
            display: flex;
            align-items: center;
            justify-content: center;

            .closeIcon {
              height: 15px !important;
              width: 15px !important;
            }
          }

          .closeIconDiv:hover {
            background-color: #d3d3d3;
            cursor: pointer;
          }
        }

        .selectorTagContainer {
          display: flex !important;
          flex-direction: column;
          align-items: flex-start !important;
          padding: 10px 30px 10px 30px;
          gap: 5px;

          // background-color: #0075ff;
          input {
            outline: none;
            padding-left: 1%;
            border: 1px solid lightblue;
            border-radius: 3px;
          }

          .countOfAssets {
            font-size: 12px !important;
            font-weight: 500;
            color: #0075ff;
            margin: 0px !important;
          }

          // .assetSelector {
          //   width: 65%;
          //   padding: 8px;
          //   outline: none;
          //   border: 1px solid gray;
          //   border-radius: 5px;
          // }
          .selectSearchInput {
            padding: 4px 10px 4px 10px;
            outline: none;
            border: 1px solid gray;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            width: 70%;
          }

          .bulkSelectSearchListCont {
            border: 1px solid gray;
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 5px;
            overflow-y: scroll;
            max-height: 20vh;
            min-height: 5vh;
            background-color: #ffffff;
            position: absolute;
            top: 68px;
            width: 63%;

            .selectSearchList {
              font-size: 14px;
              font-weight: 500;
              padding: 5px;
              width: 100%;
            }

            .selectSearchList:hover {
              background-color: #d3eef2;
              cursor: pointer;
              border-radius: 5px;
            }

            .selectedList {
              font-size: 14px;
              font-weight: 500;
              padding: 5px;
              width: 100%;
              background-color: #2680eb;
              color: #ffffff;
            }
          }

          .bulkSelectSearchListCont::-webkit-scrollbar {
            display: none;
          }
        }

        .relocateAssetFooterCont {
          display: flex !important;
          flex-direction: row !important;
          justify-content: flex-end !important;
          align-items: center !important;
          grid-gap: 5px;
          gap: 10px;
          padding: 10px 30px 25px 10px;

          .assetCancelBtn {
            border: 1px solid #cfc8b8;
            background-color: #fcf2da;
            font-size: 15px;
            padding: 5px 10px 5px 10px;
            border-radius: 3px;
            font-weight: 500;
          }

          .assetRelocateBtn {
            border: 1px solid #5711e2;
            background-color: #0075ff;
            font-size: 15px;
            padding: 5px 10px 5px 10px;
            border-radius: 3px;
            font-weight: 300;
            color: #ffffff;
          }
        }
      }
    }
  }

}

// Bulk Change Status
.bulkChangeStatusPopupModal {
  div {
    // width: 25vw;
    border-radius: 15px;
    border: none;
  }
}

.bulkChangeStatusPopupHeader {
  padding: 30px 25px 10px 25px !important;

  .bulkChangeStatusHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }

  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}

.bulkChangeStatusPopupBody {
  padding: 10px 25px 0px 25px !important;

  .bulkChangeStatusBodyCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .bulkSelectTxt {
      font-size: 12px;
      margin: 0px;
      font-weight: 500;
      color: #5711e2;
    }

    .changeStatusSelector {
      width: 90%;
      outline: none;
      border: 1px solid #bdcbfb;
      border-radius: 3px;
      padding: 0px;
      font-size: 13px;
      font-weight: 400;

      option {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.bulkChangeStatusPopupFooter {
  padding: 10px 25px 25px 0px !important;

  .bulkChangeStatusCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }

  .bulkChangeStatusUpdateBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 300;
    color: #ffffff;
  }
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: none !important;
}

.css-1pahdxg-control:hover {
  box-shadow: none !important;
  border-color: none !important;
}

.height100_asset {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayGrid {
  display: grid;
}

.assetSettingsLoadBtnCont {
  flex-direction: column;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loadMoreBtn {
    border: none;
    background-color: #0075ff;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 40px 3px 40px;
    border-radius: 20px;
  }

  .loadingBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: #0075ff;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 40px 3px 40px;
    border-radius: 20px;

    .loaderAnimation {
      position: relative;
      border: 1px solid #f3f3f3;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border-top: 1px solid #3498db;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      left: 10px;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .assetSettingsCardContainer {
    .assetSettingsCardsCont {
      flex-wrap: wrap;
      overflow-y: scroll;
      justify-content: center;
      padding: 10px 10px 0px 10px;
    }
  }
}

.bulkRelocateSelectorDropdown {
  width: 70%;
  border: 1px solid black !important;
  border-radius: 8px !important;
}