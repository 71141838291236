.ringGauge {
  height: 100px !important;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 11%;
}

@media only screen and (max-width: 600px) {
  .ringGauge {
    width: 16%;
    margin: 2rem;
  }
}
