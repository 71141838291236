.sopViolationsCont {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  .sopViolationsCountTxtCont {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    .sopViolationsCountTxt {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
    }
    .sopViolationsCount {
      font-size: 25px;
      font-weight: 800;
      color: #000000;
      .sopAverageCountTxt {
        font-size: 12px;
        font-weight: 600;
        .sopCountArrowImg {
          height: 14px;
          padding-bottom: 3px;
          margin-right: 3px;
          margin-left: 5px;
        }
      }
    }
  }
  .repeatedSopCont {
    padding-left: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    .repeatedSopHeader {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 600px) {
  .sopViolationsCont .repeatedSopCont {
    padding-left: 10px;
  }
}
