.display-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  li {
    font-size: 12px;
    font-weight: 400;
    list-style: none;
    font-size: 13px;
    padding: 10%;
    padding-left: 0%;
    width: 26vw;
  }

  .lottie {
    margin-right: 20px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .display-container {
    li {
      width: 59vw;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .display-container {
    li {
      width: 59vw;
    }
  }
}
