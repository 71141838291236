.selectedEventChartCard {
    display: flex;
    flex-direction: row;
    align-items: center;

    .eventHeadingText {
        width: 30%;
        font-size: 11px;
        font-weight: 400;
        color: rgba(27, 27, 27, 1);
        margin: 0;
    }

    .selectedEventDataDiv {
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        .selectedEventColor {
            min-width: 10%;
            width: 90%;
            display: flex;
            flex-direction: row;
            background-color: rgba(32, 113, 238, 1);
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            justify-content: flex-end;
            align-items: center;
            padding: 4px 8px 4px 8px;

            .selectedEventPer {
                font-size: 10px;
                font-weight: 300;
                text-align: center;
                color: rgba(255, 255, 255, 1);
                margin: 0;
            }
        }

        .selectedEventValue {
            font-size: 14px;
            font-weight: 500;
            color: rgba(27, 27, 27, 1);
            margin: 0;
        }
    }

    .hoverMainDiv {
        display: flex;
        flex-direction: column !important;
        border-width: 1;
        background-color: #C2CEFC;
        border-radius: 5px;
        position: absolute;
        align-content: center;
        padding: 4x 4px 4px 4px;
        margin-left: 4vw;
        margin-top: 10vh;

        .hoverTitle {
            display: flex;
            flex-direction: row;
            gap: 5px;
            cursor: default;
            margin: 4px 4px 0px 4px;

            .hoverName {
                margin: 0;
                font-weight: 400;
                font-size: 12px;
                color: #000;
                cursor: default;
            }

            .hoverValue {
                margin: 0;
                font-weight: 600;
                font-size: 12px;
                color: #000;
                cursor: default;
            }
        }

        .hoverPercentage {
            margin: 0;
            font-weight: 800;
            font-size: 16px;
            color: #000;
            cursor: default;
            margin: 0px 4px 0px 4px;
        }

        .hoverViewTrend {
            display: flex;
            cursor: pointer;
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            color: #004494;
            border-bottom-width: 1px;
            border-bottom-color: #004494;
            margin: 0px 4px 4px 4px;
        }
    }
}