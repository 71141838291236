.loader-cont {
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-slide>div {
    margin: 6px 6px 6px 0px;
    height: 22vh;
    display: flex;
}

.energyConsumptionContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 10px 20px 10px;
    // border: 1px solid yellow;

    // .filterChipsContainer {
    //     box-shadow: 0px 1px 0px skyblue;
    //     display: flex;
    //     flex-direction: column;
    //     margin-bottom: 10px;
    //     padding-bottom: 6px;

    //     .filterCloseCont {
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: end;

    //         .closeIcon {
    //             height: 8px;
    //             width: 8px;
    //         }
    //     }

    //     .filterChipsDiv {
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         gap: 10px;
    //         margin: 0px 0px 10px 0px;

    //         .filterChips {
    //             display: flex;
    //             flex-direction: row;
    //             align-items: center;
    //             border: 1px solid #843939;
    //             background-color: white;
    //             padding: 2px 10px 2px 10px;
    //             border-radius: 10px;
    //             gap: 5px;

    //             .chipHeader {
    //                 margin: 0px;
    //                 padding: 0px;
    //                 font-size: 10px;
    //                 font-weight: 500;
    //                 color: #843939;
    //             }

    //             .chipCloseIcon {
    //                 height: 8px;
    //                 width: 10px;
    //             }
    //         }
    //     }

    //     .filterChipSelectorCont {
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         flex-wrap: wrap;
    //         gap: 10px;

    //         .filterSelectorContainer {
    //             display: flex;
    //             flex-direction: column;
    //             width: 20%;
    //             position: relative;

    //             .chipFilterSelector {
    //                 border: 1px solid #DFE2EB;
    //                 display: flex;
    //                 flex-direction: row;
    //                 align-items: center;
    //                 padding: 0px 0px 0px 5px;
    //                 border-radius: 3px;
    //                 img{
    //                     width: 10%;
    //                 }
    //                 input {
    //                     outline: none;
    //                     border: none;
    //                     width: 80%;
    //                     font-size: 12px;
    //                     font-weight: 500;
    //                 }
    //             }

    //             .chipFilterList {
    //                 border: 1px solid #DFE2EB;
    //                 list-style-type: none;
    //                 margin: 0px;
    //                 padding: 0px;
    //                 border-radius: 3px;
    //                 position: absolute;
    //                 top: 25px;
    //                 width: 100%;

    //                 li {
    //                     cursor: pointer;
    //                     font-size: 12px;
    //                     font-weight: 600;
    //                     border-bottom: 1px solid #DFE2EB;
    //                     padding: 2px 0px 2px 5px;
    //                     background-color: #ffffff;
    //                 }
    //                 li:hover {
    //                     background-color: rgb(242, 251, 253);
    //                 }
    //             }
    //         }

    //         .selectorChip {
    //             cursor: pointer;
    //             border: 1px solid #843939;
    //             display: flex;
    //             flex-direction: row;
    //             align-items: center;
    //             padding: 2px 10px 2px 10px;
    //             border-radius: 10px;

    //             .selectorChipHeader {
    //                 margin: 0px;
    //                 font-size: 9px;
    //                 font-weight: 500;
    //                 color: #843939;
    //             }
    //         }
    //     }
    // }
    .filterChipsContainer {
        box-shadow: 0px 1px 0px skyblue;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding-bottom: 6px;
        gap: 10px;

        .filterChipsDiv {
            display: flex;
            width: 92%;
            align-items: center;
            justify-content: center;

            // background-color: #707070;
            .filterChipsMapCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                // margin: 0px 0px 10px 0px;
                flex-wrap: wrap;
                width: 900%;

                .filterChips {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 1px solid #843939;
                    background-color: white;
                    padding: 2px 10px 2px 10px;
                    border-radius: 10px;
                    gap: 5px;

                    .chipHeader {
                        margin: 0px;
                        padding: 0px;
                        font-size: 10px;
                        font-weight: 500;
                        color: #843939;
                    }

                    .chipCloseIcon {
                        height: 8px;
                        width: 10px;
                    }
                }
            }

            .filterCloseCont {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                background-color: white;
                padding: 6px 6px 6px 6px;
                border-radius: 50%;
                width: 10%;

                .closeIcon {
                    height: 8px;
                    width: 8px;
                }
            }

            .filterCloseCont:hover {
                background-color: #c2dcfa;
            }


        }

        .filterChipSelectorCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            .filterSelectorContainer {
                display: flex;
                flex-direction: column;
                width: 20%;
                position: relative;

                .chipFilterSelector {
                    border: 1px solid #DFE2EB;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 3px 5px 3px 5px;
                    border-radius: 3px;
                    justify-content: space-between;
                    // background-color: #0075FF;
                    img {
                        width: 12px;
                        height: 12px;
                    }

                    input {
                        outline: none;
                        border: none;
                        width: 80%;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                .chipFilterList {
                    border: 1px solid #DFE2EB;
                    list-style-type: none;
                    margin: 0px;
                    padding: 0px;
                    border-radius: 3px;
                    position: absolute;
                    top: 25px;
                    width: 100%;
                    // border: 5px solid black;
                    max-height: 130px;
                    overflow-y: auto;

                    li {
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 600;
                        border-bottom: 1px solid #DFE2EB;
                        padding: 2px 0px 2px 5px;
                        background-color: #ffffff;
                    }

                    li:hover {
                        background-color: rgb(242, 251, 253);
                    }
                }
            }

            .selectorChip {
                cursor: pointer;
                border: 1px solid #843939;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 2px 10px 2px 10px;
                border-radius: 10px;

                .selectorChipHeader {
                    margin: 0px;
                    font-size: 9px;
                    font-weight: 500;
                    color: #843939;
                }
            }
        }
    }

    .energyConsumptionTabsCont {
        // background-color: #0075FF;
        width: 95%;
        height: 5vh;
        display: flex;
        margin-bottom: 10px;
        gap: 18px;

        .toastTabSwitch {
            // cursor: pointer;
            cursor: pointer;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 6px;
            gap: 8px;
            height: 100%;
            border-bottom: 2px solid #0075ff;
            margin-right: 10px;

            p,
            img {
                margin: 0;
                color: #0075FF;
                font-weight: 500;
                font-size: 15px;
                font-family: fantasy;
                height: 20px;
            }

            img {
                width: 13px;
            }

            width:max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            height: 100%;
            border-bottom: 2px solid #0075FF;
            margin-right: 10px;

        }

        margin-bottom: 10px;
    }

    // .filterChipsContainer {
    //     box-shadow: 0px 1px 0px skyblue;
    //     display: flex;
    //     flex-direction: column;
    //     margin-bottom: 10px;
    //     padding-bottom: 6px;

    //     .filterCloseCont {
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: end;

    //         .closeIcon {
    //             height: 8px;
    //             width: 8px;
    //         }
    //     }

    //     .filterChipsDiv {
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         gap: 10px;
    //         margin: 0px 0px 10px 0px;

    //         .filterChips {
    //             display: flex;
    //             flex-direction: row;
    //             align-items: center;
    //             border: 1px solid #843939;
    //             background-color: white;
    //             padding: 2px 10px 2px 10px;
    //             border-radius: 10px;
    //             gap: 5px;

    //             .chipHeader {
    //                 margin: 0px;
    //                 padding: 0px;
    //                 font-size: 10px;
    //                 font-weight: 500;
    //                 color: #843939;
    //             }

    //             .chipCloseIcon {
    //                 height: 8px;
    //                 width: 10px;
    //             }
    //         }
    //     }

    //     .filterChipSelectorCont {
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         flex-wrap: wrap;
    //         gap: 10px;

    //         .filterSelectorContainer {
    //             display: flex;
    //             flex-direction: column;
    //             width: 20%;
    //             position: relative;

    //             .chipFilterSelector {
    //                 border: 1px solid #DFE2EB;
    //                 display: flex;
    //                 flex-direction: row;
    //                 align-items: center;
    //                 padding: 0px 0px 0px 5px;
    //                 border-radius: 3px;

    //                 img {
    //                     width: 10%;
    //                 }

    //                 input {
    //                     outline: none;
    //                     border: none;
    //                     width: 80%;
    //                     font-size: 12px;
    //                     font-weight: 500;
    //                 }
    //             }

    //             .chipFilterList {
    //                 border: 1px solid #DFE2EB;
    //                 list-style-type: none;
    //                 margin: 0px;
    //                 padding: 0px;
    //                 border-radius: 3px;
    //                 position: absolute;
    //                 top: 25px;
    //                 width: 100%;

    //                 li {
    //                     cursor: pointer;
    //                     font-size: 12px;
    //                     font-weight: 600;
    //                     border-bottom: 1px solid #DFE2EB;
    //                     padding: 2px 0px 2px 5px;
    //                     background-color: #ffffff;
    //                 }

    //                 li:hover {
    //                     background-color: rgb(242, 251, 253);
    //                 }
    //             }
    //         }

    //         .selectorChip {
    //             cursor: pointer;
    //             border: 1px solid #843939;
    //             display: flex;
    //             flex-direction: row;
    //             align-items: center;
    //             padding: 2px 10px 2px 10px;
    //             border-radius: 10px;

    //             .selectorChipHeader {
    //                 margin: 0px;
    //                 font-size: 9px;
    //                 font-weight: 500;
    //                 color: #843939;
    //             }
    //         }
    //     }
    // }

    .energyConsumptionDetailsContainer {
        display: flex;
        flex-direction: column;

        .energyStatsContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            // gap: 50px;
            gap: 18px;
            margin-bottom: 12px;
            margin-top: 12px;

            .statsDetailsDiv {
                display: flex;
                flex-direction: column;

                .statsHeader {
                    // margin: 0px;
                    // margin-left: 5px;
                    // font-size: 12px;
                    // font-weight: 400;
                    margin: 0 0 0 5px;
                    font-size: .93rem;
                    font-weight: 400;
                }

                .consumptionDetails {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // gap: 10px;
                    gap: 3px;

                    .statsImage {
                        // height: 40px;
                        height: 21px;
                        width: 20px;
                    }

                    .consumptionCount {
                        // margin: 0px;
                        // font-size: 13px !important;
                        // font-weight: 700 !important;
                        margin: 0;
                        font-size: 1.16rem !important;
                        font-weight: 700 !important;
                    }
                }
            }

            .borderContainer {
                // border-left: 3px solid #DFE2EB;
                // height: 9vh;
                border-left: 1px solid #dfe2eb;
                height: 5.2vh;
            }
        }

        .subMetersCardsContainer {
            display: flex;
            flex-direction: column;

            .energyActiveAssetTypeChip {
                display: flex;
                align-items: center;
                justify-content: space-around;
                background-color: #374DC6;
                width: max-content;
                gap: 13px;
                padding: 3px 13px 3px 13px;
                border-radius: 15px;
                cursor: pointer;
                margin-top: 1px;
                margin-bottom: 10px;

                img {
                    width: 13px;
                }

                p {
                    // margin: 0;
                    // font-size: 12px;
                    // font-weight: 400;
                    // color: white;
                    margin: 0;
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: #fff;
                }
            }

            .energyAssetTypeCont {
                display: flex;
                flex-direction: row;
                overflow-x: scroll;
                overflow-y: none !important;
                grid-gap: 9px;
                gap: 9px;
                margin-bottom: 10px;
                margin-top: 5px;
                margin-top: 10px;
                padding: 5px 0px 5px 5px;

                // background-color: #5038F5;
                .energyAssetTypeCard {
                    // cursor: pointer;
                    // border: 1px solid rgb(181, 209, 243);
                    // display: flex;
                    // align-items: center;
                    // grid-gap: 10px;
                    // gap: 10px;
                    // min-width: 220px;
                    // border-radius: 3px;
                    // padding: 6px 10px 6px 10px;
                    // width: 250px;
                    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .12);
                    cursor: pointer;
                    border: 1px solid #b5d1f3;
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;
                    gap: 10px;
                    min-width: 220px;
                    border-radius: 6px;
                    padding: 6px 10px;
                    width: 250px;
                    img {
                        width: 22%;
                    }

                    .assetTypeInfoCont {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 68%;

                        div {
                            .energyAssetTypeDesc {
                                font-size: 10px;
                                font-weight: 400;
                                margin: 0;
                            }

                            .energyAssetTypevalue {
                                font-size: 11px;
                                font-weight: 600;
                                margin: 0;
                            }
                        }

                        img {
                            width: 6px;
                        }
                    }
                }

                .energyAssetTypeCard:hover {
                    background-color: #F2ECFF;
                    border: 1px solid blue;
                }
            }

            .energyAssetTypeCont::-webkit-scrollbar {
                display: none;
            }


            .metricCardScrollBtnsCont {
                display: flex;
                flex-direction: row;
                overflow-x: scroll;
                grid-gap: 10px;
                gap: 10px;
                margin-bottom: 10px;
                padding: 5px 0px 5px 5px;
            }

            .metricCardScrollBtnsCont::-webkit-scrollbar {
                display: none;
            }

            .subMetersHeader {
                font-size: 18px;
                font-weight: 600;
                margin: 0px;
                color: black;
            }



            .energyConsumptionsCardsCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                // justify-content: space-between;
                flex-wrap: wrap;
                gap: 5px;

                .consumptionsCard {
                    border: 1px solid #DEDEE3;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: auto;
                    padding-left: 6px;
                    padding-right: 6px;
                    border-radius: 5px;
                    height: 4.8rem;
                    min-width: 165px;
                    flex-grow: 1;
                    margin: 2px;
                    background-color: #FCFCFC;

                    .cardHeader {
                        // margin: 0px;
                        // font-weight: 600;
                        // font-size: 11px;
                        margin: 0;
                        font-weight: 400;
                        font-size: 0.818rem;
                    }

                    .energyConsumptionReadingCont {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5px;

                        .subMetersText {
                            margin: 0;
                            font-size: 0.835rem;
                            font-weight: 600;

                            .subMeterSpan {
                                font-size: 10px;
                                font-weight: 400;
                            }
                        }

                        img {
                            // width: 10px;
                            // height: 10px;
                            height: 16px;
                        }

                        .apparentText {
                            margin: 0px;
                            font-size: 10px;
                            font-weight: 700;
                            color: red;
                        }
                    }

                    .apparentText {
                        font-size: 11px;
                        font-weight: 400;
                        color: grey;
                        margin: 0px;

                        span {
                            font-size: 10px;
                            font-weight: 500;
                            color: black;
                        }
                    }
                }

                @media screen and (max-width: 1200px) {
                    .consumptionsCard {
                        width: calc(25% - 8px);
                        flex-grow: 1;
                    }
                }

                @media screen and (max-width: 992px) {
                    .consumptionsCard {
                        width: calc(33.33% - 8px);
                        flex-grow: 1;
                    }
                }

                @media screen and (max-width: 768px) {
                    .consumptionsCard {
                        width: calc(50% - 8px);
                        flex-grow: 1;
                    }
                }

                @media screen and (max-width: 576px) {
                    .consumptionsCard {
                        width: calc(100% - 8px);
                        flex-grow: 1;
                    }
                }
            }

            // .averageReactivePower {
            //     // width: 100%;
            //     // margin-top: 2px;
            //     // padding: 17px 0px 17px 0px;
            //     // border-radius: 6px;
            //     // border: 1px solid #DEDEE3;
            //     // display: flex;
            //     // flex-direction: column;
            //     // align-items: center;
            //     // flex-grow: 1;
            //     border: 1px solid #DEDEE3;
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     justify-content: center;
            //     padding: auto;
            //     padding-left: 6px;
            //     padding-right: 6px;
            //     border-radius: 5px;
            //     height: 13vh;
            //     // width: 100%;
            //     // width: calc(20% - 8px);
            //     // width: calc(33.33% - 8px);
            //     flex-grow: 1;
            //     margin: 2px;


            //     .cardHeader {
            //         margin: 0px;
            //         font-weight: 600;
            //         font-size: 11px;
            //     }

            //     .energyConsumptionReadingCont {

            //         display: flex;
            //         flex-direction: row;
            //         align-items: center;
            //         gap: 5px;

            //         .subMetersText {
            //             margin: 0px;
            //             font-size: 11px;
            //             font-weight: 700;

            //             .subMeterSpan {
            //                 font-size: 12px;
            //                 font-weight: 400;
            //             }
            //         }
            //     }

            //     .apparentText {
            //         margin: 0px;
            //         font-size: 12px;
            //         font-weight: 700;
            //         color: black;
            //     }
            // }


            // .avgEnergyConsumption {
            //     display: flex;
            //     flex-direction: column;
            //     grid-gap: 5px;
            //     gap: 5px;
            //     margin: 10px 0px 10px 0px;

            //     .avgConsumptionHeader {
            //         margin: 0px;
            //         font-size: 13px;
            //         font-weight: 400;
            //     }

            //     .avgConsumptionStatsCont {
            //         display: flex;
            //         flex-direction: row;
            //         align-items: center;
            //         gap: 10px;

            //         .avgIcon {
            //             height: 25px;
            //             width: 25px;
            //         }

            //         .statsCount {
            //             margin: 0px;
            //             font-size: 18px;
            //             font-weight: 800;
            //         }
            //     }
            // }
        }

        .averageReactivePower {
            width: 100%;
            margin-top: 2px;
            padding: 17px 0px 17px 0px;
            border-radius: 6px;
            border: 1px solid #DEDEE3;
            display: flex;
            flex-direction: column;
            align-items: center;

            .cardHeader {
                margin: 0px;
                font-weight: 600;
                font-size: 11px;
            }

            .energyConsumptionReadingCont {

                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;

                .subMetersText {
                    margin: 0;
                    font-size: 0.835rem;
                    font-weight: 600;

                    .subMeterSpan {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .apparentText {
                    margin: 0px;
                    font-size: 12px;
                    font-weight: 700;
                    color: black;
                }
            }
        }


        //     .filterTypesCont {
        //         display: flex;
        //         flex-direction: row;
        //         align-items: center;
        //         gap: 15px;

        //         .filterType {
        //             display: flex;
        //             flex-direction: row;
        //             align-items: center;
        //             gap: 5px;

        //             .filterTypeHeader {
        //                 margin: 0px;
        //                 font-size: 10px;
        //                 font-weight: 400;
        //                 color: #3D3C3C;
        //             }

        //             img {
        //                 height: 20px;
        //                 width: 20px;
        //             }
        //         }
        //     }
        // }

        // .filterIconsCont {
        //     margin: 0px 52px 0px 0px;
        //     cursor: pointer;
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        //     gap: 10px;

        //     img {
        //         cursor: pointer;
        //         height: 20px;
        //         width: 60px;
        //     }
        // }
    }


    .energyConsumptionGraphsDataContainer {
        // border: 1px solid green;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 15px 0px 10px 0px;

        .shiftWiseEnergyConsumptionCardsCont {
            // border: 1px solid blue;
            display: flex;
            flex-direction: column;
            width: 35%;
            grid-gap: 3px;
            gap: 8px;
            padding: 5px 10px 10px 10px;

            .shiftWiseConsumptionCard {
                display: flex;
                flex-direction: row;
                grid-gap: 8px;
                gap: 10px;
                align-items: center;
                padding: 5px 10px 5px 5px;

                .shiftWiseIcon {
                    height: 22px;
                    width: 22px;
                }

                .shiftWiseHeadersContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    .shiftWiseCradHeader {
                        margin: 0px;
                        color: #707070;
                        font-weight: 500;
                        font-size: 10px;
                    }

                    .shiftWiseCardValues {
                        margin: 0px;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
            }

            .shiftOneContainer {
                display: flex;
                padding: 5px 10px 5px 10px;
                align-items: center;
                // flex-direction: column;
                border-radius: 5px;
                justify-content: space-between;
                background-color: #F6C8B6;
                height: 10vh;

                img {
                    width: 11px;
                    height: 10px;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    .shiftWiseTextOne {
                        margin: 0px;
                        font-size: 11px;
                        font-weight: 600;

                        span {
                            margin: 0;
                            border-radius: 15px;
                            font-size: 7.5px;
                            background-color: #E1E4F1;
                            padding: 2px 10px 2px 10px;
                            margin-left: 3px;
                        }

                    }

                    .shiftWiseTextTwo {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 500;
                        padding-bottom: 3px;
                    }

                    .shiftWiseTextThree {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }

            .shiftOneContainer:hover {

                border: 1px solid #E86437;
            }

            .shiftTwoContainer {
                display: flex;
                padding: 5px 10px 5px 10px;
                align-items: center;
                border-radius: 5px;
                justify-content: space-between;
                background-color: #EFA181;
                height: 10vh;

                img {
                    width: 11px;
                    height: 10px;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    .shiftWiseTextOne {
                        margin: 0px;
                        font-size: 11px;
                        font-weight: 600;

                        span {
                            margin: 0;
                            border-radius: 15px;
                            font-size: 7.5px;
                            background-color: #E1E4F1;
                            padding: 2px 10px 2px 10px;
                            margin-left: 3px;
                        }
                    }

                    .shiftWiseTextTwo {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 500;
                        padding-bottom: 3px;
                    }

                    .shiftWiseTextThree {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }

            .shiftTwoContainer:hover {
                border: 1px solid #E86437;
            }

            .shiftThreeContainer {
                display: flex;
                padding: 5px 10px 5px 10px;
                align-items: center;
                border-radius: 5px;
                justify-content: space-between;
                background-color: #E86437;
                height: 10vh;

                img {
                    width: 11px;
                    height: 10px;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    .shiftWiseTextOne {
                        margin: 0px;
                        font-size: 11px;
                        font-weight: 600;

                        span {
                            margin: 0;
                            border-radius: 15px;
                            font-size: 7.5px;
                            background-color: #E1E4F1;
                            padding: 2px 10px 2px 10px;
                            margin-left: 3px;
                        }
                    }

                    .shiftWiseTextTwo {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 500;
                        padding-bottom: 3px;
                    }

                    .shiftWiseTextThree {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }

            .shiftThreeContainer:hover {
                border: 1px solid #E86437;
            }
        }

        .energyConsumptionHourlyGraphContainer {
            // border: 1px solid brown;
            display: flex;
            flex-direction: column;
            width: 65% !important;
            

            .energyConsumptionGraphFilters {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px 10px 20px;

                .filterContainer {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    cursor: pointer;

                    .filterBtnCont {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 4px 10px 4px 10px;
                        gap: 5px;
                        border-radius: 5px;
                        background-color: #F0F3FC;
                        border: 1px solid #5038F5;

                        .filterHeader {
                            margin: 0px;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 0px;
                        }

                        img {
                            height: 10px;
                            width: 10px;
                        }
                    }

                    .selectorContainer {
                        border: 1px solid #CCCCCC;
                        padding: 0px;
                        margin: 0px;
                        list-style-type: none;
                        border-radius: 5px;
                        position: absolute;
                        top: 30px;
                        width: 100%;
                        background-color: #ffffff;
                        z-index: 1;

                        li {
                            font-size: 12px;
                            font-weight: 600;
                            border-bottom: 1px solid #CCCCCC;
                            padding: 5px 5px 5px 5px;

                        }

                        li:hover {
                            background-color: #eef4f9;
                        }
                    }
                }

                .filterTypesCont {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 15px;

                    .filterType {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5px;

                        .filterTypeHeader {
                            // margin: 0px;
                            // font-size: 8px;
                            // font-weight: 400;
                            // color: #3D3C3C;
                            margin: 0;
                            font-size: 0.6rem;
                            font-weight: 400;
                            color: #3d3c3c;
                        }

                        img {
                            height: 15px;
                            width: 20px;
                        }
                    }
                }


                // .filterTypesCont {
                //     display: flex;
                //     flex-direction: row;
                //     align-items: center;
                //     gap: 15px;

                //     .filterType {
                //         display: flex;
                //         flex-direction: row;
                //         align-items: center;
                //         gap: 5px;

                //         .filterTypeHeader {
                //             margin: 0px;
                //             font-size: 10px;
                //             font-weight: 400;
                //             color: #3D3C3C;
                //         }

                //         img {
                //             height: 20px;
                //             width: 20px;
                //         }
                //     }
                // }
            }

            // .energyConsumptionGraphCont {
            //     border: 1px solid green;
            // }
        }

        @media screen and (max-width: 1200px) {
            .energyConsumptionGraphsDataContainer {
                flex-direction: row;
            }

            .shiftWiseEnergyConsumptionCardsCont {
                width: 35%;
            }

            .energyConsumptionHourlyGraphContainer {
                width: 65%;
                
            }
        }

        @media screen and (max-width: 932px) {
            .energyConsumptionGraphsDataContainer {
                flex-direction: column;
            }

            .shiftWiseEnergyConsumptionCardsCont {
                width: 100%;

                .shiftOneContainer {
                    width: 55%;
                }

                .shiftTwoContainer {
                    width: 75%;
                }

                .shiftThreeContainer {
                    width: 95%;
                }
            }

            .energyConsumptionHourlyGraphContainer {
                width: 100%;
                
            }
        }

        // @media screen and (max-width: 768px) {
        //     .energyConsumptionGraphsDataContainer{
        //         flex-direction: column;
        //     }
        //     .shiftWiseEnergyConsumptionCardsCont {
        //         width: 100%;
        //         .shiftOneContainer{
        //             width: 55%;
        //         }
        //         .shiftTwoContainer{
        //             width: 75%;
        //         }
        //         .shiftThreeContainer{
        //             width: 95%;
        //         }
        //     }
        //     .energyConsumptionHourlyGraphContainer{
        //         width: 100%;
        //     }
        // }

        // @media screen and (max-width: 576px) {
        //     .energyConsumptionGraphsDataContainer{
        //         flex-direction: column;
        //     }
        //     .shiftWiseEnergyConsumptionCardsCont {
        //         width: 100%;
        //         .shiftOneContainer{
        //             width: 55%;
        //         }
        //         .shiftTwoContainer{
        //             width: 75%;
        //         }
        //         .shiftThreeContainer{
        //             width: 95%;
        //         }
        //     }
        //     .energyConsumptionHourlyGraphContainer{
        //         width: 100%;
        //     }
        // }
    }

    .energyConsumptionGraphsDataContainer-HalfWidth {
        // border: 1px solid green;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 20px;
        margin: 15px 0px 10px 0px;

        .shiftWiseEnergyConsumptionCardsCont {
            // border: 1px solid blue;
            display: flex;
            flex-direction: column;
            // width: 35%;
            width: 100%;
            grid-gap: 3px;
            gap: 3px;
            padding: 5px 10px 10px 10px;

            .shiftWiseConsumptionCard {
                display: flex;
                flex-direction: row;
                grid-gap: 8px;
                gap: 10px;
                align-items: center;
                padding: 5px 10px 5px 5px;

                .shiftWiseIcon {
                    height: 22px;
                    width: 22px;
                }

                .shiftWiseHeadersContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    .shiftWiseCradHeader {
                        margin: 0px;
                        color: #707070;
                        font-weight: 500;
                        font-size: 10px;
                    }

                    .shiftWiseCardValues {
                        margin: 0px;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
            }

            .shiftOneContainer {
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px 5px 10px;
                width: 42%;
                border-radius: 5px;
                background-color: #F6C8B6;
                height: 10vh;

                img {
                    width: 11px;
                    height: 10px;
                }

                div {
                    display: flex;
                    flex-direction: column;


                    .shiftWiseTextOne {
                        margin: 0px;
                        font-size: 11px;
                        font-weight: 600;

                        span {
                            margin: 0;
                            border-radius: 15px;
                            font-size: 7.5px;
                            background-color: #E1E4F1;
                            padding: 2px 10px 2px 10px;
                            margin-left: 3px;
                        }
                    }

                    .shiftWiseTextTwo {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 500;
                        padding-bottom: 3px;
                    }

                    .shiftWiseTextThree {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }

            .shiftOneContainer:hover {

                border: 1px solid #E86437;
            }

            .shiftTwoContainer {
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px 5px 10px;
                width: 62%;
                border-radius: 5px;
                background-color: #EFA181;
                height: 10vh;

                img {
                    width: 11px;
                    height: 10px;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    .shiftWiseTextOne {
                        margin: 0px;
                        font-size: 11px;
                        font-weight: 600;

                        span {
                            margin: 0;
                            border-radius: 15px;
                            font-size: 7.5px;
                            background-color: #E1E4F1;
                            padding: 2px 12px 2px 12px;
                            margin-left: 3px;
                        }
                    }

                    .shiftWiseTextTwo {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 500;
                        padding-bottom: 3px;
                    }

                    .shiftWiseTextThree {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }

            .shiftTwoContainer:hover {
                border: 1px solid #E86437;
            }

            .shiftThreeContainer {
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                padding: 5px 10px 5px 10px;
                width: 95%;
                border-radius: 5px;
                background-color: #E86437;
                align-items: center;
                height: 10vh;

                img {
                    width: 11px;
                    height: 10px;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    .shiftWiseTextOne {
                        margin: 0px;
                        font-size: 11px;
                        font-weight: 600;

                        span {
                            margin: 0;
                            border-radius: 15px;
                            font-size: 7.5px;
                            background-color: #E1E4F1;
                            padding: 2px 12px 2px 12px;
                            margin-left: 3px;
                        }
                    }

                    .shiftWiseTextTwo {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 500;
                        padding-bottom: 3px;
                    }

                    .shiftWiseTextThree {
                        margin: 0px;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }

            .shiftThreeContainer:hover {
                border: 1px solid #E86437;
            }
        }

        .energyConsumptionHourlyGraphContainer {
            // border: 1px solid brown;
            display: flex;
            flex-direction: column;
            // width: 65%;
            width: 100% !important;
            

            .energyConsumptionGraphFilters {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px 10px 20px;

                .filterContainer {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    cursor: pointer;

                    .filterBtnCont {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 4px 10px 4px 10px;
                        gap: 5px;
                        border-radius: 5px;
                        background-color: #F0F3FC;
                        border: 1px solid #5038F5;

                        .filterHeader {
                            margin: 0px;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 0px;
                        }

                        img {
                            height: 10px;
                            width: 10px;
                        }
                    }

                    .selectorContainer {
                        border: 1px solid #CCCCCC;
                        padding: 0px;
                        margin: 0px;
                        list-style-type: none;
                        border-radius: 5px;
                        position: absolute;
                        top: 30px;
                        width: 100%;
                        background-color: #ffffff;
                        z-index: 1;

                        li {
                            font-size: 12px;
                            font-weight: 600;
                            border-bottom: 1px solid #CCCCCC;
                            padding: 5px 5px 5px 5px;

                        }

                        li:hover {
                            background-color: #eef4f9;
                        }
                    }
                }

                .filterTypesCont {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 15px;

                    .filterType {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5px;

                        .filterTypeHeader {
                            // margin: 0px;
                            // font-size: 8px;
                            // font-weight: 400;
                            // color: #3D3C3C;
                            margin: 0;
                            font-size: 0.6rem;
                            font-weight: 400;
                            color: #3d3c3c;
                        }

                        img {
                            height: 15px;
                            width: 20px;
                        }
                    }
                }


                // .filterTypesCont {
                //     display: flex;
                //     flex-direction: row;
                //     align-items: center;
                //     gap: 15px;

                //     .filterType {
                //         display: flex;
                //         flex-direction: row;
                //         align-items: center;
                //         gap: 5px;

                //         .filterTypeHeader {
                //             margin: 0px;
                //             font-size: 10px;
                //             font-weight: 400;
                //             color: #3D3C3C;
                //         }

                //         img {
                //             height: 20px;
                //             width: 20px;
                //         }
                //     }
                // }
            }

            // .energyConsumptionGraphCont {
            //     border: 1px solid green;
            // }
        }

        @media screen and (max-width: 1200px) {
            .energyConsumptionGraphsDataContainer {
                flex-direction: row;
            }

            .shiftWiseEnergyConsumptionCardsCont {
                width: 100%;

                .shiftOneContainer {
                    width: 55%;
                }

                .shiftTwoContainer {
                    width: 75%;
                }

                .shiftThreeContainer {
                    width: 95%;
                }
            }

            .energyConsumptionHourlyGraphContainer {
                width: 100%;
                
            }
        }

        @media screen and (max-width: 932px) {
            .energyConsumptionGraphsDataContainer {
                flex-direction: column;
            }

            .shiftWiseEnergyConsumptionCardsCont {
                width: 100%;

                .shiftOneContainer {
                    width: 55%;
                }

                .shiftTwoContainer {
                    width: 75%;
                }

                .shiftThreeContainer {
                    width: 95%;
                }
            }

            .energyConsumptionHourlyGraphContainer {
                width: 100%;
                
            }
        }

        // @media screen and (max-width: 768px) {
        //     .energyConsumptionGraphsDataContainer{
        //         flex-direction: column;
        //     }
        //     .shiftWiseEnergyConsumptionCardsCont {
        //         width: 100%;
        //         .shiftOneContainer{
        //             width: 55%;
        //         }
        //         .shiftTwoContainer{
        //             width: 75%;
        //         }
        //         .shiftThreeContainer{
        //             width: 95%;
        //         }
        //     }
        //     .energyConsumptionHourlyGraphContainer{
        //         width: 100%;
        //     }
        // }

        // @media screen and (max-width: 576px) {
        //     .energyConsumptionGraphsDataContainer{
        //         flex-direction: column;
        //     }
        //     .shiftWiseEnergyConsumptionCardsCont {
        //         width: 100%;
        //         .shiftOneContainer{
        //             width: 55%;
        //         }
        //         .shiftTwoContainer{
        //             width: 75%;
        //         }
        //         .shiftThreeContainer{
        //             width: 95%;
        //         }
        //     }
        //     .energyConsumptionHourlyGraphContainer{
        //         width: 100%;
        //     }
        // }
    }




    .viewAssetNavCont {
        display: flex;
        flex-direction: row;
        justify-content: end;

        .viewAssetText {
            font-size: 13px;
        }
    }
}


.energyConsumptionPopup {
    div {
        border: none
    }
}

.energyConsumptionHeaderCont {
    padding: 20px 35px 10px 35px !important;

    .energyHeaderIconTextCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .energyPopupHeader {
            margin: 0px !important;
            font-size: 18px !important;
            font-weight: 600 !important;
        }
    }

    .closeIconDiv {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        img {
            cursor: pointer;
            height: 15px;
            width: 15px;
        }
    }

    .closeIconDiv:hover {
        background-color: #d3d3d3;
        cursor: pointer;
    }
}

.energyConsumptionBodyCont {
    padding: 45px 35px 15px 35px !important;
    border: 1px solid yellow;

    .energyBodayDetailsCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .energyDetailsText {
            margin: 0px;
            font-size: 15px;
            font-weight: 500;
        }


        .input-cont {
            width: 13%;
            height: 4%;
            border: 2px solid rgb(172, 172, 250);
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            .energyPopupInput {
                outline: none;
                border: none;
                font-size: 12px;
                padding: 5px 5px 5px 5px;
                border-radius: 3px;
                width: 60%;
            }
            .arrow-cont{
                // background-color: #0075FF;
                display: flex;
                flex-direction: column;
                border: 2px solid rgb(172, 172, 250);
                border-top: none;
                border-bottom: none;
                border-right: none;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                width: 40%;
                .downButton{
                    border-top: 2px solid rgb(172, 172, 250);
                }
                button{
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    // border-bottom: 2px solid rgb(172, 172, 250);
                img{
                    // height: 85%;
                    width: 65%;
                }
            }
            }

        }
    }
}

.energyConsumptionNavCont {
    border: 1px solid blue;
    padding: 15px 35px 15px 30px !important;

    .consumptionNavDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;


        .energyResetBtnCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .resetBtnText {
                margin: 0px;
                font-size: 14px;
                font-weight: 500;
                color: #707070;
            }
        }

        .energyConsumptionBtnsCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .energyCancelBtn {
                border: 1px solid gray;
                background-color: #fcf2da;
                color: black;
                font-size: 13px;
                padding: 5px 10px 5px 10px;
                border-radius: 3px;
                font-weight: 500;
            }

            .energyApplyBtn {
                color: white;
                font-size: 13px;
                padding: 5px 15px 5px 15px;
                background-color: #0f43f9;
                border: 1px solid #5711e2;
                border-radius: 3px;
                border: none;
                font-weight: 300;
            }
        }
    }

}