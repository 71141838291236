.liveChart {
  // margin-top: 40px;

  .echarts-for-react {
    // height: 320px !important;
  }

  .download {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 4%;
    box-shadow: 1px 2.5px 3px #00000029;
    cursor: pointer;

    div {
      background-color: #0f43f9;
      border-radius: 10%;
      padding: 2px;
      height: 20px;
      width: 50px;
      animation-name: liveBtn;
      opacity: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      div {
        height: 7px;
        width: 7px;
        display: flex;
        margin-top: 5px;
        background-color: #fff;
        border-radius: 5px;
        animation: blinker 1s linear infinite;
      }

      h1 {
        color: #fff;
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
}

.loader {
  border: 4px solid #ffffff;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.legendWithDownload {
  text-align: center;
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;

  img {
    position: absolute;
    right: 0;
  }
}

.chartGroupMainCont {
  display: flex;
  flex-direction: row;
  margin: 10px 20px 10px 20px;
  gap: 16px;

  .chartGroupButton {
    display: flex;
    flex-direction: row;
    border: 1px solid;
    border-radius: 8px;
    width: 50%;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 8px;
    cursor: pointer;

    .chartGroupIcon {
      width: 15px;
      height: 15px;
    }

    .chartGroupName {
      margin: 0;
      font-size: 14px;
    }
  }

  .selectedGroupChartButton {
    background-color: rgba(47, 128, 237, 1);
    border-color: rgba(252, 252, 252, 1);
  }

  .unselectedGroupChartButton {
    background-color: rgba(245, 245, 250, 1);
    border-color: rgba(189, 203, 251, 1);
  }

  .selectedGroupChartFont {
    color: rgba(252, 252, 252, 1);
  }

  .unselectedGroupChartFont {
    color: rgba(27, 27, 27, 1);
  }
}

.deviceFilterButtonsCont {
  text-align: center;
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .dropDownFilterCont {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40px;

    .dropDownFilterOne {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      .filterValue {
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
      }

      .filterDownIcon {
        height: 10px;
        width: 10px;
      }
    }

    .dropDownFilterTwo {
      border: 1px solid #dae2ff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: white;
      width: 100%;
      top: 23px;
      border-radius: 8px;

      .filterValue {
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .buttonsCont {
    margin-right: 60px;
  }
}

.deviceFilterButtonsContCenter {
  text-align: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legendsFilterMainContainer {
  display: flex;
  flex-direction: row;
  margin: 10px 20px 10px 20px;
  gap: 8px;
  align-items: center;
  justify-content: center;

  .dropDownFilterCont {
    display: flex;
    flex-direction: column;
    position: relative;

    .dropDownFilterOne {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      .filterValue {
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
      }

      .filterDownIcon {
        height: 10px;
        width: 10px;
      }
    }

    .dropDownFilterTwo {
      border: 1px solid #DAE2FF;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: white;
      width: 100%;
      top: 23px;
      border-radius: 8px;

      .filterValue {
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
      }

    }
  }

  .legendsFilterContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .legendFiltersContScroll {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      grid-gap: 8px;
      gap: 8px;
      width: 90%;

      &::-webkit-scrollbar {
        display: none;
      }

      .legendButtonsCont {
        border-width: 1px;
        border-style: solid;
        padding: 4px 16px 4px 16px;
        border-radius: 25px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        white-space: nowrap;

        .legendsText {
          margin: 0px;
          font-size: 10px;
          font-weight: 500;
        }
      }

    }

    .legendFilters {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: center;

      .legendButtonsCont {
        border-width: 1px;
        border-style: solid;
        padding: 4px 16px 4px 16px;
        border-radius: 25px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;

        .legendsText {
          margin: 0px;
          font-size: 10px;
          font-weight: 500;
        }
      }
    }

    .rawChartIconsCont {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d0e5fd;
      padding: 5px 15px 5px 15px;
      border-radius: 25px;
      cursor: pointer;

      .toggleIcon {
        width: 15px;
        height: 15px;
      }
      
      .toggleIconActive {
        width: 15px;
        height: 15px;
        transform: rotate(180deg);
      }
    }
  
  }

}

.groupsHoverCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px 6px 10px;
  gap: 10px;

  .groupsBtns {
    border-width: 1px;
    border-style: solid;
    padding: 4px 16px 4px 16px;
    border-radius: 25px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;

    .groupBtsText {
      margin: 0px;
      font-size: 8px;
      font-weight: 500;
    }
  }
}

.inlineRadius {
  list-style-type: none;
  margin: 0px;

  li {
    background-color: #e6f4f1;
    color: #707070;
    cursor: pointer;
    display: inline-block;
    padding: 4px;

    p {
      font-family: "Inter";
      font-size: 10px;
      padding: 0px;
      margin: 0px;
    }

    &.active {
      background-color: #0e9cff;
      color: #fff;
    }

    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding-left: 8px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-right: 8px;
    }
  }
}

.switchRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 20px;
  z-index: 9;
}

.trendChartMainCont {
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 20px;
  padding: 16px 0;
  gap: 16px;

  .trendChartLegendMainCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .trendChartLegendContScroll {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      grid-gap: 8px;
      gap: 8px;
      width: 90%;
      .trendChartLegendButton {
        display: flex;
        flex-direction: row;
        border: 1px solid;
        border-radius: 25px;
        padding: 3px 16px 3px 16px;
        gap: 8px;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        .selectedLegendIcon {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }

        .trendChartLegend {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .selectedLegendButton {
        background-color: rgba(0, 117, 255, 1);
        border-color: rgba(0, 117, 255, 1);
      }

      .unselectedLegendButton {
        background-color: rgba(255, 255, 255, 1);
        border-color: rgba(162, 167, 159, 1);
      }
      .unselectedLegendButton:hover {
        background-color: #d0e5fd;
      }

      .selectedLegendText {
        color: rgba(255, 255, 255, 1);
      }

      .unselectedLegendText {
        color: rgba(162, 167, 159, 1);
      }
    }
    .trendChartLegendContScroll::-webkit-scrollbar {
      display: none;
    }

    .trendChartLegendCont {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;

      .trendChartLegendButton {
        display: flex;
        flex-direction: row;
        border: 1px solid;
        border-radius: 25px;
        padding: 3px 16px 3px 16px;
        gap: 8px;
        justify-content: center;
        align-items: center;

        .selectedLegendIcon {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }

        .trendChartLegend {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .selectedLegendButton {
        background-color: rgba(0, 117, 255, 1);
        border-color: rgba(0, 117, 255, 1);
      }

      .unselectedLegendButton {
        background-color: rgba(255, 255, 255, 1);
        border-color: rgba(162, 167, 159, 1);
      }
      .unselectedLegendButton:hover {
        background-color: #d0e5fd;
      }

      .selectedLegendText {
        color: rgba(255, 255, 255, 1);
      }

      .unselectedLegendText {
        color: rgba(162, 167, 159, 1);
      }
    }
    .trendChartIconsCont {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d0e5fd;
      padding: 5px 15px 5px 15px;
      border-radius: 25px;
      .toggleIcon {
        width: 15px;
        height: 15px;
      }
      .toggleIconActive {
        width: 15px;
        height: 15px;
        transform: rotate(180deg);
      }
    }
  }

  .trendCartSettingMainCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dateRagneFrequenciesDiv {
      display: flex;
      flex-direction: column;
      position: relative;

      .dropDownView {
        border: 1px solid rgba(80, 56, 245, 1);
        padding: 2px 8px 2px 8px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background-color: rgba(240, 243, 252, 1);
        gap: 8px;
        cursor: pointer;

        .dropDownText {
          margin: 0px;
          font-size: 12px;
          font-weight: 500;
        }

        .arrowIcon {
          height: 10px;
          width: 10px;
        }
      }

      .dateRagneFrequenciesMenu {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(189, 203, 251, 1);
        margin: 4px 0px;
        max-height: 20vh;
        z-index: 1;
        overflow-y: scroll;
        border-radius: 4px;
        background-color: #fff;
        position: absolute;
        top: 25px;
        .dateRagneFrequenciesButton {
          display: flex;
          flex-direction: row;
          padding: 2px 4px;
          cursor: pointer;

          .dateRagneFrequenciesText {
            margin: 0;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      // .dateRagneFrequenciesMenu::-webkit-scrollbar{
      //   display: none;
      // }
    }

    .trendChartSettingCont {
      display: flex;
      flex-direction: row;
      height: 4vh;
      justify-content: center;
      align-items: center;
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease-in-out;
}

.rotate-0 {
  transform: rotate(0deg);
  transition: transform 0.1s ease-in-out;
}

.chart-switch {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .switchBtn {
    margin-right: 10px;
  }

  img {
    width: 26px;
    height: 14px;
    object-fit: contain;

    &:first-child {
      width: 28px;
      height: 18px;
    }
  }

  .chart-switch-img {
    margin-right: 4rem;
  }
}

.marginVr {
  margin: 12px 0px;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  // -webkit-appearance: none;
  //  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 50%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.arrow-chiller {
  svg {
    color: #707070;
    padding-top: 15px;
    font-size: 24px;
    position: relative;
    bottom: 7px;
  }
}

.arrow-chiller {
  cursor: pointer;
}

.assetDetailPage {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #d7dbec;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  height: 74vh;
  overflow-y: scroll;

  .leftCol {
    background-color: white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    // min-height: calc(100vh - 190px);
    .maincontent {
      padding-top: 20px !important;
      padding: 3px;
      overflow-y: hidden;

      .gaugeUtilization {
        .graphUtill {
          .gGraph {
            color: transparent;
            text-shadow: 0 0 5px rgb(0 0 0 / 28%);

            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: -20px;
          }
        }
      }

      .showMobile {
        display: none;
      }

      .header {
        padding-left: 20px;
      }

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        .AssetDetal_header {
          display: flex;
          flex-direction: row;
          width: 100%;

          .image {
            margin-left: 1rem;
            margin-right: 1rem;
            height: 3vw;
            width: 3vw;
            border: 1px solid #038aff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: space-around;
            justify-content: center;

            img {
              height: 2vw;
              width: 2vw;
            }
          }

          .headText {
            h6 {
              font-size: 0.55rem;
              color: #021b79;
              text-transform: uppercase;
              margin: 0;
              font-weight: 700;
            }

            h2 {
              color: #131523;
              font-size: 1.1rem !important;
              margin: 0;
              line-height: 1.4rem !important;
            }

            h5 {
              letter-spacing: 0px;
              color: #131523;
              margin: 0;
              font-size: 11px;
              font-weight: 400;
            }
          }
        }
        .acControllerIcon {
          padding: 8px;
          border-radius: 8px;
          border: 1px solid rgba(154, 210, 255, 1);
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
  }

  .acControlTooltip {
    border-radius: 25px;
    padding: 2px 8px;
    font-size: 8px;
    margin-top: 4px !important;

    &::before {
      content: none !important;
    }

    &::after {
      content: none !important;
    }
  }

  .rightCol {
    background-color: #f5f6fa;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    // min-height: calc(100vh - 190px);
    .rightDivImple {
      padding-top: 24px;

      // made some changes alert list
      .alertList {
        display: flex;
        justify-content: space-around;
        margin-left: 8px;
        margin-right: 8px;
        gap: 10px;
        padding: 0px 5px 0px 5px;

        .alert-List {
          display: flex !important;
        }

        .download {
          background: #fcf2da 0% 0% no-repeat padding-box;
          border: 1px solid #70707052;
          // box-shadow: 1px 2.5px 3px #00000029;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          width: 100%;
          height: 4vh;
          border-radius: 3px;

          cursor: pointer;
          align-items: flex-start;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: space-around;
          padding-top: 4px;

          img {
            width: 12px;
            margin-right: 5%;
            margin-top: 1%;
          }

          h2 {
          }

          &:after {
            font-size: 12px;
          }

          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            min-width: 135px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            margin-top: 19.5px;
            z-index: 1;
          }

          .dropdown-content a {
            color: black;
            padding: 4px 16px;
            text-decoration: none;
            display: block;
            font-size: 12px;
          }

          .arrow {
            border: solid black;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            padding-left: 3px;
            font-size: small;
          }

          .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }

          h3 {
            font-size: 13px;
            color: black;
          }
        }
      }
    }
  }
}

.selectDropdown {
  position: relative;
  padding-bottom: 10px;
}

.selectedOption {
  background-color: #0f43f9;
  border-radius: 3px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;

  &:after {
    content: "▼";
    font-size: 1rem;
  }

  &-list {
    position: absolute;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
    background-color: #dae2ff;
    border: 1px solid #0f43f9;
    display: none;

    div {
      padding: 10px 20px;
      border-bottom: 1px solid #000;
    }

    &.showList {
      display: block;
    }
  }
}

.tabsList-mobile {
  display: none;
}

.tabsList-desktop {
  margin-top: 13px;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: white;
  min-height: auto;
  margin: 10px 15px 10px 15px;

  .tabBackClor {
    background-color: #ffffff;
    padding: 15px !important;
    position: relative;
    width: 100%;

    .tabClass {
      h6 {
        text-align: center;
        margin: 0;
        padding: 1px;
        font-size: 12px;
      }
    }
  }

  .tabsList-col {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h2 {
    text-align: center;
    margin: 0;
    padding: 1px;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
}

.TabBtn {
  padding: 30px;
  min-width: 6.25rem;
  width: 100%;
  position: relative;
  padding: 20px;

  h5 {
    text-align: left;
    padding-left: 0px;
    margin-left: 8px;
    margin-top: 18px;
    font-size: 20px;
  }

  h6 {
    text-align: left;
    padding-left: 0px;
    margin-left: 6px;
    margin-top: 2px;
    margin-bottom: 5px;
    font-size: 10px;
  }
}

.assetlistpopup-container {
  display: block;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  .assetlist-popup {
    border: 1px solid #f44336;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    justify-content: space-evenly;
    align-items: center;
    width: 40vw;
    height: 10vh;
    z-index: 9999;

    .indication-icon {
      display: flex;
      width: 20px;
      height: 20px;
    }

    p {
      font-size: 13px;
      justify-content: center;
      color: #182f43;
      align-items: center;
      margin-top: 15px;

      span {
        font-weight: 700;
      }
    }

    button {
      background-color: #ffc10766;
      border: 1px solid #dca604;
      padding: 2px;
      width: 5vw;
      height: 4vh;
      border-radius: 6px;
      color: #182f43;
      font-size: 10px;
      font-weight: 600;
    }

    .exit-icon {
      display: flex;
      width: 20px;
      height: 20px;
    }
  }
}

.containerSet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px 10px 20px;

  .consuptionCardAD {
    min-height: 17vh;
    width: 95%;
    background-color: #66bf4130;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 8px;
  }

  .borderScss {
    border: 2px solid #b3ddff;
    border-top: 2px solid #b3ddff;
    line-height: 0;
    text-align: left;
    margin-top: 40px;
    height: auto;
    width: 100%;
    border-radius: 10px;
    position: relative;
    padding: 8px;

    span {
      background-color: #ffffff;
      padding: 0 10px;
      margin: 0 20px;
      font-size: 12px;
    }

    .liveButton {
      border: 1px solid #f00;
      background-color: red;
      color: white;
      padding: 3px 5px 3px 5px;
      width: 10px;
      border-radius: 3px;
      font-size: 0.6rem;
      display: flex;
      width: 3vw;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: -9px;
      height: 3vh;
    }

    .lastUpdatedScss {
      position: absolute;
      right: 0;
      width: fit-content;
      height: fit-content;
      padding: 8px;
      background-color: #ffffff;
      top: -9px;
    }

    .liveDataAssetType {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: 16px 2px 12px 2px;
    }
  }

  .downButton {
    border: none;
    background: transparent;
    position: relative;
    bottom: 16px;
  }

  .downButton_Active {
    border: none;
    background: transparent;
    position: relative;
    bottom: 16px;
    transform: rotate(180deg);
  }

  .demand-scss {
    border-radius: 6px;
    border: 1px solid #d0e5fd;
    background: #ecf4fd;
    min-height: 15vh;
    width: 95%;
    margin: 15px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .assetDetailPage .rightCol .rightDivImple .alertList .download {
    background: #fcf2da 0% 0% no-repeat padding-box;
    border: 1px solid #70707052;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    width: 21vw;
    height: 4vh;
    border-radius: 3px;
    padding: 5px;

    img {
      width: 20px;
      margin-right: 5%;
      margin-top: 5%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .assetDetailPage {
    .leftCol {
      .maincontent {
        .gaugeUtilization {
          .graphUtill {
            .gGraph {
              color: transparent;
              text-shadow: 0 0 5px rgb(0 0 0 / 28%);
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
          }
        }

        .header {
          > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .image {
            margin-left: 1rem;
            margin-right: 1rem;
            height: 10vw;
            width: 10vw;
            border: 1px solid #038aff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: space-around;
            justify-content: center;

            img {
              height: 6.5vw;
              width: 6.5vw;
            }
          }
        }
      }
    }
  }

  .arrow-chiller {
    svg {
      position: relative;
      bottom: 4px;
    }
  }

  .assetDetailPage .rightCol {
    background-color: white;
  }

  .maincontent {
    padding: 20px;

    .rightDivImple-mobile {
      padding-top: 15px;
      overflow: scroll;

      .showMobile {
        display: flex !important;
        overflow: scroll !important;
        width: 402px;
        padding-bottom: 10px;

        .download-mobile {
          position: absolute;
          top: 20px;
          right: 10px;
          background: #fcf2da 0% 0% no-repeat padding-box;
          border: 1px solid #70707052;
          border-radius: 3px;
          display: flex;
          justify-content: space-around;
          width: 10vw;
          height: 4vh;
          padding: 5px;

          &:after {
            font-size: 12px;
          }

          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            min-width: 135px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            margin-top: 19.5px;
            z-index: 1;
          }

          .dropdown-content a {
            color: black;
            padding: 4px 16px;
            text-decoration: none;
            display: none;
            font-size: 12px;
          }

          &:hover .dropdown-content {
            display: block;
          }

          .arrow {
            border: solid black;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            padding-left: 3px;
            font-size: small;
          }

          .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }

          h3 {
            display: none;
            font-size: 13px;
            color: black;
          }
        }
      }
    }
  }

  .tabsList-desktop {
    display: none;
  }

  .tabsList-mobile {
    display: block;
    margin-top: 13px;
    padding: 10px;
    background-color: white;
    min-height: calc(100vh - 320px);
    overflow-y: scroll;
  }

  .assetDetailPage {
    position: relative;

    .rightDivImple {
      padding-top: 15px;
      overflow: scroll;
    }

    .alertList {
      display: none !important;
    }
  }

  .tabClass-button {
    overflow: scroll;
  }

  .assetlistpopup-container {
    display: none;
  }

  .headText {
    h2 {
      font-size: 15px !important;
    }
  }

  .alerterrorState {
    position: absolute;
    /* margin: 0 0 0 0; */
    top: 13.5%;
    z-index: 999;
    width: 76%;
    background-color: #fddada;
    border-color: #f64747;
    color: #f64747;
    padding: 2%;
    border: 1px solid;
    border-radius: 6px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0px auto;
    height: 4%;
  }

  .containerSet .consuptionCardAD {
    min-height: 11vh;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .maincontent {
    .header {
      > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .image {
        margin-left: 1rem;
        margin-right: 1rem;
        height: 6vw !important;
        width: 6vw !important;
        border: 1px solid #038aff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: space-around;
        justify-content: center;

        img {
          height: 4.5vw !important;
          width: 4.5vw !important;
        }
      }
    }
  }

  .assetlistpopup-container {
    position: absolute;
    bottom: 150px;
    left: 50%;

    .assetlist-popup {
      width: 60vw;
      height: 5vh;

      button {
        padding: 2px;
        width: 6vw;
        height: 2vh;
      }
    }
  }

  .alerterrorState {
    position: absolute;
    /* margin: 0 0 0 0; */
    top: 16.5%;
    z-index: 999;
    width: 46%;
    background-color: #fddada;
    border-color: #f64747;
    color: #f64747;
    padding: 1%;
    border: 1px solid;
    border-radius: 6px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    height: 4%;
  }

  .containerSet .consuptionCardAD {
    min-height: 10vh;
    width: 95%;
    background-color: #66bf4130;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .assetDetailPage .rightCol .rightDivImple .alertList .download h3 {
    font-size: 20px;
    color: black;
    margin-top: 5px;
  }
}

.alerterrorState {
  position: absolute;
  /* margin: 0 0 0 0; */
  top: 13.5%;
  z-index: 999;
  width: 30%;
  background-color: #fddada;
  border-color: #f64747;
  color: #f64747;
  padding: 1%;
  border: 1px solid;
  border-radius: 6px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  height: 9%;

  span {
    float: right;
    cursor: pointer;
  }
}

.Blink {
  animation: blinker 0.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

//toaster popup

.Toastify__toast-container--bottom-right {
  bottom: 2em;
  left: 5em !important;
  min-width: 45vw !important;
  max-width: 50vw !important;
}

.Toastify__toast {
  position: relative;
  min-height: 64px;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 0rem;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: sans-serif;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  background-color: white !important;
  color: rgb(148, 143, 143) !important;
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 0px 4px 0px 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: center;
  color: rgb(148, 143, 143) !important;
}

.assetDetailPage::-webkit-scrollbar{
  width: 8px;
}

.activityTabScss {
  .display-container{
    height: 26vh;
  }
}