.filterTicketListPopup {
    width: 80%;
    height: 65%;
    position: absolute;
    bottom: 8%;
    z-index: 9999 !important;
    background-color: #FFFFFF;
    //border-radius: 5px;
    //padding: 5px;
    //border-top: 3px solid #c6c6c6;
    overflow: scroll;

    &-filterList {
        display: flex;
        align-items: center;

        .filterName {
            display: flex;
            cursor: pointer;
            flex-direction: row;
            width: 85%;
            overflow-x: scroll;
            padding: 10px;
            padding-bottom: 0;

            .alertEachRow-divSet {
                display: flex;
                margin: 3px;
                transition: background-color 1s ease-out 30ms;
                min-width: fit-content;
                border-radius: 10px;
                padding: 6px;
                align-items: center;

                .AlertCountcontainerSet {
                    width: 1.5rem;
                    height: 1.5rem;
                    border: 1px solid #0457c2;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 0.6rem;
                    font-size: 15px !important;
                    font-weight: 700;
                    margin-right: 12px;
                }

                h3 {
                    font-size: 13px;
                    font-weight: 600 !important;
                    margin: 0;
                    color: white;
                }
            }

            .alertEachRow-divSet:hover {
                background-color: #1b548f !important
            }

        }

        .closeState {
            margin-left: 2%;
            cursor: pointer;
        }

    }

}

.pagination_value {
    position: sticky;
    bottom: -1px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 25%;

    button {
        min-width: 5vw;
        border: navajowhite;
        font-size: 10px;
        font-weight: 400;
        margin: 0px 0px 0px 0px;
        font-family: "Inter", sans-serif !important;
        height: 6vh;
        border-radius: 30px;
        background-color: rgba(4, 112, 223, 0);
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        span {
            font-size: 10px;
            margin-left: 8px;
            font-weight: 500;
            margin-right: 8px;
        }
    }

    h3 {
        font-size: 20px;
        margin-left: 0px;
        font-weight: 500;
    }
}

.alertsStyle {
    font-size: 0.8rem;
    text-align: end;
    margin-right: 19px;
    margin: 0;
    margin-right: 17px;
    font-weight: 400;
    margin: 10px 15px 10px 10px;
}

//.modal-backdrop {
//    --bs-backdrop-zindex: 1050;
//    --bs-backdrop-bg: #000;
//    --bs-backdrop-opacity: 0.5;
//    position: fixed;
//    top: 0;
//    left: 0;
//
//    width: 100vw;
//    height: 100vh;
//    z-index: 99999 !important;
//}
//
//.modal-backdrop.show {
//    opacity: var(--bs-backdrop-opacity);
//// z-index: 99999 !important;
//    z-index: 999 !important;
//}
//
//.modal-content {
//    background-color: transparent !important;
//    padding: 0 !important;
//    margin: 0 !important;
//    border: none !important;
//    outline: none !important;
//    height: none !important;
//}

.modelPopAlerts {
    //z-index: 99999 !important;
    z-index: 99999 !important;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100vw !important;

    .alertset {
        height: auto;
        // margin-top: 10%;
        padding: 20px 20px 20px 20px;
        background-color: #FFFFFF;
        border-radius: 8px;

        .alertset_div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .alertse_divSpace {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                min-width: 32vw;

                .alertsImg {
                    width: 35px;
                    height: 35px;

                }

                div {
                    margin-left: 3%;

                    .alertTypeDesc {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .fixedAssetName {
                        margin: 0;
                        font-size: 12px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #426bfa;
                    }
                }
            }
        }

        .alertDesc {
            font-size: 14px;
            color: #000;
            font-family: sans-serif;
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 10px;
        }

        .reasonClose {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 10px 10px 10px 10px;
            transition: height 0.3s ease; /* The height will transition smoothly in 0.3 seconds with an ease timing function */
            .reason {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                margin-bottom: 10px;
            }

            .selectDropdownClass {
                outline: none;
                border-width: 2px;
                border-color: #A7B8F4;
                width: 40%;
                margin: 0px 0px 10px 0px;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                border-radius: 4px;
            }
        }

        .otherReason {
            border: 2px solid #dde2fc;
            outline: none;
            border-radius: 4px;
            width: 95%;
            margin: 0px 0px 10px 0px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 4px;
        }

        .footerAlert {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 10px 10px 0px 10px;

            .ViewStyle {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 2px solid #dde2fc;
                text-align: center;
                color: #0075ff;
                height: 5vh;
                width: 10vw;
                border-radius: 5px;

                p {
                    text-align: center;
                    margin-top: 10%;
                    font-family: sans-serif;
                    font-size: 14px;
                    color: #0075ff;
                }

                img {
                    margin-right: 10px;
                }
            }

            .closeStyle {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 2px solid #4663f7;
                text-align: center;
                background-color: #0075ff;
                color: #FFFFFF;
                border-radius: 5px;
                height: 5vh;
                min-width: 10vw;

                p {
                    text-align: center;
                    font-family: sans-serif;
                    font-size: 14px;
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }
}
img,
svg {
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    .modelPopAlerts .alertset .footerAlert .ViewStyle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 2px solid #dde2fc;
        text-align: center;
        color: #0075ff;
        height: 5vh;
        min-width: 20vh;
        border-radius: 5px;
        padding: 10px;
        align-items: center;
    }

    .modal-backdrop {
        --bs-backdrop-zindex: 1050;
        --bs-backdrop-bg: #000;
        --bs-backdrop-opacity: 0.5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--bs-backdrop-zindex);
        width: 108vw;
        height: 100vh;
        background-color: var(--bs-backdrop-bg);
    }
}

.loader {
    border: 4px solid #FFFFFF;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
element.style {}

.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: -49.2rem !important;
    min-width: 42vw !important;
    z-index: 99999 !important;
}

.Toastify__toast {
    position: relative;
    min-height: 64px;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 0rem;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    max-height: var(--toastify-toast-max-height);
    overflow: hidden;
    font-family: sans-serif;
    font-family: var(--toastify-font-family);
    cursor: pointer;
    direction: ltr;
    background-color: #8ec265 !important;
    color: white !important;
}

@media (min-width: 576px) {
    .alertset {
    min-width: 50vw;
}
}