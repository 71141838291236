.mainContainerDiv {
  padding: 16px;
  border: 1px solid #bdcbfb;
  border-radius: 6px;
}

.marginTopMinus2px {
  margin-top: -2px;
}

.dataInRow {
  display: flex;
  flex-direction: row;
}

.lightTextData {
  color: #6c6363;
  font-size: 12px;
}

.blackTextData {
  color: #000000;
  font-size: 13px;
}

.notYetQcRow,
.qcOkRow,
.qcFailedRow {
  margin-left: 16px;
}

.qrDuplicateRow,
.qcRejectedRow {
  margin-left: 64px;
}

.qcRejectedCategoryListRow {
  margin-left: 128px;
}

.qrCategoryText {
  width: 25%;
}

.qcRejectedCategoryText {
  width: 50%;
}

.changePointer {
  cursor: pointer;
}

.qrChevrons {
  width: 5%;
  text-align: center;
  align-items: center;

  .chevronImgIcon {
    height: 8px;
  }

  .rotate-90-deg {
    transform: rotate(0deg);
    transition: transform 0.1s ease-in-out;
  }
  .rotate-0-deg {
    transform: rotate(-90deg);
    transition: transform 0.1s ease-in-out;
  }
}
