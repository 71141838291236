.incidentLoadMoreCardHeaderCont {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 35px;
  .incidentLoadMoreAttachementTxt {
    font-size: 13px !important;
    font-weight: bold !important;
    color: black;
    position: relative;
    right: 6px;
  }
  .incidentLoadMoreTimeTxt {
    font-size: 13px !important;
    font-weight: bold !important;
    color: black;
    position: relative;
    right: 22px;
  }
  .incidentLoadMoreTypeTxt {
    font-size: 13px !important;
    font-weight: bold !important;
    color: black;
    position: relative;
    right: 18px;
  }
  .incidentLoadMoreCountTxt {
    font-size: 13px !important;
    font-weight: bold !important;
    color: black;
    position: relative;
    right: 78px;
  }

  .incidentLoadMoreLocationTxt {
    font-size: 13px !important;
    font-weight: bold !important;
    color: black;
    position: relative;
    right: 90px;
  }
}

@media only screen and (max-width: 600px) {
  .incidentLoadMoreCardHeaderCont {
    .incidentLoadMoreAttachementTxt {
      font-size: 6px !important;
      left: 35px;
    }
    .incidentLoadMoreTimeTxt {
      font-size: 6px !important;
      left: 16px;
    }
    .incidentLoadMoreTypeTxt {
      font-size: 6px !important;
      right: 0px;
    }
    .incidentLoadMoreCountTxt {
      font-size: 6px !important;
      right: 27px;
    }
    .incidentLoadMoreLocationTxt {
      font-size: 6px !important;
      right: 44px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .incidentLoadMoreCardHeaderCont {
    .incidentLoadMoreAttachementTxt {
      font-size: 11px !important;
      left: 25px;
    }
    .incidentLoadMoreTimeTxt {
      font-size: 11px !important;
      left: 40px;
    }
    .incidentLoadMoreTypeTxt {
      font-size: 11px !important;
      left: 45px;
    }
    .incidentLoadMoreCountTxt {
      font-size: 11px !important;
      left: 7px;
    }
    .incidentLoadMoreLocationTxt {
      font-size: 11px !important;
      right: 44px;
    }
  }
}
