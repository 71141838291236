.settingFilterBtnCont {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  .filterSearchCont {
    position: relative;
    display: flex;
    align-items: center;
    .filterSearchInput {
      outline: none;
      border: none;
      border-bottom: 1px solid black;
      color: #707070;
      font-size: 12px;
      border-radius: 0px;
    }
  }
  .filterBtnConteiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 40px;
    gap: 5px;
    .facilitySettingsExportBtn {
      background-color: #fcf2da;
      border: 0.5px solid #cfc8b8;
      font-size: 14px;
      padding: 5px 10px 5px 10px;
      border-radius: 2px;
      font-weight: 500;
      img {
        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
    }
    .facilityFilterBtn {
      background-color: #e1efff;
      border: 1px solid #0f43f9;
      font-size: 14px;
      padding: 5px 10px 5px 10px;
      border-radius: 2px;
      font-weight: 400;
      img {
        height: 13px;
        width: 13px;
        margin-right: 5px;
      }
    }
    .addFacilityBtn {
      border: none;
      background-color: #0f43f9;
      color: #ffffff;
      font-size: 14px;
      padding: 5px 10px 5px 10px;
      border-radius: 2px;
      font-weight: 300;
      img {
        height: 12px;
        width: 14px;
        margin-right: 5px;
      }
    }
  }
}
.settingCardCont {
  height: 60%;
}
.facilitySettingsFooter {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  .loadMoreBtn {
    border: none;
    background-color: #0075ff;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 40px 3px 40px;
    border-radius: 20px;
  }
  .loadingBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: #0075ff;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 40px 3px 40px;
    border-radius: 20px;
    .loaderAnimation {
      position: relative;
      border: 1px solid #f3f3f3;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border-top: 1px solid #3498db;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      left: 10px;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.active-tab {
  border-bottom: 2px solid rgb(90, 221, 251);
  color: rgb(90, 221, 251);
}
.in-active-tab {
  border-bottom: 2px solid #fff;
  color: #000;
}


// Create Facility Popup
.createFacilityPopupModal {
  z-index: 1050 !important;
  margin-left: -50px !important;
  div {
    width: 46vw;
    border: none;
    border-radius: 15px;
  }
}
.createFacilityPopHeader {
  padding: 35px 35px 10px 35px !important;
  .createFacilityHeader {
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.createFacilityPopBody {
  padding: 10px 35px 0px 35px !important;
  .createFacilityBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .createFacilityHeaderNav {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 25px;
      border-bottom: 1px solid #d7dbec;
      width: 100%;
      border-radius: 0px !important;
      .createFacilityNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #373f6d;
      }
      .activeCreateFacilityNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #0075ff;
        border-bottom: 1px solid #0075ff;
      }
    }
    .facilityBasicInfoCont {
      height: 50vh;
      width: 100%;
      overflow-y: scroll;
      border-radius: 0px !important;
      padding-top: 10px;
      .basicInfoCont {
        height: 70vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        .basicInfoInputsCont {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          .textInputsCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .inputsDivContainer {
              width: 100%;
              border-radius: 0px;
              position: relative;
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 90%;
              }
              .createFacilityIconDiv {
                position: absolute;
                width: 40px;
                height: 25px;
                border-radius: 0px;
                right: 30px;
                top: 3px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .createFacilityIcon {
                  height: 20px;
                  width: 20px;
                }
              }
            }

            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .emailSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              left: 90px;
              top: 0px;
              z-index: 1000;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                .closeIconDev {
                  height: 20px;
                  width: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  img {
                    height: 10px;
                    width: 10px;
                    cursor: pointer;
                  }
                }
                .closeIconDev:hover {
                  background-color: #d3d3d3;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .selectorInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            select {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 6px;
              border-radius: 5px;
              font-size: 13px;
              width: 90%;
              option {
                font-size: 13px;
                font-weight: 500;
              }
            }
          }
          .phoneNumberInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .selectAndInputCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              .imgAndSelectCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #bdcbfb;
                outline: none;
                width: 25%;
                padding: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                img {
                  height: 10px;
                  width: 15px;
                }
                select {
                  border: none;
                  outline: none;
                  font-size: 13px;
                  font-weight: 500;
                  border-radius: 0px !important;
                  option {
                    font-size: 13px;
                    font-weight: 500;
                    color: #000;
                  }
                }
              }
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-left: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .phoneNumberSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              left: 90px;
              top: 0px;
              z-index: 1000;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                img {
                  height: 10px;
                  width: 10px;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .sizeFacilityInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .inputAndSelectCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: none;
              }
              select {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 6px;
                font-size: 13px;
                font-weight: 500;
                width: 25%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                option {
                  font-size: 13px;
                  font-weight: 500;
                  color: #000;
                }
              }
            }
          }
        }
        .mapContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid grey;
          height: 15vh;
        }
        .latAndLongInputsCont {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          padding-top: 10px;
          .textInputsCont {
            width: 50%;
            // border: 1px solid brown;
            display: flex;
            flex-direction: column;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
            }
            input {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 5px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              width: 90%;
            }
          }
        }
      }
    }
    .facilityBasicInfoCont::-webkit-scrollbar {
      display: none;
    }
    .facilityAttributesCont {
      height: 50vh;
      width: 100%;
      border-radius: 0px !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 10px;
      .textInputsCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        input {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 5px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 90%;
        }
      }
      .unitOfEnergyInputCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        .selectAndInputCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          .imgAndSelectCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid #bdcbfb;
            outline: none;
            width: 27%;
            padding: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            img {
              height: 10px;
              width: 15px;
            }
            select {
              border: none;
              outline: none;
              font-size: 13px;
              font-weight: 500;
              border-radius: 0px !important;
              option {
                font-size: 13px;
                font-weight: 500;
                color: #000;
              }
            }
          }
          input {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 63%;
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
      .selectorInputCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        select {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 6px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 90%;
          option {
            font-size: 13px;
            font-weight: 500;
            color: #000;
          }
        }
      }
      .contractDemandInputCont {
        width: 50%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 4px;
          padding-top: 5px;
        }
        .inputAndSelectCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          input {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 65%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right: none;
          }
          select {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 6px;
            font-size: 13px;
            font-weight: 500;
            width: 25%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            option {
              font-size: 13px;
              font-weight: 500;
              color: #000;
            }
          }
        }
      }
    }
    .facilityUsersCont {
      width: 100%;
      height: 50vh;
      border-radius: 0px !important;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      overflow-y: scroll;
      .facilityUsersAddBtnCont {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .facilityUsersAddBtn {
          background-color: #0f43f9;
          color: #ffffff;
          border: none;
          border-radius: 3px;
          padding: 3px 10px 3px 10px;
          font-size: 15px;
          font-weight: 300;
          img {
            height: 12px;
            width: 12px;
            margin-right: 8px;
          }
        }
      }
      .facilityUsersAndRoleContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .facilityUsersAndRoleHeaders {
          display: flex;
          flex-direction: row;
          width: 100%;
          p {
            font-size: 15px;
            margin: 0px;
            font-weight: 500;
            width: 48%;
            padding-bottom: 5px;
          }
        }
        .facilityUsersAndRoleSelectorsCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 20px;
          align-items: center;
          margin-bottom: 10px;
          .facilityUsersAndRoleSelector {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 6px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 44%;
          }
          img {
            height: 20px;
            width: 15px;
            cursor: pointer;
          }
        }
      }
    }
    .facilityUsersCont::-webkit-scrollbar {
      display: none;
    }
  }
}
.createFacilityPopupFooter {
  padding: 25px 35px 30px 30px !important;
  .facilityCancelBtn {
    border: 1px solid gray;
    background-color: #fcf2da;
    color: black;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  .facilityNextBtn {
    color: white;
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    background-color: #0f43f9;
    border: 1px solid #5711e2;
    border-radius: 3px;
    border: none;
    font-weight: 300;
  }
  .facilityCreateBtn {
    color: white;
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    background-color: #0f43f9;
    border: 1px solid #5711e2;
    border-radius: 3px;
    border: none;
    font-weight: 300;
  }
}

// Add Address Popup
.createAddressPopup {
  z-index: 1060 !important;
  div {
    border-radius: 15px;
    border: none;
  }
}
.createAddressHeaderCont {
  padding: 35px 35px 20px 35px !important;
  .createAddressTxt {
    margin: 0px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.createAddressBody {
  padding: 0px 35px 0px 35px !important;
  .createAddressBodyCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .createAddressInputsCont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // width: 65%;
      .addressTextInputsCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 5px;
          padding-top: 5px;
        }
        input {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 5px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 75%;
        }
      }
      .addressSelectorCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 5px;
          padding-top: 5px;
        }
        select {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 6px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 75%;
        }
      }
    }
    .createAddressPurposeCont {
      flex-direction: column;
      display: flex;
      width: 35%;
      justify-content: center;
      padding-left: 25px;
      gap: 5px;
      .addressPurposeCont {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        gap: 10px;
        .purposeCheckbox {
          height: 12px;
          width: 12px;
          cursor: pointer;
          border: 1px solid #275efe !important;
        }
        label {
          font-size: 14px;
          font-weight: 500;
          margin: 0px;
        }
      }
    }
  }
}
.createAddressFooter {
  padding: 5px 35px 30px 35px !important;
  .cancelBtn {
    border: 1px solid gray;
    background-color: #fcf2da;
    color: black;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  .addAddressBtn {
    color: white;
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    background-color: #0f43f9;
    border: 1px solid #5711e2;
    border-radius: 3px;
    border: none;
    font-weight: 300;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .settingFilterBtnCont {
    height: 6%;
  }

  // Create Facility Popup
  .createFacilityPopupModal {
    div {
      width: 80vw;
    }
  }
  .createFacilityPopBody {
    .createFacilityBody {
      .facilityBasicInfoCont {
        height: 40vh;
      }
      .facilityAttributesCont {
        height: 40vh;
      }
      .facilityUsersCont {
        height: 40vh;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .settingFilterBtnCont {
    height: 6%;
    .filterBtnConteiner {
      margin-right: 10px;
      .facilitySettingsExportBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 10px;
          width: 10px;
          margin-right: 2px;
        }
      }
      .facilityFilterBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 8px;
          width: 8px;
          margin-right: 2px;
        }
      }
      .addFacilityBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 8px;
          width: 8px;
          margin-right: 2px;
        }
      }
    }
  }

  .settingCardCont {
    height: 65%;
  }

  // Create Facility Popup
  .createFacilityPopupModal {
    margin-left: 0px !important;
    div {
      width: 96vw;
    }
  }
  .createFacilityPopHeader {
    padding: 20px 20px 10px 20px !important;
    .createFacilityHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .createFacilityPopBody {
    padding: 10px 20px 0px 20px !important;
    .createFacilityBody {
      .createFacilityHeaderNav {
        gap: 15px;
        .createFacilityNavTxt {
          font-size: 13px;
        }
        .activeCreateFacilityNavTxt {
          font-size: 13px;
        }
      }
      .facilityBasicInfoCont {
        height: 40vh;
        .basicInfoCont {
          height: 55vh;
          .basicInfoInputsCont {
            .textInputsCont {
              label {
                font-size: 13px;
              }
              input {
                padding: 4px;
                font-size: 10px;
              }
              .createFacilityIconDiv {
                position: absolute;
                width: 10px;
                height: 25px;
                border-radius: 0px;
                right: 5px;
                top: 4px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .createFacilityIcon {
                  height: 15px;
                  width: 20px;
                }
              }

              // .setPurpose {
              //   margin: 0px;
              //   font-size: 14px;
              //   font-weight: 500;
              //   color: #4e23d9;
              //   text-decoration: underline;
              //   padding-top: 3px;
              //   cursor: pointer;
              //   // width: 50%;
              // }
              // .emailSetPurpose {
              //   width: 18vw;
              //   border: 1px solid #bdcbfb;
              //   background-color: white;
              //   position: absolute;
              //   display: flex;
              //   flex-direction: column;
              //   border-radius: 5px !important;
              //   padding: 10px 10px 10px 10px;
              //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
              //   left: 90px;
              //   top: 0px;
              //   z-index: 1000;
              //   .setPurposeHeader {
              //     display: flex;
              //     flex-direction: row;
              //     align-items: center;
              //     justify-content: flex-end;
              //     width: 100%;
              //     .closeIconDev {
              //       height: 20px;
              //       width: 20px;
              //       display: flex;
              //       align-items: center;
              //       justify-content: center;
              //       border-radius: 50%;
              //       img {
              //         height: 10px;
              //         width: 10px;
              //         cursor: pointer;
              //       }
              //     }
              //     .closeIconDev:hover {
              //       background-color: #d3d3d3;
              //       cursor: pointer;
              //     }
              //   }
              //   .setPurposeDataCont {
              //     display: flex;
              //     flex-direction: column;
              //     align-items: center;
              //     justify-content: flex-start;
              //     width: 100%;
              //     .setPurposeData {
              //       display: flex;
              //       flex-direction: row;
              //       align-items: center;
              //       grid-gap: 5px;
              //       grid-gap: 5px;
              //       gap: 5px;
              //       width: 100%;
              //       input {
              //         height: 11px;
              //         width: 11px;
              //         cursor: pointer;
              //         border: 1px solid #275efe !important;
              //       }
              //       label {
              //         font-size: 12px;
              //         font-weight: 500;
              //         margin: 0px;
              //         cursor: pointer;
              //       }
              //     }
              //   }
              // }
            }
            .selectorInputCont {
              label {
                font-size: 13px;
              }
              select {
                padding: 5px;
                font-size: 10px;
                option {
                  font-size: 10px;
                }
              }
            }
            .phoneNumberInputCont {
              label {
                font-size: 13px;
              }
              .selectAndInputCont {
                .imgAndSelectCont {
                  padding: 5px;
                  img {
                    height: 8px;
                    width: 10px;
                  }
                  select {
                    font-size: 10px;
                    option {
                      font-size: 10px;
                    }
                  }
                }
                input {
                  padding: 4px;
                  font-size: 10px;
                }
              }
              // .setPurpose {
              //   margin: 0px;
              //   font-size: 14px;
              //   font-weight: 500;
              //   color: #4e23d9;
              //   text-decoration: underline;
              //   padding-top: 3px;
              //   cursor: pointer;
              //   // width: 50%;
              // }
              // .phoneNumberSetPurpose {
              //   width: 18vw;
              //   border: 1px solid #bdcbfb;
              //   background-color: white;
              //   position: absolute;
              //   display: flex;
              //   flex-direction: column;
              //   border-radius: 5px !important;
              //   padding: 10px 10px 10px 10px;
              //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
              //   left: 90px;
              //   top: 0px;
              //   z-index: 1000;
              //   .setPurposeHeader {
              //     display: flex;
              //     flex-direction: row;
              //     align-items: center;
              //     justify-content: flex-end;
              //     width: 100%;
              //     img {
              //       height: 10px;
              //       width: 10px;
              //       cursor: pointer;
              //     }
              //   }
              //   .setPurposeDataCont {
              //     display: flex;
              //     flex-direction: column;
              //     align-items: center;
              //     justify-content: flex-start;
              //     width: 100%;
              //     .setPurposeData {
              //       display: flex;
              //       flex-direction: row;
              //       align-items: center;
              //       grid-gap: 5px;
              //       grid-gap: 5px;
              //       gap: 5px;
              //       width: 100%;
              //       input {
              //         height: 11px;
              //         width: 11px;
              //         cursor: pointer;
              //         border: 1px solid #275efe !important;
              //       }
              //       label {
              //         font-size: 12px;
              //         font-weight: 500;
              //         margin: 0px;
              //         cursor: pointer;
              //       }
              //     }
              //   }
              // }
            }
            .sizeFacilityInputCont {
              label {
                font-size: 13px;
              }
              .inputAndSelectCont {
                input {
                  padding: 4px;
                  font-size: 10px;
                }
                select {
                  padding: 5px;
                  font-size: 10px;
                  option {
                    font-size: 10px;
                  }
                }
              }
            }
          }
          .mapContainer {
            height: 15vh;
          }
          .latAndLongInputsCont {
            .textInputsCont {
              label {
                font-size: 13px;
              }
              input {
                padding: 4px;
                font-size: 10px;
              }
            }
          }
        }
      }
      .facilityBasicInfoCont::-webkit-scrollbar {
        display: none;
      }
      .facilityAttributesCont {
        height: 40vh;
        .textInputsCont {
          label {
            font-size: 13px;
          }
          input {
            padding: 4px;
            font-size: 10px;
          }
        }
        .unitOfEnergyInputCont {
          label {
            font-size: 13px;
          }
          .selectAndInputCont {
            .imgAndSelectCont {
              padding: 5px;
              img {
                height: 8px;
                width: 10px;
              }
              select {
                font-size: 10px;
                option {
                  font-size: 10px;
                }
              }
            }
            input {
              padding: 4px;
              font-size: 10px;
            }
          }
        }
        .selectorInputCont {
          label {
            font-size: 13px;
          }
          select {
            padding: 5px;
            font-size: 10px;
            option {
              font-size: 10px;
            }
          }
        }
        .contractDemandInputCont {
          label {
            font-size: 13px;
          }
          .inputAndSelectCont {
            input {
              padding: 4px;
              font-size: 10px;
            }
            select {
              padding: 5px;
              font-size: 10px;
              option {
                font-size: 10px;
              }
            }
          }
        }
      }
      .facilityUsersCont {
        height: 40vh;
        .facilityUsersAddBtnCont {
          .facilityUsersAddBtn {
            padding: 2px 8px 2px 8px;
            font-size: 13px;
            img {
              height: 10px;
              width: 10px;
              margin-right: 8px;
            }
          }
        }
        .facilityUsersAndRoleContainer {
          .facilityUsersAndRoleHeaders {
            p {
              font-size: 13px;
            }
          }
          .facilityUsersAndRoleSelectorsCont {
            .facilityUsersAndRoleSelector {
              padding: 5px;
              font-size: 10px;
            }
            img {
              height: 15px;
              width: 12px;
              cursor: pointer;
            }
          }
        }
      }
      .facilityUsersCont::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .createFacilityPopupFooter {
    padding: 25px 35px 30px 30px !important;
    .facilityCancelBtn {
      border: 1px solid gray;
      background-color: #fcf2da;
      color: black;
      font-size: 13px;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      font-weight: 500;
    }
    .facilityNextBtn {
      color: white;
      font-size: 13px;
      padding: 5px 15px 5px 15px;
      background-color: #0f43f9;
      border: 1px solid #5711e2;
      border-radius: 3px;
      border: none;
      font-weight: 300;
    }
    .facilityCreateBtn {
      color: white;
      font-size: 13px;
      padding: 5px 15px 5px 15px;
      background-color: #0f43f9;
      border: 1px solid #5711e2;
      border-radius: 3px;
      border: none;
      font-weight: 300;
    }
  }

  // Add Address Popup
  .createAddressHeaderCont {
    padding: 20px 20px 10px 20px !important;
    .createAddressTxt {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        cursor: pointer;
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .createAddressBody {
    padding: 0px 20px 0px 20px !important;
    .createAddressBodyCont {
      .createAddressInputsCont {
        .addressTextInputsCont {
          label {
            font-size: 13px;
          }
          input {
            padding: 4px;
            font-size: 10px;
            width: 85%;
          }
        }
        .addressSelectorCont {
          label {
            font-size: 13px;
          }
          select {
            padding: 5px;
            font-size: 10px;
            width: 85%;
          }
        }
      }
    }
  }
  .createAddressFooter {
    padding: 10px 20px 20px 20px !important;
    .cancelBtn {
      font-size: 10px;
      padding: 3px 8px 3px 8px;
    }
    .addAddressBtn {
      font-size: 10px;
      padding: 4px 8px 4px 8px;
    }
  }
}

.tooltip {
  font-size: 8px !important;
  width: 16% !important;
}
