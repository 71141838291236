.scoreCardScss {
  display: flex;
  justify-content: space-between;
  border: 1.3px solid #ccc;
  padding: 7px;
  border-left: 7px solid #00a925;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #666258;
    margin: 0;
  }
  h4 {
    font-size: 23px !important;
    font-weight: 500 !important;
    margin: 0;
    span {
      font-size: 12px !important;
      margin-left: 3px;
    }
  }
  &:hover {
    box-shadow: 0 3px 6px rgb(0 0 0 / 11%), 0 3px 6px rgb(0 0 0 / 20%);
  }
}
