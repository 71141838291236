// .videoAnalyticTicketsContainer {
//     display: flex;
//     flex-direction: column;
//     padding: 10px;
//     padding-top: 25px;
//     .ticketTypeNotificationCont {
//       display: flex;
//       flex-direction: row;
//       justify-content: flex-start;
//       align-items: center;
//       .ticketTypeNotification {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         margin-right: 25px;
//         .ticketTypeNotificationTxt {
//           font-size: 13px;
//           color: black;
//           font-weight: 500;
//         }
//         .ticketTypeNotificationCount {
//           font-size: 18px;
//           color: black;
//           font-weight: 700;
//           margin-top: -10px;
//         }
//       }
//     }
//   }

.faultTicketsContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 25px;
  .faultTicketsNotificationCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    .faultTicketsNotification {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .faultTicketsNotificationTxt {
        font-size: 13px;
        color: black;
        font-weight: 500;
      }
      .faultTicketsNotificationCount {
        font-size: 18px;
        color: black;
        font-weight: 700;
        margin-top: -10px;
      }
    }
  }
}
