.dataComplianAsset {
  margin-top: 56px;
}

.datacomplian-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #dae2ff;
  border-radius: 5px;
  margin: 4px;
  align-items: center;
  padding: 10px;
  height: 50px;

  div {
    justify-content: space-between;
  }

  .atsuya-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 2rem;
    width: 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-top: 0px;
    margin-right: 7px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .area-details {
    display: flex;

    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
      font-weight: 700;
    }

    p {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
    }
  }

  .customer-id {
    display: flex;
    flex-direction: column;

    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 5px !important;
    }
  }

  .customer-time {
    display: flex;

    .abberviation {
      margin-right: 35px;
      font-size: 12px;
    }
    .performance-zone {
      display: flex;
      flex-direction: row;

      h6 {
        color: #182f43;
        font-family: "Roboto";
        margin-top: 25px;
        font-size: 12px;
        margin-bottom: 0px !important;
        font-weight: 700;
      }
    }
  }
  .customer-Chart {
    height: 10vh;
    width: 10vw;
    position: relative;
    top: 7px;
  }
}

@media only screen and (max-width: 600px) {
  .datacomplian-container .customer-Chart {
    height: auto;
    width: auto;
    position: relative;
    right: 63px;
    top: 12px;
  }

  .datacomplian-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #dae2ff;
    border-radius: 5px;
    margin: 4px;
    align-items: center;
    padding: 10px;
    height: 85px;
    position: relative;
  }

  .customer-time {
    display: flex;
    flex-direction: column;
    margin-right: -10px;

    .abberviation {
      margin-right: 35px;
      font-size: 12px;
    }
    .performance-zone {
      display: flex;
      flex-direction: row;

      h6 {
        color: #182f43;
        font-family: "Roboto";
        font-size: 10px;
        margin-bottom: 0px !important;
        font-weight: 700;
        margin-left: -20px;
      }
    }
  }

  .datacomplian-container {
    .area-details {
      .atsuya-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 2rem;
        width: 2rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        margin-top: 0px;
        margin-right: 7px;
        position: relative;
        bottom: 17px;
      }

      h6 {
        color: #182f43;
        font-family: "Roboto";
        font-size: 12px;
        margin-bottom: 0px !important;
        font-weight: 700;
        position: relative;
        bottom: 16px;
      }

      p {
        color: #182f43;
        font-family: "Roboto";
        font-size: 12px;
        margin-bottom: 0px !important;
        position: relative;
        right: 40px;
        top: 22px;
      }
    }
  }

  .datacomplian-container .customer-id h6 {
    color: #182f43;
    font-family: "Roboto";
    font-size: 12px;
    position: absolute;
    top: 48px;
    left: 10px;
    margin-bottom: 5px !important;
  }

  .datacomplian-container .customer-time .performance-zone h6 {
    color: #182f43;
    font-family: "Roboto";
    margin-top: 25px;
    font-size: 12px;
    margin-bottom: 0px !important;
    font-weight: 700;
    position: relative;
    bottom: 18px;
    right: 55px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .datacomplian-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #dae2ff;
    border-radius: 5px;
    margin: 4px;
    align-items: center;
    padding: 10px;
    height: 60px;
  }

  .datacomplian-container .customer-Chart {
    height: 10vh;
    width: 10vw;
    position: relative;
    top: 35px;
  }

  .datacomplian-container .customer-time .performance-zone h6 {
    color: #182f43;
    font-family: "Roboto";
    margin-top: 25px;
    font-size: 12px;
    margin-bottom: 0px !important;
    font-weight: 700;
    position: relative;
    top: 27px;
  }
}
