.ticketsTotal {
    border: 1px solid #aebeff;
    padding: 5px;
    margin-top: 2%;
    border-radius: 6px;
    margin-bottom: 18px;
    margin-left: 10px;
    margin-right: 10px;
    &-header {
        display: flex;
        justify-content: space-between;
        padding: 4px 10px 0px 10px;

        h3 {
            font-size: 12px;
            font-weight: 500;
        }
    }

    &-subCard {
        margin-left: 1px;
        margin-right: 1px;
        .header {
            display: flex;
            background-color: #faaca7;
            padding: 7px;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            height: 41px;
            padding-bottom: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            h3 {
                font-size: 12px;
                font-weight: 500;
            }
        }

        .body {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-start;
            background-color: #fdd9d7;
            padding: 10px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            .titleHead {
                display: flex;
                flex-direction: column;
                align-items: center;
                h4 {
                    font-size: 12px !important;
                    font-weight: 300 !important;
                }

                h3 {
                    font-size: 12px;
                    font-weight: 500;
                }
                &:nth-child(1) {
                    h3{
                        background-color: red;
                        width: 50%;
                        padding: 4px;
                        border-radius: 7px;
                        color: white;
                    }
                }

               
            }
        }

    }
}

@media screen and (max-width: 650px) {
    .ticketsTotal {
        border: 1px solid #aebeff;
        padding: 5px;
        margin-top: 4%;
        margin-left: 0px;
        margin-right: 0px;
    
        &-header {
            display: flex;
            justify-content: space-between;
    
            h3 {
                font-size: 12px;
                font-weight: 500;
            }
        }
    
        &-subCard {
            .header {
                display: flex;
                background-color: #faaca7;
                padding: 7px;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                height: 41px;
                padding-bottom: 0;
    
                h3 {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
    
            .body {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
                background-color: #fdd9d7;
                padding: 10px;
    
                .titleHead {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    h4 {
                        font-size: 12px !important;
                        font-weight: 300 !important;
                    }
    
                    h3 {
                        font-size: 12px;
                        font-weight: 400;
                    }
                    &:nth-child(1) {
                        h3{
                            background-color: red;
                            width: 50%;
                            padding: 4px;
                            border-radius: 7px;
                        }
                    }
    
                   
                }
            }
    
        }
    }
  }
  