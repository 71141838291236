.createdAndEditAddressPopup {
  div {
    border-radius: 15px;
    border: none;
  }
}
.createFacilityAddressHeaderCont {
  padding: 30px 30px 10px 30px !important;
  .addAddressTxt {
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}

.addressPopModalCont {
  display: flex;
  flex-direction: row;
  padding: 10px 30px 10px 30px !important;
  .addressInputsCont {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50vh;
    .inputOneCont {
      display: flex;
      flex-direction: column;
      height: 100%;
      .popInputCont {
        justify-content: center;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        height: 11vh;
        .lableTxt {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .inputPopCont {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .popInput {
            border: 1px solid #a7b8f4;
            border-radius: 5px;
            outline: none;
            width: 250px;
            padding: 5px;
            font-size: 13px;
            font-weight: 500;
          }
          .addressSelector {
            border: 1px solid #a7b8f4;
            border-radius: 5px;
            outline: none;
            width: 250px;
            padding: 6px;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
    .inputThree {
      display: flex;
      flex-direction: column;
      .lableTxt {
        font-size: 13px;
        font-weight: 600;
      }
      .ContactPurposesList {
        height: 100%;
        overflow: scroll;
        .checkBoxDiv {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .checkbox {
            margin: 0;
            padding: 0;
            margin-right: 6px;
          }
          .checkboxTxt {
            margin-top: 6px;
            font-size: 12px;
            font-weight: 500;
            cursor: default;
          }
        }
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.close {
  border: none !important;
}
.addressPopupModalFooter {
  padding: 10px 30px 30px 30px !important;
  .cancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 14px;
    padding: 5px 12px 5px 12px;
    border-radius: 3px;
    font-weight: 500;
  }
  .addAddressBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 14px;
    color: #ffffff;
    padding: 5px 12px 5px 12px;
    border-radius: 3px;
    font-weight: 400;
  }
}

.formErrorPostalCode {
  font-size: 10px;
  margin: 0px !important;
  color: #dc3545;
}
