.dgContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  grid-gap: 0px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5%;
  .cardOneCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0px;
    gap: 10px;
    .cardOneHeading {
      font-size: 12px;
      color: #000000;
    }
    .cardOneValuesCont {
      display: flex;
      justify-content: center;
      align-items: center;
      .cardOneIcon {
        height: 20px;
        width: 15px;
        position: relative;
        bottom: 8px;
        cursor: default;
      }
      .cardOneValues {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        padding-left: 5px;
      }
    }
  }
  .cardTwoCont {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 5px;
    .cardTwoHeading {
      font-size: 12px;
      color: #000000;
    }
    .cardTwoValuesCont {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: -12px;
      .cardTwoIcon {
        height: 20px;
        width: 15px;
        position: relative;
        bottom: 8px;
        cursor: default;
      }
      .cardTwoValues {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        padding-left: 5px;
      }
    }
    .cardTwoTxt {
      font-size: 8px;
      border: 1px solid #9fb4fd;
      background-color: #daecfc;
      padding: 6px;
      border-radius: 5px;
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .dgContainer {
    gap: 8px;
    .cardOneCont {
      .cardOneHeading {
        font-size: 8px;
      }
      .cardOneValuesCont {
        .cardOneIcon {
          height: 15px;
          width: 10px;
        }
        .cardOneValues {
          font-size: 9px;
        }
      }
    }
    .cardTwoCont {
      .cardTwoHeading {
        font-size: 8px;
        position: relative;
        top: 10px;
      }
      .cardTwoValuesCont {
        .cardTwoIcon {
          height: 15px;
          width: 10px;
        }
        .cardTwoValues {
          font-size: 9px;
        }
      }
      .cardTwoTxt {
        font-size: 4px;
        padding: 5px;
        margin-left: 0px;
        position: relative;
        left: 13px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .dgContainer {
    gap: 40px;
  }
}
