.userCardCont {
  border: 1px solid #bdcbfb;
  display: flex;
  flex-direction: column;
  width: 24%;
  border-radius: 10px;
  cursor: pointer;
  .userCardInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 10px;
    .userCardInput {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 50%;
      border: 2px solid #bbb;
      width: 16px;
      height: 16px;
      outline: none;
    }
    .userStatusCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      .userEnableTxt {
        margin: 0px;
        background-color: #aadeac;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }
      .userDisabledCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        position: relative;
        img {
          height: 20px;
          width: 15px;
        }
        .userDisabledTxt {
          margin: 0px;
          background-color: #ff967e;
          text-transform: uppercase;
          font-size: 8px;
          font-weight: 600;
          padding: 2px 5px 2px 5px;
          border-radius: 50px;
        }
        // .loackHoverTxt {
        //   margin: 0px;
        //   background-color: #ff967e;
        //   font-size: 8px;
        //   font-weight: 200;
        //   padding: 4px 6px 4px 6px;
        //   color: white;
        //   border-radius: 2px;
        //   position: absolute;
        //   bottom: 0px;
        // }
      }
      .profileHoverImgCont {
        position: relative;
        .profileHoverImg {
          height: 22px;
          width: 10px;
        }
        .userHoverCont {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0px;
          background-color: white;
          border-radius: 5px;
          position: absolute;
          border: 1px solid gray;
          top: 0px;
          right: 10px;
          width: 111px;
          transition: all 0.2s ease-in;
          display: none;
          li {
            margin: 0px;
            font-size: 11px;
            font-weight: 500;
            border-bottom: 1px solid gray;
            width: 100%;
            padding: 3px 15px 3px 15px;
            cursor: pointer;
          }
          li:hover {
            background-color: #e7ecff;
          }
        }
      }
      .profileHoverImgCont:hover {
        .userHoverCont {
          display: block;
        }
      }
    }
  }
  .userCardProfileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px 0px 10px;
    gap: 15px;
    .userProvileImgCont {
      height: 62px;
      width: 62px;
      border: 1px solid #bdcbfb;
      .userLogoImage {
        height: 62px;
        width: 62px;
      }
    }
    .userProfileDetails {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      margin: 0px;
      padding: 0px;
      .userName {
        margin: 0px;
        font-size: 13px;
        color: #2e8ece;
        font-weight: 600;
        word-break: break-all;
      }
      .userEmail {
        margin: 0px;
        font-size: 11px;
        font-weight: 500;
      }
      .userNumber {
        margin: 0px;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
  .userCardDetailsCont {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px 0px 10px 10px;
    .userRoleDetails {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin: 0px 0px 2px 0px;
      .userRoleHeader {
        margin: 0px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        color: #707070;
      }
      .userRole {
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .userReportDetailsCont {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      margin: 0px;
      padding: 0px;
      .userReportHeader {
        margin: 0px;
        padding-top: 5px;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: #707070;
      }
      .reporterName {
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
      }
      .reporterRole {
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
.userCardCont:hover {
  border: 1px solid #2680eb;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
}
.userActiveCardCont {
  border: 1px solid #2680eb;
  border: 1px solid #bdcbfb;
  display: flex;
  flex-direction: column;
  width: 24%;
  border-radius: 10px;
  cursor: pointer;
  .userCardInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 10px;
    .userCardInput:checked {
      clip-path: circle(46% at 50% 50%);
      width: 16px;
      height: 16px;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
    }
    .userStatusCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      position: relative;
      &:hover {
        .userHoverCont {
          display: block;
        }
      }
      .userEnableTxt {
        margin: 0px;
        background-color: #aadeac;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 50px;
      }
      .userDisabledCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        img {
          height: 20px;
          width: 15px;
        }
        .userDisabledTxt {
          margin: 0px;
          background-color: #ff967e;
          text-transform: uppercase;
          font-size: 8px;
          font-weight: 600;
          padding: 2px 5px 2px 5px;
          border-radius: 50px;
        }
      }
      .profileHoverImg {
        height: 22px;
        width: 10px;
      }
    }
    .userHoverCont {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0px;
      background-color: white;
      border-radius: 5px;
      position: absolute;
      border: 1px solid gray;
      top: 0px;
      right: 10px;
      width: 111px;
      transition: all 0.2s ease-in;
      display: none;
      li {
        margin: 0px;
        font-size: 11px;
        font-weight: 500;
        border-bottom: 1px solid gray;
        width: 100%;
        padding: 3px 15px 3px 15px;
        cursor: pointer;
      }
      li:hover {
        background-color: #e7ecff;
      }
    }
  }
  .userCardProfileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px 0px 10px;
    gap: 15px;
    .userProvileImgCont {
      height: 62px;
      width: 62px;
      border: 1px solid #bdcbfb;
      .userLogoImage {
        height: 62px;
        width: 62px;
      }
    }
    .userProfileDetails {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      margin: 0px;
      padding: 0px;
      .userName {
        margin: 0px;
        font-size: 13px;
        color: #2e8ece;
        font-weight: 600;
        word-break: break-all;
      }
      .userEmail {
        margin: 0px;
        font-size: 11px;
        font-weight: 500;
      }
      .userNumber {
        margin: 0px;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
  .userCardDetailsCont {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px 0px 10px 10px;

    .userRoleDetails {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin: 0px 0px 2px 0px;
      .userRoleHeader {
        margin: 0px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        color: #707070;
      }
      .userRole {
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .userReportDetailsCont {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      margin: 0px;
      padding: 0px;
      .userReportHeader {
        margin: 0px;
        padding-top: 5px;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: #707070;
      }
      .reporterName {
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
      }
      .reporterRole {
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

// Add to facility Popup
.userAddFacilityPopupModal {
  div {
    border: none;
    border-radius: 20px;
    width: 40vw;
  }
}
.userAddFacilityPopHeaderCont {
  padding: 30px 35px 20px 35px !important;
  .userAddFacilityPopHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.userAddFacilityPopBody {
  padding: 5px 35px 5px 35px !important;
  .userAddFacilityPopBodyCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    .userAddFacilityPopBodyHeaderss {
      margin: 0px;
      font-size: 15px !important;
      font-weight: 500 !important;
      padding-bottom: 25px;
    }
    .userAddFacilityDetailsCont {
      display: flex;
      flex-direction: column;
      width: 100%;
      .userAddFacilityDetails {
        display: flex;
        flex-direction: row;
        align-items: start;
        margin-bottom: 10px;
        width: 100%;
        .popFacilityTxt {
          width: 48%;
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
        }
        .popRoleTxt {
          width: 48%;
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
        }
      }
      .userAddFacilitySelectorsHeightCont {
        width: 100%;
        height: 14vh;
        overflow-y: scroll;
        border-radius: 0px !important;
        .userAddFacilitySelectorsCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          width: 100%;
          .popFacilitySelect {
            width: 45%;
            outline: none;
            border: 1px solid #bdcbfb;
            padding: 5px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 400;
            color: #707070;
            option {
              font-size: 13px;
              font-weight: 400;
              color: #707070;
            }
          }
          .popRoleSelect {
            width: 45%;
            outline: none;
            border: none;
            border: 1px solid #bdcbfb;
            padding: 5px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 400;
            color: #707070;
            option {
              font-size: 13px;
              font-weight: 400;
              color: black;
            }
          }
          img {
            cursor: pointer;
            height: 20px;
            width: 14px;
            position: relative;
            right: 5px;
          }
        }
      }
      .userAddFacilitySelectorsHeightCont::-webkit-scrollbar {
        display: none;
      }

      .userAddFacilityDetailsBtnCont {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 5px;
        .userAddFacilityDetailsBtn {
          background-color: #0f43f9;
          margin: 0px;
          color: #ffffff;
          border: none;
          padding: 3px 10px 3px 10px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 300;
          img {
            height: 11px;
            width: 13px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.userAddFacilityPopFooterCont {
  padding: 0px 35px 30px 0px !important;
  .userAddFacilityCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 12px 5px 12px;
    border-radius: 2px;
    font-weight: 500;
  }
  .userAddFacilityMappingBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 15px;
    color: #ffffff;
    padding: 5px 12px 5px 12px;
    border-radius: 2px;
    font-weight: 300;
  }
}

// Add to Groups Popup
.userAddGroupsPopupModal {
  div {
    border: none;
    border-radius: 20px;
    width: 40vw;
  }
}
.userAddGroupsPopHeaderCont {
  padding: 30px 35px 20px 35px !important;
  .userAddGroupsPopHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.userAddGroupsPopBody {
  padding: 5px 35px 5px 35px !important;
  .userAddGroupsPopBodyCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    .userAddGroupsPopBodyHeader {
      margin: 0px;
      font-size: 15px !important;
      font-weight: 500 !important;
      padding-bottom: 25px;
    }
    .userAddGroupsDetailsCont {
      display: flex;
      flex-direction: column;
      width: 100%;
      .userAddGroupsDetails {
        display: flex;
        flex-direction: row;
        align-items: start;
        margin-bottom: 10px;
        .popFacilityGroupsTxt {
          width: 44%;
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
        }
        .popGroupsRoleTxt {
          width: 44%;
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
        }
      }
      .userAddFroupsSelectorsHeightCont {
        width: 100%;
        height: 14vh;
        overflow-y: scroll;
        border-radius: 0px !important;
        .userAddGroupsSelectorsCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          width: 100%;

          .popGroupsSelect {
            width: 45%;
            outline: none;
            border: 1px solid #bdcbfb;
            padding: 5px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 400;
            color: #707070;
            option {
              font-size: 13px;
              font-weight: 400;
              color: #707070;
            }
          }
          .popGroupsRoleSelect {
            width: 45%;
            outline: none;
            border: 1px solid #bdcbfb;
            padding: 5px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 400;
            color: #707070;
            option {
              font-size: 13px;
              font-weight: 400;
              color: black;
            }
          }
          img {
            cursor: pointer;
            height: 20px;
            width: 14px;
            position: relative;
            right: 5px;
          }
        }
      }
      .userAddFroupsSelectorsHeightCont::-webkit-scrollbar {
        display: none;
      }

      .userAddGroupsDetailsBtnCont {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 5px;
        .userAddGroupsDetailsBtn {
          background-color: #0f43f9;
          margin: 0px;
          color: #ffffff;
          border: none;
          padding: 3px 10px 3px 10px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 300;
          img {
            height: 11px;
            width: 13px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.userAddGroupsPopFooterCont {
  padding: 0px 35px 30px 0px !important;
  .userAddGroupsCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 12px 5px 12px;
    border-radius: 2px;
    font-weight: 500;
  }
  .userAddGroupsMappingBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 15px;
    color: #ffffff;
    padding: 5px 12px 5px 12px;
    border-radius: 2px;
    font-weight: 300;
  }
}

// Delete Popup
.deleteUserPopupModal {
  div {
    border: none;
    border-radius: 20px;
  }
}
.deleteUserPopHeaderCont {
  padding: 30px 35px 10px 35px !important;
  .deleteUserPopHeader {
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.deleteUserPopBoday {
  padding: 10px 35px 10px 35px !important;
  .deleteUserBodyCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .deleteUserContentTxt {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.deleteUserFooter {
  padding: 10px 35px 30px 0px !important;
  .deleteUserCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  .deleteUserDeleteBtn {
    border: 1px solid #ea5037;
    background-color: #ff5539;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 300;
  }
}

// View User Popup
.viewUsersPopupModal {
  z-index: 1050 !important;
  div {
    border: none;
    border-radius: 15px;
  }
}
.viewUserHeaderPopCont {
  padding: 30px 20px 10px 20px !important;
  margin: 0px 20px 0px 20px !important;
  border-bottom: 1px solid #bdcbfb !important;
  border-radius: 0px !important;
  .viewUserHeaderContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .viewUserProfileCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .profileImgCont {
        width: 50px;
        height: 50px;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .profileDetails {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        .userNameTxt {
          font-size: 14px;
          font-weight: 500;
          color: #5e83e8;
        }
        .userMailTxt {
          font-size: 11px;
          font-weight: 500;
        }
        .userNumberTxt {
          font-size: 11px;
          font-weight: 500;
        }
      }
    }
    .userRoleContainer {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      .userRoleHeader {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: #777777;
      }
      .userRoleName {
        font-size: 12px;
        font-weight: 600;
      }
    }
    .userReportContainer {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      .reportsHeader {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: #777777;
      }
      .reporterName {
        font-size: 12px;
        font-weight: 600;
      }
      .reporterNumber {
        font-size: 11px;
        font-weight: 400;
      }
      .reporterRol {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .viewUserPopCloseIcon {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.viewUsersPopBoday {
  padding: 15px 40px 10px 40px !important;
  .viewUserBodyContainer {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    .viewUserAddressCont {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 10px;

      .addressHeader {
        color: #707070;
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
      }
      .addressInfoTxt {
        font-size: 15px;
        font-weight: 600;
        width: 80%;
        margin: 0px;
      }
    }
    .viewUserDetailsCont {
      display: flex;
      flex-direction: row;
      gap: 120px;
      .facilityAndRoleDetails {
        list-style-type: none;
        margin: 0px 0px 10px 0px;
        padding: 0px;
        .facilityAndRoleHeader {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 10px;
        }
        li {
          font-size: 15px;
          font-weight: 500;
          padding-bottom: 5px;
        }
      }
      .facilityAndRoleGroupsCont {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        .facilityAndRoleGroupsHeader {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 10px;
        }
        li {
          font-size: 15px;
          font-weight: 500;
          padding-bottom: 5px;
        }
      }
    }
  }
}
.viewUsersPopFooter {
  padding: 10px 40px 25px 35px !important;
  .viewUserFooterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .enabledStatusTxt {
      margin: 0px;
      font-size: 10px;
      background-color: #aadeac;
      text-transform: uppercase;
      font-weight: 600;
      padding: 2px 5px 2px 5px;
      border-radius: 10px;
    }
    .viewUserStatusCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .disableStatusTxt {
        margin: 0px;
        font-size: 10px;
        background-color: #ff967e;
        text-transform: uppercase;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 10px;
      }
      .statusAttemptCont {
        border: 1px solid #ff967e;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 5px 2px 5px;
        img {
          height: 12px;
          width: 12px;
        }
        .attemptTxt {
          margin: 0px;
          font-size: 10px;
          color: #ff967e;
        }
      }
    }
    .viewUserBtnsCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .editBtn {
        background-color: #ffffff;
        border: 1px solid #000000;
        font-size: 13px;
        border-radius: 2px;
        padding: 5px 12px 5px 12px;
        font-weight: 500;
        img {
          height: 12px;
          width: 12px;
          margin-right: 5px;
        }
      }
      .deleteBtn {
        background-color: #ea5037;
        border: 1px solid #ea5037;
        font-size: 13px;
        border-radius: 2px;
        padding: 5px 12px 5px 12px;
        font-weight: 400;
        color: #ffffff;
        img {
          height: 14px;
          width: 14px;
          margin-right: 5px;
        }
      }
    }
  }
}

// Edit User Popup

.editUserPopupModal {
  z-index: 1050 !important;
  margin-left: -50px !important;
  div {
    width: 46vw;
    border: none;
    border-radius: 15px;
  }
}
.editUserPopHeader {
  padding: 35px 35px 10px 35px !important;
  .editUserHeader {
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.editUserPopBody {
  padding: 10px 35px 0px 35px !important;
  .editUserBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .editUserHeaderNav {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 25px;
      border-bottom: 1px solid #d7dbec;
      width: 100%;
      border-radius: 0px !important;
      .editUserNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #373f6d;
      }
      .activeeditUserNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #0075ff;
        border-bottom: 1px solid #0075ff;
      }
    }
    .userBasicInfoCont {
      height: 50vh;
      width: 100%;
      overflow-y: scroll;
      border-radius: 0px !important;
      padding-top: 10px;
      .basicInfoCont {
        height: 70vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        .basicInfoInputsCont {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          .textInputsCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            input {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 5px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              width: 90%;
            }
            .editUserIcon {
              height: 12px;
              width: 20px;
              cursor: pointer;
              position: absolute;
              bottom: 10px;
              right: 33px;
            }
            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .emailSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              top: 85px;
              z-index: 1000;
              right: 0px;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                .purposeCloseCont {
                  height: 20px;
                  width: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 10px;
                    width: 10px;
                    cursor: pointer;
                  }
                }
                .purposeCloseCont:hover {
                  background-color: #d3d3d3;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .selectorInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            select {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 6px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              // color: #cccccc;
              width: 90%;
              option {
                font-size: 13px;
                font-weight: 500;
                color: #000;
              }
            }
          }
          .phoneNumberInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .selectAndInputCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              .imgAndSelectCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #bdcbfb;
                outline: none;
                width: 25%;
                padding: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                img {
                  height: 10px;
                  width: 15px;
                }
                select {
                  border: none;
                  outline: none;
                  font-size: 13px;
                  font-weight: 500;
                  border-radius: 0px !important;
                  option {
                    font-size: 13px;
                    font-weight: 500;
                    color: #000;
                  }
                }
              }
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-left: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .phoneNumberSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              left: 90px;
              top: 0px;
              z-index: 1000;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                .purposeCloseCont {
                  height: 20px;
                  width: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 10px;
                    width: 10px;
                    cursor: pointer;
                  }
                }
                .purposeCloseCont:hover {
                  background-color: #d3d3d3;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .sizeFacilityInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .inputAndSelectCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: none;
              }
              select {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 6px;
                font-size: 13px;
                font-weight: 500;
                width: 25%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                option {
                  font-size: 13px;
                  font-weight: 500;
                  color: #000;
                }
              }
            }
          }
        }
        .credentialsInputsCont {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          .credentialsHeder {
            margin: 0px;
            font-size: 15px;
            font-weight: 600;
            color: #000;
            padding-bottom: 2px;
          }
          .credentialsHederBorder {
            border: 1px solid #000;
            width: 62px;
          }
          .userNameInputCont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 5px;
            .textInputsCont {
              width: 100%;
              display: flex;
              flex-direction: column;
              position: relative;
              label {
                font-size: 15px;
                margin: 0px;
                font-weight: 500;
                padding-bottom: 4px;
                padding-top: 5px;
              }
              .passwordCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                gap: 25px;
                input {
                  border: 1px solid #bdcbfb;
                  outline: none;
                  padding: 5px;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 500;
                  width: 45%;
                }
              }
              p {
                margin: 0px;
                font-size: 13px;
                font-weight: 500;
                text-decoration: underline;
                color: #4e23d9;
              }
              .confirmStatusCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 50%;
                gap: 10px;
                img {
                  height: 10px;
                  width: 10px;
                }
                p {
                  margin: 0px;
                  font-size: 13px;
                  font-weight: 500;
                  text-decoration: underline;
                  color: green;
                }
              }
            }
          }
          .passwordsInputsCont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            .textInputsCont {
              width: 50%;
              display: flex;
              flex-direction: column;
              position: relative;
              label {
                font-size: 15px;
                margin: 0px;
                font-weight: 500;
                padding-bottom: 4px;
                padding-top: 5px;
              }
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 90%;
              }
            }
          }
        }
        .profileImgFormatCont {
          margin-top: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          grid-gap: 10px;
          gap: 10px;
          width: 100%;
          .profileImgCont {
            height: 60px;
            width: 60px;
            border-radius: 5px;
            border: 1px solid #bdcbfb;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .profileImgDetails {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            .profileImgHeader {
              font-size: 15px;
              font-weight: 500;
              color: black;
              padding-bottom: 2px;
            }
            li {
              font-size: 13px;
              font-weight: 500;
              color: #707070;
              margin-bottom: -2px;
            }
          }
          .removeImgIcon {
            position: relative;
            right: 290px;
            bottom: 25px;
            height: 18px;
          }
        }
      }
    }
    .userBasicInfoCont::-webkit-scrollbar {
      display: none;
    }

    .facilitiesContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      height: 50vh;
      .facilitiesAddBtnCont {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .facilitiesAddBtn {
          background-color: #0f43f9;
          color: #ffffff;
          border: none;
          border-radius: 3px;
          padding: 3px 10px 3px 10px;
          font-size: 15px;
          font-weight: 300;
          img {
            height: 12px;
            width: 12px;
            margin-right: 8px;
          }
        }
      }
      .facilityAndRoleContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .facilityAndRoleHeaders {
          display: flex;
          flex-direction: row;
          p {
            font-size: 15px;
            margin: 0px;
            font-weight: 500;
            width: 43%;
            padding-bottom: 5px;
          }
        }
        .facilityAndRoleSelectorsCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 20px;
          align-items: center;
          .facilityAndRoleSelector {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 6px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 44%;
          }
          img {
            height: 20px;
            width: 15px;
          }
        }
      }
    }

    .facilityGroupsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      height: 50vh;
      .facilityGroupsAddBtnCont {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .facilityGroupsAddBtn {
          background-color: #0f43f9;
          color: #ffffff;
          border: none;
          border-radius: 3px;
          padding: 3px 10px 3px 10px;
          font-size: 15px;
          font-weight: 300;
          img {
            height: 12px;
            width: 12px;
            margin-right: 8px;
          }
        }
      }
      .facilityGroupsAndRoleContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .facilityGroupsAndRoleHeaders {
          display: flex;
          flex-direction: row;
          p {
            font-size: 15px;
            margin: 0px;
            font-weight: 500;
            width: 43%;
            padding-bottom: 5px;
          }
        }
        .facilityGroupsAndRoleSelectorsCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 20px;
          align-items: center;
          .facilityGroupsAndRoleSelector {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 6px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 44%;
          }
          img {
            height: 20px;
            width: 15px;
          }
        }
      }
    }
  }
}
.editUserPopupFooter {
  padding: 25px 35px 30px 30px !important;
  .editUserFooterCont {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .editSwitchCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
      }
    }
    .editUserBtnsCont {
      display: flex;
      flex-direction: row;
      gap: 5px;
      justify-content: end;
      .editUserCancelBtn {
        border: 1px solid gray;
        background-color: #fcf2da;
        color: black;
        font-size: 13px;
        padding: 5px 10px 5px 10px;
        border-radius: 3px;
        font-weight: 500;
      }
      .editUserNextBtn {
        color: white;
        font-size: 13px;
        padding: 5px 15px 5px 15px;
        background-color: #0f43f9;
        border: 1px solid #5711e2;
        border-radius: 3px;
        border: none;
        font-weight: 300;
      }
      .editUserUpdateBtn {
        color: white;
        font-size: 13px;
        padding: 5px 15px 5px 15px;
        background-color: #0f43f9;
        border: 1px solid #5711e2;
        border-radius: 3px;
        border: none;
        font-weight: 300;
      }
    }
  }
}

.activeTab {
  // border-bottom: px solid #0075ff;
  // color: #0075ff !important;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: #0075ff;
  border-bottom: 1px solid #0075ff;
}

@media (max-width: 2600px) {
  .userCardCont {
    width: 16%;
  }
  .userActiveCardCont {
    width: 16%;
  }
}

@media (max-width: 2000px) {
  .userCardCont {
    width: 19%;
  }
  .userActiveCardCont {
    width: 19%;
  }
}

@media (max-width: 1440px) {
  .userCardCont {
    width: 24%;
  }
  .userActiveCardCont {
    width: 24%;
  }
}

@media (max-width: 1110px) {
  .userCardCont {
    width: 30%;
  }
  .userActiveCardCont {
    width: 30%;
  }
}

@media (max-width: 850px) {
  .userCardCont {
    width: 46%;
  }
  .userActiveCardCont {
    width: 46%;
  }
}

@media (max-width: 550px) {
  .userCardCont {
    width: 96%;
  }
  .userActiveCardCont {
    width: 96%;
  }
}

@media only screen and (max-width: 600px) {
  // Add to facility Popup
  .userAddFacilityPopupModal {
    div {
      width: 95vw;
    }
  }
  .userAddFacilityPopHeaderCont {
    padding: 15px 20px 10px 20px !important;
    .userAddFacilityPopHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        height: 13px;
        width: 13px;
        cursor: pointer;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .userAddFacilityPopBody {
    padding: 5px 20px 5px 20px !important;
    .userAddFacilityPopBodyCont {
      .userAddFacilityPopBodyHeaderss {
        font-size: 12px !important;
        padding-bottom: 15px;
      }
      .userAddFacilityDetailsCont {
        .userAddFacilityDetails {
          .popFacilityTxt {
            font-size: 12px;
          }
          .popRoleTxt {
            font-size: 12px;
          }
        }
        .userAddFacilitySelectorsHeightCont {
          height: 8vh;
          .userAddFacilitySelectorsCont {
            .popFacilitySelect {
              padding: 3px;
              font-size: 10px;
              option {
                font-size: 10px;
              }
            }
            .popRoleSelect {
              padding: 3px;
              font-size: 10px;
              option {
                font-size: 10px;
              }
            }
            img {
              height: 15px;
              width: 12px;
            }
          }
        }
        .userAddFacilitySelectorsHeightCont::-webkit-scrollbar {
          display: none;
        }

        .userAddFacilityDetailsBtnCont {
          .userAddFacilityDetailsBtn {
            padding: 3px 10px 3px 10px;
            font-size: 10px;
            img {
              height: 9px;
              width: 10px;
            }
          }
        }
      }
    }
  }
  .userAddFacilityPopFooterCont {
    padding: 0px 20px 15px 0px !important;
    .userAddFacilityCancelBtn {
      font-size: 12px;
      padding: 3px 10px 3px 10px;
    }
    .userAddFacilityMappingBtn {
      font-size: 12px;
      padding: 3px 10px 3px 10px;
    }
  }

  // Add to Groups Popup
  .userAddGroupsPopupModal {
    div {
      width: 95vw;
    }
  }
  .userAddGroupsPopHeaderCont {
    padding: 15px 20px 10px 20px !important;
    .userAddGroupsPopHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        height: 13px;
        width: 13px;
        cursor: pointer;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .userAddGroupsPopBody {
    padding: 5px 20px 5px 20px !important;
    .userAddGroupsPopBodyCont {
      .userAddGroupsPopBodyHeader {
        font-size: 12px !important;
        padding-bottom: 15px;
      }
      .userAddGroupsDetailsCont {
        .userAddGroupsDetails {
          .popFacilityGroupsTxt {
            font-size: 12px;
          }
          .popGroupsRoleTxt {
            font-size: 12px;
          }
        }
        .userAddFroupsSelectorsHeightCont {
          height: 8vh;
          .userAddGroupsSelectorsCont {
            .popGroupsSelect {
              padding: 3px;
              font-size: 10px;
              option {
                font-size: 10px;
              }
            }
            .popGroupsRoleSelect {
              padding: 3px;
              font-size: 10px;
              option {
                font-size: 10px;
              }
            }
            img {
              height: 15px;
              width: 12px;
            }
          }
        }
        .userAddFroupsSelectorsHeightCont::-webkit-scrollbar {
          display: none;
        }

        .userAddGroupsDetailsBtnCont {
          .userAddGroupsDetailsBtn {
            padding: 3px 10px 3px 10px;
            font-size: 10px;
            img {
              height: 9px;
              width: 10px;
            }
          }
        }
      }
    }
  }
  .userAddGroupsPopFooterCont {
    padding: 0px 20px 15px 0px !important;
    .userAddGroupsCancelBtn {
      font-size: 12px;
      padding: 3px 10px 3px 10px;
    }
    .userAddGroupsMappingBtn {
      font-size: 12px;
      padding: 3px 10px 3px 10px;
    }
  }

  // View User Popup
  .viewUserHeaderPopCont {
    padding: 15px 10px 5px 10px !important;
    margin: 0px 10px 0px 10px !important;
    .viewUserHeaderContainer {
      gap: 10px;
      .viewUserProfileCont {
        .profileImgCont {
          width: 40px;
          height: 40px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .profileDetails {
          .userNameTxt {
            font-size: 11px;
          }
          .userMailTxt {
            font-size: 10px;
          }
          .userNumberTxt {
            font-size: 9px;
          }
        }
      }
      .userRoleContainer {
        .userRoleHeader {
          font-size: 9px;
        }
        .userRoleName {
          font-size: 10px;
        }
      }
      .userReportContainer {
        .reportsHeader {
          font-size: 9px;
        }
        .reporterName {
          font-size: 10px;
        }
        .reporterNumber {
          font-size: 11px;
        }
        .reporterRol {
          font-size: 11px;
        }
      }
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      .viewUserPopCloseIcon {
        height: 13px;
        width: 13px;
        cursor: pointer;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .viewUsersPopBoday {
    padding: 10px 20px 10px 20px !important;
    .viewUserBodyContainer {
      .viewUserAddressCont {
        .addressHeader {
          font-size: 13px;
        }
        .addressInfoTxt {
          font-size: 13px;
        }
      }
      .viewUserDetailsCont {
        .facilityAndRoleDetails {
          .facilityAndRoleHeader {
            font-size: 13px;
          }
          li {
            font-size: 12px;
          }
        }
        .facilityAndRoleGroupsCont {
          .facilityAndRoleGroupsHeader {
            font-size: 13px;
          }
          li {
            font-size: 12px;
          }
        }
      }
    }
  }
  .viewUsersPopFooter {
    padding: 5px 20px 10px 20px !important;
    .viewUserFooterContainer {
      .enabledStatusTxt {
        margin: 0px;
        font-size: 10px;
        background-color: #aadeac;
        text-transform: uppercase;
        font-weight: 600;
        padding: 2px 5px 2px 5px;
        border-radius: 10px;
      }
      .viewUserStatusCont {
        .disableStatusTxt {
          margin: 0px;
          font-size: 10px;
          background-color: #ff967e;
          text-transform: uppercase;
          font-weight: 600;
          padding: 2px 5px 2px 5px;
          border-radius: 10px;
        }
        .statusAttemptCont {
          border: 1px solid #ff967e;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 5px 2px 5px;
          img {
            height: 12px;
            width: 12px;
          }
          .attemptTxt {
            margin: 0px;
            font-size: 10px;
            color: #ff967e;
          }
        }
      }
      .viewUserBtnsCont {
        .editBtn {
          font-size: 12px;
          padding: 3px 10px 3px 10px;
          img {
            height: 12px;
            width: 12px;
            margin-right: 5px;
          }
        }
        .deleteBtn {
          font-size: 12px;
          padding: 3px 10px 3px 10px;
          img {
            height: 14px;
            width: 14px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
