.background {
  position: fixed !important;
  left: 0px;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 1, 0.6);
  z-index: 99900;
}
// .modal-wrapper {
//   background: #3c88f7 0% 0% no-repeat padding-box;
//   box-shadow: 1px 1px 10px #00000029;
//   border: 1px solid #d7dbec;
//   border-radius: 6px;
//   margin: 0 auto 0;
//   transition: all 0.8s;
//   width: 40vw;
//   position: absolute;
//   z-index: 999999 !important;
//   display: flex !important;
//   flex-direction: column;
//   justify-content: space-between;
//   opacity: 1;
//   top: 8%;
//   left: 33%;
//   padding: 15px;
//   background-color: white;
//   .modal-header-style {
//     display: flex;
//     justify-content: space-around;
//     height: 12vh;
//     width: 100%;
//     background-color: #eff2ff;
//     padding: 6px;
//     align-items: center;

//     justify-content: inherit;

//     .details {
  //       display: flex;
  //       align-items: start;
  //       margin-top: 10px;

//       img {
  //         margin-left: 10px;
  //         margin-right: 10px;
  //         margin-top: 3px;
  //       }

//       h3 {
//         font-size: 20px;
//         font-weight: 600;
//       }
//     }

//     .close-modal-btn {
//       color: rgb(0, 0, 0);
//       cursor: pointer;
//       float: right;
//       font-size: 30px;
//       margin: 0;
//       margin-right: 2%;
//     }

//     .close-modal-btn:hover {
//       color: rgb(167, 0, 0);
//     }
//   }

//   .modal-bodyD {
//     padding: 10px 0px 0px 14px;
//     text-align: start;
//     display: flex !important;
//     flex-direction: row;
//     height: 20vh !important;
//     align-items: flex-start !important;

//     p {
  //       margin-top: 0;
  //       margin-bottom: 0rem;
  //     }

//     .leftCnt {
//       .inputBox {
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         padding: 13px;

//         .inputBox-label {
  //           input[type="radio"i] {
  //             margin-right: 10px;
  //             accent-color: #3c88f7;
  //           }

//           input[type="radio"]:after {
//             background-color: #426bfa;
//           }
//         }
//       }
//     }

//     .rightCnt {
//       padding: 3%;
//       margin-left: 15%;
//       .inputBox {
//         .typeDownload {
//           label {
//             margin-right: 20px;
//             margin-top: 11px;
//           }
//         }
//       }
//     }
//   }

//   .inputTextSpace {
//     padding: 10px;
//     margin-left: 16px;

//     .inputBox {
  //       input {
  //         width: 90%;
  //       }
  //     }
  //   }

//   .modal-footer {
//     background: #ffffff 0% 0% no-repeat padding-box;
//     height: 54px;
//     padding: 0px;
//     position: sticky;
//     display: flex !important;
//     border-top: 0px solid #dee2e6;

//     padding-right: 32px;

//     button {
  //       img {
  //         color: white;
  //         margin-right: 13px;
  //         height: 14px;
  //       }
  //     }

//     .btn-Action {
//       width: 9rem;
//       border-radius: 2px !important;
//       padding: 0px;
//       background-color: #674aff !important;
//       color: #5711e2;
//       background-color: none !important;
//       color: white !important;
//       border: 1px solid #5711e2;
//       content: "▼" !important;
//     }

//     .btn-Submit {
//       background-color: #fcf2da;
//       color: #000000;
//       border: 1px solid #70707052;
//     }
//   }

//   .btn-cancel {
//     background-color: #b71c1c;
//     float: left;
//   }

//   .btn-continue {
//     background-color: #1b5e20;
//     float: right;
//   }
// }
body.modal-open .modal-wrapper {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.faultDetailsPopCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .faulDetailsPopHeaderCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid yellow;
    .faulDetailsPopHeaderTxtCont {
      width: 60%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      .faulDetailsPopHeaderTxt {
        font-size: 15px;
        font-weight: 600;
        color: #d1d1c2;
      }
      .faulDetailsPopHeaderPara {
        background-color: #f96b60e5;
        color: #ffffff;
        font-size: 13px;
        font-weight: 600;
        padding: 2px 15px 2px 15px;
        border-radius: 5px;
      }
    }
    .faultPopupCloseBtn {
      border: none;
      background-color: #ffffff;
      .popupClose {
        height: 20px;
      }
    }
  }

  .popupRadioBtnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
    .popupRadioBtns {
      // border: 1px solid green;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      .radiobtnsLable {
        font-size: 12px;
        color: #000000;
        font-weight: 500;
      }
    }
  }

  .popupchartContainer {
    height: 270px;
    position: relative;
    top: 15px;
  }

  .popupChartDetailsContsiner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .popupChartReadingsCont {
      border: 1px solid #e7e7e7;
      border-radius: 10px;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 20px;
      padding-top: 8px;
      .popupChartReadings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .readingsContainer {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          .readingsTxt {
            font-size: 12px;
            color: #707070;
            font-weight: 400;
          }
          .readingsCount {
            font-size: 12px;
            color: #001f64;
            font-weight: 700;
          }
        }
      }
    }
    .popupChartFrequencyCont {
      width: 40%;
      display: flex;
      flex-direction: column;

      justify-content: center;
      .frequencyCont {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .frequencyTxt {
          font-size: 16px;
          font-weight: 700;
          color: #000000;
        }
        .frequencyStatus {
          font-size: 12px;
          font-weight: 500;
          background-color: #dbc589;
          padding: 2px 10px 0px 10px;
          border-radius: 5px;
          border: 1px solid #add43c;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .modal-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 10px #00000029;
    border: 1px solid #d7dbec;
    border-radius: 6px;
    margin: 0 auto 0;
    transition: all 0.8s;
    width: 78vw;
    position: absolute;
    z-index: 9999;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    top: 10%;
    left: 4%;
    right: 14px;
    position: fixed;
  }
  .modal-wrapper .modal-footer .btn-Action {
    width: 9rem;
    border-radius: 2px !important;
    padding: 0px;
    background-color: #674aff !important;
    color: #5711e2;
    background-color: none !important;
    color: white !important;
    border: 1px solid #5711e2;
    content: "▼" !important;
    margin-right: 62px;
    height: 36px;
  }

  .modal-wrapper .modal-header-style {
    display: flex;
    justify-content: space-between;
    height: 10vh;
    align-items: center;
    background-color: #eff2ff;
    padding: 5px;
  }

  .modal-wrapper .modal-footer .btn-Submit {
    background-color: #fcf2da;
    color: #000000;
    border: 1px solid #70707052;
    margin-right: 56px;
    width: 145px;
    padding: 2px;
    margin-right: 62px;
  }

  .modal-wrapper .modal-footer {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 54px;
    padding: 0px;
    position: sticky;
    display: flex !important;
    border-top: 0px solid #dee2e6;
    padding-right: 32px;
    height: 93px;
  }

  .modal-bodyD {
    text-align: start;
    height: auto;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
  }

  .faultDetailsPopCont {
    .faulDetailsPopHeaderCont {
      .faulDetailsPopHeaderTxtCont {
        width: 80%;
        .faulDetailsPopHeaderTxt {
          font-size: 12px;
        }
        .faulDetailsPopHeaderPara {
          font-size: 10px;
          padding: 0px 10px 0px 10px;
        }
      }
      .faultPopupCloseBtn {
        .popupClose {
          height: 20px;
          padding-bottom: 4px;
        }
      }
    }
  }
  .faultDetailsPopCont {
    .popupRadioBtnsContainer {
      .popupRadioBtns {
        width: 70%;
        .radiobtnsLable {
          font-size: 10px;
        }
      }
    }
  }

  .faultDetailsPopCont {
    .popupChartDetailsContsiner {
      .popupChartReadingsCont {
        .popupChartReadings {
          .readingsContainer {
            .readingsTxt {
              font-size: 8px;
            }
            .readingsCount {
              font-size: 9px;
            }
          }
        }
      }
    }
  }

  .faultDetailsPopCont
    .popupChartDetailsContsiner
    .popupChartReadingsCont
    .popupChartReadings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    right: 25px;
  }
  .faultDetailsPopCont
    .popupChartDetailsContsiner
    .popupChartFrequencyCont
    .frequencyCont
    .frequencyTxt {
    font-size: 12px;
  }
  .faultDetailsPopCont
    .popupChartDetailsContsiner
    .popupChartFrequencyCont
    .frequencyCont
    .frequencyStatus {
    font-size: 8px;
    padding: 0px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .modal-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 10px #00000029;
    border: 1px solid #d7dbec;
    border-radius: 6px;
    margin: 0 auto 0;
    transition: all 0.8s;
    width: 63vw;
    position: absolute;
    z-index: 9999;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    top: 20%;
    left: 20%;
  }

  .modal-wrapper .modal-header-style {
    display: flex;
    justify-content: space-between;
    height: 7vh;
    background-color: #eff2ff;
    padding: 6px;
  }
}
