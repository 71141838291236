.AHU-status {
  align-items: center;
  display: inline-flex;
  gap: .75rem;
  position: relative;
}

.AHU-status .group {
  position: relative;
}

.AHU-status .statusText {
  color: #000000;
  font-family: "Inter-Medium", Helvetica, serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.AHU-status.tripped {
  justify-content: center;
}

.AHU-status.true .group {
  height: 18.7px;
  margin-bottom: -0.85px;
  margin-left: -0.85px;
  margin-top: -0.85px;
  width: 20.16px;
}

.AHU-status.false .group {
  height: 19.09px;
  margin-bottom: -0.92px;
  margin-left: -0.92px;
  margin-top: -0.92px;
  width: 47.84px;
}

.AHU-status.tripped .group {
  height: 22px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  width: 29.5px;
}

.AHU-status.true .statusText {
  margin-top: -0.5px;
}

.AHU-status.false .statusText {
  margin-top: -0.37px;
}

