.usersFilterBtnCont {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-left: 15px;
  .usersBtnsConteiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 40px;
    gap: 5px;
    .userExportBtn {
      background-color: #fcf2da;
      border: 0.5px solid #cfc8b8;
      font-size: 13px;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    }
    .usersFilterBtn {
      background-color: #e1efff;
      border: 0.5px solid #0f43f9;
      font-size: 13px;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      img {
        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
    }
    .addUserBtn {
      border: none;
      background-color: #0f43f9;
      color: #ffffff;
      font-size: 13px;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      img {
        height: 14px;
        width: 14px;
        margin-right: 5px;
      }
    }
  }
}
.usersCardsCont {
  height: 70%;
}

// Add User Popup

.createUserPopupModal {
  z-index: 1050 !important;
  margin-left: -50px !important;
  div {
    width: 46vw;
    border: none;
    border-radius: 15px;
  }
}
.createUserPopHeader {
  padding: 35px 35px 10px 35px !important;
  .createUserHeader {
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.createUserPopBody {
  padding: 10px 35px 0px 35px !important;
  .createUserBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .createUserHeaderNav {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 25px;
      border-bottom: 1px solid #d7dbec;
      width: 100%;
      border-radius: 0px !important;
      .createUserNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #373f6d;
      }
      .activecreateUserNavTxt {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #0075ff;
        border-bottom: 1px solid #0075ff;
      }
    }
    .userBasicInfoCont {
      height: 50vh;
      width: 100%;
      overflow-y: scroll;
      border-radius: 0px !important;
      padding-top: 10px;
      .basicInfoCont {
        height: 70vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        .basicInfoInputsCont {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          .textInputsCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            input {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 5px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              width: 90%;
            }
            .createUserIcon {
              height: 15px;
              width: 20px;
              cursor: pointer;
              position: absolute;
              bottom: 8px;
              right: 33px;
            }
            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .emailSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              top: 85px;
              z-index: 1000;
              right: 0px;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                .purposeCloseCont {
                  height: 20px;
                  width: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 10px;
                    width: 10px;
                    cursor: pointer;
                  }
                }
                .purposeCloseCont:hover {
                  background-color: #d3d3d3;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .selectorInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            select {
              border: 1px solid #bdcbfb;
              outline: none;
              padding: 6px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              // color: #cccccc;
              width: 90%;
              option {
                font-size: 13px;
                font-weight: 500;
                color: #000;
              }
            }
          }
          .phoneNumberInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .selectAndInputCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              .imgAndSelectCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #bdcbfb;
                outline: none;
                width: 25%;
                padding: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                img {
                  height: 10px;
                  width: 15px;
                }
                select {
                  border: none;
                  outline: none;
                  font-size: 13px;
                  font-weight: 500;
                  border-radius: 0px !important;
                  option {
                    font-size: 13px;
                    font-weight: 500;
                    color: #000;
                  }
                }
              }
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-left: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
            .setPurpose {
              margin: 0px;
              font-size: 14px;
              font-weight: 500;
              color: #4e23d9;
              text-decoration: underline;
              padding-top: 3px;
              cursor: pointer;
              // width: 50%;
            }
            .phoneNumberSetPurpose {
              width: 18vw;
              border: 1px solid #bdcbfb;
              background-color: white;
              position: absolute;
              display: flex;
              flex-direction: column;
              border-radius: 5px !important;
              padding: 10px 10px 10px 10px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              left: 90px;
              top: 0px;
              z-index: 1000;
              .setPurposeHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                .purposeCloseCont {
                  height: 20px;
                  width: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 10px;
                    width: 10px;
                    cursor: pointer;
                  }
                }
                .purposeCloseCont:hover {
                  background-color: #d3d3d3;
                  cursor: pointer;
                }
              }
              .setPurposeDataCont {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .setPurposeData {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  grid-gap: 5px;
                  grid-gap: 5px;
                  gap: 5px;
                  width: 100%;
                  input {
                    height: 11px;
                    width: 11px;
                    cursor: pointer;
                    border: 1px solid #275efe !important;
                  }
                  label {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .sizeFacilityInputCont {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
              font-size: 15px;
              margin: 0px;
              font-weight: 500;
              padding-bottom: 4px;
              padding-top: 5px;
            }
            .inputAndSelectCont {
              display: flex;
              flex-direction: row;
              width: 100%;
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 65%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: none;
              }
              select {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 6px;
                font-size: 13px;
                font-weight: 500;
                width: 25%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                option {
                  font-size: 13px;
                  font-weight: 500;
                  color: #000;
                }
              }
            }
          }
        }
        .credentialsInputsCont {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          .credentialsHeder {
            margin: 0px;
            font-size: 15px;
            font-weight: 600;
            color: #000;
            padding-bottom: 2px;
          }
          .credentialsHederBorder {
            border: 1px solid #000;
            width: 62px;
          }
          .userNameInputCont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 5px;
            .textInputsCont {
              width: 50%;
              display: flex;
              flex-direction: column;
              position: relative;
              label {
                font-size: 15px;
                margin: 0px;
                font-weight: 500;
                padding-bottom: 4px;
                padding-top: 5px;
              }
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 90%;
              }
            }
          }
          .passwordsInputsCont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            .textInputsCont {
              width: 50%;
              display: flex;
              flex-direction: column;
              position: relative;
              label {
                font-size: 15px;
                margin: 0px;
                font-weight: 500;
                padding-bottom: 4px;
                padding-top: 5px;
              }
              input {
                border: 1px solid #bdcbfb;
                outline: none;
                padding: 5px;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 500;
                width: 90%;
              }
            }
          }
        }
        .profileImgFormatCont {
          margin-top: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          .profileImgCont {
            height: 60px;
            width: 60px;
            border-radius: 5px;
            border: 1px solid #bdcbfb;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .profileImgDetails {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            .profileImgHeader {
              font-size: 15px;
              font-weight: 500;
              color: black;
              padding-bottom: 2px;
            }
            li {
              font-size: 13px;
              font-weight: 500;
              color: #707070;
              margin-bottom: -2px;
            }
          }
        }
      }
    }
    .userBasicInfoCont::-webkit-scrollbar {
      display: none;
    }

    .facilitiesContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      height: 50vh;
      .facilitiesAddBtnCont {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .facilitiesAddBtn {
          background-color: #0f43f9;
          color: #ffffff;
          border: none;
          border-radius: 3px;
          padding: 3px 10px 3px 10px;
          font-size: 15px;
          font-weight: 300;
          img {
            height: 12px;
            width: 12px;
            margin-right: 8px;
          }
        }
      }
      .facilityAndRoleContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .facilityAndRoleHeaders {
          display: flex;
          flex-direction: row;
          p {
            font-size: 15px;
            margin: 0px;
            font-weight: 500;
            width: 43%;
            padding-bottom: 5px;
          }
        }
        .facilityAndRoleSelectorsCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 20px;
          align-items: center;
          .facilityAndRoleSelector {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 6px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 44%;
          }
          img {
            height: 20px;
            width: 15px;
          }
        }
      }
    }

    .facilityGroupsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 15px;
      height: 50vh;
      .facilityGroupsAddBtnCont {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .facilityGroupsAddBtn {
          background-color: #0f43f9;
          color: #ffffff;
          border: none;
          border-radius: 3px;
          padding: 3px 10px 3px 10px;
          font-size: 15px;
          font-weight: 300;
          img {
            height: 12px;
            width: 12px;
            margin-right: 8px;
          }
        }
      }
      .facilityGroupsAndRoleContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .facilityGroupsAndRoleHeaders {
          display: flex;
          flex-direction: row;
          p {
            font-size: 15px;
            margin: 0px;
            font-weight: 500;
            width: 43%;
            padding-bottom: 5px;
          }
        }
        .facilityGroupsAndRoleSelectorsCont {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 20px;
          align-items: center;
          .facilityGroupsAndRoleSelector {
            border: 1px solid #bdcbfb;
            outline: none;
            padding: 6px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 500;
            width: 44%;
          }
          img {
            height: 20px;
            width: 15px;
          }
        }
      }
    }
  }
}
.createUserPopupFooter {
  padding: 25px 35px 30px 30px !important;
  .userCancelBtn {
    border: 1px solid gray;
    background-color: #fcf2da;
    color: black;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  .userNextBtn {
    color: white;
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    background-color: #0f43f9;
    border: 1px solid #5711e2;
    border-radius: 3px;
    border: none;
    font-weight: 300;
  }
  .userCreateBtn {
    color: white;
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    background-color: #0f43f9;
    border: 1px solid #5711e2;
    border-radius: 3px;
    border: none;
    font-weight: 300;
  }
}

.activeTab {
  border-bottom: 2px solid #0075ff;
  color: #0075ff !important;
}

// Edit Address Popup
.userAddAddressPopup {
  z-index: 1060 !important;
  div {
    border-radius: 15px;
    border: none;
  }
}
.userAddAddressHeaderCont {
  padding: 35px 35px 20px 35px !important;
  .userAddAddressTxt {
    margin: 0px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.userAddAddressBody {
  padding: 0px 35px 0px 35px !important;
  .userAddAddressBodyCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .userAddAddressInputsCont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 65%;
      .addressTextInputsCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 5px;
          padding-top: 5px;
        }
        input {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 5px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 90%;
        }
      }
      .addressSelectorCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        label {
          font-size: 15px;
          margin: 0px;
          font-weight: 500;
          padding-bottom: 5px;
          padding-top: 5px;
        }
        select {
          border: 1px solid #bdcbfb;
          outline: none;
          padding: 6px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          width: 90%;
        }
      }
    }
    .userAddAddressPurposeCont {
      flex-direction: column;
      display: flex;
      width: 35%;
      justify-content: center;
      padding-left: 25px;
      gap: 5px;
      .addressPurposeCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .purposeCheckbox {
          height: 12px;
          width: 12px;
          cursor: pointer;
          border: 2px solid #275efe !important;
        }
        label {
          font-size: 14px;
          font-weight: 500;
          margin: 0px;
          cursor: pointer;
        }
      }
    }
  }
}
.userAddAddressFooter {
  padding: 5px 35px 30px 35px !important;
  .cancelBtn {
    border: 1px solid gray;
    background-color: #fcf2da;
    color: black;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  .addAddressBtn {
    color: white;
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    background-color: #0f43f9;
    border: 1px solid #5711e2;
    border-radius: 3px;
    border: none;
    font-weight: 300;
  }
}

@media only screen and (max-width: 600px) {
  .usersFilterBtnCont {
    height: 6%;
    .usersBtnsConteiner {
      margin-right: 10px;
      .userExportBtn {
        padding: 5px 5px 5px 5px;
        font-size: 8px;
        img {
          height: 10px;
          width: 10px;
          margin-right: 2px;
        }
      }
      .usersFilterBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 8px;
          width: 8px;
          margin-right: 2px;
        }
      }
      .addUserBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 8px;
          width: 8px;
          margin-right: 2px;
        }
      }
    }
  }
}
