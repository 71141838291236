.filterDataContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .filterDataHeading {
    font-weight: 500;
    text-align: center;
    font-size: 13px;
    color: #000000;
  }

  .filterImgHoverCont {
    position: relative;
    img {
      height: 12px;
      width: 12px;
      cursor: pointer;
    }
    .filterCardHoverCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px;
      background-color: white;
      border-radius: 5px;
      position: absolute;
      border: 1px solid #bdcbfb;
      top: 15px;
      right: 0px;
      width: 170px;
      transition: all 0.2s ease-in;
      display: none;
    
      .filterInput {
        font-size: 12px;
        width: 100%;
        text-align: left;
        margin-top: 4px;

        .filterDataText {
          margin-left: 4px;
        }
      }
    }
  }
  .filterImgHoverCont:hover {
    .filterCardHoverCont {
      display: block;
    }
  }
}
