:root {
  --size: 90px;
  --bord: 10px;
}

.x-60 {
  --value: 80%;
}

.pl-5 {
  padding-left: 5px !important;
}

.donut {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  position: relative;
  transform: rotate(160deg);
  background: conic-gradient(#1d76bb var(--value), #98ddff var(--value));
  -webkit-mask: radial-gradient(
    farthest-side,
    transparent calc(100% - var(--bord)),
    #fff calc(100% - var(--bord) + 1px)
  );
  mask: radial-gradient(
    farthest-side,
    transparent calc(100% - var(--bord)),
    #fff calc(100% - var(--bord) + 1px)
  );
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 0px 0px;
    position: relative;
    min-height: 53vh;
    .chart-container {
      height: 100px;
      width: 85%;
    }
    .echarts-for-react {
      min-height: 220px !important;
      width: 100%;
      position: absolute;
      top: 25px;
      /* left: -25px; */
        right: 113px;
    }
  }
  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 15px;
  }
  &-text {
    position: absolute;
    text-align: center;
    h3 {
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
    }
    h5 {
      font-size: 9px;
      font-weight: 600;
      margin-bottom: 0px;
    }
  }
  &-describe {
    display: flex;
    align-items: flex-end;
  }
  &-describeList {
    &:first-child {
      margin-right: 20px;
    }
    div {
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      h2 {
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 26px !important;
        padding-left: 20px;
        padding-right: 10px;
        position: relative;
        margin-bottom: 0px;
        &::before {
          content: "";
          position: absolute;
          left: 0px;
          top: 7px;
          border-radius: 1px;
          width: 12px;
          height: 12px;
        }
      }
      &:first-child {
        h2 {
          &::before {
            background-color: #3c88f7;
          }
        }
      }
      &:nth-child(2n) {
        h2 {
          &::before {
            background-color: #ff2626;
            }
            }
            }
            
            &:nth-child(3n) {
              h2 {
                &::before {
                  background-color: #31b26f;
                }
              }
            }
            
            &:nth-child(4n) {
              h2 {
                &::before {
                  background-color: #0c2506;
                }
              }
            }
            
            &:nth-child(5n) {
              h2 {
                &::before {
                  background-color: #9a17be;
                }
              }
            }
            
            &:nth-child(6n) {
              h2 {
                &::before {
                  background-color: #036563;
          }
        }
      }
      &:last-child {
        h2 {
          &::before {
            background-color: #f29052;
          }
        }
      }
      h4 {
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-bottom: 0px;
      }
    }
    p {
      margin-bottom: 7px;
      font-weight: 500;
      line-height: 1.375rem;
      display: flex;
      align-items: center;
      font-size: 12px;
      svg {
        color: rgb(255, 255, 255);
        border-radius: 7px;
        padding: 3px 4px;
        font-size: 8px;
        margin-right: 5px;
      }
      &.positive {
        svg {
          background-color: #4caf50;
        }
      }
      &.negative {
        svg {
          background-color: #f44336;
        }
      }
      span {
        padding-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .donut {
    &-container {
      flex-direction: column;
      padding-bottom: 10px;
      margin-top: 5vh;
      .chart-container {
        width: 100%;
      }
      .echarts-for-react {
        width: 100%;
        top: -30px;
        left: -10px;
      }
    }
    &-wrapper {
      margin-bottom: 10px;
    }
    &-describeList {
      &:first-child {
        margin-right: -5px;
        position: relative;
        right: 24px;
      }
      p {
        span {
          display: none;
        }
      }
    }
  }
}

.donut-describeList div h4 {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 0px;
}

