.facilityCard {
  border: 1px solid #bdcbfb;
  border-radius: 6px;
  &.Excellent {
    .facilityCard-header {
      h6 {
        background-color: rgba($color: #3dac85, $alpha: 0.13);
        color: #3dac85;
      }
    }
  }
  &.Good {
    .facilityCard-header {
      h6 {
        background-color: rgba($color: #f39c12, $alpha: 0.13);
        color: #f39c12;
      }
    }
  }
  &.Poor {
    .facilityCard-header {
      h6 {
        background-color: rgba($color: #ff4c30, $alpha: 0.13);
        color: #ff4c30;
      }
    }
  }
  &-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    h5,
    h6 {
      color: #9f9f9f;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0px;
    }
    h6 {
      border-radius: 10px;
      padding: 3px 10px;
    }
    p {
      color: #038aff;
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 0px;
    }
  }
  &-body {
    display: flex;
    border-top: 1px solid #bdcbfb;
    border-bottom: 1px solid #bdcbfb;
    padding-right: 10px;
  }
  &-chart {
    width: 40% !important;
    .gaugechart {
      height: 140px !important;
      .echarts-for-react {
        height: 140px !important;
      }
    }
    .gaugechart-text {
      bottom: 20px;
      h2 {
        font-size: 16px !important;
        line-height: 32px !important;
      }
      p {
        font-size: 10px !important;
      }
    }
  }
  .snackItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 117px;
    width: 59%;
    margin-top: 10px !important;
    margin: 0px;
    background-color: white;
    &-details {
      width: 50%;
      flex: 0 0 50%;
      display: flex;
      height: 40px;
      align-items: center;
      img {
        width: 25px;
        margin-left: 5px;
      }
      p {
        font-size: 14px;
        margin-bottom: 0px;
      }
      .msg {
        padding-left: 5px;
        p {
          font-size: 12px !important;
        }
      }
    }
  }
  &-footer {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    p {
      margin-bottom: 0px;
      padding: 8px 10px;
      border-bottom: 1px solid #d7dbec;
      font-size: 12px;
      color: #131523;
    }
  }
}

@media only screen and (max-width: 600px) {
  .faciltyMobile {
    width: 100% !important;
  }
  .facilityCard-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .facilityCard .snackItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 10px !important;
    margin: 0px;
    margin-bottom: 10px;
    background-color: white;
  }
  .facilityCard-footer p {
    font-size: 11px;
  }
  .facilityCard-chart {
    width: 100% !important;
  }
  .facilityCard .snackItem-details {
    padding-left: 10px;
  }
}
