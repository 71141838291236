.paginationMainCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    margin: 0px 0px 16px 0px;

    .paginationText {
        font-size: 13px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        margin: 0;
    }
}