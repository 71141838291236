.eventDataList {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 8px;

    .eventDataCard {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 20%;
        justify-content: space-between;
        border-bottom: 1px solid blue;
        padding: 0px 0px 10px 0px;
        cursor: pointer;

        .eventDetailsDiv {
            display: flex;
            flex-direction: column;

            .eventName {
                font-size: 14px;
                font-weight: 400;
                color: rgba(112, 112, 112, 1);
                margin: 0;
            }

            .eventValue {
                font-size: 25px;
                font-weight: 700;
                margin: 0;
                color: rgba(27, 27, 27, 1);
            }

            .eventCategory {
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(112, 112, 112, 1);
                border-radius: 4px;
                margin: 0;
                height: 22px;
            }
        }

        .horizontalLineDiv {
            width: 0.5px;
            background-color: rgb(159, 155, 155);
        }
    }
}