.gaugechart {
  height: 200px;
  position: relative;
  .echarts-for-react {
    height: 200px !important;
  }
  &-text {
    position: absolute;
    bottom: 40px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    h2 {
      margin-bottom: 0px;
      padding-bottom: 0px;
      line-height: 22px !important;
      font-size: 22px !important;
      font-weight: 700 !important;
    }
    p {
      font-size: 12px;
    }
  }
  &-bottom {
    position: relative;
    top: -50px;
    left: 0px;
    text-align: center;
  }
  &-rank {
    span {
      font-size: 14px;
      font-weight: 700;
      color: #161616;
      background-color: #86ff53;
      border-radius: 3px;
      padding: 5px 10px;
    }
    div {
      font-weight: 600;
      font-size: 12px;
      color: #000000;
      margin-top: 5px;
    }
  }
  &-desc {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    div {
      position: relative;
      padding-left: 15px;
      font-size: 12px;
      margin-right: 5px;
      &:before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: 4px;
        left: 0px;
      }
      &:first-child {
        &:before {
          background-color: #f44336;
        }
      }
      &:nth-child(2n) {
        &:before {
          background-color: #ffc107;
        }
      }
      &:last-child {
        &:before {
          background-color: #4caf50;
        }
      }
    }
  }
}
