.faultTicketsCardCont {
  border: 1px solid #bdcbfb;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  .faultTicketsIdAndTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bdcbfb;
    height: 26px;
    padding: 12px;
    background-color: #f0f3ff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .faultTicketsId {
      font-size: 12px;
      font-weight: 500;
      color: black;
      padding-top: 15px;
    }
    .faultTicketsTime {
      font-size: 12px;
      font-weight: 500;
      color: black;
      padding-top: 15px;
    }
  }
  .faultTicketsIncormationCont {
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    .faultTicketsAddressInfoCont {
      list-style-type: none;
      .faultTicketsAddressInfoTxt {
        font-size: 13px;
        font-weight: 600;
        color: black;
        .faultRepeat {
          font-size: 8px;
          color: #ff4c30;
          background-color: #fae1ea;
          font-weight: 400;
          padding: 2px;
          border-radius: 10px;
          padding-left: 5px;
          padding-right: 5px;
          margin-left: 10px;
          position: relative;
          bottom: 2px;
        }
      }
      .faultTicketsAddressInfo {
        font-size: 13px;
        font-weight: 500;
        color: black;
        .faultTicketsLocation {
          height: 15px;
          padding-bottom: 2px;
          padding-right: 3px;
        }
      }
    }
    .faultTicketsImportance {
      border: 1px solid #add43c;
      background-color: #f8c22d;
      list-style-type: none;
      padding: 4px;
      border-radius: 5px;
      .faultTicketsImportanceTxt {
        font-size: 10px;
        color: #ffffff;
        font-weight: 400;
      }
    }
  }
}
