// .searchbar{
//     width: 80%;
// }
// .searchbar .icon .search{
//     color: blue;
// }
// .searchbar .ui.icon.input>input{
//     border: 1px solid blue;
//      border-color: blue;
//     // width: 20%;
// }
// .searchbar .ui.input {
//     border-color: blue;
//     font-size: 1em;
//     width: 100%;
// }
// .searchbar .form-control{
//     border-color: blue;
// }
// .form-control:focus {
//     outline: 0 !important;
//     border-color: initial;
//     box-shadow: none;
// }
// .searchbar .hover-according .btn{
//     width: auto;
//     margin: 4px;
//     border-color: blue;
//     // box-shadow: 0 0 0px 1px #6128e7 inset, 0 0 0 0 #a333c8 inset;
//     background: transparent;

//     border-radius: 20px;
//     padding: 1px 20px;
// }
// .searchbar .hover-according .btn:hover{
//     background:#6128e7;
//     color: white;
// }
// .searchbar .hover-according .btn:focus{
//     background:#6128e7;
//     color: white;
// }
// .searchbar .hover-according{
//     display: none;
//     top: 37px;
//     position: absolute;
//     background-color: #f9f9f9;
//     min-width: 160px;
//     box-shadow: 0px 16px 16px 0px rgba(0,0,0,0.2);
//     z-index: 1;
//     height: auto;
//     width: 100%;
//     padding: 10px;
//     align-items: center;
//     padding: 0px;
//     border: 1px solid blue;
//     border-radius: 4px;
// }
// .searchbar:hover .hover-according {
//     display: block;
//     padding: 10px;
// }

// .hover-according p{
//     margin: 10px;

// }
.searchCont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 40px 0px 0px;
  .choice-select {
    border: 2px solid #ea5037;
    border-radius: 5px;
    border: 1px solid #bdcbfb;
    padding: 2px;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    background-color: white;
    .select-tag {
      border: none;
      outline: none;
      /* width: 35%; */
      font-size: 13px;
      background-color: #dee7fc;
      color: #000;
      text-align: start;
      border-radius: 50px;
      padding: 1px;
      margin-right: 9px;
    }
    .searchInput {
      font-family: "Inter Tight";
      font-size: 15px;
      border-left-width: 2px solid #000;
      padding: 5px 10px 5px 10px;
      outline: none;
      width: 55%;
      border: none;
    }
    .searchicon {
      height: 18px;
      width: 20px;
      margin-left: 7px;
    }
  }
}

.editManufacturerTxt {
  font-family: "Inter Tight";
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.editPopContainer {
  display: flex;
  flex-direction: column;
  height: 330px;
  padding: 15px;

  .inputsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    .inputText {
      font-family: "Inter Tight";
      font-size: 15px;
      font-weight: 500;
    }
    .inputCont {
      .modalInput {
        border: 1px solid #a7b8f4;
        width: 80%;
        padding: 5px;
        border-radius: 5px;
        outline: none;
        font-size: 14px;
        color: #000;
      }
      .editIcon {
        position: relative;
        background-color: transparent;
        border: none;
        right: 30px;
        bottom: 0;
      }
      .upArrowBtn {
        position: relative;
        background-color: transparent;
        border: none;
        right: 30px;
        bottom: 10px;
      }
      .downArrowBtn {
        position: relative;
        background-color: transparent;
        border: none;
        right: 56px;
        top: 8px;
      }
    }
  }
}

.cancleButton {
  font-family: "Inter Tight";
  background-color: #fcf2da;
  border: 1px solid #cfc8b8;
  color: black;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}
.createButton {
  font-family: "Inter Tight";
  background-color: #0075ff;
  border: 1px solid #5711e2;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.del-pop-up {
  margin-top: 11%;
  .pop-upheader {
    font-family: "Inter Tight";
    margin-top: 15px;
    font-size: 15px;
    font-weight: 700;
  }
  .cancel-btn {
    background-color: #fcf2da;
    color: black;
    font-weight: 500;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-family: "Inter Tight";
  }
  .delete-btn {
    font-family: "Inter Tight";
    background-color: #ea5037;
    color: white;
    font-weight: 500;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }

  div {
    border: none;
    padding-top: 0;
  }
  .body-del-popup {
    // border: none;
    width: 100%;
    padding-left: 3%;
    margin-bottom: -20px;
    margin-top: -10px;
    p {
      font-size: 13.5px;
    }
  }
}
.editAddressTxt {
  font-family: "Inter Tight";
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.editAddressPopModalCont {
  display: flex;
  flex-direction: row;
  height: 300px;
  .inputOneCont {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    padding: 10px;
    .popInputCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #000;
      .lableTxt {
        font-family: "Inter Tight";
        font-size: 15px;
        font-weight: 600;
      }
      .inputPopCont {
        .popInput {
          border: 1px solid #a7b8f4;
          padding: 5px;
          border-radius: 5px;
          outline: none;
        }
      }
    }
  }
  .inputTwoCont {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    padding: 10px;
    .popInputCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .lableTxt {
        font-size: 15px;
        font-weight: 600;
      }
      .inputPopCont {
        position: relative;
        .popInput {
          border: 1px solid #a7b8f4;
          padding: 5px;
          border-radius: 5px;
          outline: none;
        }
        .downBtn {
          position: absolute;
          border: none;
          background-color: transparent;
          right: 0;
          top: 5px;
        }
      }
      .selector {
        padding: 5px;
        width: 55%;
        border-radius: 5px;
        border-color: #a7b8f4;
        outline: none;
      }
    }
  }
}

.updateAddressBtn {
  font-family: "Inter Tight";
  background-color: #0075ff;
  border: 1px solid #5711e2;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.loader-man {
  margin-left: 48%;
  margin-top: 15%;
}

.read-or-hide {
  background-color: none;
  border: none;
  color: #5711e2;
}

.selectdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  align-content: center;
  border: none;
  background-color: transparent;
  /* width: 100px; */
  min-width: 6vw;
  p {
    margin: 0px;
    padding: 0px;
    color: #000;
    font-size: 12px;
  }
  .selectArrowicon {
    height: 10px;
    width: 10px;
  }
  .selectArrowiconActive {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    animation-duration: 5s;
    transition: all 0.3s ease;
    height: 10px;
    width: 10px;
  }
}
.options {
  width: 280px;
}
.option {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border: none;
  outline: none;
  width: 100%;
}
.option:hover {
  background-color: #0075ff;
  border-radius: 2px;
  color: #fff;
  .labeltext {
    color: #fff;
  }
}
.selectbox {
  background-color: #ffffff;
  padding: 1px;
  position: relative;
  /* top: 14%; */
  width: 100%;
  /* left: 26%; */
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: -38px;
  z-index: 99999;
}
.selectedIcon {
  height: 10px;
  width: 12px;
  margin-right: 15px;
  margin-left: 10px;
  border: none;
  outline: none;
}
.labeltext {
  font-size: 11px;
  color: #000;
  padding: 0;
  margin: 0;
}

.searchIconName {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .close {
    img {
      height: 20px;
      cursor: pointer;
    }
  }
  .searchBtt {
    width: 6vw;
    height: 4vh;
    background-color: #038aff;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    h3 {
      font-size: 0.7rem;
      margin: 0;
      // margin-left: 8px;
      margin-right: 0px;
      color: white;
    }
    img {
      height: 14px;
      width: 20px;
      margin-left: 7px;
      color: white;
    }
  }
}

//media for tab responsive
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .selectdiv {
    width: 78px;
  }
  .searchCont .choice-select .select-tag {
    margin-right: 4px;
  }
}
@media only screen and (max-width: 600px) {
  .searchCont {
    width: 63% !important;
    padding: 0px 0px 0px 0px !important;
  }
  .searchCont .choice-select .select-tag {
    border: none;
    outline: none;
    width: 9vh;
    font-size: 13px;
    background-color: #dee7fc;
    color: #000;
    text-align: start;
    border-radius: 50px;
    padding: 1px;
    margin-right: 4px;
    margin-left: 2px;
  }
  .selectdiv {
    justify-content: flex-start;
    width: 100px;
    min-width: 8vw;
    p {
      margin-right: 5px;
    }
  }
}

.assetSearchCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.56667rem 0.74167rem;
  width: 24.61111rem;
  min-width: 17.61111rem;
  border-radius: 5px;
  border: 1px solid rgb(0, 117, 255);
  background: #ffffff;
  z-index: 99999;
  grid-gap: 8px;
  gap: 8px;

  .left-element {
    margin-right: 5px;
    width: 100px;
  }

  .middle-element {
    flex: 2;
    width: 100%;
    text-decoration-color: #cccccc;
    caret-color: #000000;
  }

  input {
    outline: none;
    font-size: 0.66667rem;
    border: none;
  }

  .right-element-close {
    margin-right: 5px;
    width: 1rem;
    height: 1rem;
  }

  .icon-search {
    display: inline-block;
    position: fixed;
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    will-change: transform, opacity;
  }
  .icon-search::before {
    content: "";
    display: inline-block;
    width: 15%;
    height: 40%;
    background: rgb(0, 117, 255);
    position: absolute;
    left: 75%;
    top: 75%;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: all 0.3s;
  }
  .icon-search::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 71%;
    height: 70%;
    border: 1.8px solid rgb(0, 117, 255);
    border-radius: 50%;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: all 0.3s;
  }
  .icon-search.close::before {
    height: 80%;
    left: 50%;
    top: 50%;
  }
  .icon-search.close::after {
    border-width: 1px;
    border-radius: 0;
    background: rgba(0, 117, 255, 1);
    width: 0;
    height: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.3s, background 0s 0.3s;
  }

  .dropdown-toggle {
    width: 100% !important;
    height: 20px !important;
    border: #ffffff !important;
    background: #dee7fc;
    border-radius: 40px !important;
    z-index: 10 !important;
  }

  .dropdown-menu {
    z-index: 999 !important;
    width: 24.61111rem !important;
    margin-left: -0.8rem !important;
    font-size: 0.66667rem;
    border-color: #bdcbfb;
    background: #f1f4fc !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: none !important;
    margin-top: 5px !important;
  }
}

#dropdown-variants-secondary:hover {
  background-color: rgba(222, 231, 252, 1) !important;
  color: inherit;
}

@media only screen and (max-width: 500px) {
  .assetSearchCont {
    width: 17rem !important;
    .dropdown-menu {
      width: 26.6rem !important;
    }
  }
}

@media only screen and ( max-width: 600px) {
  .assetSearchCont {
    img {
      height: 15px;
      width: 15px;
      margin-left: 10px;
    }

    .settingSearch {
      font-size: 10px;
    }

    .dropdown-menu {
      width: 17.6rem !important;
    }
  }
}

.searchIconDiv{
  display: flex;
  flex-direction: row;
  align-items: center;

  .closeIcon{
    width: 25px;
    height: 20px;
  }

  .searchButtonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 117, 255, 1);
    padding: 1px 12px 1px 10px;
    border-radius: 25px;
    gap: 8px;
    cursor: pointer;

    .searchIcon{
      width: 12px;
      height: 12px;
    }

    .searchBtnText{
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 11px;
      color: #ffffff;
    }
  }
}