.filterChipRoot {
  display: flex;
  width: 100%;
  padding-right: 18px;
  align-items: center;

  .filterChipsParent {
    display: flex;
    max-width: 100%;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 4px;
    margin-bottom: 4px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    overflow-x: scroll;
    overflow-y: hidden;

    .filterChipSettings {
      display: flex;
      border-radius: 50px;
      padding: 0.24rem 0.6rem;
      border: 1px solid #0075FF;
      color: #0075FF;
      font-family: Inter, serif;
      font-size: 0.75rem;
      font-style: normal;
      line-height: normal;
      margin-right: 5px;
      height: 24px;
      white-space: nowrap;

      &:hover {
        background: rgba(0, 117, 255, 0.05);
      }

      .filterChipSettingsKey {
        font-weight: 500;
        cursor: default;
      }

      .filterChipSettingsValue {
        font-weight: 400;
        margin-left: 3px;
        cursor: default;
      }

      .closeIconFiltersDiv {
        width: 10px;
        height: 10px;
        margin-top: -1px;
        padding: 0;
        margin-left: 4px;

        .closeIconFilter {
          width: inherit;
          height: inherit;
        }
      }
    }
  }

  .settingsFilterClearAll{
    color: #EA5037;
    font-family: Inter, serif;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 600;
    width: 80px;
    padding-left: 8px;
    line-height: normal;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    cursor: pointer;
  }
}


.filterChipsParent::-webkit-scrollbar {
  display: none;
}