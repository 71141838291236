.toggle-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  top: 6px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: white;
  border-radius: 25px;
  top: -1px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  border: 1px solid #5038f5;
  height: 13px;
  width: 28px;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 7px;
  height: 7px;
  background-color: #5038f5;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: white;
  left: -6px;
  margin-left: -2px;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #5038f5;
}
