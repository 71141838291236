.RepeatedTicketsCard {
  margin-top: 56px;
}

.repeatedtickets-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #dae2ff;
  border-radius: 5px;
  margin: 4px;
  align-items: center;
  padding: 10px;

  div {
    justify-content: space-between;
  }

  .atsuya-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 2rem;
    width: 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-top: 0px;
    margin-right: 7px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .area {
    display: flex;

    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
      font-weight: 700;
    }

    p {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
    }
  }

  .client-ids {
    display: flex;
    flex-direction: column;

    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 5px !important;
    }
  }

  .ticket-values {
    display: flex;
    flex-direction: row;
    img {
      margin-right: 6px;
      filter: invert(44%) sepia(281%) saturate(325%) hue-rotate(683deg)
        brightness(90%) contrast(124%);
      width: 20px;
      height: 20px;
    }

    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 16px;
      margin-bottom: 0px !important;
      font-weight: 700;
    }
  }
}

@media only screen and (max-width: 600px) {
  .repeatedtickets-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #dae2ff;
    border-radius: 5px;
    margin: 4px;
    align-items: center;
    padding: 10px;
    height: 80px;
    position: relative;
  }

  .repeatedtickets-container {
    .area {
      .atsuya-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 2rem;
        width: 2rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        margin-top: 0px;
        margin-right: 7px;
        position: relative;
        bottom: 16px;
      }

      h6 {
        color: #182f43;
        font-family: "Roboto";
        font-size: 12px;
        margin-bottom: 0px !important;
        font-weight: 700;
        position: relative;
        bottom: 16px;
      }

      p {
        color: #182f43;
        font-family: "Roboto";
        font-size: 12px;
        margin-bottom: 0px !important;
        position: relative;
        top: 21px;
        right: 40px;
      }
    }
  }

  .repeatedtickets-container .client-ids h6 {
    color: #182f43;
    font-family: "Roboto";
    font-size: 12px;
    margin-bottom: 5px !important;
    position: absolute;
    top: 45px;
    left: 10px;
  }

  .repeatedtickets-container .ticket-values {
    display: flex;
    flex-direction: row;
    position: relative;
    right: 25px;
  }

  .repeatedtickets-container .ticket-values img {
    margin-right: 6px;
    filter: invert(44%) sepia(281%) saturate(325%) hue-rotate(683deg)
      brightness(90%) contrast(124%);
    width: 22px;
    height: 22px;
  }

  .repeatedtickets-container .ticket-values h6 {
    color: #182f43;
    font-family: "Roboto";
    font-size: 19px;
    margin-bottom: 0px !important;
    font-weight: 700;
  }
}
