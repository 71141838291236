.newAssetDetailsContainer {
    // background-color: rgb(255, 255, 255);
    // height: 75vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}

.assetDetailsLeftContainer {
    width: 50%;
    padding: 1.2rem;
    padding-bottom: 0;
    gap: 2%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: width 0.4s ease;
    background-color: rgb(255, 255, 255);
    height: 100%;
    // border: 1px solid rgb(204, 204, 204);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    box-shadow: 3px 3px 6px 3px rgba(103, 113, 255, 0.12);
}

.newAssetExpandButtonContainer {
    position: absolute;
    right: -17px;
    top: 60px;
    background-color: #374DC6;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .newAssetExpandButtonIcon {
        width: 0.5rem;
    }
}

.assetDetailsBackNavigationContainer {
    // background-color: #374DC6;
    width: 50%;
    display: flex;
    gap: 0.5rem;
    margin-bottom: 15px;

    // height: 20%;
    // justify-content: center;
    // align-items: center;
    // height: 15%;
    .newAssetDetailsBackNavigationIcon {
        width: 1.4rem;
    }

}

.assetDetailsNameAndIconContainer {
    display: flex;
    justify-content: center;
    // align-items: center;
    gap: 0.5rem;
}

.newAssetDetailsIconContainer {
    display: flex;
    border-radius: 8px;
    border: 1px solid #9AD2FF;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;

    .newAssetIcon {
        height: 80%;
        width: 80%;
    }
}

.newAssetDetailsAssetNameConatiner {
    display: flex;
    flex-direction: column;
    line-height: 1.2;

    p {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
    }

    .newAssetDetailsAssetContent1 {
        color: #004497;
        font-size: 12px;
    }

    .newAssetDetailsAssetContent2 {
        color: #131523;
        font-size: 24px;
    }

    .newAssetDetailsAssetContent3 {
        color: #131523;
        font-size: 15.21px;
    }
}

.assetDetailsRightContainer {
    width: 50%;
    height: 95%;
    padding-left: 1.2rem;
    padding-top: 1.0rem;
    background-color: #F5F6FA;
    display: flex;
    flex-direction: column;
}

.assetDetailsRightContainer .WatchListbuttonContainer {
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assetDetailsRightContainer .WatchListbuttonContainer button {
    padding: 9px 78px;
    border: none;
    text-align: center;
    color: white;
    background-color: rgb(160, 160, 9);
    border-radius: 4px;
}

.assetDetailsRightContainer .watchListBodyContainer {
    height: 90%;
    width: 93%;
    margin-top: 0.8rem;
    background-color: #ffffff;
}

.readingsAndChartContainer {
    display: flex;
    // height: 80%;
    flex: 1;
    flex-wrap: wrap;
    background-color: rgb(255, 255, 255);
    // border: 1px solid grey;
    padding-left: 30px;
    padding-right: 30px;
    // height: fit-content;
    gap: 14px;

    // height: 10vh;
    .leftReadingsContainer {
        width: 49.5%;
        display: flex;
        flex-direction: column;
        gap: 14px;
        align-items: center;

        .meterReadingscontainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            // background-color: #F44336;
            // height: 100%;
            // height: fit-content;
            // flex: 1;

            .newAssetDetailsViewAllButtonContainer {
                display: flex;
                align-items: center;
                gap: 10px;
                padding-top: 5px;

                p {
                    margin: 0;
                    color: #5230D4;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 0;
                }
            }

            .meterRadingsCompleteBlueContainer {
                display: flex;
                // margin-bottom: 5px;
                flex-direction: column;
                background-color: #E6F2FE;
                border-radius: 8px;
                height: 100%;
                padding: 15px;
                padding-bottom: 4px;

                img {
                    width: 80%;
                }

                .meterLiveIconContainer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        margin: 0;
                        padding: 0;
                        color: black;
                        font-size: 12px;
                        font-weight: 400;
                    }

                    .live {
                        background-color: #F44336;
                        width: 39.7px;
                        height: 19px;
                        border-radius: 76.37px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        p {
                            color: white;
                            font-size: 9.76px;
                            font-weight: 500;
                            animation: blink 1s infinite;
                        }
                    }
                }

                .dynamicMeterReadingsContainer {
                    margin-top: 5px;
                    gap: 15px;
                    width: 100%;
                    display: flex;
                    height: auto;
                    max-height: 50vh;
                    box-sizing: border-box;
                    overflow-y: auto;
                    padding-bottom: 5px;
                    align-items: baseline;
                    .newAssetMeterDetailsCard {
                        display: flex;
                        flex-direction: column;

                        p {
                            color: #374DC6;
                            font-size: 16px;
                            font-weight: 500;
                            padding: 0;
                            margin: 0;
                            width: max-content;
                        }

                        .meterDetailsIocnAndLabelContainer {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .meterDetailsReUseIconContainer {
                                width: 39px;
                                height: 39px;
                                background-color: #ffffff;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img {
                                    width: 19.1px;
                                    height: 20px;
                                }
                            }

                            .meterDetailsCardDymanicLabelsAndValuesConatiner {
                                display: flex;
                                gap: 10px;

                                .meterDetailsCardDymanicLabelsAndValuesCardConatiner {
                                    flex-direction: column;
                                    border-color: 1px solid grey;
                                    flex-shrink: 0;
                                    display: inline-flex;
                                    align-items: flex-start;
                                    min-width: max-content;
                                    width: auto;

                                    p {
                                        color: #707070;
                                        font-size: 12px;
                                    }

                                    h3 {
                                        margin: 0;
                                        padding: 0;
                                        color: black;
                                        font-weight: 600;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    .rightChartContainer {
        width: 49.5%;
        height: 65%;
        // background-color: #374DC6;
        // border: 1px solid grey;
        display: flex;
        flex-direction: column;
        align-items: center;

        .rigtChartToggleButtonsContainer {
            display: flex;
            // border: 1px solid green;
            gap: 10px;
            width: 85%;
            gap: 4%;

            .newAssetDetailsToggleButton {
                border: 1px solid #BDCBFB;
                background-color: #F5F6FA;
                display: flex;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
                justify-content: center;
                gap: 13px;
                width: 48%;
                padding: 8px 0px 8px 0px;

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 18px;
                }
            }

            .newAssetDetailsActiveToggleButton {
                border: 1px solid #BDCBFB;
                background-color: #2F80ED;
                display: flex;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
                justify-content: center;
                gap: 13px;
                width: 48%;
                padding: 8px 0px 8px 0px;
                color: white;

                p {
                    margin: 0;
                    font-size: 18px;
                    padding: 0;
                }
            }
        }

        .newAssetDetailsLiveChartFilterChipsContainer {
            // border: 1px solid green;
            display: flex;
            width: 90%;
            // height: auto;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            margin-top: 18px;
            gap: 0.5%;
            box-sizing: border-box;

            .filterChipsOverFlowContainer {
                // border: 1px solid black;
                width: 98.5%;
                // overflow: auto;
                flex-wrap: wrap;
                display: flex;
                gap: 10px;
                scrollbar-width: none;
                justify-content: center;

                .button-container {
                    position: relative;
                    // margin-bottom: 10px;
                }

                button {
                    padding: 0px 10px 0px 10px;
                    background-color: transparent;
                    flex-shrink: 0;
                    width: max-content;
                    border: 1px solid transparent;
                    border-radius: 19px;
                    cursor: pointer;
                    height: 32px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    img {
                        width: 15px;
                    }
                }

                .overlay {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: flex;
                    flex-direction: row;
                    /* Change to row for horizontal arrangement */
                    background-color: #fff;
                    border: 1px solid #ccc;
                    padding: 10px;
                    z-index: 1;
                    gap: 8px;

                    .overlayButton {
                        border-radius: 18px;
                        border: 1px solid;
                        width: max-content;
                        font-size: 11px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        img {
                            width: 4px;
                        }
                    }
                }

                .overlay div {
                    padding: 5px;
                    cursor: pointer;
                    margin-right: 5px;
                    /* Add some space between options */
                }

                // box-sizing: border-box;
                // padding-bottom: 10px;
                .filterChipButtons {
                    flex-shrink: 0;
                    display: inline-flex;
                    align-items: flex-start;
                    min-width: max-content;
                    width: auto;
                    border: none;
                    cursor: pointer;
                    padding: 2px 18px 2px 18px;
                    border-radius: 20px;
                }
            }

            .filterChipsOverFlowContainer ::-webkit-scrollbar {
                display: none !important;
            }

            .filterChipToggleButton {
                border: none;
                height: 31px;
                width: 5%;
                border-radius: 13px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 80%;
                }
            }
        }

        .newAssetDetailsLiveChartContainer {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// popupcssss....................
.liveMeterPopupCss{
    div{
    background: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .customLiveMetersPopupContainer{
        background-color: white !important;
        width: 80% !important;
        padding: 25px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        .customHeaderContainer{
            // background-color: #374DC6 !important;
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid grey;
            padding-bottom: 10px;
            .readingsHeadingContainer{
                display: flex;
                flex-direction: column;
                .updatedDesc{
                    font-size: 13px !important;
                    font-weight: 500;
                    align-self: self-start;
                    color: rgb(9, 9, 9);
                }
                .readingsHeading{
                    display: flex;
                    align-self: flex-start;
                    gap: 10px;
                    h2{
                    margin: 0;
                    align-self: flex-start ;
                    padding: 0;
                    font-size: 16px !important;
                    color: blue;
                    }
                
                .live{
                    background-color: rgb(215, 0, 0);
                    color: white;
                    border-radius: 30px;
                    margin: 0;
                    padding: 3px 9px;
                    font-size: 12px;
                    span{
                         animation: blink 1s infinite;
                    }
                    // p{    
                   
                    // }
                }
            }
            }
        }
        .cutomBodyContainer{
            margin-top: 10px;
            display: flex !important;
            flex-wrap: wrap !important;
            width: 100% !important;
            .threeRowDynamicContainer{
                width: 33.3%;
                max-height: 50vh;
                display: flex;
                flex-direction: column;
                align-self: flex-start;
                gap: 18px;
                // background-color: #9AD2FF !important;
                .newAssetMeterDetailsCard {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: flex-start;
                    gap: 6px;
                    // width: 98%;
                    p {
                        color: #374DC6;
                        font-size: 17px !important;
                        font-weight: 500;
                        padding: 0 !important;
                        margin: 0 !important;
                        // max-width: max-content !important;
                    }

                    .meterDetailsIocnAndLabelContainer {
                        display: flex !important;
                        align-items: flex-start !important;
                        gap: 15px;
                        // background-color: #F44336 !important;

                        .meterDetailsReUseIconContainer {
                            width: 39px;
                            height: 39px;
                            background-color: #edf6ff !important;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 19.1px;
                                height: 20px;
                            }
                        }

                        .meterDetailsCardDymanicLabelsAndValuesConatiner {
                            display: flex;
                            gap: 10px;

                            .meterDetailsCardDymanicLabelsAndValuesCardConatiner {
                                flex-direction: column;
                                border-color: 1px solid grey;
                                flex-shrink: 0;
                                display: inline-flex;
                                align-items: flex-start;
                                min-width: max-content;
                                width: auto;

                                p {
                                    color: #707070;
                                    font-size: 13px !important;
                                }

                                h3 {
                                    margin: 0;
                                    padding: 0;
                                    color: black;
                                    font-weight: 600;
                                    font-size: 13px !important;
                                }
                            }
                        }
                    }
                }
            }
            .singleLiveMetersData{
                // background-color: #5230D4 !important;
                width: 100%;
                display: flex !important;
                flex-direction: row !important;
                justify-content: flex-start;
                gap: 25px;
                margin-top: 18px;
                align-items: baseline;
                .singleLiveMetersCard{
                    display: flex;
                    // background-color: red !important;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    flex-shrink: 0;
                    .singleLiveTitle{
                        color: #0575E6;
                        font-weight: 500;
                        font-size: 16px !important;
                        margin: 0;
                        padding: 0;
                    }
                    .singleLiveValue{
                        color: #000000;
                        font-weight: 500;
                        font-size: 14px !important;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
    }
}

.singleLiveMetersCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    .singleLiveTitle{
        color: #0575E6;
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
    .singleLiveValue{
        color: #000000;
        font-weight: 500;
        font-size: 15px;
        margin: 0;
        padding: 0;
    }
    .minMaxFontText{
        margin: 0px;
        font-size: 13px;
        font-weight: 500;
        span{
            font-weight: 700;
        }
    }

}



/* Small devices (mobile phones, less than 576px) */
@media (max-width: 767px) {
    .liveMeterPopupCss{
        div{
        .customLiveMetersPopupContainer{
            .cutomBodyContainer{
                .threeRowDynamicContainer{
                    width: 33.3%;
                    // background-color: #494949 !important;
                }
            }
        }
        }
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .liveMeterPopupCss{
        div{
            justify-content: flex-start !important;
        .customLiveMetersPopupContainer{
            .customHeaderContainer{
                justify-content: space-between !important;
            .cutomBodyContainer{
                .threeRowDynamicContainer{
                    width: 50% !important;
                    // background-color: yellow !important;
                    // .newAssetMeterDetailsCard .meterDetailsIocnAndLabelContainer .meterDetailsCardDymanicLabelsAndValuesConatiner .meterDetailsCardDymanicLabelsAndValuesCardConatiner{
                    //     h3{
                    //         font-size: small !important;
                    //     }
                    // }
                }
            }
        }
        }
        }
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .liveMeterPopupCss{
        div{
        .customLiveMetersPopupContainer{
            .cutomBodyContainer{
                .threeRowDynamicContainer{
                    width: 33.3%;
                    // background-color: #3f9700 !important;
                }
            }
        }
        }
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media ((min-width: 1200px) and (max-width:1819px)) {
    .liveMeterPopupCss{
        div{
        .customLiveMetersPopupContainer{
            .cutomBodyContainer{
                .threeRowDynamicContainer{
                    width: 33.3%;
                    // background-color: #004497 !important;
                }
            }
        }
        }
    }
}



/* For screens smaller than 1820px */
@media (max-width: 1820px) {
    .newAssetDetailsContainer {
        margin-top: 0.5%;
        height: fit-content;

        .assetDetailsLeftContainer {
            padding: 0.6rem;
        }
    }

    .newAssetDetailsIconContainer {
        width: 38px;
        border-radius: 7px;
        height: 38px;

        .newAssetIcon {
            height: 80%;
            width: 80%;
        }
    }

    .newAssetDetailsAssetNameConatiner {
        line-height: 1.1;

        .newAssetDetailsAssetContent1 {
            color: #004497;
            font-size: 9px;
        }

        .newAssetDetailsAssetContent2 {
            color: #131523;
            font-size: 14px;
        }

        .newAssetDetailsAssetContent3 {
            color: #131523;
            font-size: 10.21px;
        }
    }

    .newAssetExpandButtonContainer {
        right: -11px;
        width: 1.3rem;
        top: 50px;
        height: 1.3rem;
        border-radius: 50%;

        .newAssetExpandButtonIcon {
            width: 0.3rem;
        }
    }

    .assetDetailsRightContainer .WatchListbuttonContainer button {
        padding: 3px 40px;
        /* Adjust padding for medium screens */
        // background-color: aquamarine;
    }

    .assetDetailsRightContainer {
        height: 93%;
    }

    .assetDetailsRightContainer .watchListBodyContainer {
        height: 85%;
    }

    .assetDetailsBackNavigationContainer {
        width: 60%;
        // height: 10%;
        margin-bottom: 15px;

        .newAssetDetailsBackNavigationIcon {
            width: 0.7rem;
        }
    }

    .readingsAndChartContainer {
        padding-left: 20px;
        padding-right: 20px;
        height: 0%;
        gap: 14px;

        .leftReadingsContainer {
            height: 50%;
            gap: 12px;

            .meterReadingscontainer {
                .newAssetDetailsViewAllButtonContainer {
                    padding-top: 5px;

                    p {
                        font-size: 10px;
                    }

                    img {
                        width: 2%;
                    }
                }

                .meterRadingsCompleteBlueContainer {
                    padding-top: 10px;
                    ;

                    .meterLiveIconContainer {
                        p {
                            font-size: 9px
                        }

                        .live {
                            width: 31.7px;
                            height: 13px;
                            // animation: blink 1s infinite;

                            p {
                                font-size: 7.76px;
                                animation: blink 1s infinite;
                            }
                        }
                    }

                    .dynamicMeterReadingsContainer {
                        max-height: 62vh;

                        .newAssetMeterDetailsCard {
                            gap: 3px;

                            p {
                                font-size: 10px;
                            }

                            .meterDetailsIocnAndLabelContainer {
                                .meterDetailsReUseIconContainer {
                                    width: 30px;
                                    height: 30px;
                                }

                                .meterDetailsCardDymanicLabelsAndValuesConatiner .meterDetailsCardDymanicLabelsAndValuesCardConatiner {
                                    gap: 2px;

                                    p {
                                        font-size: 9px;
                                    }

                                    h3 {
                                        font-size: 9px;
                                    }
                                }
                            }
                        }

                    }

                }

            }
        }

        .rightChartContainer {
            .rigtChartToggleButtonsContainer {
                .newAssetDetailsActiveToggleButton {
                    height: 28px;
                    border-radius: 4px;

                    img {
                        width: 15px;
                    }

                    p {
                        font-size: 13px;
                    }
                }

                .newAssetDetailsToggleButton {
                    height: 28px;
                    border-radius: 4px;

                    img {
                        width: 15px;
                    }

                    p {
                        font-size: 13px;
                    }
                }
            }

            .newAssetDetailsLiveChartFilterChipsContainer {

                // position: absolute;
                .filterChipsOverFlowContainer {
                    gap: 4px;
                    line-height: 1;
                    justify-content: center;

                    .button-container {
                        position: relative;
                        // margin-bottom: 10px;
                    }

                    button {
                        padding: 0px 13px 0px 13px;
                        background-color: transparent;
                        flex-shrink: 0;
                        width: max-content;
                        border: 1px solid transparent;
                        border-radius: 19px;
                        cursor: pointer;
                        height: 17px;
                        font-size: 8px;
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        img {
                            width: 9px;
                        }
                    }

                    .overlay {
                        position: absolute;
                        // top: 120%;
                        left: 0;
                        display: flex;
                        flex-direction: row;
                        /* Change to row for horizontal arrangement */
                        background-color: #fff;
                        // border: 1px solid #ccc;
                        padding: 4px;
                        border-radius: 3px;
                        z-index: 1;
                        gap: 4px;
                        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);

                        .overlayButton {
                            border-radius: 20px;
                            border: 1px solid;
                            width: max-content;
                            font-size: 8px;
                            height: 15px;
                            display: flex;
                            align-items: center;
                            gap: 2px;

                            img {
                                width: 4px;
                            }
                        }
                    }

                    .overlay div {
                        padding: 4px;
                        cursor: pointer;
                        margin-right: 5px;
                        /* Add some space between options */
                    }

                }
            }
        }
    }
    .singleLiveMetersCard .singleLiveTitle{
            font-size: 11px;
        }
    .singleLiveMetersCard .singleLiveValue{
            font-size: 10px;
        }
        .singleLiveMetersCard{
            .minMaxFontText{
                font-size: 8.4px;
                font-weight: 500;
                span{
                    font-weight: 700;
                }
            }
        
        }

        // popup lenovo css..................
        .liveMeterPopupCss{
            div{
            .customLiveMetersPopupContainer{
                width: 85% !important;
                padding: 20px;
                border-radius: 8px;
                .customHeaderContainer{
                    padding-bottom: 10px;
                    .readingsHeadingContainer{
                        .updatedDesc{
                            font-size: 11px !important;
                        }
                        .readingsHeading{
                            gap: 6px;
                            h2{
                            font-size: 13px !important;
                            }
                        
                        .live{
                            border-radius: 30px;
                            padding: 2px 6px;
                            font-size: 8px !important;
                        }
                    }
                    }
                }
                .cutomBodyContainer{
                    margin-top: 10px;
                    width: 100% !important;
                    .threeRowDynamicContainer{
                        width: 33.3%;
                        max-height: 50vh;
                        gap: 10px;
                        .newAssetMeterDetailsCard {
                            gap: 3px;
                            // width: 98%;
                            p {
                                font-size: 11px !important;
                                font-weight: 500;
                                // max-width: max-content !important;
                            }
        
                            .meterDetailsIocnAndLabelContainer {
                                gap: 7px;
                                // background-color: #F44336 !important;
        
                                .meterDetailsReUseIconContainer {
                                    width: 35px;
                                    height: 35px;
                                    img {
                                        width: 17.1px;
                                        height: 18px;
                                    }
                                }
        
                                .meterDetailsCardDymanicLabelsAndValuesConatiner {
                                    gap: 10px;
        
                                    .meterDetailsCardDymanicLabelsAndValuesCardConatiner {
                                        p {
                                            font-size: 10px !important;
                                        }
        
                                        h3 {
                                            font-weight: 600;
                                            font-size: 10px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .singleLiveMetersData{
                        gap: 20px;
                        margin-top: 18px;
                        .singleLiveMetersCard{
                            .singleLiveTitle{
                                font-size: 11px !important;
                            }
                            .singleLiveValue{
                                font-size: 11px !important;
                            }
                        }
                    }
                }
            }
            }
        }

}

@keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  
  .shimmer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 22vh;
    overflow: hidden;
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    background: linear-gradient(to right, #f0f0f0 20%, #e0e0e0 40%, #f0f0f0 60%);
    background-size: 1000px 100%;
    animation: shimmer 1.5s infinite linear;
  }
  .shimmer-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 22vh;
    overflow: hidden;
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    // background: linear-gradient(to right, #f0f0f0 20%, #e0e0e0 40%, #f0f0f0 60%);
    // background-size: 1000px 100%;
    // animation: shimmer 1.5s infinite linear;
  }
  .shimmer-buttons{
    width: 100px;
    border-radius: 15px;
    height: 30px;
  }
  .shimmerToggleButtons {
      border-radius: 5px;
      width: 48%;
      height: 40px;
  }
  .minMaxThresHolds{
    font-size: 11px;
    margin: 0px; 
  }
