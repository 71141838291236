@import "./theme/variable";
// @import "~bootstrap/scss/bootstrap";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Inter", sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif !important;
  overflow: hidden;
}

h2 {
  font-weight: $h2-wieght !important;
  font-size: $h2-size !important;
  line-height: $h2-height !important;
}

h4 {
  font-weight: $h4-wieght !important;
  font-size: $h4-size !important;
  line-height: $h4-height !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.width-2 {
  width: 2% !important;

  .navBar-menu {
    display: none;
  }
}

.width-98 {
  width: 98% !important;
}

.filterReason {
  outline: none;
  border-width: 2px;
  border: 2px solid #dde2fc;
  width: 25vw;
  margin: 0px 0px 10px 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}

.pageFixScss {
  margin: 0px 0px 0px 5px;
}

.toggle-FontAwesome {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 0px;
  border-radius: 17px;
  float: right;
  margin-right: 70%;
  margin-top: 10px;
  z-index: 9;
  position: absolute;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 12px;
  }
}

.header-badges-rightOpen {
  margin-left: 30px;
}

.searchWrapper {
  padding: 0 3%;
}

.dateBtnRow {
  margin-left: 9%;
  padding: 10px 0px;
  display: none;
}

.header-right-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -41px;

  &.rightOpen {
    margin-right: 52px;
  }
}
.assetCountScss {
  margin-right: 15px;
  cursor: initial;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  // display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 400;
  display: none;
}
.ph-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.profileName {
  border: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #edeef8;

  img {
  }

  p {
    margin-right: 12px;
    width: 5vw;
  }
}

.__react_component_tooltip {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 8px 21px;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999999 !important;
}

.layout-in {
  background-color: #e1e3f0 !important;
}

.hide-overflow{
  overflow-y: hidden !important;
}

.layout-Out {
  background-color: #edeef8 !important;
}
.page-Layout {
  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  padding: 10px 10px 30px 10px !important;
  height: calc(100vh - 84px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    background: #e4e4e4;
  }

  &::-webkit-scrollbar-track-piece {
    background: #e4e4e4;
  }
  &::-webkit-scrollbar-button {
    background: #e4e4e4;
    height: 1%;
  }
  &.pl-40 {
    padding: 20px 20px 5% 40px !important;
  }
}
.removeOverFlow {
  overflow-y: hidden !important;
  background-color: #edeef8 !important;
  border: none !important;
}

.page-Layout:hover {
  overflow-y: scroll;
}

//ToDO
// .page-Layout:hover {
//   overflow-y: scroll;
// }
.page-Layout:hover::-webkit-scrollbar {
  //width: 0.3em;
  height: 0em;
  cursor: pointer;
}

.page-Layout:hover::-webkit-scrollbar-track-piece {
  background: #edeef8;
}
.page-Layout:hover::-webkit-scrollbar-thumb {
  background: #374dc6;
  //border-radius: 5px;
  cursor: pointer;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-0 {
  padding-right: 0px !important;
}
//ToDo
.pt-10 {
  padding-top: 10px;
}

// header new scss start for mobile and dashboard
.header-row {
  display: flex;
  height: 11vh;
  justify-content: flex-start;
  align-items: center;
  .mobile-Style {
    .header-badges {
      width: 100%;
    }
    .header-badges-rightOpen {
      width: 46vh;
    }
  }
  .searchAndDate {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 72%;
    align-content: center;
    height: 10vh;
    justify-content: center;
  }
  .btnRow {
    width: 100%;
    text-align: center;
    &.btnRow-click {
      width: auto;
      padding-left: 0;
      position: absolute;
      top: 77px;
      margin-left: 0px;
      left: 13.5%;
      margin-top: 0px;
    }
  }
}

.horizontalData {
  .xsScroll-wrapper {
    overflow-x: auto;
  }
}

// .profileDp{
//   position: relative;
//   display: inline-block;
//   .dropbtn{
//     border: none;
//   }
//   .dropdown-content{
//     display: none;
//     position: relative;
//     background-color: #f1f1f1;
//     min-width: 160px;
//     box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
//     z-index: 1;
//     a {
//       color: black;
//       padding: 12px 16px;
//       text-decoration: none;
//       display: block;
//     }
//     a:hover {background-color: #ddd;}
//   }

// .dropdown-content

// .profileDp:hover .dropdown-content {display: block;}

// .profileDp:hover .dropbtn {background-color: #3e8e41;}
// }

.dropbtn {
  // background-color: #04AA6D;
  // color: white;
  // padding: 16px;
  // font-size: 16px;

  border: none;

  p {
    background-color: #edeef8;
    font-size: 10px;
    margin-bottom: 0px;
  }
}

.profileDp {
  position: relative;
  display: inline-block;
}

//ToDo
.dropdown-content {
  display: none;
  position: absolute;
  top: 43px;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 16%);
  z-index: 999999 !important;
  width: 12vw;
  font-size: 0.7rem;
  text-align: center;
  right: 5%;
  a {
    &:nth-child(2) {
      margin: 0;
    }

    span {
      padding: 10px;

      img {
        width: 20px;
        height: 18px;
      }
    }
  }
}

.dropdown-content a {
  color: black;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ffffff;
}

.profileDp:hover .dropdown-content {
  display: block;
}

.profileDp:hover .dropbtn {
  background-color: none;
}

.temp {
  width: 80%;
  display: flex;
  justify-content: center;
}

/*Header Button today thisweek thisMonth Buttons Starts*/
.temp .btn {
  width: auto;
  margin: 4px;
  color: #000;
  font-size: 11px;
  border-color: blue;
  // box-shadow: 0 0 0px 1px #6128e7 inset, 0 0 0 0 #a333c8 inset;
  background: transparent;
  border-radius: 20px;
  padding: 1px 15px;
}

.temp .btn:hover {
  background: #6128e7 !important;
  color: white !important;
}

.temp .btn:focus {
  background: #6128e7 !important;
  color: white !important;
}

/*Header Button today thisweek thisMonth Buttons end*/

.atsuyaLogo {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border-radius: 8px;
  height: 3.2rem;
  width: 3.2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-top: 0;
  justify-content: center;
  img {
    width: 40px;
  }
}

.temp .ui.button {
  min-width: 10vw;
  margin: 4px;
  box-shadow: 0 0 0px 0px #6128e7 inset, 0 0 0 0 #ffffff inset;
  background: transparent;
  border-radius: 20px;
  border-color: #038aff;
  padding: 1px 10px;
  height: 32px;

  &:hover {
    background: #3c88f7;
    color: white;
  }
}

/*Header Chart img style start*/
.chatCta {
  background-color: #0575e6;
  border-radius: 60%;

  cursor: pointer;
  width: 43px;
  height: 43px;
  margin-left: 15px;
}

/*Header Chart img style end*/

/*Header Avatar img Satrt*/
.profileDp {
  border-radius: 5px;
  margin-left: 10px;
  // display: flex;
  // flex-direction: column;

  // position: relative;
  // div:first-child{
  //   width: 5rem;
  //   border: 1px solid transparent;
  // }
  img {
    border-radius: 5px;
    width: 41px;
    height: 41px;
    max-width: 100%;
    object-fit: cover;
    // position: absolute;
    //   z-index: 99;
    // }
    // .logoutDiv{
    //   width: 5rem;
    //   // border: 1px solid #038aff;
    //   padding: 0.2rem;
    //   margin-left: -1.1rem;
    //   margin-top: 0.1rem;
    //   a{
    //     margin-left: 9px;
    //     font-weight: 500;
    //   }
    // }
    // .menu{
    //   list-style-type: none;
    //     margin: 0;
    //   padding: 0;
    //   // position: absolute;
  }
}

/*Header Avatar img End*/

// component Name: INSIGHTS
// path - a1Components/INSIGHTS/index.tsx
// scss end

/*
portalHeader
*/
// .portalHeader{
//   box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
//   width: 100%;
// }

/*component name : badge
path: a1Components/header/badge/badge.tsx
componrnt start
*/
.header-badge {
  border: 1px solid;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  padding: 5px 2px 5px 16px;
  border-radius: 4px;
  width: 261px;
  h4 {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 0px;
  }

  svg {
    padding-bottom: 5px;
  }

  p {
    color: #4caf50;
    font-size: 14px;
    font-weight: 400;
    padding: 0px 26px 0px 10px;
    margin-bottom: 5px;
  }
}

/*component name : badge
path: a1Components/header/badge/badge.tsx
componrnt end
*/

// datepicker start
.SingleDatePicker_picker {
  top: 50px;
  left: 30px;
}

.SingleDatePicker {
  position: relative;
  top: 4px;
  display: inline-block;
  border-radius: 10px;
}

.SingleDatePicker input[type="text"] {
  background: url(./assets/icons/ic-calendar.svg) no-repeat 5px;
}

.SingleDatePickerInput__withBorder {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  border-radius: 10px;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 30px;
  width: 12px;
}

.DateInput_input {
  font-size: 10px;
  color: black;
  height: 28px;
  padding-left: 30px;
  border: 1px solid blue;
  border-radius: 10px;
}

.DateRangePicker_picker {
  z-index: 999;
}

.DateInput_fang {
  z-index: 9999;
}

// @media only screen and (max-width: 600px) {
//   // .SingleDatePicker .SingleDatePicker_1 .SingleDatePicker_picker{
//   //     top: 66px;
//   //     left: -167.328125px;
//   // }

// }

// datepicker end
/*component name : Emission
path: a1Components/E-Charts/EmissionsComponent
componrnt start
*/
.emissions {
  background: #f3faf7 url(./assets/icons/emissions-bg.svg) no-repeat;
  display: flex;
  flex-direction: row;
  background-size: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 40px 5px 20px 15px;
  background-position: bottom right;
  margin: 0 -10px;
  h2 {
    color: #16a085;
  }

  .emissions-sub {
    font-size: "0.4";
  }

  .emissions-data-left {
    padding-top: 5px;
  }

  .emissions-data-left h2 {
    font-size: 1.8rem !important;
    margin-top: 5px;
    font-weight: 400 !important;
  }

  .emissions-data-left .trendData {
    display: flex !important;
  }

  .emissions-data-right {
    padding-top: 20px;
    padding-right: 10px;
  }

  .emissions-data-right p {
    margin-bottom: 0px;
    font-weight: 600 !important;
    font-size: 14px;
  }

  .emissions-data-right h2 {
    padding-top: 0px;
  }

  .nav-link {
    padding: 10px 0px;
  }

  .nav-link p {
    font-size: 13px;
    font-weight: 400;
    padding: 0px 0px 2px;
    color: #2699fb;
  }
}

/*component name : Emission
path: a1Components/E-Charts/EmissionsComponent
componrnt end
*/

.activitySubAlert {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: 12rem;
  overflow-y: scroll;
  box-sizing: content-box;
}

.subAlertContainerHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
}
//ToDo
// component Name: CardWrapper
// path - a1Components/activitySubAlerts/index.tsx
// scss end

// component Name: CardWrapper
// path - a1Components/CardWrapper/index.tsx
// scss start
// .cardWrapper {
//   margin-top: 25px;
//   margin-right: 15px;
//   margin-left: 15px;

//   &-header {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     flex-direction: row;
//     padding: 5px;

//     h2 {
//       font-size: 18px !important;
//       line-height: 20px !important;
//       margin-bottom: 0px;
//     }
//   }

//   &-body {
//     background-color: #fff;
//     border-radius: 10px;
//     position: relative;
//     box-shadow: 1px 5px 7.5px #00000029;
//     border: 1px solid #dae2ff;
//     .tab_component{
//       height: 20vh;
//       padding: 20px;
//       background-color: #df5a49;
//     }
//   }

//   &-children {
//     padding-left: 10px;
//     padding-right: 10px;
//   }

//   &-footer {
//     background-color: #fff;
//     border-top: 1.5px solid #dae2ff;
//     padding: 5px;
//     border-radius: 6px;

//     p {
//       padding: 2px;
//       font-size: 12px;
//       padding-left: 15px;
//       margin-bottom: 0rem !important;
//     }

//     a {
//       color: #038aff;
//       cursor: pointer;
//       font-weight: 600;
//       text-decoration: underline;
//     }
//   }

//   .ctaIcons {
//     display: flex;
//     cursor: pointer;

//     > div {
//       border: 1px solid #fff;
//       border-radius: 3px;
//       cursor: pointer;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 30px;
//       height: 30px;
//       margin-left: 6px;
//       padding: 1px;

//       i {
//         margin: 0px !important;
//       }
//     }
//   }

//   .info-tip {
//     position: absolute;
//     top: 15px;
//     right: 15px;
//     width: 30px;
//     height: 30px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 2;

//     &:hover img {
//       color: gray;
//     }
//   }
//   // some pop-up alignment changes have done
//   .tooltip-content {
//     position: absolute;
//     display: flex;
//     top: -13px;
//     background-color: #426bfa;
//     padding: 15px;
//     border-radius: 6px;
//     visibility: hidden;
//     opacity: 0;
//     transform: translateX(-50%) translateY(50px);
//     transition: 0.5s;
//     height: auto;
//     min-width: 256px;
//     right: -124px;
//     padding-bottom: 0px;
//   }

//   .tooltip-content::after {
//     content: "";
//     position: absolute;
//     width: 15px;
//     height: 15px;
//     background: #426bfa;
//     top: -6px;
//     right: 6px;
//     // left: 60%;
//     transform: rotate(45deg);
//   }

//   .info-tip:hover .tooltip-content {
//     visibility: visible;
//     opacity: 1;
//     // transform: translateX(-50%) translateY(0px);
//   }

//   // some font change have been done
//   .tooltip-content p {
//     color: #fff;
//     align-items: center;
//     font-size: 12px;
//     padding: 3px;
//   }

//   .info-tip:hover {
//     background-color: #e5e5e5 !important;
//     border-radius: 50%;
//     // opacity: 0.8;
//   }
// }

// component Name: CardWrapper
// path - a1Components/CardWrapper/index.tsx
// scss end

.hrBarWrapper {
  width: 100%;
  position: relative;

  &:not(.noHeight) {
    height: 240px;

    .echarts-for-react {
      height: 240px !important;
    }
  }

  &.noHeight {
    padding-bottom: 20px;

    .viewAll {
      display: flex;
      flex-direction: row;
      bottom: 0px;
      margin-bottom: 20px;
      padding-right: 20px;
      position: absolute;
      left: 400px;
      .viewall-icon {
        margin-top: 3px;
        margin-left: 4px;
      }
    }

    .echarts-for-react {
      padding-bottom: 20px;
    }
  }

  .echarts-for-react {
    width: 100%;
  }

  a {
    // position: absolute;
    bottom: 12px;
    right: 10px;
    font-size: 12px;
    text-decoration: none;
  }
  .arrow-link {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 12px;
    justify-content: end;
    // flex-direction: column;
  }
  .arrow-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-left: 3px;
  }
  #arrow_1 .arrow {
    $size: 10px;
    $width: 2px;

    cursor: pointer;
    display: block;
    width: calc($size / 1.414);
    height: calc($size / 1.414 * 2);
    position: relative;

    & span,
    &:before,
    &:after {
      background: #426bfa;
      content: "";
      display: block;
      width: $size;
      height: $width;
      position: absolute;
      top: calc(50% - (#{$width} / 2));
    }

    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }

    & span {
      width: 0;
    }
    &:hover span {
      width: $size * 1.414;
    }

    @each $direction in "left" "right" {
      &.arrow--#{$direction} {
        & span,
        &:before,
        &:after {
          #{$direction}: 0;
          transform-origin: #{$direction} 50%;
        }

        &:before,
        &:after {
          transition: #{$direction} 0.3s 0.05s;
          width: 7px;
        }

        & span {
          transition: width 0.3s, #{$direction} 0.3s 0.05s;
        }

        &:hover {
          & span,
          &:before,
          &:after {
            #{$direction}: calc($size / 1.414 * -1);
          }
        }
      }
    }
  }

  // }
}

.radio-input-warpper {
  display: flex;
  margin-bottom: 5px;

  label {
    color: #182f43;
    font-size: 16px;
    font-weight: 500;
    padding-left: 5px;
    line-height: 24px;
    padding-right: 10px;
  }
}

.assetTabMenu {
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px 10px;
  margin: 10px 0px;
  border-radius: 6px;

  &.active {
    background-color: #f0f0f8;
    h6 {
      font-weight: 600;
      position: relative;
      &:after {
        content: "";
        background-color: #0f43f9;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -4px;
        left: 0px;
      }
    }
  }

  h6 {
    color: #182f43;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0px;
  }
}

.scoreCard {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px 5px;
    border: 2px solid;
    border-radius: 10px;
    min-height: 60px;
    margin: 0px 3px;
  }

  [class*="col-"] {
    &:first-child {
      .scoreCard-item {
        border-color: #4caf50;
      }
    }

    &:nth-child(2n) {
      .scoreCard-item {
        border-color: #ffc107;
      }
    }

    &:last-child {
      .scoreCard-item {
        border-color: #f44336;
      }
    }
  }

  img {
    width: 18px;
    margin-right: 10px;
    margin-top: -10px;
  }

  h2 {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 0px !important;
    margin-bottom: 2px;
    &.positive {
      svg {
        background-color: #f44336;
      }
    }
    &.negative {
      svg {
        background-color: #4caf50;
      }
    }

    svg {
      color: #fff;
      padding: 1px 2px;
      border-radius: 6px;
      font-size: 8px;
    }

    span {
      color: #090d12;
      font-size: 10px;
      font-weight: 500;
    }
  }

  p {
    color: #161616;
    font-size: 10px;
    margin-bottom: 0px;
  }
}

// component Name: CardWrapper
// path - a1Components/PortalHeader/index.tsx
// scss end

// component Name: Searchbar
// path - a1Components/Searchbar/Searchbar.css
// scss start
.searchbar {
  width: 50%;
  margin-left: -20px;

  input,
  input::-webkit-input-placeholder {
    font-size: 14px;
    color: #000000;
  }
}
.faSearch {
  font-size: 15px !important;
}
.searchShrink {
  flex-wrap: wrap;
  .searchbar {
    margin-bottom: 10px;
    width: 50%;
  }
}
.searchbar .input-group .FontAwesomeIcon {
  position: absolute;
  right: 10;
  top: 10;
  z-index: 0;
  color: blue;
  font-size: 15px;
}

.searchbar .icon .search {
  color: blue;
}

.searchbar .ui.icon.input > input {
  border: 1px solid blue;
  border-color: blue;
}

.searchbar .ui.input {
  border-color: blue;
  font-size: 1em;
  width: 100%;
}

.searchbar .form-control {
  border-color: #038aff;
}

.form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

.searchbar .hover-according .btn {
  width: auto;
  margin: 6px 0px 1px 8px;
  border-color: blue;
  color: #0b0b0c;
  background: transparent;
  border-radius: 20px;
  padding: 1px 20px !important;
}

.searchbar .hover-according .btn:hover {
  background: #3c88f7;
  color: white !important;
}

.searchbar .hover-according .btn:focus {
  background: #3c88f7;
  color: white;
}

.searchbar .hover-according {
  display: none !important;
  top: 34px;
  left: 1px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 16px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  height: auto;
  width: 100%;
  padding: 10px;
  align-items: center;
  padding: 0px;
  border: 1px solid blue;
  border-radius: 0px;
}

.searchbar:hover .hover-according {
  display: block;
  // padding: 10px;
}

.hover-according {
  p {
    margin: 10px;
    font-weight: 500;
    font-size: 14px;
  }
  .history_List {
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 0.8rem;
    }
    img {
      margin-left: 10px;
      background-color: #e4f6ff;
      padding: 7px 5px 5px 5px;
      height: 6vh;
    }
  }
}

.email-input {
  display: none;
  height: 40px;
  width: 100%;
  font-size: 15px;
  color: #666666;
  text-indent: 8px;
  border: none;
}

// .search-box{
//   display: none;
// }
.search-box {
  position: relative;
  // display: none;
}

.search-box span {
  position: absolute;
  // display: none;
  right: 1em;
  top: 0;
  bottom: 0;
  line-height: 40px;
}

// component Name: Searchbar
// path - a1Components/Searchbar/Searchbar.css
// scss end

// component Name: SideNavBar
// path - a1Components/SideNavBar/index.tsx
// scss start
.navCol {
  padding-left: 5px !important;
}
.navBar-menu {
  height: calc(90vh - 68px);
  overflow-y: auto;
  padding: 45px 0px 0px 7px;
  // transition: all 1s;
  ul {
    list-style-type: none;
    padding-left: 0px;
  }
  .logout_icon {
    display: none;
  }
  li {
    &.active,
    &:hover {
      a {
        background: transparent linear-gradient(1deg, #021b79 0%, #0575e6 100%)
          0% 0% no-repeat padding-box;
        border-radius: 10px;
        color: #ffffff;
      }
      img {
        width: 25px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(3%)
          saturate(7373%) hue-rotate(205deg) brightness(118%) contrast(101%);
      }
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 5px;
    margin-bottom: 15px;

    svg {
      font-size: 22px;
    }

    img {
      width: 22px;
    }
  }

  dl,
  ol,
  ul {
    margin-bottom: 0rem;
  }
  a.active {
    background: transparent linear-gradient(1deg, #021b79 0%, #0575e6 100%) 0%
      0% no-repeat padding-box;
    border-radius: 10px;
    color: #ffffff;
    img {
      width: 25px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(3%)
        saturate(7373%) hue-rotate(205deg) brightness(118%) contrast(101%);
    }
  }

  a:hover {
    background-color: #ffffff;
    border-radius: 10px;
    color: black;
    img {
      width: 25px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(3%)
        saturate(7373%) hue-rotate(205deg) brightness(118%) contrast(101%);
    }
  }
}

.breadCrumbsFilter {
  //ToDO
  width: 100%;
  background-color: #e1e3f0 !important;
  // margin-top: 20px;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0px;
  z-index: 9;
  height: 6.5vh;

  .CrumbsChips {
    display: flex;
    justify-content: space-between;
    .filterChips {
      .topRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        &-list {
          display: flex;
          align-items: center;
          ul {
            display: flex;
            margin: 0px;
            padding: 0px;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
          }
          li {
            border: 1px solid #0075ff;
            border-radius: 15px;
            color: #0075ff;
            font-size: 13px;
            list-style-type: none;
            margin-right: 10px;
            padding: 2px 10px;
            min-width: 5vw;
            margin-bottom: 5px;
            svg {
              color: #0075ff;
              cursor: pointer;
              margin-left: 3px;
            }
          }
          span {
            color: #ff6b2d;
            cursor: pointer;
            border-bottom: 1px solid #ff6b2d;
            font-size: 12px;
            min-width: 5vw;
          }
        }
      }
    }
  }
}

.overlayEnabled {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }
}

// .sideNav{
// position: fixed;
// }
// component Name: SideNavBar
// path - a1Components/SideNavBar/index.tsx
// scss end

// component Name: TabTitleBox
// path - a1Components/TabTitleBox/index.tsx
// scss start

.assetInfoErroMsg {
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    text-align: center;
    margin: 0;
    padding: 1px;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
}

.tabTitleBox {
  cursor: pointer;
  background-color: #f5f6fa;
  border: 1px solid transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 5px;
  border-radius: 6px;
  position: relative;

  &-wrapper {
    display: flex;
    padding: 15px 5px;
    overflow-x: auto;
  }

  &:hover,
  &.active {
    background-color: transparent;
  }

  &:hover {
    border: 1px solid #426bfa;
  }

  h2 {
    color: #0b0b0c;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 22px !important;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 0px 15px;
    margin-bottom: 5px;

    svg {
      font-size: 9px;
      background: #4caf50;
      padding: 2px 3px;
      border-radius: 10px;
      margin-right: 1px;
    }

    p {
      color: #090d12;
      font-weight: 700 !important;
      font-size: 14px !important;
      display: flex;
      align-items: center;
      margin-left: 5px;
      margin-bottom: 0px;
    }
  }

  h4 {
    color: #000000;
    font-weight: 700 !important;
    font-size: 14px !important;
    margin-bottom: 0px;
  }

  h5 {
    color: #182f43;
    font-weight: 700 !important;
    font-size: 12px !important;
    padding: 0px 15px;
    margin-bottom: 0px;
  }

  h6 {
    color: #707070;
    font-weight: 700 !important;
    font-size: 10px !important;
    text-align: center;
    padding: 0px 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      background-color: rgba($color: #707070, $alpha: 0.13);
      color: #3c3c3c;
      font-size: 8px !important;
      line-height: 12px;
      padding: 1px 6px;
      border-radius: 15px;
      margin-left: 3%;
      font-weight: 600;
    }
  }
}

//for mobile view in distribution across assets

.mobileDrop {
  display: none;
}

//for mobile view in distribution across assets end

.activeBar {
  background-color: #426bfa;
  border-radius: 12px 12px 0px 0px;
  height: 5px;
  margin: 10px 5px 0px;
  width: 94%;
  position: absolute;
  bottom: 10px;
}

.rangePart {
  background-color: #edeefc;
  padding: 5px 15px;
  text-align: center;
  margin-bottom: 7px;

  input[type="range"] {
    background: linear-gradient(
      to right,
      #56ab2f 0%,
      #a8e063 80%,
      #bdbfd0 0%,
      #bdbfd0 100%
    );
    border-radius: 4px;
    height: 3px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    position: relative;
    top: -10px;

    &.high {
      background: linear-gradient(
        to right,
        #f44336 0%,
        #ff9328 90%,
        #bdbfd0 0%,
        #bdbfd0 100%
      );

      &::-webkit-slider-thumb {
        background: #f44336;
      }
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: 2px solid #fff;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: #56ab2f;
      margin-top: -1px;
    }
  }
}

.thresoldChange {
  display: flex;
  position: relative;

  p {
    position: absolute;
    left: 0px;
    top: -10px;
    color: #000000;
    font-weight: 500 !important;
    font-size: 8px !important;
  }
}

.xs-tab {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;

  &-cta {
    position: absolute;
  }

  &:hover {
    .xs-tab-list {
      display: block;
    }
  }

  &-list {
    background-color: #f5f6fa;
    display: none;
    margin: 0px;
    padding: 10px 20px 0px;
    position: absolute;
    top: 40px;
    z-index: 99;
  }

  li {
    list-style-type: none;
    color: #182f43;
    font-weight: 600 !important;
    font-size: 13px !important;
    padding-bottom: 10px;

    &:hover {
      color: #000;
    }
  }

  svg {
    font-size: 2rem;
  }
}

// component Name: TabTitleBox
// path - a1Components/TabTitleBox/index.tsx
// scss end

// component Name: ToggleTheme
// path - a1Components/ToggleTheme/index.tsx
// scss start
.toggleTheme {
  background-color: #dfe6ff;
  border-color: #dfe6ff;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}

.outerCircle {
  border: 1px solid #dae2ff;
  border-radius: 18px;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.innerCircle {
  width: 18px;
  height: 18px;
  border: 1px solid #038aff;
  border-radius: 27px;
  padding: 2px;
}

.halfCircle {
  width: 7px;
  height: 12px;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  background-color: #038aff;
}

// component Name: ToggleTheme
// path - a1Components/ToggleTheme/index.tsx
// scss end

// .btnRow{

//       width: auto;
//       padding-left: 0;
//       position: absolute;
//       top: 120px;
//       margin-left: 0px;
//       left: 8.5%;
//       margin-top: 0px;
// }

// component Name: TileCard
// path - a1Components/TileCard/index.tsx
// scss start
.tileIcon-cmp {
  overflow: hidden;
  position: relative;
  width: 33.33%;
  display: flex;
  border-right: 2px solid #db732e;
  flex-direction: column;
  align-items: center;

  .cmp-info {
    padding: 11px;
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: column;

    h5 {
      padding: 0;
      margin: 0;
      font-size: 0.8rem;
      font-weight: 700 !important;
    }

    h3 {
      padding: 0;
      margin: 0;
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &:nth-child(2n) {
    margin: 0 8px;
    border-right: 2px solid #db732e;
  }

  &:nth-child(3n) {
    border-right: 0px solid #db732e;
  }

  &:before {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    top: -22px;
    left: -55px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    border-top-right-radius: 60px;
  }
  img {
    width: 36px;
  }
  p {
    color: #090d12;
    font-size: 10px !important;
    display: flex;
    margin-bottom: 0rem !important;
    align-items: center;
    margin-left: 11px;
    svg {
      background-color: #4caf50;
      font-size: 7px;
      border-radius: 8px;
      padding: 2px 3px;
      margin-right: 3px;
    }
  }
}

.cardWrapper-footer {
  p {
    a {
      svg {
        display: none;
      }
    }
    a:hover {
      svg {
        display: revert;
        position: relative;
        top: 2px;
        left: 3px;
      }
    }
  }
}

// .cardWrapper-footer {

//     a:hover.footer-icon{
//       display: block !important;
//     }
// }

// .cardWrapper-footer {
//   display: none !important;
//   a:hover .footer-icon{
//     display: block !important;
//   }
// }

// component Name: TileCard
// path - a1Components/TileCard/index.tsx
// scss end

// component Name: donutDescription
// path - a1Components/donutChart/donutDescription.tsx
// scss start

// component Name: donutDescription
// path - a1Components/donutChart/donutDescription.tsx
// scss end

// component Name: footerAOne
// path - a1Components/footer/index.tsx
// scss start
.footer-Style {
  position: fixed;
  bottom: 0px;
  width: 100% !important;
  padding: 0px;
  background-color: #edeef8;
  height: 4.5vh;
  z-index: 9;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: fixed;
    right: 30px;
    p {
      font-size: 0.7rem;
      margin: 8px;
    }
  }
}

// component Name: footerAone
// path - a1Components/footer/index.tsx
// scss end

// component Name: SnackCard
// path - a1Components/SnackCard/index.tsx
// scss start
.snackCard {
  box-sizing: border-box;
  margin-top: 50px;
}

.snackCard .column {
  float: left;
  width: 50%;
  padding: 2px 2px;
}

.snackCard .snackCard-details {
  padding: 3px;
  text-align: start;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  border: 1px solid #dfe2eb !important;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: stretch;
  background-color: #c8c8c8;
  opacity: 0.2;
}

.snackCard-cards p {
  margin-bottom: 0rem;
  font-size: 13px;
}

.snackCard-img {
  padding: 8px;
}

// .snackCard-cards {
//   padding-left: 0.4vw;
// }
.snackCardCss .snackCar-content {
  font-size: 0.7rem;
  text-align: end;
}
.snackCardCss a {
  text-decoration: none;
}

.horizontalScroll {
  overflow-x: auto;
  white-space: nowrap;
}

.chart {
  overflow: hidden;
  width: 0%;
  animation: expand 1.5s ease forwards;
}

.rowd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  h6 {
    margin-bottom: 0px;
  }
}

.rowd + .rowd .chart {
  animation-delay: 0.2s;
}

.rowd + .rowd + .rowd .chart {
  animation-delay: 0.4s;
}

.block {
  display: block;
  height: 28px;
  color: #fff;
  font-size: 0.875rem;
  line-height: 0px;
  float: left;
  background-color: #8481fc;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity, 0.3s ease;
  cursor: pointer;
}

.block:nth-of-type(2),
.legend li:nth-of-type(2):before {
  background-color: #f29052;
}

.block:nth-of-type(3),
.legend li:nth-of-type(3):before {
  background-color: #3bce7a;
}

.block:nth-of-type(4),
.legend li:nth-of-type(4):before {
  background-color: #e27a3f;
}

.block:nth-of-type(5),
.legend li:nth-of-type(5):before {
  background-color: #df5a49;
}

.block:nth-of-type(6),
.legend li:nth-of-type(6):before {
  background-color: #962d3e;
}

.block:hover {
  opacity: 0.65;
}

.value {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.x-axis {
  text-align: center;
  padding: 0.5em 0 2em;
}

.y-axis {
  height: 20px;
  transform: translate(-32px, 170px) rotate(270deg);
  position: absolute;
  left: 0;
}

.legend {
  margin: 0 auto;
  padding: 0;
  font-size: 0.9em;
  margin-left: 40px !important;
}

.legend li {
  display: inline-block;
  padding: 0.25em 0.5em;
  line-height: 1em;
}

.legend li:before {
  content: "";
  margin-right: 0.5em;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #334d5c;
  top: 1px;
  position: relative;
}

.tabTitle {
  display: flex;
  padding-left: 20px;

  h6 {
    margin: 0px 5px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &.active {
      font-weight: 700;
      border-bottom: 2px solid #050505;
    }
  }
}

.snackCard .snackCard-details {
  text-align: start;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  border: 1px solid #dfe2eb !important;
  padding: 3px;
  background-color: #c8c8c8;
  opacity: 0.2;
}

.snackCard-cards p {
  margin-bottom: 0rem;
}

// component Name: SnackCard
// path - a1Components/SnackCard/index.tsx
// scss end

.portalHeader {
  padding: 10px 0px 0px;
  &.shrink {
    height: 170px;
  }
}

.logoCol {
  width: 6.5% !important;
  z-index: 999;
}
.navCol {
  width: 5.5% !important;
}

.portalHeader-rightOpen {
  padding: 25px 0px;
}

.temp .ui.button {
  position: relative;
  top: 60px;
  right: 100px;
  min-width: 24vw;
  height: 4vh;
}

// Button css
.btn {
  border: 1.5px solid #3c88f7 !important;
  border-radius: 20px !important;
  color: #000 !important;
  font-size: 10px !important;
  line-height: 12px;
  padding: 0.25rem 0.75rem !important;
  font-weight: 500 !important;

  &.active {
    color: #fff !important;
    background-color: #038aff !important;
  }
}


.btn_legends {
  border: 1.5px solid rgb(255 255 255 / 0%);
  border-radius: 20px;
  color: #000;
  font-size: 10px;
  line-height: 12px;
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  background-color: white;

  // &:hover {
  //   color: #fff !important;
  //   background-color: #038aff !important;
  //   border: 1.5px solid #038aff !important;
  // }
  .font_style{
    margin-right: 5px ;
   
  }
}

.btnAnovi {
  border: 1px solid #3c88f7 !important;
  border-radius: 4px !important;
  color: #000 !important;
  font-size: 10px !important;
  line-height: 12px;
  padding: 0.25rem 0.75rem !important;
  font-weight: 500 !important;
  background-color: white;
  // min-width: 11vw;
  min-width: 13vw;
  height: 4vh;

  &.active {
    color: #fff !important;
    background-color: #038aff !important;
  }
}

@media only screen and (max-width: 1199px) {
  .pr-0 {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .xs-col-reverse {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .pageFixScss .pageFixScssRight {
    width: 97vw;
  }
  .pageFixScss {
    margin: 0px 0px 0px 5px;
  }
  .header-badge {
    width: 210px;
    margin-left: 5px;
    border: 1px solid;
    box-shadow: 0px 3px 6px #00000029;
    padding: 5px;
    width: 197px;
    margin-left: 20px;
    h4 {
      font-size: 10px;
      font-weight: 400 !important;
    }
    p {
      color: #4caf50;
      font-size: 13px;
      font-weight: 400;
      padding: 0px 5px 0px 10px;
    }
    .faTrophy {
      font-size: 10px;
    }
  }

  .searchbar {
    margin-bottom: 17px;
  }

  .page-Layout {
    padding: 0px 5px 0px !important;
    height: calc(100vh - 300px);
  }

  .footer-Style {
    position: absolute;
    left: 0px;
    height: 30px;
  }

  .portalHeader {
    min-height: 150px;
  }

  .header-row {
    .btnRow {
      position: absolute;
      top: 110px;
      width: 100%;
      right: 10px;
      &.btnRow-click {
        width: auto;
        padding-left: 0;
        position: absolute;
        top: 120px;
        margin-left: 0px;
        left: 8.5%;
        margin-top: 0px;
      }
    }
  }

  .topRow {
    &-list {
      ul {
        display: flex;
        margin: 0px;
        padding: 0px;
        width: 80%;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          height: 2.5vh;
        }
      }
    }
  }
  .searchbar {
    width: 70%;
    position: relative;
    left: 15px;
  }

  .profileName p {
    margin-right: 14px;
    width: 5vw;
  }

  .chatCta {
    position: relative;
    right: 14px;
  }

  .toggleTheme {
    position: relative;
    right: 12px;
  }

  .profileDp:hover .dropdown-content {
    display: block;
    width: 90px;
  }

  .navBar-menu {
    width: 100%;
    height: 9vh;
    position: absolute;
    bottom: 2px;
    left: 0px;
    z-index: 999;
    padding: 5px;
    background: #f5f6fa 0% 0% no-repeat padding-box;
    border-radius: 14px 14px 0px 0px;
    opacity: 1;
    overflow: visible;
  }

  .navBar-menu ul {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    .logout_icon {
      display: none;
    }
  }

  .toggle-FontAwesome {
    display: none;
  }

  .breadCrumbsFilter {
    min-height: 5vh;
  }

  .hrBarWrapper.noHeight .viewAll {
    left: 580px;
  }

  .btnAnovi {
    border: 1px solid #3c88f7 !important;
    border-radius: 4px !important;
    color: #000 !important;
    font-size: 10px !important;
    line-height: 12px;
    padding: 0.25rem 0.75rem !important;
    font-weight: 500 !important;
    background-color: white;
    // min-width: 11vw;
    min-width: 20vw;
    height: 4vh;

    &.active {
      color: #fff !important;
      background-color: #038aff !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .btnAnovi {
    border: 1px solid #3c88f7 !important;
    border-radius: 4px !important;
    color: #000 !important;
    font-size: 10px !important;
    line-height: 12px;
    padding: 0.25rem 0.75rem !important;
    font-weight: 500 !important;
    background-color: white;
    // min-width: 11vw;
    min-width: 78vw;
    height: 6vh;

    &.active {
      color: #fff !important;
      background-color: #038aff !important;
    }
  }

  .breadCrumbsFilter {
    left: 5px !important;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 900px) {
  .scoreCard {
    flex-wrap: wrap;
    [class*="col-"] {
      flex: 0 0 auto;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: 768px) {
  @keyframes expand {
    from {
      width: 0%;
    }

    to {
      width: calc(100% - 75px);
    }
  }

  .block {
    font-size: 1em;
  }

  .legend {
    width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .profileName {
    border: none;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    img {
    }

    p {
      margin-right: 12px;
      width: 12vw;
    }
  }
  .scoreCard {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    &-item {
      margin: 0px 0px 5px;
    }
  }
  .topRow {
    &-list {
      ul {
        display: flex;
        margin: 0px;
        padding: 0px;
        width: 81%;
        max-height: 13vh;
        flex-direction: row;
        overflow: scroll;
        flex-wrap: wrap;
      }
    }
  }

  .radio-input-warpper {
    label {
      font-size: 12px;
    }
  }

  .mobileDrop {
    display: block !important;

    .mobileDropSelect {
      background: #0f43f9 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      padding: 10px;
      border: none;
    }
  }

  .tabTitleBox {
    display: none;
    width: 100%;

    &-wrapper {
      padding: 30px 5px;
      overflow: scroll;
      display: none;
    }

    &.active {
      display: block;
    }

    h2 {
      justify-content: flex-start;
    }

    h4 {
      font-size: 12px !important;
    }

    h5 {
      font-size: 14px !important;
    }
  }

  .rangePart {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row-reverse;
    padding: 10px 0px;

    > div {
      width: 60%;
    }

    input[type="range"] {
      top: -6px;
    }
  }

  .thresoldChange {
    p {
      font-size: 10px !important;
    }
  }

  .xs-tab {
    display: flex;

    &-cta {
      right: 5px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .reactToolTip {
    display: none !important;
  }
  .header-badge {
    width: 60vw !important;
  }
  .pageFixScss {
    margin: 0px 0px 0px 0px;
  }
  .align-items-start .header-right-cta {
    // position: absolute;
    right: 14px;
  }

  // .searchbar {
  //   position: absolute;
  //   top: 35px;
  //   right: 79px;
  // }

  .searchbar {
    position: absolute;
    top: 23px;
    right: 42px;
    //right: 55px; ToDo
  }

  .header-row .searchAndDate {
    // display: flex;
    // position: absolute;
    // justify-content: space-around;
    // flex-direction: column;

    // margin-left: -20%;
    // width: 29vw;
  }

  .btnRow {
    display: flex;
    position: relative;
    left: 37px;

    .btn:first-child + .btn,
    .btn:first-child + .btn + .btn {
      display: none;
    }

    .a1DatePicker {
      display: block;

      .a1DatePicker-button .btn {
        display: block;
      }
    }
  }

  .dropdown-content a {
    display: none;
  }

  .emissions {
    background: #f3faf7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 24px 20px;

    h2 {
      color: #16a085;
    }

    .faarrowrightlong {
      display: none;
    }

    .emissions-data-left {
      margin-top: 15px;
      padding-left: 0px;
    }

    .emissions-data-left h2 {
      font-size: 2.4rem !important;
    }

    .emissions-data-left p {
      display: flex !important;
    }

    .emissions-data-right {
      margin-left: 2px;
      padding-top: 20px;
    }

    .emissions-data-right h2 p {
      display: flex;
      flex-direction: row;
    }

    .nav-link {
      padding: 0px;
    }

    .nav-link p {
      font-size: 1rem;
      font-weight: 400;
      color: #2699fb;
    }
  }

  .navBar-menu {
    width: 100vw;
    height: 7vh;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 9999;
    padding: 0px;
    background: #f5f6fa 0% 0% no-repeat padding-box;
    border-radius: 14px 14px 0px 0px;
    opacity: 1;
    overflow: visible;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      li {
        // height: 6vh;
        // width: 17vw;
        // &.active,
        // &:hover,
        // &:first-child {
        //   a {
        //     background: transparent linear-gradient(1deg, #021B79 0%, #0575E6 100%) 0% 0% no-repeat padding-box;
        //     border-radius: 10px;
        //     color: #fff;
        //     height: 7vh;
        //     width: 7vh;
        //     position: relative;
        //     bottom: 20px;
        //     z-index: 9999999;
        //   }
        // }

        // a {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   width: 45px;
        //   height: 45px;
        //   padding: 5px;
        //   margin-bottom: 30px;

        svg {
          font-size: 25px;
        }
        // }

        img {
          color: #fff;
          width: 25px;
        }
      }
      .logout_icon {
        margin-top: 10px;
        display: block;
      }
    }
    a.active {
      background-color: #1144ff;
      border-radius: 10px;
      color: #ffffff;
      position: relative;
      bottom: 20px;
      z-index: 9999999;
    }

    a:hover {
      background-color: #ffffff;
      border-radius: 10px;
      color: black;
    }
  }

  .hrBarWrapper.noHeight .viewAll {
    // display: flex;
    // flex-direction: row;
    // bottom: 0px;
    // margin-bottom: 20px;
    // padding-right: 20px;
    // position: absolute;
    left: 179px;
  }

  .cardWrapper-footer p {
    padding: 2px;
    font-size: 12px;
    margin-bottom: 0rem !important;
  }

  // component Name: CardWrapper
  // path - a1Components/PortalHeader/index.tsx
  // scss end
  .tileIcon-cmp {
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 0px 5px 0px;
    border-bottom: none;
    border-right: none;
    padding: 5%;
    &:not(:last-child):after {
      background-color: #db732e;
      content: "";
      width: 75%;
      height: 2px;
      position: absolute;
      bottom: 0px;
    }
    .tileIcon-cmp-img {
      width: 15%;
    }

    img {
      top: 4px;
      left: -10px;
    }

    &:nth-child(2n) {
      margin: 0 2px;
      border-right: 0px solid #db732e;
    }

    &:nth-child(3n) {
      border-bottom: 0px solid #db732e;
    }
  }

  .snackCardCss .snackCar-content {
    display: none;
  }
  .snackCard {
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
  }

  .snackCard .column {
    padding: 2px 2px;
    .horizontalData {
      background-color: #f3faf7;
      padding: 20px 10px;
      ul {
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      li {
        padding: 4px 0px;
        height: 28px;
        margin-bottom: 10px;
      }

      svg {
        font-size: 12px;
      }

      h6 {
        line-height: 22px;
      }
    }

    .graphic {
      padding-left: 30px;
    }
  }

  @keyframes expand {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .portalHeader {
    padding: 14px 0px 20px 0px !important;
    width: 100%; //ToDo
  }

  .tabClass-button {
    overflow: scroll;
  }
  /*.page-Layout start*/

  .page-Layout {
    padding: 0px 0px 20% !important;
    height: calc(97vh - 122px);
    z-index: 1;
  }
  .footer-Style {
    position: fixed;
    bottom: 0px;
    width: 91%;
    padding: 0px;
    background-color: #e1e3f0;
    height: 5vh;
    z-index: 999;
    display: none;
    div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      p {
        font-size: 0.8rem;
        margin: 8px;
      }
    }
  }
  /*footer end*/
  .hrBarWrapper:not(.noHeight) {
    height: 275px;
  }

  /*component name : Header
Header
componrnt start
*/
  .header-badge {
    border: 1px solid;
    box-shadow: 0px 3px 6px #00000029;
    padding: 3px 0px 0px 12px;
    margin-left: 0px;

    h4 {
      font-size: 10px;
      font-weight: 400 !important;
    }

    p {
      color: #4caf50;
      font-size: 12px;
      font-weight: 400;
      padding: 0px 5px 0px 10px;
    }

    .faTrophy {
      font-size: 10px;
    }
  }
  .logoCol {
    display: block;
  }
  .header-right-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -7px;
  }

  .header-badges {
    flex: 0 0 auto;
  }

  .chatCta {
    position: absolute;
    z-index: 98;
    bottom: 90px;
    right: 40px;
    width: 40px;
    height: 40px;
  }

  .profileDp img {
    display: none;
    font-size: 10px;
  }
  .header-row .searchAndDate {
    height: 6vh;
    width: 75%;
  }

  .profileDp:hover .dropdown-content {
    display: none;
  }

  .profileDp {
    border-radius: 5px;
    margin-left: 0px;
  }

  //component Name: Searchbar
  // path - a1Components/SideNavBar/index.tsx
  // scss start
  .header-row {
    display: flex;
    height: 7vh;
  }
  .search-buttons .btns {
    position: relative;
    top: 50px;
    right: 65px;
    min-width: 23vw;
    height: 3.4vh;
  }
  .faSearch {
    font-size: 20px !important;
  }
  .searchbar {
    .form-control {
      display: none;
    }

    // .form-control
    .hover-according {
      display: none !important;
    }
  }

  .searchbar {
    .form-control {
      display: none;
    }

    .form-control svg {
      left: 40px;
    }

    .searchbar:hover .hover-according {
      display: none;
    }
  }

  .email-input {
    display: inline-block;
    height: 46px;
    position: absolute;
    width: 100%;
    font-size: 15px;
    color: #666666;
    text-indent: 8px;
    border: 1px solid #2d2d2d;
    box-shadow: 10px 8px 8px rgb(0 0 0 / 3%);
    top: -36px;
    width: 70vw;
    left: -122px;
    border-radius: 8px;
    border: none;
    font-size: 21px;
  }

  .search-box {
    position: relative;
    top: 71px;
    z-index: 99;
    left: 51px;
    box-shadow: 12px -150px 2px 202px rgb(148 139 139 / 40%); // box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    // right: 71px;
    // padding-right: 20px;
  }

  .search-box span {
    position: absolute;
    /* right: -29em; */
    top: -35px;
    bottom: 0;
    left: 3.8em;
    line-height: 40px;
    z-index: 999999999 !important;
    font-size: 30px;
  }

  //component Name: Searchbar
  // path - a1Components/SideNavBar/index.tsx
  // scss end

  // datepicker start
  .SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
    margin-left: -192px;
  }

  .DateRangePicker {
    position: relative;
    display: inline-block;
    width: 50%;
    padding: 8px;
  }

  .DateRangePickerInput__withBorder {
    display: flex;
    flex-direction: row;
    width: 79%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid blue;
  }

  .DateRangePicker {
    position: relative;
    display: inline-block;
    width: 228px;
    padding: 8px;
  }

  .DateInput_input {
    font-size: 13px;
    height: 30px;
  }

  // datepicker end

  /*component name : Emission
path: a1Components/E-Charts/EmissionsComponent
componrnt start
*/
  .emissions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 24px 20px;
    h2 {
      color: #16a085;
    }

    .emissions-sub {
      font-size: "0.4";
    }

    .faarrowrightlong {
      display: none;
    }

    .emissions-data-left {
      margin-top: 15px;
      padding-left: 0px;
    }

    .emissions-data-left h2 {
      font-size: 2.4rem !important;
    }

    .emissions-data-left p {
      display: flex !important;
    }

    .emissions-data-right {
      margin-left: 2px;
      padding-top: 20px;
    }

    .emissions-data-right h2 p {
      display: flex;
      flex-direction: row;
    }

    .nav-link {
      padding: 0px;
    }

    .nav-link p {
      font-size: 1rem;
      font-weight: 400;
      color: #2699fb;
    }
  }

  /*component name : Emission
path: a1Components/E-Charts/EmissionsComponent
componrnt end
*/

  .range_date {
    z-index: 999;
    position: absolute;
    top: 0vh !important;
    margin-left: 0px !important;
    right: 0vw !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }

  .bgWhiteDash {
    background-color: white;
    padding: 0vmax;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: stretch;
    padding-left: 43%;
  }

  .range_date_Dash {
    //   width: 100%;
    z-index: 1000;
    position: absolute;
    top: 6vh !important;
    margin-left: 0px !important;
    right: -58vw !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }

  .range_date_Dash div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .range_date_Dash .DateRangePicker div {
    font-size: 12px;
    width: 5px !important;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }

  .bgWhiteDash button {
    margin-left: 0%;
  }

  .clr {
    height: 4vh !important;
  }

  /* Header buttons in mobile view start*/
  .temp .btn {
    width: auto;
    margin: 4px;
    // height: 40px;
    display: inline-block;
    color: #000;
    border-color: blue;
    background: transparent;
    padding: 1px 16px;
  }

  .btns .buttons {
    display: none;
  }

  .toggle-FontAwesome {
    display: none;
  }

  .atsuyaLogo {
    display: flex;
    height: 2rem;
    width: 2rem;

    img {
      display: block;

      width: 15px;
    }
  }

  /*Header toggleTheme  view start*/
  .toggleTheme {
    position: relative;
    color: blue;
    top: 20px;
    left: 30px;
    display: none;
  }

  .page-Layout:hover {
    overflow-y: scroll;
  }
}

@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("LiquidCrystal"),
    url("./assets/fonts/LiquidCrystal-Normal.woff") format("truetype");
}

.passwordPopup{
     .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto;

        min-width: 50vw;
    }
}