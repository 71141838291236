.SearchInput {
  margin-bottom: 5%;
  input[type="text"] {
    border: none;
    border-bottom: 1px solid black;
    background: transparent;
  }
  input[type="text"]:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid black;
  }
}
