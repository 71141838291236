.eventSummaryMainCont {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px 20px;

  .totalEventMainCont {
    display: flex;
    flex-direction: column;

    .totalEventText {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(112, 112, 112, 1);
      margin: 0;
    }

    .totalEventDetailsDiv {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      gap: 6px;

      .totalEventCount {
        font-size: 36px;
        font-weight: 700;
        color: rgba(255, 0, 0, 1);
        margin: 0;
      }

      .statsDataCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .totalEventTimeStamp {
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          margin: 0;
        }
      }
    }
  }

  .totalEventsOverHeadingDiv {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;

    .totalEventsOverHeadingText {
      font-size: 20px;
      font-weight: 600;
      color: rgba(30, 30, 30, 1);
      margin: 0;
    }

    .refreshDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      padding: 2px 4px 3px 2px;
      border-radius: 3px;
      border: 1.5px solid rgba(55, 77, 198, 1);
      cursor: pointer;

      .refreshText {
        font-size: 16px;
        font-weight: 500;
        color: rgba(30, 30, 30, 1);
        margin: 0;
      }

      .refreshIcon {
        width: 15px;
        height: 15px;
      }

    }

  }

  .trendCartSettingMainCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dateRagneFrequenciesDiv {
      display: flex;
      flex-direction: column;
      position: relative;

      .dropDownView {
        border: 1px solid rgba(80, 56, 245, 1);
        padding: 2px 8px 2px 8px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background-color: rgba(240, 243, 252, 1);
        gap: 8px;
        cursor: pointer;

        .dropDownText {
          margin: 0px;
          font-size: 12px;
          font-weight: 500;
        }

        .arrowIcon {
          height: 10px;
          width: 10px;
        }
      }

      .dateRagneFrequenciesMenu {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(189, 203, 251, 1);
        margin: 4px 0px;
        max-height: 20vh;
        z-index: 1;
        overflow-y: scroll;
        border-radius: 4px;
        background-color: #fff;
        position: absolute;
        top: 25px;

        .dateRagneFrequenciesButton {
          display: flex;
          flex-direction: row;
          padding: 2px 4px;
          cursor: pointer;

          .dateRagneFrequenciesText {
            margin: 0;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    .trendChartSettingCont {
      display: flex;
      flex-direction: row;
      height: 4vh;
      justify-content: center;
      align-items: center;
    }
  }
}

.durationIcon{
  cursor: default;
}