.file-input-container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  @media only screen and (max-width: 767px) {
    width: fit-content;
  }

  @media only screen and (min-width: 767px) and (max-width: 1023px) {
    width: fit-content;
  }
}

.file-input-button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.file-input-image {
  width: 4.733rem;
  height: 4.733rem;
  border: 1.64px solid #bdcbfb;
  border-radius: 8px;
  object-fit: cover;
}

input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.cancel-image {
  width: 1.208rem;
  height: 1.208rem;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -7px;
  margin-top: -7px;
}

.image {
  border: 1.64px solid #bdcbfb;
  border-radius: 8px;
  padding: 24px;
  width: 100px;
  height: 100px;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    margin-bottom: 8px;
  }
}
