.emailSetPurposeCont {
    border: 1px solid #bdcbfb;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 20vw;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    left: 20%;
    top: 10%;
    z-index: 12;
    .setPurposeCloseCont {
      display: flex;
      justify-content: flex-end;
      height: fit-content;
      align-items: center;
      padding:8px 5px 8px 5px;
      img {
        height: 10px;
        width: 20px;
        cursor: pointer;
      }
    }
    .setPurposeDetailsCont {
      padding-left: 8px;
      .setPurposeDetails {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        padding-bottom: 5px;
        .setPurposeCheckbox {
          border-color: #275efe !important;
        }
        p {
          font-size: 12px;
          margin: 0;
          font-weight: 500;
        }
      }
    }
    .setPurposeDetailsCont::-webkit-scrollbar {
      display: none;
    }
  }
.checkboxTxt {
  cursor: pointer;
}