.filterMainModal{
  
.filterHeader {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headerTitle {
    color: #1E1E1E;
    font-family: Inter, serif !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 1.55rem;
  }

  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
    &:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
}

.filterBody {
  margin-top: 0;
  background: #E1E3F0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  .partition {
    padding-top: 0.87rem;
    display: flex;
    border-radius: 0.125rem;

    .filterKeyDiv {
      width: 33%;
      border-radius: 0.15rem;
      background: #FFF;
      margin-left: 0.87rem;
      margin-bottom: 0.87rem;
      padding: 0.5rem;

      .filterKey {
        padding: 0.55rem 1.5rem;
        cursor: pointer;
        transition: background-color 0.2s ease;
        font-size: 0.9rem;
        color: #000;
        font-family: Inter, serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:hover {
          background-color: #F5F6FA;
        }

        &.activeFilter {
          background-color: #E1E3F0;
        }
      }
    }

    .filterValuesDiv {
      width: 66%;
      margin-right: 0.87rem;
      padding: 0.7rem 0.7rem 0.8rem 2rem;
      .filterValuesChildDiv {
        height: 20rem;
        overflow-y: auto;
      }
      .footerButtons{
        display: flex;
        .buttonGroup{
          margin-left: auto;
        }
        .clearAll{
          padding: 0.3rem 1rem;
          border-radius: 0.20915rem;
          border: 1.094px solid #273250;
          background: #FFF;
          color: #060606;
          font-family: Inter, serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .apply{
          padding: 0.3rem 1.2rem;
          border-radius: 0.20915rem;
          border: 1.094px solid #055CC7;
          background: #055CC7;
          color: #FFF;
          font-family: Inter, serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 0.55rem;
        }
      }
      .footerButtons .clearAll:disabled, .footerButtons .apply:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

      .filterValue {
        color: #275EFE;
        display: flex;
        align-items: center;
        padding-bottom: 0.6rem;
        width: max-content;
        cursor: pointer;

        .valueString {
          color: #000;
          font-family: Inter, serif;
          font-size: 1.0942rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-left: 0.5rem;
        }
      }
    }

    .filterSearch {
      border: none;
      background: transparent;
      border-bottom: 1px solid #9A9A9A;
      color: #707070;
      font-family: Inter, serif;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.8rem;
    }

    .filterSearch:focus {
      outline: none;
      border-bottom: 1px solid black;
    }
  }

}
}

.filterMainModal{
      .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto;

        min-width: 50vw;
    }
}
