// .snackCard{
//     box-sizing: border-box;
//     margin-top: 49px;
//     margin-left: 40px;
//     margin-right: 20px;
// }
// .snackCard .column {
//   float: left;
//   width: 50%;
//   padding: 2px 4px;
  
 
// }
// .snackCard .snackCard-details {
//   padding: 3px;
//   text-align: start;
//   background-color: #FFFFFF;
//   display: flex;
//   flex-direction: row;
//   // justify-content: center;
//   border: 1px solid #DFE2EB !important;
//   flex-wrap: wrap;
//   align-content: stretch;
//   align-items: stretch;
//   }
//   .snackCard-cards p {
//     margin-bottom: 0rem;
//     font-size: 13px;
    
//   }
// .snackCard-img{
//   padding: 8px 0px;
  
  
// }

// .snackCard-cards{padding-left: 1vw;
// }
// @media only screen and (max-width: 600px) {
//   .snackCard{
//     box-sizing: border-box;
//     margin-top: 2px;
//     margin-left: 0px;
//     margin-right: 0px;
    
// }
// .snackCard .column {
//   // float: left;
//   width: 50%;
//   padding: 2px 4px;
  
 
// }
// .snackCard .snackCard-details {
//   text-align: start;
//   background-color: #FFFFFF;
//   display: flex;
//   flex-direction: row;
//   border: 1px solid #DFE2EB !important;
//   padding: 3px ;
//   }
  
// .snackCard-cards p {
//   margin-bottom: 0rem;
//   }
// .snackCard-img{
//   padding: 8px;
//   // padding-right: 15px;
// }
// }

.disable {
    .snackCard {
        &-details {
            filter: grayscale(100%);
            background-color: #F0F3FF !important;
        }
        &-cards {
            color: #ADB4D1 !important;   
        }
    }
}
.snackchart-card {
    margin-bottom: 38px;
}