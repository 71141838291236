.errorpage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 210px);
  background-color: rgb(13 110 253 / 25%);
}

.img-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.img {
  width: 40%;
  height: 40vh;
}

.wrongpage-img {
  width: 50%;
  height: 30vh;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text {
  font-size: 40px;
  font-family: "Roboto";
  color: black;
}

.paragraph {
  font-size: 20px;
  font-family: "Roboto";
  color: black;
}

.support {
  font-size: 15px;
  font-family: "Roboto";
  padding-top: 45px;
  a {
    text-decoration: none;
  }
}

.gobackAnchorclass {
  font-size: 25px;
  color: black;
  font-weight: 700;
}

.homepageAnchorclass {
  font-size: 25px;
  color: black;
  font-weight: 700;
}

.span {
  font-size: 25px;
  font-family: "Roboto";
  color: black;
}

@media only screen and (max-width: 600px) {
  .errorpage-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 100vh;
    width: 100vw;
    .text-center {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: start !important;
      .imgClass {
        margin-left: 30%;
        height: 50%;
        width: 60%;
      }
    }
    .text-Content {
      flex-direction: column;
      justify-content: left;
      align-items: center;

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
      }
      p {
        font-size: 1.3rem;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container-fluid .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img-container {
    display: flex;
    flex-direction: column;
  }

  .wrongpage-img {
    width: 70%;
    height: 40vh;
    margin-left: 50px;
    margin-bottom: 36px;
  }

  .img {
    width: 300px;
    height: 197px;
    margin-left: 38px;
    margin-bottom: 90px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 0px 0px 12px;
  }

  .text {
    font-size: 30px;
    font-family: "Roboto";
    color: black;
  }

  .paragraph {
    font-size: 20px;
    font-family: "Roboto";
    color: black;
  }

  .gobackAnchorclass {
    font-size: 20px;
    color: black;
    font-weight: 700;
  }

  .homepageAnchorclass {
    font-size: 20px;
    color: black;
    font-weight: 700;
  }

  .span {
    font-size: 20px;
    font-family: "Roboto";
    color: black;
  }
}

// @media only screen and (max-width: 1024px){
//     .container-fluid .row {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center !important;
//     }

//     .container-fluid .row .text-center .imgClass{
//         margin-left: 12%;
//         height: 104%;
//         width: 74%;
//         margin-top: -5vh;
//     }

//     // .container-fluid .row .text-Content h1{
//     //     font-size: 3rem;
//     //     font-weight: bold;
//     //     margin-left: 102px;
//     // }
// }
