.filterHeadingDiv {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    padding: 2px 0 2px 5px;

    .dropdownIcon {
        width: 12px;
        height: 12px;
        padding: 1px;
    }

    .filterHeadingText {
        font-size: 14px;
        font-weight: 600;
        color: rgba(27, 27, 27, 1);
        margin: 0;
    }
}

.rotate-90 {
    transform: rotate(-90deg);
    transition: transform 0.1s ease-in-out;
}

.rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.1s ease-in-out;
}