.cardWrapperOutlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal-wrapper-element {
  background-color: white !important;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 60vw !important;
  min-height: 30vh;
  /* height: 50vh; */
  border: 1.3px solid #3793ff;
  border-radius: 20px;
  margin: 0 auto 0;
  transition: all 0.8s;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  top: 20%;
  left: auto !important;
  right: auto;
  bottom: auto;
  position: relative;

  .modal-header-style {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff !important;
    padding: 6px;

    .details {
      display: flex;
      align-items: start;
      margin-top: 10px;
      margin-left: 16px;

      img {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 3px;
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .close-modal-btn {
      color: rgb(0, 0, 0);
      cursor: pointer;
      float: right;
      font-size: 30px;
      margin: 0;
      margin-right: 2%;
    }

    .close-modal-btn:hover {
      color: rgb(167, 0, 0);
    }
  }

  .modal-bodyD-comp {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    //height: 20vh !important;
    align-items: center;

    p {
      margin-top: 0;
      margin-bottom: 0rem;
    }

    .leftCnt {
      .inputBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 13px;
        width: 24vw;

        .inputBox-label {
          input[type="radio"i] {
            margin-right: 10px;
            accent-color: #3c88f7;
          }

          input[type="radio"]:after {
            background-color: #426bfa;
          }
        }
      }
    }

    .rightCnt {
      padding: 3%;
      margin-left: 0%;

      .inputBox {
        .selectCustomDate {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          label {
            font-size: 15px;
            font-weight: 300;
          }

          input {
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 10px;
            border: 1px solid #eff2ff;
          }
        }

        .typeDownload {
          label {
            margin-right: 20px;
            margin-top: 11px;
          }
        }
      }
    }
  }

  .inputTextSpace {
    padding: 10px;
    margin-left: 16px;

    .inputBox {
      input {
        width: 90%;
      }
    }
  }

  .modal-footer {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 10vh;
    padding: 0px;
    position: sticky;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    border-top: 0px solid #dee2e6;
    padding-right: 32px;
    align-items: center;
    .email-Report {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 3%;
      img {
        margin-left: 5px;
        height: 20px;
        width: 20px;
      }
      h1 {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-top: 10%;
        margin-left: 10px;
      }
    }
    button {
      img {
        color: white;
        margin-right: 13px;
        height: 14px;
        filter: invert(100%) contrast(200%) brightness(100%);
      }
    }

    .btn-Action {
      width: 9rem;
      border-radius: 2px !important;
      padding: 0px;
      background-color: #0075ff !important;
      color: #5711e2;
      background-color: none !important;
      color: white !important;
      border: 1px solid #5711e2;
      content: "▼" !important;
      margin-left: 20px;
    }

    .btn-Submit {
      background-color: #fcf2da;
      color: #000000;
      border: 1px solid #70707052;
    }
  }

  .btn-cancel {
    background-color: #b71c1c;
    float: left;
  }

  .btn-continue {
    background-color: #1b5e20;
    float: right;
  }
}
@media only screen and (max-width: 600px) {
  .buttonState {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20vw !important;
    button {
      margin: 0;
    }
  }
  .modal-wrapper {
    background-color: white !important;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    width: 100vw !important;
    height: 100vh !important;
    // max-height: 68vh !important;
    border: 1.3px solid #3793ff;
    border-radius: 20px;
    margin: 0 auto 0;
    transition: all 0.8s;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    top: 0% !important;
    left: 0% !important;
    right: 0px !important;
    bottom: 0% !important;
    position: fixed;
    // top: auto;
    // left: auto;
    // right: auto;
    // bottom: auto;

    .modal-bodyD-comp {
      text-align: start !important;
      padding: 0 !important;
      height: 18vh !important;
      flex-direction: column !important;
      margin-bottom: 16px;
      justify-content: flex-start;
      align-items: center;
      .leftCnt {
        .inputBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          width: 84vw;

          .inputBox-label {
            input[type="radio"i] {
              margin-right: 10px;
              accent-color: #3c88f7;
            }

            input[type="radio"]:after {
              background-color: #426bfa;
            }
          }
        }
      }
      .rightCnt {
        // padding: 32px 0px 1px 0px;
        padding: 0;
        margin-left: 0%;
        margin-right: 2vh;

        .inputBox {
          .selectCustomDate {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 5px;

            label {
              font-size: 15px;
              font-weight: 300;
            }

            input {
              font-size: 15px;
              font-weight: 300;
              margin-bottom: 10px;
              border: 1px solid #eff2ff;
            }
          }

          .typeDownload {
            label {
              margin-right: 20px;
              margin-top: 11px;
            }
          }
        }
      }
    }
  }

  .modal-wrapper .modal-footer .btn-Action {
    width: 9rem;
    border-radius: 2px !important;
    padding: 0px;
    background-color: #674aff !important;
    color: #5711e2;
    background-color: none !important;
    color: white !important;
    border: 1px solid #5711e2;
    content: "▼" !important;
    margin-right: 25px;
    height: 36px;
  }

  .modal-wrapper .modal-header-style {
    display: flex;
    justify-content: space-between;
    height: 10vh;
    align-items: center;
    padding: 0px !important;
    background-color: #ffffff !important;
  }

  .modal-wrapper .modal-footer .btn-Submit {
    background-color: #fcf2da;
    color: #000000;
    border: 1px solid #70707052;
    border-radius: 2px;
    margin-top: 2vh;
    margin-right: 40px !important;
    width: 37vw !important;
    padding: 5px !important;
    margin-bottom: 15px;
  }

  .modal-wrapper .modal-footer {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 54px;
    padding: 0px;
    position: relative;
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top: 0px solid #dee2e6;
    padding-right: 32px;
    margin-bottom: 11vh;
    // height: 65vh !important;
  }
  .inputStylescss {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    padding: 20px 0px 0px 0px !important;
    div {
      h4 {
        font-size: 13px;
        color: #000000;
        font-weight: 300;
      }
      .styleInp {
        border: 2px solid #a7b8f4;
        padding: 5px;
        width: 36vw !important;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
        height: 5vh;
        align-items: center;
        input {
          width: 13vw;
          border-right: 1px solid #a7b8f4;
          border-left: 0;
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
  }

  .inputDateRange {
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3px;
    width: 79vw !important;
    padding: 11px !important;
    padding-left: 5px;
    padding-right: 5px;
    div {
      p {
        font-size: 14px;
        color: #000000;
      }
    }
  }

  .selectCustomDate {
    background-color: #ffffff;
    padding: 5px;
    position: absolute;
    z-index: 999;
    margin-bottom: 16vh;
    left: 20px !important;
    .rdrDateRangePickerWrapper {
      margin: 0;
      .rdrDefinedRangesWrapper {
        display: none;
      }
      .rdrCalendarWrapper {
        z-index: 99999999;
      }
    }
    .bottonButtonsDiv {
      // position: fixed !important;
      left: 54% !important;
      .applyButton {
        background-color: transparent;
        border-width: 2px;
        border: 1px solid #038aff;
        border-radius: 30px;
        font-size: 12px;
        padding: 3px;
        padding-left: 15px;
        padding-right: 15px;
        font-weight: 550;
        outline: none;
        z-index: 9999;
      }
      .cancelButton {
        margin-right: 2vw;
        font-weight: 550;
        font-size: 12px;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 5px;
        z-index: 9999;
      }
    }
  }
}

//media for tab responsive
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .modal-wrapper {
    background-color: #ffffff !important;
    padding: 0 !important;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    width: 75% !important;
    /* height: 50vh; */
    border: 1.3px solid #3793ff;
    border-radius: 20px;
    margin: 0 auto 0;
    transition: all 0.8s;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    opacity: 1;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;

    .modal-bodyD-comp {
      text-align: start;
      height: 11vh !important;
      display: flex !important;
      flex-direction: row !important;
      // padding: 21px 0px 0px 14px;
      padding: 0 !important;
      margin-bottom: 16px;
      justify-content: center;
      align-items: center;
      .leftCnt {
        height: 40%;
        .inputBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          width: 41vw;

          .inputBox-label {
            input[type="radio"i] {
              margin-right: 10px;
              accent-color: #3c88f7;
            }

            input[type="radio"]:after {
              background-color: #426bfa;
            }
          }
        }
      }
      .rightCnt {
        padding: 0%;
        margin-left: 0%;

        .inputBox {
          .selectCustomDate {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 5px;

            label {
              font-size: 15px;
              font-weight: 300;
            }

            input {
              font-size: 15px;
              font-weight: 300;
              margin-bottom: 10px;
              border: 1px solid #eff2ff;
            }
          }

          .typeDownload {
            label {
              margin-right: 20px;
              margin-top: 11px;
            }
          }
        }
      }
    }

    .modal-footer {
      background: #ffffff 0% 0% no-repeat padding-box;
      height: 10vh;
      padding: 0px;
      position: sticky;
      display: flex !important;
      border-top: 0px solid #dee2e6;
      padding-right: 19px !important;

      button {
        img {
          color: white;
          margin-right: 13px;
          height: 14px;
          filter: invert(100%) contrast(200%) brightness(100%);
        }
      }

      .btn-Action {
        width: 9rem;
        border-radius: 2px !important;
        padding: 0px;
        background-color: #0075ff !important;
        color: #5711e2;
        background-color: none !important;
        color: white !important;
        border: 1px solid #5711e2;
        content: "▼" !important;
        margin-left: 20px;
      }

      .btn-Submit {
        background-color: #fcf2da;
        color: #000000;
        border: 1px solid #70707052;
      }
    }
  }

  .modal-wrapper .modal-header-style {
    display: flex;
    justify-content: space-between;
    height: 5vh;
    padding: 6px;
    margin: 0 !important;
    background-color: #ffffff !important;
  }
  .inputStylescss {
    display: flex;
    align-items: center;
    justify-content: space-around !important;
    padding: 20px 0px 0px 0px !important;
    div {
      h4 {
        font-size: 16px !important;
        font-weight: 600 !important;
      }
      .styleInp {
        border: 2px solid #a7b8f4;
        padding: 5px;
        width: 25vw !important;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
        height: 3.4vh !important;
        align-items: center;
        input {
          width: 13vw;
          border-right: 1px solid #a7b8f4;
          border-left: 0;
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
  }
  .selectCustomDate {
    margin-left: -6vw;
  }
  .inputDateRange {
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3px;
    width: 27vw !important;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    div {
      p {
        font-size: 14px;
        color: #000000;
      }
    }
  }
}
//end tab responsive

.inputStylescss {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  padding: 0px 18px 10px 18px;
  div {
    h4 {
      font-size: 16px !important;
      font-weight: 600 !important;
    }
    .styleInp {
      border: 2px solid #a7b8f4;
      padding: 5px;
      width: 16vw;
      border-radius: 5px;
      display: flex;
      justify-content: space-around;
      height: 5vh;
      align-items: center;
      input {
        width: 13vw;
        border-right: 1px solid #a7b8f4;
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
}

.inputTextValue {
  width: 100px;
  text-align: center;
  border: none;
  outline: none;
}

.warningTextMsg {
  color: #d71515;
  font-size: 0.7rem;
  margin-left: 4px;
}

.selectCustomDate {
  background-color: #ffffff;
  padding: 5px;
  position: fixed;
  z-index: 999;
  bottom: 15%;
  left: 50%;
  min-width: 20vw;
  .rdrDateRangePickerWrapper {
    margin: 0;
    .rdrDefinedRangesWrapper {
      display: none;
    }
    .rdrCalendarWrapper {
      z-index: 999;
    }
  }
  .bottonButtonsDiv {
    position: relative;
    left: 48%;
    .applyButton {
      background-color: transparent;
      border-width: 2px;
      border: 1px solid #038aff;
      border-radius: 30px;
      font-size: 12px;
      padding: 3px;
      padding-left: 15px;
      padding-right: 15px;
      font-weight: 550;
      outline: none;
      z-index: 9999;
    }
    .cancelButton {
      margin-right: 2vw;
      font-weight: 550;
      font-size: 12px;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 5px;
      z-index: 9999;
    }
  }
}

.inputDateRange {
  border: 1px solid #000000;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 3px;
  width: 16vw;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  div {
    p {
      font-size: 14px;
      color: #000000;
    }
  }
}

p {
  font-family: "Inter", sans-serif;
}

.dateDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  p {
    color: #000000;
    font-size: 12px;
    font-family: "Inter", sans-serif;
  }
}
.dateDivActive {
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  .text {
    color: #ffffff;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    margin-left: 5px;
    margin-right: 8px;
  }
}

//date Time scss
.dateTimePicket_Scss {
  position: absolute;
  width: 20vw;
  top: 20%;
  right: 2%;
  height: 28vh;
  background-color: white;
  padding: 11px;

  .bottonButtonsDiv {
    .dateTimeCard {
      input {
        border: 1px solid #ccc;
        padding: 4px;
      }
    }
    .cancelButton {
      border: none;
      margin: 10px;
      padding: 2px 20px 2px 20px;
      border-radius: 5px;
    }
    .applyButton {
      border: none;
      margin: 10px;
      background-color: #038aff;
      color: white;
      padding: 2px 20px 2px 20px;
      border-radius: 5px;
    }
  }
}
