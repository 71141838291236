.switchRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: 20px;
    z-index: 9;
  }
  
  .trendChartMainCont {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 10px 20px;
    padding: 16px 16px;
    gap: 16px;
    width: 100%;
  
    .trendChartLegendMainCont {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .trendChartLegendContScroll {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        grid-gap: 8px;
        gap: 8px;
        width: 90%;
        .trendChartLegendButton {
          display: flex;
          flex-direction: row;
          border: 1px solid;
          border-radius: 25px;
          padding: 3px 16px 3px 16px;
          gap: 8px;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
  
          .selectedLegendIcon {
            font-size: 10px;
            color: rgba(255, 255, 255, 1);
          }
  
          .trendChartLegend {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .selectedLegendButton {
          background-color: rgba(0, 117, 255, 1);
          border-color: rgba(0, 117, 255, 1);
        }
  
        .unselectedLegendButton {
          background-color: rgba(255, 255, 255, 1);
          border-color: rgba(162, 167, 159, 1);
        }
        .unselectedLegendButton:hover {
          background-color: #d0e5fd;
        }
  
        .selectedLegendText {
          color: rgba(255, 255, 255, 1);
        }
  
        .unselectedLegendText {
          color: rgba(162, 167, 159, 1);
        }
      }
      .trendChartLegendContScroll::-webkit-scrollbar {
        display: none;
      }
  
      .trendChartLegendCont {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px;
  
        .trendChartLegendButton {
          display: flex;
          flex-direction: row;
          border: 1px solid;
          border-radius: 25px;
          padding: 3px 16px 3px 16px;
          gap: 8px;
          justify-content: center;
          align-items: center;
  
          .selectedLegendIcon {
            font-size: 10px;
            color: rgba(255, 255, 255, 1);
          }
  
          .trendChartLegend {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
          }
        }
  
        .selectedLegendButton {
          background-color: rgba(0, 117, 255, 1);
          border-color: rgba(0, 117, 255, 1);
        }
  
        .unselectedLegendButton {
          background-color: rgba(255, 255, 255, 1);
          border-color: rgba(162, 167, 159, 1);
        }
        .unselectedLegendButton:hover {
          background-color: #d0e5fd;
        }
  
        .selectedLegendText {
          color: rgba(255, 255, 255, 1);
        }
  
        .unselectedLegendText {
          color: rgba(162, 167, 159, 1);
        }
      }
      .trendChartIconsCont {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #d0e5fd;
        padding: 5px 15px 5px 15px;
        border-radius: 25px;
        .toggleIcon {
          width: 15px;
          height: 15px;
        }
        .toggleIconActive {
          width: 15px;
          height: 15px;
          transform: rotate(180deg);
        }
      }
    }
  
    .trendCartSettingMainCont {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  
      .dateRagneFrequenciesDiv {
        display: flex;
        flex-direction: column;
        position: relative;
  
        .dropDownView {
          border: 1px solid rgba(80, 56, 245, 1);
          padding: 2px 8px 2px 8px;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          position: relative;
          background-color: rgba(240, 243, 252, 1);
          gap: 8px;
          cursor: pointer;
  
          .dropDownText {
            margin: 0px;
            font-size: 12px;
            font-weight: 500;
          }
  
          .arrowIcon {
            height: 10px;
            width: 10px;
          }
        }
  
        .dateRagneFrequenciesMenu {
          display: flex;
          flex-direction: column;
          border: 1px solid rgba(189, 203, 251, 1);
          margin: 4px 0px;
          max-height: 20vh;
          z-index: 1;
          overflow-y: scroll;
          border-radius: 4px;
          background-color: #fff;
          position: absolute;
          top: 25px;
          .dateRagneFrequenciesButton {
            display: flex;
            flex-direction: row;
            padding: 2px 4px;
            cursor: pointer;
  
            .dateRagneFrequenciesText {
              margin: 0;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
        // .dateRagneFrequenciesMenu::-webkit-scrollbar{
        //   display: none;
        // }
      }
  
      .trendChartSettingCont {
        display: flex;
        flex-direction: row;
        height: 4vh;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
  .rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.1s ease-in-out;
  }
  
  .rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.1s ease-in-out;
  }
  
  .chart-switch {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    .switchBtn {
      margin-right: 10px;
    }
  
    img {
      width: 26px;
      height: 14px;
      object-fit: contain;
  
      &:first-child {
        width: 28px;
        height: 18px;
      }
    }
  
    .chart-switch-img {
      margin-right: 4rem;
    }
  }