.qrGenerationParent {
  //experimental start
  width: 100%;
  height: 78vh;
  background-color: white;
  //experimental end
  border-radius: 14px;
  min-width: 75px;
  margin-top: 15px;
  overflow-x: scroll;

  .qrGenerationTopDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    margin-top: 14px;
    height: 10%;

    .qrGenerationHeader {
      margin-left: 32px;
      font-weight: 600;
    }

    .qrGenerationSearch {
      margin-right: 32px;
    }
  }

  .qrGenerationMiddleDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    margin-top: 14px;
    margin-bottom: 14px;
    height: 10%;

    .qrGenerationHeaderButtons {
      margin-right: 32px;
      margin-left: auto;
    }
  }
  .qrGenerationContentDiv {
    overflow-y: scroll;
    height: 60%;
  }

  .qrGenerationBottomDiv {
    display: flex;
    justify-content: space-between;
    //height: 10%;
    margin-top: 12px;
    font-size: 12px;
    margin-left: 32px;
    margin-right: 32px;
  }
}

.primaryButtonImgAndDark {
  border: none;
  background-color: #0f43f9;
  color: #ffffff;
  font-size: 13px;
  padding: 5px 10px 5px 10px;
  border-radius: 3px;

  img {
    height: 12px;
    width: 14px;
    margin-right: 5px;
  }
}

.marginRight10px {
  margin-right: 10px;
}

.marginLeft7 {
  margin-left: 7%;
}

.width86 {
  width: 86%;
}

.dividerLine {
  height: 1px;
  background-color: #e6e6e6;
}

@media (max-width: 1250px) {
  .childRow {
    margin-left: 20px !important;
    margin-right: 20px !important;
    padding: 0 0 !important;
  }

  .tableRow {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.showPointer {
  cursor: pointer;
}
.childRow {
  margin-left: 80px;
  margin-right: 80px;
  background-color: #f8f8fb;
  padding: 20px 60px;
  justify-content: space-between;

  .tableRow {
    margin-left: 50px;
    .sNo {
      width: 10%;
      text-align: center;
    }

    .qrCount {
      width: 17%;
      text-align: center;
    }

    .qrRejected {
      width: 17%;
      text-align: center;
    }

    .allocatedTime {
      width: 17%;
      text-align: center;
    }

    .status {
      width: 17%;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .machineDetails {
      width: 17%;
      text-align: center;
    }
  }
}

.tableHeader {
  font-weight: 600;
}

.tableRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px 24px;
  font-size: 13px;
  margin-left: 24px;
  margin-right: 24px;

  .qrChevron {
    width: 5%;
    text-align: center;

    .chevronImg {
      height: 14px;
    }

    .rotate-90 {
      transform: rotate(90deg);
      transition: transform 0.1s ease-in-out;
    }
    .rotate-0 {
      transform: rotate(0deg);
      transition: transform 0.1s ease-in-out;
    }
  }
  .qrSNo {
    width: 5%;
    text-align: center;
  }

  .qrPoNo {
    width: 8%;
    text-align: center;
  }

  .qrPlantDetail {
    width: 22%;
    text-align: center;
  }

  .qrTotalQrCount {
    width: 12.5%;
    text-align: center;
  }

  .qrGenerated {
    width: 12.5%;
    text-align: center;
  }

  .qrRejected {
    width: 12.5%;
    text-align: center;
  }

  .qrRemaining {
    width: 12.5%;
    text-align: center;
  }

  .qrStatus {
    width: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.statusBg {
  border-radius: 6px;
  padding: 2px 10px;
}

.inProgress {
  background-color: #e4dfff;
}

.completed {
  background-color: #7cdf92;
}

.paginationDiv {
  display: flex;
  cursor: pointer;
  .paginationPrev {
    border: #cccccc 1px solid;
    padding-left: 10px;
    color: #cccccc;
    padding-right: 10px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .paginationPageNo {
    border: #cccccc 1px solid;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -1px;
    margin-right: -1px;
  }
  .paginationNext {
    border: #cccccc 1px solid;
    color: #cccccc;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
}
