.reqRangeQuotaHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;

  p {
    font-weight: 700;
    font-size: 18px;
  }
}

.closeButtonIcon {
  width: 17px;
}

.inputContainer {
  padding: 20px 0 10px 15px;
  position: relative;

  .inputText {
    padding-left: 45px;
    font-size: 0.9rem;
    margin-top: 3px;
  }

  .modalInput {
    width: 92%;
    border: 1px solid #bdcbfb;
    border-radius: 5px;
  }
}

.icon1 {
  position: absolute;
  top: 28px;
  transform: translateX(-150%);
  cursor: pointer;
}

.noBorderModalItem {
  border-bottom: 0 none !important;
  border-top: 0 none !important;
}

.marginLeft20 {
  margin-left: 20px;
}

.requestPoButton {
  font-family: "Inter", serif;
  background-color: #0075ff;
  border: 1px solid #5711e2;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.marginRight25px {
  margin-right: 25px;
}

.marginBottom20px {
  margin-bottom: 20px;
}

.cancleBtn {
  background: #fcf2da;
  border: 1px solid #cfc8b8;
  border-radius: 4px;
  padding: 3px 12px;
}

.poDetailsTable {
  text-align: left;
  background: #f9f9f9;
  border: 1px solid #bdcbfb;
  border-radius: 6px;
  padding: 0px 20px 20px 20px;
  margin: 16px 36px;

  overflow: auto;

  .poDetailsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;

    .poDetailsFor {
      width: 30%;
      color: #6c6363;
      font-size: 13px;
      font-weight: 400;
    }

    .poDetailsData {
      width: 70%;
      color: #000000;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
