.loaderSnippet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .snippet {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
    animation: dotTyping 1.5s infinite linear;
  }

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
        10014px 0 0 0 #9880ff;
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff,
        10014px 0 0 0 #9880ff;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
        10014px 0 0 0 #9880ff;
    }
    50% {
      box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff,
        10014px 0 0 0 #9880ff;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
        10014px 0 0 0 #9880ff;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
        10014px -10px 0 0 #9880ff;
    }
    100% {
      box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
        10014px 0 0 0 #9880ff;
    }
  }
}

.scrolling-words-container {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
}

.scrolling-words-box {
  height: 2rem;
  margin: auto;
  overflow: hidden;
  color: black;
  ul {
    margin: 0 0.625rem;
    padding: 0;
    animation: scrollUp 4s infinite;
    li {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 2rem;
      list-style: none;
      color: black;
      font-weight: 300;
      font-family: "Inter", sans-serif !important;
      font-size: 18px;
    }
  }
  &.small {
    ul {
      li {
        font-size: 14px;
      }
    }
  }
}

// Variables
$item-count: 5;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%},
    #{$i * 25%} {
      transform: translateY(calc(-100% / $item-count) * $i);
    }
  }
}

@media screen and (max-width: 600px) {
  .scrolling-words-box {
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}
