.meterCard {
    // border: 1px solid #DAE2FF;
    // display: flex;
    // flex-direction: column;
    // padding: 5px 15px 5px 5px;
    // border-radius: 8px;
    // grid-gap: 5px;
    // gap: 5px;
    // min-width: 150px;
    // overflow: hidden;
    // align-items: flex-start;
    // height: 90px;
    // cursor: pointer;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .12);
    border: 1px solid #dae2ff;
    display: flex;
    flex-direction: column;
    padding: 10px 15px 5px 12px;
    border-radius: 8px;
    grid-gap: 5px;
    gap: 5px;
    min-width: 170px;
    max-width: 200px;
    overflow: hidden;
    align-items: flex-start;
    // height: 108px;    /* fix height to fit the content; this can be a relative hardcoded value */
    cursor: pointer;

    .mainMeterDesc {
        background-color: #F0F3FC;
        border-radius: 2px;
        padding: 2px 4px 2px 4px;
        font-size: 8px;
        border: 1px solid #5038F5;
        margin: 0;
    }

    .metricIconsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .inactiveIcon {
            height: 30px;
            width: 30px;
        }
    }

    .meterCardHeader1 {
        margin: 0px;
        font-size: 11px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        overflow: hidden;
        width: 180px;
        // height: 23%;
        padding: 5px;
    }

    .meterCardHeader {
        margin: 0px;
        font-size: 11px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        overflow: hidden;
        width: 180px;
        height: 23px;
        // background-color: #5038F5;
        padding: 5px;

        span {
            position: absolute;
            white-space: nowrap;
            transform: translateX(0);
            transition: 1s;
        }
    }

    .meterCardHeader:hover span {
        transform: translateX(calc(100px - 100%));
    }

    .meterValuesCont {
        display: flex;
        gap: 5px;
        flex-direction: row;
        align-items: center;
        width: auto;
        // background-color: aqua;

        .voltsIcon {
            // width: 11px;
            height: 18px;
        }

        .meterValues {
            margin: 0;
            font-size: .90rem;
            font-weight: 600;
        }
    }
}

.activeMeter {
    border: 1px solid #374DC6;
    background-color: #F2ECFF;
}