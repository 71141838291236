.faultDetailsCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 0px 5px;
  border-bottom: 1px solid #e7e7e7;
  .motorTxt {
    font-size: 11px;
    color: black;
    font-weight: 600;
  }
  .faultTxt {
    font-size: 11px;
    color: black;
    font-weight: 600;
  }

  .conditionTxt {
    font-size: 11px;
    color: white;
    background-color: #f96b60e5;
    font-weight: 600;
    padding: 4px;
    border-radius: 5px;
  }

  .locationContainer {
    list-style-type: none;
    position: relative;
    right: 20px;
    .areaTxt {
      font-size: 11px;
      color: black;
      font-weight: 600;
    }
    .addressTxt {
      font-size: 10px;
      color: black;
    }
  }
  .checkTxt {
    font-size: 11px;
    color: black;
    font-weight: 600;
  }
  .moreImg {
    height: 18px;
    position: relative;
    bottom: 7px;
    right: 8px;
    cursor: pointer;
  }
}
