// made some changes in alert chip
.alertChip {
  background-color: rgb(244, 67, 54);
  text-indent: 2px;
  width: 100%;
  height: 4vh;
  border-radius: 5px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(7373%)
      hue-rotate(205deg) brightness(118%) contrast(101%);
    width: 0.6rem;
  }
  h3 {
    font-size: 12px;
    color: white;
    margin-left: -5px;
    margin-top: 7.5px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .alertChip {
    background-color: rgb(244, 67, 54);
    width: 23vw;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      height: 20px;
      margin-right: 10px;
    }
    h3 {
      font-size: 20px;
      color: white;
      line-height: 2.4;
      margin-left: 5px;
      margin: 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .alertChip {
    width: 34vw;
    margin: 2px;
    h3 {
      margin-bottom: 0rem;
      display: flex;
      align-items: center;
      margin-top: 1.5px;
      // margin-left: 5px;
    }
  }
}
