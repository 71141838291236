.adDGConsuptionCont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  .dgConsuption {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    .cardOne {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      .cardOneImg {
        height: 35px;
        width: 35px;
        margin-left: 15px;
      }
      .cardOneDetails {
        display: flex;
        flex-direction: column;
        justify-content: start;
        list-style-type: none;
        position: relative;
        right: 20px;
        top: 5px;
        .cardOneValue {
          font-size: 15px;
          font-family: Liquid Crystal;
          font-weight: 600;
        }
        .cardOneTxt {
          font-size: 12px;
        }
      }
    }

    .cardsContainer {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      .cardTwoCont {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        .cardTwoImg {
          height: 25px;
          width: 25px;
          position: relative;
          bottom: 15px;
          left: 15px;
        }
        .cardTwoDetails {
          display: flex;
          flex-direction: column;
          justify-content: start;
          list-style-type: none;
          position: relative;
          bottom: 7px;
          right: 10px;
          .cardTwoValue {
            font-size: 15px;
            font-weight: 600;
            font-family: "Liquid Crystal";
          }
          .cardTwoTxt {
            font-size: 12px;
          }
        }
      }
    }
  }
  .dgImages {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      width: 140px;
      height: 70px;
      position: relative;
      right: 18px;
      opacity: 0.3;
    }
  }
}
@media only screen and (max-width: 600px) {
  .adDGConsuptionCont {
    .dgConsuption {
      gap: 15px;
      .cardOne {
        .cardOneImg {
          height: 30px;
          width: 25px;
          margin-left: 15px;
        }
        .cardOneDetails {
          .cardOneValue {
            font-size: 9px;
          }
          .cardOneTxt {
            font-size: 9px;
          }
        }
      }
      .cardsContainer {
        .cardTwoCont {
          .cardTwoImg {
            height: 20px;
            width: 15px;
          }
          .cardTwoDetails {
            .cardTwoValue {
              font-size: 9px;
            }
            .cardTwoTxt {
              font-size: 7px;
            }
          }
        }
      }
    }
    .dgImages {
      .image {
        width: 90px;
        height: 70px;
        position: relative;
        right: 18px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .adDGConsuptionCont {
    .dgConsuption {
      gap: 15px;
    }
  }
}
