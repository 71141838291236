.cardMeterMainDiv {
  width: 100%;
  height: 10.5vw;
  background-color: rgba(237, 238, 248, 1);
  border-radius: 6px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 768px) {
    height: 23vw;
  }

  @media only screen and (max-width: 992px) and (min-width: 769px) {
    height: 17vw;
  }

  @media only screen and (max-width: 1400px) and (min-width: 993px) {
    height: 12vw;
  }

  @media only screen and (min-width: 2560px) {
    height: 14vh;
  }


  .assetCardMeterRow {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    border-bottom: 1px solid rgba(207, 217, 254, 1);
    padding: 0px 0px 8px 0px;

  }

}

.assetCardMeterVerticalLine {
  width: 1px;
  height: calc(100% - 8px);
  background-color: rgba(207, 217, 254, 1);
}

.cardMeterNoData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  .noDataIcon {
    width: 25px;
    height: 25px;
  }

  .noDataText {
    font-size: 12px;
    font-weight: 400;
    color: rgba(55, 77, 198, 1);
    margin: 0;
  }

}