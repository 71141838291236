.loaderClass {
    background-color: #0075FF;
    display: flex;
    width: 12.75rem;
    height: 2rem;
    padding: 0.5rem 2.875rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 30px;
    cursor: wait;

    .loader {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }

    .loasderText {
        color: #FFF;
        font-family: "Inter";
        font-size: 1.04625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    }
}

.loaderText {
    background-color: #0075FF;
    display: flex;
    width: 12.75rem;
    height: 1.6rem;
    padding: 0.5rem 2.875rem;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;

    .loasderText {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-family: "Inter";
        font-size: 1.04625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 767px) {
    .loaderClass {
        width: 9.75rem;
        height: 30px;

        .loader {
            display: none;
        }

        .loasderText {
            font-size: 0.8rem;
        }
    }

    .loaderText {
        width: 8.75rem;
        height: 30px;

        .loasderText {
            font-size: 0.8rem;
        }
    }
}

@media only screen and (max-width: 575px) {
    .loaderClass {
        width: 8.75rem;
        height: 26px;

        .loader {
            display: none;
        }

        .loasderText {
            font-size: 0.7rem;
        }
    }

    .loaderText {
        width: 9.75rem;
        height: 28px;

        .loasderText {
            font-size: 0.7rem;
        }
    }
}