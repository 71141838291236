.filter {
  &-cta {
    cursor: pointer;
    display: flex;
    margin-right: 8px;

    .filter-btn {
      background-color: #ffffff;
      border: none !important;
      border-radius: 3px;
      box-shadow: 0px 3px 5px 2px #00000029;
    }
    img {
      width: 19px;
      margin-right: 10px;
    }
  }
  &-modal {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba($color: #000000, $alpha: 0.29);
    border-radius: 6px;
    width: 50vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    img {
      cursor: pointer;
      width: 12px;
    }
  }
  &-body {
    background-color: #e1e3f0;
    display: flex;
    padding: 10px;
    min-height: 40vh;
  }
  aside {
    background-color: #fff;
    width: 35%;
    height: 40vh;
    overflow: hidden;
    //
    &:hover {
      overflow-x: none;
      overflow-y: scroll;
    }

    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.2em;
    }
    &::-webkit-scrollbar-button {
      background: white;
      height: 1%;
    }
    &::-webkit-scrollbar-track-piece {
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      background: #d6caca;
      border-radius: 20px;
    }

    ul {
      margin: 10px 20px;
      padding: 0px;
    }
    li {
      border-radius: 3px;
      cursor: pointer;
      list-style-type: none;
      font-weight: 500;
      font-size: 16px;
      padding: 5px 20px;
      &.active {
        background-color: #0f0ade1a;
      }
    }
  }
  main {
    margin: 15px 20px;
    width: 65%;
    position: relative;
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
  }
  .buttonContainer {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .filter-text {
    font-size: 15px;
  }
}

.tag {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 3px;
  cursor: pointer;
  color: #707070;
  font-size: 12px;
  padding: 5px 15px;
  margin: 0 5px 5px 0;
  &.active {
    background-color: #6554c0;
    border: 1px solid #6554c0;
    color: #fff;
  }
}

.form-check {
  margin-bottom: 5px !important;
  &-label {
    color: #707070;
    font-size: 14px;
    font-weight: 500;
  }
}
.assetCountScss {
  margin-right: 15px;
  cursor: initial;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .filter {
    &-modal {
      width: 90vw;
      top: 25%;
    }
  }
}

@media screen and (max-width: 767px) {
  .filter {
    &-cta {
      &.mobile {
        text-indent: -9999px;

        height: 30px;

        padding-left: 7px;
        img {
          margin: 0px;
          width: 16px;
        }

        span {
          display: none;
        }

        .filter-btn {
          background-color: #ffffff;
          border: none !important;
          border-radius: 50%;
          height: 35px;
          width: 35px;
          margin-left: 45px;
          box-shadow: 0px 3px 5px 2px #00000029;
        }
      }
    }
    &-header {
      align-items: center;
      background-color: #ffffff;
      height: 10vh;
      h2 {
        margin-bottom: 0px;
        font-size: 18px !important;
      }
    }
    &-modal {
      width: 100vw;
      inset: 0px;
      transform: unset;
      min-height: 100vh;
      z-index: 999999 !important;
      overflow-y: auto;
    }
    &-body {
      background-color: #f0f3ff;
      min-height: 92vh;
      display: block;
      padding-top: 20px;
    }
    .accordion {
      width: 95vw;
      margin-bottom: -10px;
      &-header {
        background: #0f0ade1a 0% 0% no-repeat padding-box;
        border: 1px solid #0f0ade;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        h4 {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0px;
        }
      }
      &-body {
        padding: 14px 5px;
        display: none;
      }
      &-header.open + .accordion-body {
        display: block;
      }
    }
    .tagWrapper {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media only screen and (max-width: 600px) {
  .filter {
    &-cta {
      cursor: pointer;
      display: flex;
      margin-right: 8px;
      width: 14vw;
    }
  }
  .btnApply {
    border: none;
    background-color: #ffffff;
    margin-left: 217px;
    color: #038aff;
    position: relative;
    top: 70px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .filter {
    &-cta {
      cursor: pointer;
      display: flex;
      margin-right: 8px;
    }
  }
}
