.filterComponentMainDiv {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 20px;
    border-bottom: 2px solid #cfcfcf;

    .selectedFilterListDiv {
        width: 95%;
        display: flex;
        flex-direction: row;
        padding: 4px 0px 4px 0px;
        gap: 8px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .selectedOptionDiv {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 8px 2px 8px;
            border: 1px solid rgba(132, 57, 57, 1);
            border-radius: 25px;
            cursor: pointer;
            gap: 6px;
            white-space: nowrap;

            .selectedOptionText {
                font-size: 12px;
                font-weight: 400;
                color: rgba(132, 57, 57, 1);
                margin: 0;
            }

        }
    }

    .filterListMainDiv {
        width: 95%;
        display: flex;
        flex-direction: row;
        padding: 4px 0px 4px 0px;
        gap: 8px;
        // overflow-x: scroll;
        flex-wrap: wrap;

        &::-webkit-scrollbar {
            display: none;
        }

        .filterSelectorMainContainer {
            display: flex;
            flex-direction: column;
            position: relative;
            border: 1px solid;

            .chipFilterSelector {
                border: 1px solid #dfe2eb;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 3px 5px;
                border-radius: 3px;
                justify-content: space-between;

                .searchFilterInput {
                    outline: none;
                    border: none;
                    width: 80%;
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            .chipFilterList {
                border: 1px solid #dfe2eb;
                list-style-type: none;
                margin: 0;
                padding: 0;
                border-radius: 3px;
                position: absolute;
                top: 30px;
                width: 100%;
                max-height: 130px;
                overflow-y: auto;
                z-index: 99999;
                // box-shadow: 1px 1px #dfe2eb;
                -webkit-box-shadow: 0 0 5px #dfe2eb;
        box-shadow: 0 0 5px #dfe2eb;

                li {
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 600;
                    border-bottom: 1px solid #dfe2eb;
                    padding: 2px 0 2px 5px;
                    background-color: #fff;
                }
            }
        }

        .selectedOptionDiv {
            display: flex;
            flex-direction: row;
            border: 1px solid rgba(189, 203, 251, 1);
            gap: 8px;
            padding: 3px 5px 3px 5px;
            border-radius: 3px;
            cursor: pointer;
            white-space: nowrap;
            align-items: center;

            .selectedFilterText {
                font-size: 14px;
                font-weight: 400;
                color: rgba(27, 27, 27, 1);
                margin: 0;
                outline: none;
                border: none;
            }
        }

        .unSelectedOptionDiv {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 8px 2px 8px;
            border: 1px solid rgba(132, 57, 57, 1);
            border-radius: 25px;
            cursor: pointer;
            gap: 4px;
            white-space: nowrap;

            .unSelectedOptionText {
                font-size: 12px;
                font-weight: 400;
                color: rgba(132, 57, 57, 1);
                margin: 0;
            }

        }
    }
}