.eventListCard {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(228, 228, 228, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
    padding: 8px 8px 8px 8px;
    gap: 8px;
    cursor: pointer;

    .cardBody {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 8px 0px;
        border-bottom: 1px solid rgba(228, 228, 228, 1);
        gap: 8px;

        .cardTitleDiv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .eventTitleAndIcon {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                .eventIcon {
                    width: 25px;
                    height: 25px;
                }

                .eventTitle {
                    font-size: 14px;
                    font-weight: 600;
                    color: rgba(27, 27, 27, 1);
                    margin: 0;
                }
            }

            .eventLastOccured {
                font-size: 12px;
                font-weight: 500;
                color: rgba(27, 27, 27, 1);
                margin: 0;
            }

        }

        .eventCategoryMainDiv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .eventCategoryDiv {
                display: flex;
                flex-direction: row;
                gap: 10px;
                justify-content: center;
                align-items: center;

                .eventCategoryName {
                    background-color: rgba(60, 75, 242, 0.12);
                    font-size: 10px;
                    font-weight: 500;
                    color: rgba(60, 75, 242, 1);
                    margin: 0;
                    border-radius: 25px;
                    padding: 4px 10px 4px 10px;
                }

                .eventDescName {
                    background-color: rgba(220, 164, 63, 0.12);
                    font-size: 13px;
                    font-weight: 500;
                    color: rgba(220, 164, 63, 1);
                    margin: 0;
                    border-radius: 25px;
                    padding: 4px 10px 4px 10px;
                }

            }

            .eventLastTimestamp {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .lastTimeStampText {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(27, 27, 27, 1);
                    margin: 0;
                }
            }
        }

    }

    .cardFooter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .eventAssetCount {
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: center;
            align-items: center;

            .eventOccuredCount {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .eventOccuredCountValue {
                    background-color: rgba(149, 150, 158, 1);
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    margin: 0;
                    border-radius: 4px;
                    padding: 2px 4px 2px 4px;
                }

                .eventOccuredCountText {
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(71, 70, 68, 1);
                    margin: 0;
                }
            }

            .assetDiv {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .assetCountText {
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    color: rgba(0, 0, 0, 1);
                }
            }
        }

        .eventFacilityDetails {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .facilityDetails {
                font-size: 12px;
                font-weight: 600;
                color: rgba(9, 12, 155, 1);
                margin: 0;
            }
        }
    }

}