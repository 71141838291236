.incidentSummaryCardContainer {
  border: 1px solid #bdcbfb;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px;
  height: auto;
  .incidentSummaryCardImg {
    cursor: pointer;
    width: 11%;
    .incidentImg {
      height: 90px;
      width: 100%;
    }
  }
  .incidentSummaryDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 12px;
    text-align: inherit;
    .incidentDateTime {
      font-size: 12px;
      color: black;
      font-weight: 500;
    }
    .incidentTime {
      font-size: 12px;
      color: black;
      font-weight: 500;
    }
    .incidentSummaryContHead {
      font-size: 12px;
      color: black;
      font-weight: 700;
    }
    .incidentSummaryContSub {
      font-size: 12px;
      color: black;
      font-weight: 500;
    }
    // .incidentAddress{
    //     font-size: 12px;
    //     color: black;
    //     font-weight: 500;
    //     width: 20%;
    // }
  }
  .incidentSummaryDetailsAddress {
    width: 22%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 12px;

    .incidentSummaryContHead {
      font-size: 12px;
      color: black;
      font-weight: 700;
    }
    .incidentSummaryContAddress {
      font-size: 12px;
      color: black;
      font-weight: 500;
    }
  }
}

.modalCloseBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  .modalCloseBtn {
    margin-right: 15px;
    margin-top: 10px;
    border: none;
    background: transparent;
  }
}

.imageModalPopupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .imageModalPopupHeader {
    font-size: 13px;
    color: black;
    font-weight: 600;
  }
  .imageModalPopupIncidenTimeCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    .imageModalPopupIncidenTimeTxt {
      font-size: 12px;
      color: black;
      margin-top: -20px;
      position: relative;
      right: 32px;
    }
    .imageModalPopupIncidenTime {
      font-size: 12px;
      color: black;
      margin-top: -20px;
    }
  }
  .imageModalPopupAddressCont {
    .imageModalPopupAddress {
      font-size: 13px;
      color: black;
    }
  }
  .imageModalPopupImgContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .imageModalPopupImg {
      height: 25vh;
      width: 75%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .incidentSummaryCardContainer {
    height: 11vh;
  }
  .incidentSummaryCardContainer .incidentSummaryCardImg .incidentImg {
    height: 40px;
  }
  .incidentSummaryCardContainer .incidentSummaryDetails .incidentDateTime {
    font-size: 8px;
    padding-left: 6px;
  }
  .incidentSummaryCardContainer .incidentSummaryDetails .incidentTime {
    font-size: 8px;
    padding-left: 4px;
  }
  .incidentSummaryCardContainer
    .incidentSummaryDetails
    .incidentSummaryContHead {
    font-size: 8px;
    padding-right: 12px;
  }
  .incidentSummaryCardContainer
    .incidentSummaryDetails
    .incidentSummaryContSub {
    font-size: 8px;
  }
  .imageModalPopupContainer .imageModalPopupImgContainer .imageModalPopupImg {
    height: 15vh;
  }
  .incidentSummaryCardContainer
    .incidentSummaryDetailsAddress
    .incidentSummaryContHead {
    font-size: 8px;
  }
  .incidentSummaryCardContainer
    .incidentSummaryDetailsAddress
    .incidentSummaryContAddress {
    font-size: 4px;
    color: black;
    font-weight: 500;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .incidentSummaryCardContainer .incidentSummaryCardImg .incidentImg {
    height: 70px;
  }

  .incidentSummaryCardContainer {
    height: 10vh;
    .incidentSummaryDetails {
      .incidentDateTime {
        font-size: 10px;
      }
      .incidentTime {
        font-size: 10px;
      }
      .incidentSummaryContHead {
        font-size: 10px;
      }
      .incidentSummaryContSub {
        font-size: 10px;
      }
    }
    .incidentSummaryDetailsAddress {
      .incidentSummaryContHead {
        font-size: 10px;
      }
      .incidentSummaryContAddress {
        font-size: 8px;
        width: 160px;
      }
    }
  }
  .incidentSummaryCardContainer .incidentSummaryDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    position: relative;
    left: 50px;
    margin-right: 28px;
  }
  .imageModalPopupContainer .imageModalPopupImgContainer .imageModalPopupImg {
    height: 15vh;
  }
}
