.faultSummaryBtnCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  list-style-type: none;
  padding-right: 7px;
  background-color: #f0f3ff;
  padding-left: 5px;
  cursor: pointer;
  .faultSummaryBtnTxt {
    font-size: 13px;
    color: black;
    font-weight: 500;
  }
  .faultSummaryBtnCount {
    font-size: 20px;
    color: black;
    font-weight: 700;
    .faultSummaryBtnCountSpan {
      font-size: 12px;
      color: black;
      font-weight: 600;
      .faultArrow {
        height: 13px;
        margin-bottom: 6px;
        margin-right: 4px;
      }
    }
  }
}

// @media only screen and (max-width: 600px) {
//   .incidentSummaryBtnCont {
//     padding-right: 10px;
//     padding-left: 10px;
//     .incidentSummaryBtnTxt {
//       font-size: 10px;
//       color: black;
//       font-weight: 500;
//     }
//     .incidentSummaryBtnCount {
//       font-size: 15px;
//       color: black;
//       font-weight: 700;
//       .incidentSummaryBtnCountSpan {
//         font-size: 10px;
//         color: black;
//         font-weight: 600;
//         .incidentArrow {
//           height: 10px;
//           margin-bottom: 0px;
//           margin-right: 4px;
//         }
//       }
//     }
//   }
// }
