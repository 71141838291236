.emergyCont {
  // display: flex;
  // flex-direction: row;
  gap: 7px;
  margin-top: 10px;
  width: 100%;
  .cardsRowCont {
    .rowOne {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      .card1Cont {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .card1Heading {
          margin-bottom: 20px;
          font-size: 12px;
          color: #000000;
        }
        .card1ValuesCont {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .card1Icon {
            height: 20px;
            width: 15px;
            position: relative;
            bottom: 8px;
            cursor: default;
          }
          .card1Values {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            p {
              font-size: 12px;
              color: #000000;
              font-weight: 500;
              padding-left: 5px;
            }
          }
        }
      }

      .card2Cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .card2Heading {
          margin-bottom: 20px;
          font-size: 12px;
          color: #000000;
        }
        .card2ValuesCont {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .card2Icon {
            height: 20px;
            width: 15px;
            position: relative;
            bottom: 8px;
            cursor: default;
          }
          .card2Values {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            p {
              font-size: 12px;
              color: #000000;
              font-weight: 500;
              padding-left: 5px;
            }
          }
        }
      }

      .card3Cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .card3Heading {
          margin-bottom: 20px;
          font-size: 12px;
          color: #000000;
        }
        .card3ValuesCont {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .card3Icon {
            height: 20px;
            width: 15px;
            position: relative;
            bottom: 8px;
            cursor: default;
          }
          .card3Values {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            p {
              font-size: 12px;
              color: #000000;
              font-weight: 500;
              padding-left: 5px;
            }
          }
        }
      }

      .card4Cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .card4Heading {
          margin-bottom: 20px;
          font-size: 12px;
          color: #000000;
        }
        .card4ValuesCont {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .card4Icon {
            height: 20px;
            width: 15px;
            position: relative;
            bottom: 8px;
            cursor: default;
          }
          .card4Values {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            p {
              font-size: 12px;
              color: #000000;
              font-weight: 500;
              padding-left: 5px;
            }
          }
        }
      }

      .card5Cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .card5Heading {
          margin-bottom: 20px;
          font-size: 12px;
          color: #000000;
        }
        .card5ValuesCont {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .card5Icon {
            height: 20px;
            width: 15px;
            position: relative;
            bottom: 8px;
            cursor: default;
          }
          .card5Values {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            p {
              font-size: 12px;
              color: #000000;
              font-weight: 500;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .emergyCont {
    gap: 15px;
    .card1Cont {
      .card1Heading {
        font-size: 9px;
      }
      .card1ValuesCont {
        .card1Values {
          p {
            font-size: 9px;
          }
        }
      }
    }

    .card2Cont {
      .card2Heading {
        font-size: 9px;
      }
      .card2ValuesCont {
        .card2Values {
          p {
            font-size: 9px;
          }
        }
      }
    }

    .card3Cont {
      .card3Heading {
        font-size: 9px;
      }
      .card3ValuesCont {
        .card3Values {
          p {
            font-size: 9px;
          }
        }
      }
    }

    .card4Cont {
      .card4Heading {
        font-size: 9px;
      }
      .card4ValuesCont {
        .card4Values {
          p {
            font-size: 9px;
          }
        }
      }
    }

    .card5Cont {
      .card5Heading {
        font-size: 9px;
      }
      .card5ValuesCont {
        .card5Values {
          p {
            font-size: 9px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .emergyCont {
    gap: 60px;
  }
}
