.switchBtn {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  &.grouped {
    // Styles for grouped state
    .switchBtn-label {
      background: #5038F5;  
    }

    .switchBtn-label::before {
      background: #fafafa;  
    }
  }

  &.ungrouped {
    // Styles for ungrouped state
    .switchBtn-label {
      background: #fafafa;  
    }

    .switchBtn-label::before {
      background: #5038F5;  
    }
  }
  h6 {
    margin-bottom: 0px;
    margin-right: 10px;
  }
  &-label {
    display: inline-block;
    position: relative;
    width: 33px;
    height: 18px;
    border-radius: 20px;
    background: #5038F5;
    border: 1px solid #5038F5;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;
      width: 12px;
      height: 12px;
      background: #fff;
      border-radius: 50%;
      transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
        background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
        box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  &-label:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28),
      0 0 0 20px rgba(128, 128, 128, 0.1);
  }
  input:checked + .switchBtn-label {
    background: #5038F5;
  }
  input:checked + .switchBtn-label::before {
    left: 16px;
    background: #fafafa;
  }
  input:checked + .switchBtn-label:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
  }
}

.btnPrimary {
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  padding: 5px 10px;
  min-width: 120px;
}

.btnClear {
  border: 1px solid #273250;
  background-color: #fff;
  margin-right: 10px;
  color: #000;
}

.btnApply {
  border: 1px solid #055cc7;
  background-color: #055cc7;
  margin-right: -13px;
  color: #fff;
}

.linkWithIcon {
  text-align: right;
  margin-bottom: 10px;
  position: relative;
  right: 20px;
  transition: all 1s ease;
  margin-top: 10px;
  margin-bottom: 25px;
  margin-right: 21px;
  a {
    font-size: 12px;
  }
  .hover {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .normal {
      display: none;
    }
    .hover {
      display: inline-block;
    }
  }
}

.linkWithIcon .viewAll > .viewall-icon {
  margin-left: 5px;
  margin-top: 2px;
}

@media screen and (max-width: 767px) {
  .switchBtn {
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .btnApply {
    border: none;
    background-color: #ffffff;
    margin-left: 217px;
    color: #038aff;
  }
}

@media only screen and (max-width: 600px) {
  .btnApply {
    border: none;
    background-color: #ffffff;
    margin-left: 217px;
    color: #038aff;
  }
  
.linkWithIcon {
  text-align: right;
  margin-bottom: 10px;
  position: relative;
  right: 10px;
  transition: all 1s ease;
  margin-top: 10px;
  margin-bottom: 25px;

  a {
    font-size: 12px;
  }
  .hover {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .normal {
      display: none;
    }
    .hover {
      display: inline-block;
    }
  }
}

}