.modalmainContainerHeader {
  border-bottom-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 36px;
  margin-left: 36px;
  margin-right: 36px;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1e1e1e;
  }
}

.popUpCloseButton {
  margin-left: auto;
  //  margin-right: 15px;
  // margin-top: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }

  .closeIcon {
    width: 15px;
    height: 15px;
  }
}

.modalmainContainerBody {
  margin-top: 0;

  .machineDetailsBodyContainer {
    margin-left: 24px;
    margin-right: 24px;
    overflow-y: scroll;
    height: 70vh;
  }
}

.tableRows {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 12px 24px;
  font-size: 12px;
  margin-left: 24px;
  margin-right: 24px;

  .qrChevron {
    width: 5%;
    text-align: center;

    .chevronImg {
      height: 14px;
    }

    .rotate-90 {
      transform: rotate(90deg);
      transition: transform 0.1s ease-in-out;
    }
    .rotate-0 {
      transform: rotate(0deg);
      transition: transform 0.1s ease-in-out;
    }
  }
  .machineId {
    width: 20%;
  }

  .heading {
    font-weight: 600;
    color: #000000;
    font-size: 15px;
  }

  .dataText {
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    color: #000000;
    padding-right: 16px;
  }

  .machineTotalCount {
    width: 15%;
  }

  .machineTotalQc {
    width: 15%;
  }

  .machineQcFail {
    max-width: 17%;
  }

  .MachineNyqc {
    width: 12.5%;
  }

  .machineStatus {
    width: 20%;
    text-align: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
  }
}

.statusBg {
  border-radius: 6px;
  padding: 2px 10px;
}

.failed {
  background-color: #e9414b;
  color: #ffffff;
  text-align: center;
  padding: 2px 10px;
  border-radius: 6px;
}

.inProgress {
  background-color: #e4dfff;
}

.completed {
  background-color: #7cdf92;
}

.bottomLine {
  border: 0.928039px solid #e6e6e6;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 8px;
  padding: 0px 24px;
}
