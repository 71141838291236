.tempEnergyContainer {
    display: flex;
    flex-direction: row;
    .cardsRowCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .rowOne {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: baseline;
        gap: 50px;
        .card1Container {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          .card1Heading {
            font-size: 12px;
            color: #000000;
          }
          .card1ValuesCont {
            display: flex;
            justify-content: center;
            align-items: center;
            .card1Icon {
              height: 25px;
              width: 20px;
              position: relative;
              bottom: 8px;
              cursor: default;
            }
            .card1Values {
              font-size: 12px;
              color: #f44336;
              font-weight: 600;
              padding-left: 5px;
            }
          }

          .card1Celcius {
            font-size: 10px;
            span {
              font-weight: 600;
            }
          }
          .cardSetPoint{
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            margin-top: -14px;
        
            h2{
              font-size: 12px !important;
              color: #000000;
              font-weight: 500 !important;
            }
            h3{
              font-size: 12px !important;
              color: #000000;
              font-weight: 400 !important;
              margin-left: 7px;
              span{
                margin: 0;
                padding: 0;
            
              }
            }

          }
        }

        .card2Container {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          .card2Heading {
            font-size: 12px;
            color: #000000;
          }
          .card2ValuesCont {
            display: flex;
            justify-content: center;
            align-items: center;
            .card2Icon {
              height: 20px;
              width: 10px;
              position: relative;
              bottom: 8px;
              cursor: default;
            }
            .card2Values {
              font-size: 12px;
              color: #000000;
              font-weight: 600;
              padding-left: 5px;
            }
          }

          .card1Celcius {
            font-size: 10px;
            span {
              font-weight: 600;
            }
          }
          .cardSetPoint{
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            margin-top: -14px;
        
            h2{
              font-size: 12px !important;
              color: #000000;
              font-weight: 500 !important;
            }
            h3{
              font-size: 12px !important;
              color: #000000;
              font-weight: 400 !important;
              margin-left: 7px;
              span{
                margin: 0;
                padding: 0;
            
              }
            }

          }
        }

      }
      .rowTwo {
        display: flex;
        flex-direction: row;
        gap: 25px;
        .card5Cont {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          gap: 5px;
          .card5Heading {
            font-size: 12px;
            color: #000000;
          }
          .card5ValuesCont {
            display: flex;
            justify-content: center;
            align-items: center;
            .card5Icon {
              height: 20px;
              width: 15px;
              position: relative;
              bottom: 8px;
              cursor: default;
            }
            .card5Values {
              font-size: 12px;
              color: #000000;
              font-weight: 600;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    .tempEnergyContainer {
      .cardsRowCont {
        .rowOne {
          gap: 20px;
          .card1Container {
            .card1Heading {
              font-size: 9px;
            }
            .card1ValuesCont {
              .card1Icon {
                height: 20px;
                width: 10px;
              }
              .card1Values {
                font-size: 9px;
              }
            }
            .card1Celcius {
              font-size: 7px;
            }
          }
  
          .card2Cont {
            .card2Heading {
              font-size: 9px;
            }
            .card2ValuesCont {
              .card2tIcon {
                height: 20px;
                width: 10px;
              }
              .card2Values {
                font-size: 12px;
              }
            }
          }
  
          .card3Cont {
            .card3Heading {
              font-size: 9px;
            }
            .card3ValuesCont {
              .card3Icon {
                height: 20px;
                width: 15px;
              }
              .card3Values {
                font-size: 12px;
              }
            }
          }
  
          .card4Cont {
            .card4Heading {
              font-size: 9px;
            }
            .card4ValuesCont {
              .card4Icon {
                height: 20px;
                width: 15px;
              }
              .card4Values {
                font-size: 12px;
              }
            }
            .card4Ideal {
              font-size: 5px;
              padding: 5px;
            }
          }
  
          .card5Cont {
            .card5Heading {
              font-size: 9px;
            }
            .card5ValuesCont {
              .card5Icon {
                height: 20px;
                width: 15px;
              }
              .card5Values {
                font-size: 12px;
              }
            }
          }
        }
  
        .rowTwo {
          .card5Cont {
            .card5Heading {
              font-size: 9px;
            }
            .card5ValuesCont {
              .card5Icon {
                height: 20px;
                width: 15px;
              }
              .card5Values {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .tempEnergyContainer {
      .cardsRowCont {
        .rowOne {
          gap: 80px;
        }
        .rowTwo {
          gap: 60px;
        }
      }
    }
  }
  