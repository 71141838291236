.inputsCont {
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
  }
  .setPurposeTxt {
    cursor: pointer;
    margin: 0;
    font-size: 0.833rem;
    color: #4e23d9;
    text-decoration: underline;
  }
  .formErrorPhoneNumber{
    display: block;
    height: 100%;
    font-size: 0.6rem;
    padding-top: 0.3rem;
    padding-bottom: 0.2rem;
  }
  .facilityInputs {
    outline: none;
    border: 1px solid #bdcbfb;
    border-radius: 5px;
    font-size: 0.75rem;
    padding: 4px;
  }
  .numberInputCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    .numberInputImgCont {
      display: flex;
      height: 1.792rem;
      flex-direction: row;
      align-items: center;
      width: 30%;
      padding: 4px;
      margin-right: -1px;
      border: 1px solid #bdcbfb;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      img {
        height: 10px;
        width: 15px;
      }
      .numberSelector {
        border: none;
        outline: none;
        font-size: 0.6rem;
        font-weight: 500;
      }
    }
    .numberInput {
      width: 70%;
      height: 1.792rem;
      outline: none;
      border: 1px solid #bdcbfb;
      font-size: 12px;
      padding: 4px;
      border-radius: 0 5px 5px 0;
    }
  }
}

// Phone Number Set Purpose Container

.phoneNumberSetPurposeCont {
  border: 1px solid #bdcbfb;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 18vw;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: absolute;
  left: 20%;
  z-index: 10;
  .setPurposeCloseCont {
    display: flex;
    justify-content: flex-end;
    height: 5vh;
    align-items: center;
    padding: 5px 2px 0 0;
    img {
      height: 10px;
      width: 20px;
      cursor: pointer;
    }
  }
  .setPurposeDetailsCont {
    padding-left: 8px;
    .setPurposeDetails {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      padding-bottom: 5px;
      .setPurposeCheckbox {
        border-color: #275efe !important;
      }
      p {
        font-size: 12px;
        margin: 0px;
        font-weight: 500;
      }
    }
  }
  .setPurposeDetailsCont::-webkit-scrollbar {
    display: none;
  }
}

.createOrgHeader{
  font-size: 0.833rem;
}
