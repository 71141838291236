// .seeMore {
//   width: 100%;
//   // border: 1px solid black;
//   text-align: center;
// }

// .seeMoreDiv {
//   width: 30%;
//   // border: 1px solid red;
//   margin: auto;
// }

// .tabStatus {
//   background: #d10000 0% 0% no-repeat padding-box;
//   border-radius: 0.188rem;
//   font-size: 0.6rem;
//   padding: 2px 5px;
//   color: #ffffff;
//   font-weight: 600;
// }

// .blockAlertDiv {
//   width: 92%;
//   margin: auto;
// }

.alertcontainerSet {
display: flex;
    justify-content: center;

.alertEachRowSet {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
        margin: 8px 0px;
        flex-direction: column;
        width: 20vw;
        align-content: flex-start;

    .alertEachRow-divSet {
            display: flex;
            flex-wrap: wrap;
            margin: 6px;
            width: 100%;
            transition: background-color 1s ease-out 30ms;
            flex-direction: row;
            align-content: center;
            border-radius: 10px;
            align-items: baseline;

    .AlertCountcontainerSet {
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid #0457c2;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.6rem;
            font-size: 15px !important;
            font-weight: 700;
            margin-right: 12px;
            margin-top: 3%;
            margin-left: 4%;
        }

          h3 {
              font-size: 13px;
              font-weight: 600 !important;
          }
        }

        .alertEachRow-divSet:hover {
            background-color: #6d9ac9 !important;
        }
  }
}

.tabBody-alertCards {
    overflow: scroll;
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

// component Name: ActivitySubAlert
// path - a1Components/activitySubAlerts/index.tsx
// scss start
.activitySector {
div {
        img {
            width: 2vw;
            margin-top: 10px;
        }

        h6 {
            margin: 15px 10px 5px 0px !important;
            font-size: 15px;
            margin: 0;
            font-weight: 400;
        }

        h5 {
            margin: 15px 0px 3px 0px !important;
            font-size: 12px;
            margin: 0;
            border: 1px solid #ccc;
            padding: 2px;
            background-color: #e7ecff;
        }

        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        height: 4vh;
        }
}

.activitySubAlert {
margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    max-height: 12rem;
    overflow-y: scroll;
    box-sizing: content-box;
}

.subAlertContainerHeader {
display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
}

.lottieLoading {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;

}
@media only screen and (max-width: 600px) {

    .activitySector div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 6vh;
        margin-bottom: 12px;

        img {
            width: 5vw;
            margin-top: 10px;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .activitySector div img {
        width: 2vw;
        margin-top: 10px;
    }
}