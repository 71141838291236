.waterContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  .cardHeading {
    font-size: 12px;
    color: #000000;
  }
  .cardValuesCont {
    display: flex;
    justify-content: center;
    align-items: center;
    .cardIcon {
      height: 20px;
      width: 10px;
      position: relative;
      bottom: 8px;
      cursor: default;
    }
    .cardValues {
      font-size: 12px;
      color: #f44336;
      font-weight: 500;
    }
  }
  .cardCelcius {
    font-size: 10px;
    span {
      font-weight: 600;
    }
  }
}
