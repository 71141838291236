.eventListDetailPopUp {
    margin-top: 0;

    div {
        border: none;
    }
}

.loadingBox1 {
    width: 100%;
    display: flex;
    height: 15rem;
    justify-content: center;
}

.eventDetailsMainDiv {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    gap: 8px;
    border: 1px solid;

    .eventDetailsHeader {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .titleAndTimeDiv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .eventDetailsTitle {
                font-size: 13px;
                font-weight: 600;
                color: rgba(27, 27, 27, 1);
                margin: 0;

            }

            .timeDiv {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .eventTimeText {
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(27, 27, 27, 1);
                    margin: 0;
                }
            }

        }

        .eventDetailsDiv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;

            .timeStampAndEventCountDiv {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .eventTimeStampData {
                    font-size: 12px;
                    text-align: right;
                    color: rgba(27, 27, 27, 1);
                    margin: 0;
                }

                .eventCountDiv {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .eventOccuredCunt {
                        background-color: rgba(149, 150, 158, 1);
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(255, 255, 255, 1);
                        margin: 0;
                        border-radius: 4px;
                        padding: 2px 4px 2px 4px;
                    }

                    .textCount {
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(71, 70, 68, 1);
                        margin: 0;
                    }
                }
            }

            .eventCategoryDiv {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                .eventCategoryData {
                    background-color: rgba(60, 75, 242, 0.12);
                    font-size: 10px;
                    font-weight: 500;
                    color: rgba(60, 75, 242, 1);
                    margin: 0;
                    border-radius: 25px;
                    padding: 4px 10px 4px 10px;
                }

                .eventCategoryDataName {
                    background-color: rgba(220, 164, 63, 0.12);
                    font-size: 13px;
                    font-weight: 500;
                    color: rgba(220, 164, 63, 1);
                    margin: 0;
                    border-radius: 25px;
                    padding: 4px 10px 4px 10px;
                }
            }
        }

        .facilityDetailsAndDownloadDiv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .facilityDetailsDiv {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .facilityDataDiv {
                    display: flex;
                    flex-direction: column;

                    .eventFaciliyName {
                        font-size: 13px;
                        font-weight: 600;
                        color: rgba(9, 12, 155, 1);
                        margin: 0;
                    }

                    .eventFacilityAddress {
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(27, 27, 27, 1);
                        margin: 0;
                    }
                }
            }

            .downloadButton {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border: 1px solid rgba(207, 200, 184, 1);
                background-color: rgba(252, 242, 218, 1);
                border-radius: 8px;
                padding: 4px 8px 4px 8px;
                cursor: pointer;

                .downloadButtonText {
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(6, 6, 6, 1);
                    margin: 0;
                }
            }
        }
    }

    .eventSnapshotParentDiv {
        height: 35vh;
        overflow-y: scroll;

        .eventSnapshotMainDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .eventSnapShotDiv {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 8px;

                .noMediaAvailableDiv {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 16px;

                    .noMediaAvailable {
                        font-size: 16px;
                        font-weight: 500;
                        margin: 0;
                        color: #000;
                    }

                }

                .eventSnapshot {
                    width: 200px;
                    height: 200px;
                }

            }
        }
    }

    .metadataParentDiv {
        display: flex;
        flex-direction: column;
        background-color: rgba(225, 227, 240, 1);
        padding: 4px 8px 4px 8px;
        border-radius: 6px;

        .metadataDivHeading {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            width: fit-content;

            .metaDataHeadingText {
                font-size: 15px;
                font-weight: 400;
                color: rgba(66, 107, 250, 1);
                margin: 0;
            }
        }

        .metadataDiv {
            display: flex;
            flex-direction: row;

            .metaDataText {
                font-size: 16px;
                font-weight: 400;
                color: rgba(27, 27, 27, 1);
                margin: 0;
            }
        }
    }

    .modalFooterDiv {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .closeButtonDiv {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(95, 91, 91, 1);
            background-color: rgba(255, 255, 255, 1);
            padding: 4px 8px 4px 8px;
            cursor: pointer;

            .closeButtonText {
                font-size: 15px;
                text-align: left;
                color: rgba(95, 91, 91, 1);
                margin: 0;
            }
        }
    }
}

.rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.1s ease-in-out;
}

.rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.1s ease-in-out;
}