/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif !important;
}

code {
  font-family:'Roboto', sans-serif !important;
}

h2 {
  font-weight: 500 !important;
  font-size: 25px !important;
}

p {
  color: #182F43;
  font-weight: 400 !important;
  font-size: 17px !important;
  margin: 0px;
}

a {
  color: #0F43F9;
  font-weight: 500 !important;
  font-size: 17px !important;
} */

a {
  text-decoration: none !important;
}
