.assetSettingsFilterBtnCont {
  display: flex;
  width: 102%;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  // border: 1px solid yellow;
  .assetSettingsBtnsConteiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 40px;
    gap: 5px;
    .assetSettingsExportBtn {
      background-color: #fcf2da;
      border: 0.5px solid #cfc8b8;
      font-size: 13px;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    }
    .assetSettingsFilterBtn {
      background-color: #e1efff;
      border: 0.5px solid #0f43f9;
      font-size: 13px;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      img {
        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
    }
    .addAssetBtn {
      border: none;
      background-color: #0f43f9;
      color: #ffffff;
      font-size: 13px;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      img {
        height: 14px;
        width: 14px;
        margin-right: 5px;
      }
    }
    .productAddributesBtn {
      border: none;
      background-color: #0f43f9;
      color: #ffffff;
      font-size: 13px;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      img {
        height: 14px;
        width: 14px;
        margin-right: 5px;
      }
    }
  }
}
.assetSettingsCardsCont {
  height: 70%;
  // border: 1px solid yellowgreen;
}

// Product Attributes Popup
.productAttributePopupModal {
  div {
    width: 45vw;
    border: none;
    border-radius: 15px;
  }
}
.productAttributePopupHeader {
  padding: 30px 35px 0px 35px !important;
  .productAttributeHeaderCont {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #bdcbfb;
    gap: 20px;
    border-radius: 0px !important;
    .productAttributeHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .productAttributeHeaderTxt {
        margin: 0px !important;
        font-weight: 600 !important;
        font-size: 18px !important;
      }
      .closeIconDiv {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
          cursor: pointer;
          height: 15px;
          width: 15px;
        }
      }
      .closeIconDiv:hover {
        background-color: #d3d3d3;
        cursor: pointer;
      }
    }
    .productAttributeHeaderSelect {
      width: 47%;
      outline: none;
      border: 1px solid #bdcbfb;
      border-radius: 3px;
      padding: 5px;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 20px;
      option {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
.productAttributePopupBody {
  padding: 10px 20px 10px 35px !important;
  .productAttributeBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 0px !important;
    flex-wrap: wrap;
    .inputTextCont {
      display: flex;
      flex-direction: column;
      width: 50%;
      position: relative;
      padding-top: 10px;
      label {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
        padding-bottom: 5px;
      }
      input {
        outline: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid #bdcbfb;
        width: 90%;
      }
    }
    .inputAndSelectCont {
      display: flex;
      flex-direction: column;
      width: 50%;
      position: relative;
      padding-top: 10px;
      label {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
        padding-bottom: 5px;
      }
      .inputSelect {
        display: flex;
        flex-direction: row;
        width: 100%;
        input {
          outline: none;
          border-right: none;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          padding: 5px;
          font-size: 13px;
          font-weight: 500;
          border: 1px solid #bdcbfb;
          width: 70%;
        }
        select {
          outline: none;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          padding: 5px;
          font-size: 13px;
          font-weight: 500;
          border: 1px solid #bdcbfb;
          width: 20%;
          border-left: none;
        }
      }
    }
    .selectInputCont {
      display: flex;
      flex-direction: column;
      width: 50%;
      position: relative;
      padding-top: 10px;
      label {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
        padding-bottom: 5px;
      }
      select {
        outline: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid #bdcbfb;
        width: 90%;
        option {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}
.productAttributePopupFooter {
  padding: 5px 35px 20px 0px !important;
  .productAttributeCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
  .productAttributeUpdateBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 300;
    color: #ffffff;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .assetSettingsFilterBtnCont {
    height: 6%;
  }
}
@media only screen and (min-width: 1375px)  {
  .assetSettingsFilterBtnCont {
    height: 6%;
    width: 99.5%;
  }
}

@media only screen and (max-width: 600px) {
  .assetSettingsFilterBtnCont {
    height: 6%;
    .assetSettingsBtnsConteiner {
      margin-right: 10px;
      .assetSettingsExportBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 10px;
          width: 10px;
          margin-right: 2px;
        }
      }
      .assetSettingsFilterBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 8px;
          width: 8px;
          margin-right: 2px;
        }
      }
      .addAssetBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 8px;
          width: 8px;
          margin-right: 2px;
        }
      }
      .productAddributesBtn {
        font-size: 8px;
        padding: 5px 5px 5px 5px;
        img {
          height: 8px;
          width: 8px;
          margin-right: 2px;
        }
      }
    }
  }
}
