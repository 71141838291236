.addAndEditAssetModalPopup {
  //margin-left: -50px;
  margin-top: 0;
}
.addAndEditAssetModalHeader {
  padding: 30px 35px 0px 35px !important;
  border: none !important;
  .addAndEditAssetHeader {
    margin: 0px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.labelStyle{
  color: red !important;
  font-size: 10px !important; 
}
.addAndEditAssetModalBody {
  padding: 5px 35px 10px 35px !important;
  margin-top: 0;
  .edit-body-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    .editAssetParentRow {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      //height: 10vh;
      .editAssetRow {
        width: 50%;
       
      }
    }
    .editAssetRowKey {
      font-size: 15px;
      margin: 0px;
      font-weight: 500;
      padding-bottom: 4px;
      padding-top: 5px;
    }

    .editAssetRowValue {
      width: 90%;
      border: 1px solid #bdcbfb;
      outline: none;
      padding: 5px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 500;
    }

    .editBodypara {
      font-size: 12px;
      margin-top: 10px;
      font-weight: 500;
    }

    .edit-para-link {
      color: #4e23d9;
      text-decoration: underline;
      font-size: 12px;
      margin-top: 10px;
      cursor: pointer;
      padding-left: 5px;
      font-weight: 500;
    }

    .editAssetImage {
      height: 70px;
      width: 70px;
    }

    .EditCloseImg {
      height: 15px;
      border-radius: 60%;
      padding: 3px;
      margin-top: -5px;
      border: 1px solid black;
      background-color: white;
      margin-left: -10px;
    }

    .editAssetImageCont {
      padding-left: 15px;
      margin-top: 2px;
    }

    .editAssetImgHeading {
      font-size: 16px;
    }

    .editAssetImgInstr {
      font-size: 13px;
      color: gray;
      margin-top: -15px;
    }
    .editAssetImgInstr2 {
      font-size: 13px;
      color: gray;
      margin-top: -15px;
    }
  }
}
.addAndEditAssetModalFooter {
  margin: 10px 15px 0px 35px !important;
  border: none !important;
  .createAssetFooterCont {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    .createAssetCancelBtn {
      border: 1px solid #cfc8b8;
      background-color: #fcf2da;
      font-size: 15px;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      font-weight: 500;
    }
    .createAssetBtn {
      cursor: pointer;
      border: 1px solid #5711e2;
      background-color: #0075ff;
      font-size: 15px;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      font-weight: 300;
      color: #ffffff;
    }
  }
  .editAssetFooterCont {
    display: flex;
    align-items: center;
    justify-content: end;
    justify-content: space-between;
    width: 100%;
    border-radius: 0px;
    .assetStatusDropDownCont {
      border-radius: 0px;
      width: 50%;
      display: flex;
      align-items: center;
      gap: 30px;
      position: relative;
      cursor: pointer;
      .statusTextCont {
        margin: 0px;
        font-size: 11px;
        font-weight: 600;
        background-color: #5aa15d4d;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 0px 10px 0px 10px;
        border-radius: 8px;
        text-transform: uppercase;
        cursor: pointer;
        .statusDot {
          height: 8px;
          width: 8px;
          background-color: #aadeac;
        }
      }
      .statusTextsCont {
        position: absolute;
        border: 1px solid #bdcbfb;
        width: 50%;
        border-radius: 0px;
        background-color: white;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: start;
        border-radius: 5px;
        padding: 10px 0px 10px 5px;
        height: 22vh;
        overflow: scroll;
        top: 0;
        .statusTextCont {
          margin: 0px;
          font-size: 11px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 0px;
          gap: 8px;
          padding: 2px 11px 3px 10px;
          border-radius: 20px;
          text-transform: uppercase;
          margin-bottom: 10px;
          .statusDot {
            height: 8px;
            width: 8px;
          }
        }
      }
    }
    .editAssetFooterBtnsCont {
      display: flex;
      justify-content: end;
      width: 50%;
      border-radius: 0px;
      gap: 10px;
      .editAssetCancelBtn {
        border: 1px solid #cfc8b8;
        background-color: #fcf2da;
        font-size: 15px;
        padding: 5px 10px 5px 10px;
        border-radius: 3px;
        font-weight: 500;
      }
      .editAssetUpdateBtn {
        cursor: pointer;
        border: 1px solid #5711e2;
        background-color: #0075ff;
        font-size: 15px;
        padding: 5px 10px 5px 10px;
        border-radius: 3px;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }
}

.asset-primary-button {
  background: #0075ff;
  border: 1px solid #5711e2;
  border-radius: 4.18279px;
  color: #ffffff;
  font-size: 16px;
  padding: 6px;
}

.tooltip {
  font-size: 8px !important;
  width: 16% !important;
}

.rotateIcon {
  transform: rotate(180deg);
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .addAndEditAssetModalPopup div {
    width: 70vw;
  }

  .addAndEditAssetModalBody .edit-body-cont .editAssetParentRow {
    height: 6vh;
  }
}

@media only screen and (max-width: 600px) {
  .addAndEditAssetModalPopup {
    margin-left: 0px;

    div {
      width: 95vw;
    }
  }

  .addAndEditAssetModalBody .edit-body-cont .editAssetParentRow {
    height: 8vh;
  }
}

.addAndEditAssetModalPopup{
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
    min-width: 50vw;
}
}