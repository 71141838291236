.offcanvas {
    border-radius: 10px 0px 0px 10px;

    .offcanvas-header {
        width: 100%;
    }

    .offcanvas-title {
        width: 100%;
    }

}

.acControllerModalmainContainerHeader {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    .acControllerModalmainContainerHeaderDetails {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 2px solid rgba(28, 76, 245, 1);
        padding: 0px 0px 8px 0px;

        .acControllerIcon {
            width: 23px;
            height: 23px;
        }

        .acControllerModalHeader {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .acControllerModalHeaderTitle {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: rgba(0, 0, 0, 1);
                margin: 0;
            }

            .acControllerModalHeaderSubTitle {
                color: rgba(112, 112, 112, 1);
                font-size: 11px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                margin: 0;
            }
        }
    }

    .closeIconDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 50px;

        &:hover {
            background-color: rgba(242, 245, 247, 1);
        }

        .closeIcon {
            width: 35px;
            height: 35px;
        }
    }

}

.rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.1s ease-in-out;
}

.rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.1s ease-in-out;
}

.acControllerContainerDiv {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .bodyContentMainDiv {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .acControllerHeadingDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            .acControllerArrowIcon {
                width: 14px;
                height: 14px;
            }

            .acControllerHeadingText {
                font-size: 14px;
                margin: 0;
                font-weight: 500;
            }
        }

        .acControllerDataInputDiv {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .acControllerDataDiv {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .uiModeToogle {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    .uiModeStatus {
                        font-size: 12px;
                    }

                }

                .acControllerCategory {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                }

                .acControllerInput {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgba(167, 184, 244, 1);
                    border-radius: 4px;
                    padding: 4px 6px;
                    cursor: pointer;
                    outline: none;

                    &::-webkit-calendar-picker-indicator {
                        filter: invert(60%) sepia(78%) saturate(3799%) hue-rotate(159deg) brightness(91%) contrast(101%);
                    }
                }

                .tempInputDiv {
                    display: flex;
                    flex-direction: row;
                    border: 1px solid rgba(167, 184, 244, 1);
                    border-radius: 4px;
                    align-items: center;
                    padding: 0px 8px 0px 8px;
                    height: 5vh;
                    width: 100%;

                    .temperatureInput {
                        border: none;
                        width: 5vw;
                        outline: none;
                        font-size: 14px;
                        background: transparent;
                    }

                    .tempUnit {
                        margin: 0;
                        width: 2vw;
                    }

                    .horizontalImage {
                        height: 20px;
                        width: 2vw;
                    }
                }

                .tempErrorMessage {
                    color: red;
                    font-size: 8px;
                    margin: 0;
                }
            }

        }

        .timeErrorMessage {
            color: red;
            font-size: 8px;
            margin: 0;
        }

        .weekdaysHeading {
            font-size: 13px;
            font-weight: 400;
            color: rgba(27, 27, 27, 1);
            margin: 0;
        }


        .weekDaysContainer {
            display: flex;
            flex-direction: row;
            gap: 5px;

            .weekDayText {
                width: 2vw;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;
            }

        }

        .successDivCont {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            margin-top: 12px;

            .successText {
                margin: 0;
                color: rgba(66, 173, 70, 1);
                font-size: 13px;
            }
        }

        .loading-container {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            margin-top: 12px;

            .loading-spinner {
                height: 18px;
                width: 18px;
                color: rgba(29, 81, 232, 1);
                position: relative;
                display: inline-block;
                border: 2px solid;
                border-radius: 50%;
                border-top-color: transparent;
                animation: rotate 1s linear infinite;
            }

            .acknowledgementWaitText{
                font-size: 12px;
                font-weight: 600;
                margin: 0;
            }
        }

        .applyButtonDiv {
            width: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 117, 255, 1);
            border: 1px solid rgba(87, 17, 226, 1);
            border-radius: 6px;
            cursor: pointer;
            padding: 3px 8px;
            margin: 8px 0px 0px 0px;

            .applyButtonText {
                margin: 0;
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                font-weight: 500;
            }
        }

    }


    .acControllerTimerContainer {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-bottom: 11px;

        .acControllerArrowIcon {
            width: 14px;
            height: 14px;
            margin-top: 3px;
            transition: transform 0.5s ease;
        }

        .timerMOdeText {
            font-size: 14px;
        }

        .timerModeDetails {
            display: flex;
            flex-direction: row;
            gap: 35px;

            .timerModeDetailsText {
                display: flex;
                flex-direction: column;

                .acControllerTimeContainerHeader {
                    font-size: 12px;
                }

                .acControllerTimeContainer {
                    border: 1px solid rgba(218, 226, 255, 1);
                    border-radius: 5px;
                    padding: 5px;
                    width: 100%;

                    .acControllerTimeContainerDetails {
                        display: flex;
                        flex-direction: row;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media only screen and (min-width: 1439px) and (max-width: 2560px) {
    .tempInputDiv {
        height: 3vh !important;

        .temperatureInput {
            width: 3vw !important;
        }
    }
}

@media only screen and (min-width: 599px) and (max-width: 1024px) {
    .tempInputDiv {
        height: 3vh !important;
    }

    .weekDaysContainer {
        gap: 7px !important;

        .weekDayText {
            width: 3vw !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    .tempInputDiv {
        height: 3vh !important;

        .temperatureInput {
            width: 11vw !important;
        }
    }

    .weekDaysContainer {
        gap: 7px !important;

        .weekDayText {
            width: 7vw !important;
        }
    }
}