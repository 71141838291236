.singleTypeFilterMainDiv {
    width: 100%;
    display: flex;

    .filterSelectorMainContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(5, 117, 230, 1);
        cursor: pointer;
        padding: 3px 12px 3px 12px;
        width: 100%;

        .chipFilterSelector {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 3px 5px;
            justify-content: space-between;
            gap: 8px;

            .chipFilterPlaceholder {
                font-size: 12px;
                font-weight: 400;
                color: rgba(112, 112, 112, 1);
                margin: 0;
            }

            .dropdownIcon {
                width: 12px;
                height: 12px;
            }

            .searchFilterInput {
                outline: none;
                border: none;
                width: 80%;
                font-size: 12px;
                font-weight: 500;
            }
        }

        .singleTypeFilterList {
            border: 1px solid #dfe2eb;
            list-style-type: none;
            margin: 0;
            padding: 4px 4px 4px 4px;
            border-radius: 4px;
            position: absolute;
            top: 37px;
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            z-index: 1;
            left: 0;
            background-color: #fff;

        }
    }

}


    .selectorMainContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(225, 227, 240, 1);
        cursor: pointer;
        padding: 4px 12px 4px 12px;

        &:not([disabled]):hover {
            background-color: rgba(245, 246, 250, 1);
            border-color: rgba(189, 203, 251, 1);
        }

        &[disabled] {
            cursor: default;
        }

        .filterSelector {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 3px 5px;
            justify-content: space-between;
            gap: 8px;
            width: 100%;

            .chipFilterPlaceholder {
                font-size: 12px;
                font-weight: 400;
                color: rgba(112, 112, 112, 1);
                margin: 0;

                .selectedFilterCount {
                    background-color: rgba(207, 217, 254, 1);
                    border-radius: 2px;
                    padding: 2px 4px 2px 4px;
                    margin: 0px 8px 0px 8px;
                }

            }

            .dropdownIcon {
                width: 12px;
                height: 12px;
            }

        }
    }

    .singleTypeFilterList::-webkit-scrollbar {
        width: 8px;
    }