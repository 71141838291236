.facilityDropsCardsCont {
  border: 1px solid #402d9f;
  background-color: #f0f3ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  width: 100%;
  height: 7vh;
  margin-bottom: 5px;
  // position: relative;
  .facilityCompanyCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 27%;
    // background-color: #402d9f;
    gap: 5px;
    img {
      height: 12px;
    }
    p {
      margin: 0px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .regionAndCacilitiesCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    justify-content: left;
    // background-color: #402d9f;
    gap: 5px;
    p {
      margin: 0px;
      font-size: 12px;
      font-weight: 500;
      color: #707070;
    }
  }
  .subGroupsAndUsersBtnsCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 29%;
    // background-color: #402d9f;
    gap: 5px;
    .subGroupsBtn {
      font-size: 10px;
      border: 1px solid #000000;
      background-color: #ffffff;
      padding: 2px 5px 2px 5px;
      border-radius: 2px;
      font-weight: 500;
      img {
        height: 13px;
        width: 15px;
        margin-right: 2px;
        margin-bottom: 1px;
      }
    }
    .addUsersBtn {
      font-size: 10px;
      border: 1px solid #000000;
      background-color: #ffffff;
      padding: 2px 5px 2px 5px;
      border-radius: 2px;
      font-weight: 500;
      img {
        height: 11px;
        width: 15px;
        margin-right: 2px;
        margin-bottom: 1px;
      }
    }
  }
  .facilityUsersProfiles {
    display: flex;
    flex-direction: row;
    width: 9%;
    img {
      height: 25px;
      width: 65px;
    }
  }
  .cardFunctionsComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 5px;
    position: relative;
    .facilityGroupIcons {
      width: 30px;
    }
    .facilityGroupIcons:hover {
      background-color: white;
      border-radius: 3px;
    }
  }

  .dropCardImgCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      height: 15px;
      cursor: pointer;
      width: 15px;
    }
  }
}

.moveCardCont {
  background-color: white;
  border: 1px solid #00000029;
  position: absolute;
  right: 2px;
  top: 25px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  box-shadow: 1px 5px 7.5px #00000029;
  width: 13vw;
  z-index: 100;
  .moveCardHeaderCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .moveCardHeader {
      margin: 0px;
      font-size: 14px;
      font-weight: 600;
    }
    .closeIconDiv {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .moveCardCloseIcon {
        height: 10px;
        width: 10px;
        cursor: pointer;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .moveCardSelectCont {
    border: 1px solid #bdcbfb;
    outline: none;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #707070;
    padding: 5px;
    margin-top: 10px;
  }
  .moveCardBtnCont {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    .moveCardBtn {
      border: none;
      background-color: #0f43f9;
      color: #ffffff;
      font-size: 12px;
      padding: 5px 10px 5px 8px;
      border-radius: 2px;
      font-weight: 300;
      .moveCardImg {
        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
    }
  }
}

.editToolTip {
  margin: 0px;
  font-size: 9px;
  font-weight: 600;
  position: absolute;
  top: 24px;
  left: -18px;
  background-color: white;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.disableToolTip {
  margin: 0px;
  font-size: 9px;
  font-weight: 600;
  position: absolute;
  top: 24px;
  left: 14px;
  background-color: white;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.deleteToolTip {
  margin: 0px;
  font-size: 9px;
  font-weight: 600;
  position: absolute;
  top: 24px;
  left: 50px;
  background-color: white;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.moveToolTip {
  margin: 0px;
  font-size: 9px;
  font-weight: 600;
  position: absolute;
  top: 24px;
  right: -15px;
  background-color: white;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
