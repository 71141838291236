.adAirConsuptionCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    .cardDetailsCont {
      min-width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .cardDetails {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        position: relative;
        left: 30px;
        top: 6px;
        .cardHeader {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 3px;
        }
        .imgContainer {
          display: flex;
          flex-direction: row;
          .imgCountCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .icons {
              height: 23px;
              width: 23px;
              position: relative;
              bottom: 7px;
              margin-right: 8px;
            }
            .counts {
              font-size: 13px;
              font-family: "Liquid Crystal" !important;
              padding-left: 3px;
              font-weight: 600;
              min-width: 5vw;
              display: flex;
              flex-direction: row;
              gap: 3px;
              align-items: baseline;

              .liquidCrystalFont{
                font-family: "Light";
                letter-spacing: 2px;
              }
            }
          }
        }
      }
    }
    .cardImages {
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      .image1 {
        height: 80px;
        width: 60px;
        position: relative;
        top: 12px;
        right: 25px;
        opacity: 0.2;
      }
      .image2 {
        height: 70px;
        width: 50px;
        position: relative;
        top: 16px;
        right: 20px;
        opacity: 0.2;
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    .adEnergyConsuptionCont {
      .cardDetailsCont {
        .cardDetails {
          .cardHeader {
            font-size: 9px;
          }
          .imgContainer {
            .imgCountCont {
              .counts {
                font-size: 8px;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  