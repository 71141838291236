// .cardWrapper {
//     margin-bottom: 20px;
//     &-header {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         flex-direction: row;
//         padding: 5px;
//         h2{ 
//             margin: 0px;
//         }
//     }
//     &-body {
//         background-color: #fff;
//         border: 1px solid #DAE2FF;
//         border-radius: 6px;
//         position: relative;
//         box-shadow: 0px 3px 6px #00000029;
//         border: 1px solid #DAE2FF;
//         border-radius: 6px 6px 0px 0px;
//     }
//     &-children {
//         padding-left: 10px;
//         padding-right: 10px;
//     }
//     &-footer {
//         background-color: #fff;
//         border-top: 1px solid #000;
//         padding: 5px;
//         p {
//             padding: 5px;
//         }
//         a {
//             color: #038aff;
//             cursor: pointer;
//             text-decoration: underline;
//         }
//     }
//     .ctaIcons {
//         display: flex;
//         >div {
//             border: 1px solid #fff;
//             border-radius: 3px;
//             cursor: pointer;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 35px;
//             height: 35px;
//             margin-left: 6px;
//             padding: 1px;
//             i {
//                 margin: 0px !important;
//             }
//         }
//     }
//     .info-tip {
//         position: absolute;
//         top: 20px;
//         right: 20px;
//         width: 30px;
//         height: 30px;
//         border: 2px solid;
//         border-radius: 30px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         z-index: 999;
//     }
// }
// component Name: CardWrapper
// path - a1Components/activitySubAlerts/index.tsx
// scss end

// component Name: CardWrapper
// path - a1Components/CardWrapper/index.tsx
// scss start
.cardWrapper {
    margin-top: 1.2rem;
    margin-right: 1px;
        margin-left: 1px;
        margin-bottom: 10vh;
        transition: width 1s ease;
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 5px;

        h2 {
            font-size: 1.05rem !important;
            line-height: 20px !important;
            margin-bottom: 0px;
        }

        .icon_Placement {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            min-width: 5vw;
        }
    }

    &-body {
        background-color: #fff;
        border-radius: 6px;
        position: relative;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
        border: 1px solid #dae2ff;

        .tab_component {
            height: 20vh;
            border-bottom: 1.4px solid #dadada;
        }
    }

    &-children {
        padding-left: 10px;
        padding-right: 10px;
    }

    &-footer {
        background-color: #fff;
        border-top: 1.5px solid #dae2ff;
        padding: 5px;
        border-radius: 6px;

        p {
            padding: 2px;
            font-size: 12px;
            padding-left: 15px;
            margin-bottom: 0rem !important;
        }

        a {
            color: #038aff;
            cursor: pointer;
            font-weight: 600;
            text-decoration: underline;
        }
    }
    

    .ctaIcons {
        cursor: pointer;
        margin-left: 3px;
        background-color: #fff;
        border-color: #fff;
        width:2vw;
        text-align: center; 
        >div {
            border: 1px solid #fff;
            border-radius: 3px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            margin-left: 6px;
            padding: 1px;

            i {
                margin: 0px !important;
            }
        }
    }

    .info-tip {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        &:hover img {
            color: gray;
        }
    }

    // some pop-up alignment changes have done
    .tooltip-content {
        position: absolute;
        display: flex;
        top: -13px;
        background-color: #426bfa;
        padding: 15px;
        border-radius: 6px;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-50%) translateY(50px);
        transition: 0.5s;
        height: auto;
        min-width: 256px;
        right: -124px;
        padding-bottom: 0px;
    }

    .tooltip-content::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: #426bfa;
        top: -6px;
        right: 6px;
        // left: 60%;
        transform: rotate(45deg);
    }

    .info-tip:hover .tooltip-content {
        visibility: visible;
        opacity: 1;
        // transform: translateX(-50%) translateY(0px);
    }

    // some font change have been done
    .tooltip-content p {
        color: #fff;
        align-items: center;
        font-size: 12px;
        padding: 3px;
    }

    .info-tip:hover {
        background-color: #e5e5e5 !important;
        border-radius: 50%;
        // opacity: 0.8;
    }
}

// component Name: CardWrapper
// path - a1Components/CardWrapper/index.tsx
// scss end



//media for mobiles
@media only screen and (max-width: 600px) {
.ctaIcons {
    cursor: pointer;
    margin-left: 3px;
    background-color: #fff;
    border-color: #fff;
    width:6vw !important;
    text-align: center; 
    span{
        div{
            width:6vw !important;
        }
    }
}
}