.eventContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  .dropdownCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: flex-start;
    .selector {
      font-size: 12px;
      padding: 5px 5px 5px 5px;
      border-radius: 5px;
      font-weight: 500;
      border: 1px solid blue;
      margin-left: 30px;
      margin-top: 10px;
    }
  }
  .headerCont {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid brown;
    p {
      font-size: 13px;
      font-weight: 600;
      color: black;
      position: relative;
      top: 8px;
    }
  }
  .detailsCont {
    background-color: #f0f3ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 5px;
      border-radius: 3px;
    .detailsHeaderCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: 0px solid #dae2ff;
      width: 100%;
      padding: 5px 5px 0px 5px;
      border-radius: 3px;
      .detailsHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        top: 5px;
        .headerIcon {
          height: 10px;
          width: 15px;
          position: relative;
          bottom: 8px;
          margin-right: 5px;
          transition: 0.5;
        }
        .headerIconActive {
          transform: rotate(90deg);
        }
        img {
          height: 12px;
          width: 20px;
          position: relative;
          bottom: 8px;
          cursor: pointer;
        }
        p {
          font-size: 13px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .count {
        font-size: 13px;
        position: relative;
        top: 7px;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .eventContainer .detailsCont {
    background-color: #f0f3ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 109vw;
    padding: 5px;
    border-radius: 3px;
  }

}