.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2pxrgba(0, 0, 0, 0.9);
  background: #0032ff !important;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.carousel .thumbs-wrapper {
  margin: -15px 9px !important;
  overflow: hidden;
}

.carousel .control-dots {
  position: absolute;
  bottom: -1px !important;
  margin: 0px 0 !important;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}
::-webkit-scrollbar {
  width: 3px;
  height: 8px;
  cursor:grab !important;
 scroll-behavior: smooth;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6125f4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6125f4;
  width: 4px !important;
  cursor: pointer !important;

}
::-webkit-scrollbar:hover {

  width: 4px !important;
  cursor: pointer !important;
  scroll-behavior: smooth;
}

.ticketstable:hover{
  cursor: pointer;
}

.ticketstable {
  max-height: 65vh;
  overflow: scroll;
  margin-top: 20px;

  .backButton {
    left: 0;
    height: 6vh;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background: #ffffff;
    text-align: left;
  }

  table {
    width: 100%;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    border: 0;

    tbody {

      .divBorder {
        border: 1px solid #bdcbfb;
        margin-bottom: 10px;
        margin-top: 17px;
        border-radius: 6px;
        margin-left: 10px;
        margin-right: 10px;

        .ticketStatus {
          display: flex;
          justify-content: space-between;
          padding: 6px 7px 0px 7px;
          background-color: #faaca7;
          margin: 5px;
          border-radius: 3px;
          h3 {
            font-size: 12px;
          }
        }

        .mobileHeader {
          border-top-right-radius: 6px;
          /* display: block !important; */
          margin: 0px;
          background-color: #f0f3ff;
          padding: 5px 10px 0px 10px;
          border-bottom: 1px solid #bdcbfb;
          border-top-left-radius: 6px;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          font-size: 12px;

          h3{
            font-size: 12px;
          }


        }

        tr {
          border-bottom: 0px solid #ddd;
          margin-bottom: 0rem;
          display: flex;
          flex-direction: row;
          align-content: flex-start;
          justify-content: space-between;
          overflow-x: auto;
          background-color: rgba(127, 255, 212, 0);
          padding: 0px 7px 0px 7px;

          .headerMobile {
            h3 {
              display: block !important;
              text-align: center;
              font-size: 12px;
            }

            &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              .tdStyleScssEng {
                .mobileViewStyle {
                  .navClickStyles {
  
                    border: 0px solid red !important;
                    background-color: #ff0000 !important;
                    /* color: black !important; */
                    font-size: 12px;
                    min-width: 0px;
                    width: 0px;
                    font-weight: 500;
                    width: 100%;
                    font-size: 12px;
                    color: white !important;
                  }
                }
              }
            }

          }

          .tdStyleScssEng {
            .mobileViewStyle {
              .navClickStyles {
                border: 0px solid red !important;
                background-color: #ffffff !important;
                color: black !important;
                font-size: 12px;
                min-width: 0px;
                width: 0px;
                font-weight: 500;
                width: 100%;
                font-size: 13px;


              }

            }

          }

          td {

            text-align: center !important;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-around;
          }

         

        }
      }

    }
  }

  table caption {
    font-size: 1.3em;
  }


  table td::before {
    /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
    content: attr(data-label);
    float: left;
    font-weight: bold;
        text-transform: capitalize;
  }

  table td:last-child {
    border-bottom: 0;
  }

  .tdStyleScssEng {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .ticketstable {
    overflow-x: auto;
    max-height: 55vh;

    .divBorder {
      border: 1px solid #bdcbfb;
      margin-bottom: 10px;
      margin-top: 17px;
      border-radius: 6px;
      width: 76vw;

      .mobileHeader {
        border-top-right-radius: 6px;
      
        margin: 0px;
        background-color: #f0f3ff;
        padding: 11px;
        border-bottom: 1px solid #bdcbfb;
        border-top-left-radius: 6px;
        width: 76vw;

      }
    }
  }
}