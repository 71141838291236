.summaryCards {
  padding: 10px 15px;
  border: 1px solid #dae2ff;
  margin: 4px;
  position: relative;
  border-radius: 5px;
  img {
    padding: 0px;
    float: right;
    background: #f2f5f7;
    margin-right: -10px;
    margin-top: -3px;
  }
  p {
    margin-bottom: 0rem !important;
  }
  .cardValue {
    display: flex;
    align-items: center;
    h3 {
      color: #4caf50;
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0px;
    }
    h6 {
      margin-bottom: -5px;
      font-weight: 500;
      line-height: 1.375rem;
      display: flex;
      align-items: center;
      font-size: 12px;
      svg {
        border-radius: 7px;
        padding: 3px 4px;
        font-size: 8px;
        margin-right: 5px;

        color: white;
        margin-left: 20px;
      }
    }
  }
  .idealValue {
    background: #daecfc 0% 0% no-repeat padding-box;
    border: 1px solid #9fb4fd;
    border-radius: 4px;
    color: black;
    opacity: 1;
    text-decoration: none;
    border-radius: 6px;
    height: 22px;
    display: inline-block;
    overflow: hidden;
    transition: all 0.5s;
    padding: 0px;
    margin-top: 5px;
    img {
      font-size: 12px !important;
      width: 1rem;
      height: 1rem;
      background: none;
    }
    .text {
      font-size: 12px;
      margin-right: 2px;
      padding: 2px 5px 5px 5px;
      display: flex;
      align-items: start !important;
      img {
        margin-right: 3px;
        margin-top: 0px;
      }
      span {
        display: none;
      }
    }
    &:hover {
      transition: all 0.5s;
      .text {
        span {
          display: inline-block;
        }
      }
    }
    .icon {
      font-size: 12px;
      margin-right: 2px;
      padding: 1px 0px 3px 9px;
      display: flex;
      align-items: start !important;
      img {
        margin-right: 3px;
        margin-top: 1px;
      }
      span {
        display: none;
      }
    }
    &:hover {
      transition: all 0.5s;
      .icon {
        span {
          display: inline-block;
        }
        .text {
          margin-bottom: 5px;
        }
      }
    }
  }
  .tooltip-content {
    position: absolute;
    background-color: #426bfa;
    transform: translateX(1%) translateY(10px);
    transition: 0.2s;
    min-width: 131px;
    padding: 2px;
    border-radius: 4px;
    margin-top: 37px;
    margin-left: 100px;
    visibility: hidden;
    right: 10px;
    z-index: 9;
  }
  .summaryCards__faDownload:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
    transform: translateX(0%) translateY(0px);
  }
  .tooltip-content::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: #426bfa;
    top: -6px;
    right: 6px;
    transform: rotate(45deg);
  }
  .tooltip-content p {
    padding-left: 10px;
    color: #fff;
    margin-bottom: 0;
    font-size: 12px;
  }
  .cardValue > h3 > h6 {
    margin-bottom: 0rem !important;
  }
  .cardTitle h4 p {
    font-size: 12px;
    color: #000000;
  }
}
.cardValue .positive {
  color: #4caf50;
  svg {
    background: #4caf50;
  }
}
.cardValue .negative {
  color: #f44336;
  svg {
    background: #f44336 !important;
  }
}

@media only screen and (max-width: 600px) {
  .idealValue {
    span {
      display: inline-block !important;
    }
    &:hover {
      transition: all 0.5s;
      .text {
        span {
          display: none !important;
        }
      }
    }
  }
}
