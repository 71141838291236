.trackntraceContainer {
  padding-left: 22px;
  padding-right: 10px;
  margin-top: 10px;
  background-color: #fff;
  height: 81.8vh;
  margin-left: 7px;
  width: 98%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  .headerSearchCont {
    height: 12%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 0px 20px;
    h4 {
      margin: 0px;
      font-size: 25px !important;
      font-weight: 500 !important;
    }
    //.settingsSearchCont {
    //  display: flex;
    //  position: relative;
    //  align-items: center;
    //  .settingSearch {
    //    outline: none;
    //    font-size: 10px;
    //    padding: 5px 0px 5px 25px;
    //    border-radius: 5px;
    //    border: 1px solid #a7b8f4;
    //    background-color: #ffffff;
    //    color: #8f94a5;
    //    justify-content: space-between;
    //  }
    //  img {
    //    //position: absolute;
    //    //height: 15px;
    //    //width: 20px;
    //    //margin-left: 5px;
    //    //cursor: pointer;
    //    //flex: 1;
    //  }
    //}
  }

  .settingsSearchCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.36667rem 0.54167rem;
    width: 17.61111rem;
    min-width: 17.61111rem;
    border-radius: 5px;
    border: 1px solid #a7b8f4;
    background: #ffffff;

    .left-element {
      margin-right: 5px;
      width: 100px;
    }

    .middle-element {
      flex: 2;
      width: 100%;
      text-decoration-color: #cccccc;
    }

    input {
      outline: none;
      font-size: 0.66667rem;
      border: none;
    }

    .right-element-close {
      margin-left: 10px;
      width: 1rem;
      height: 1rem;
    }

    .icon-search {
      display: inline-block;
      position: fixed;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      will-change: transform, opacity;
    }
    .icon-search::before {
      content: "";
      display: inline-block;
      width: 10%;
      height: 40%;
      background: #585858;
      position: absolute;
      left: 75%;
      top: 75%;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: all 0.3s;
    }
    .icon-search::after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 70%;
      height: 70%;
      border: 1.5px solid #585858;
      border-radius: 50%;
      top: 40%;
      left: 40%;
      transform: translate(-50%, -50%) rotate(0deg);
      transition: all 0.3s;
    }
    .icon-search.close::before {
      height: 80%;
      left: 50%;
      top: 50%;
    }
    .icon-search.close::after {
      border-width: 1px;
      border-radius: 0;
      background: #585858;
      width: 0;
      height: 70%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: all 0.3s, background 0s 0.3s;
    }

    .dropdown-toggle {
      width: 100% !important;
      height: 20px !important;
      border: #ffffff !important;
      background: #dee7fc;
      border-radius: 40px !important;
      z-index: 10 !important;
    }

    .dropdown-menu {
      z-index: 9 !important;
      width: 17.64rem !important;
      margin-left: -0.6rem !important;
      font-size: 0.66667rem;
      border-color: #bdcbfb;
      background: #f1f4fc !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-top: none !important;
      margin-top: 2px !important;
    }
  }

  .width-100 {
    width: 100%;
  }

  .settingsNavCont {
    height: 6%;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 10px;
    margin-right: 18px;
    border-bottom: 1px solid #eaecf1;
    margin-top: 1%;
    .settingsNav {
      width: 100%;
      display: flex;
      //justify-content: space-between;
      .settingsHeaders {
        margin: 0px;
        width: 20%;
        max-width: 336px;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
      }
      .activeSettingsHeaders {
        margin: 0px;
        max-width: 336px;

        width: 20%;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid #0075ff;
        color: #0075ff;
        cursor: pointer;
      }
    }

    .settingsPaginationCount {
      color: #000;
      text-align: right;
      font-family: Inter, serif;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 20%;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .trackntraceContainer {
    .headerSearchCont {
      height: 10%;
    }
    .settingsNavCont {
      height: 4%;
      .settingsNav {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .trackntraceContainer {
    .headerSearchCont {
      h4 {
        font-size: 20px !important;
      }
      .settingsSearchCont {
        img {
          height: 15px;
          width: 15px;
          margin-left: 10px;
        }
        .settingSearch {
          font-size: 10px;
        }
      }
    }
    .settingsNavCont {
      height: 3%;
      .settingsNav {
        width: 100%;
        .settingsHeaders {
          font-size: 8px;
          font-weight: 500;
        }
        .activeSettingsHeaders {
          font-size: 8px;
          font-weight: 500;
        }
      }
      .settingsPaginationCount {
        font-size: 8px;
        width: 30%;
      }
    }
  }
}
