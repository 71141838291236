.customToast-Div {
    display: flex;
    background-color: #333;
    position: absolute;
  .custom-toast {
    position: fixed;
    //top: 20px;
    left: 10vw;
    bottom: 8vh;
    // background-color: #FCA9A4;
    color: #000;
    //width: 5%;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    width: 45vw;
    //animation: fadeOut 4s ease-in forwards;
    z-index: 9999;
    .toast-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px;
      .image_Div {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        .email-icon {
          height: 40;
          width: 40;
        }
      }
      .middle_Div {
        width: 80%;
        h6{
            margin: 0;
            padding: 0;
            color: #000000;
            font-size: 14px;
        }
        .message {
          margin: 0;
          padding: 0;
          margin-top: 3px;
          color: #000000;
          font-size: 12px;
        }
        .anchor {
            text-decoration: underline;
            color: #1805eb;
            cursor: pointer;
            width: max-content;
            margin: 0;
            padding: 0;
            margin-top: 3px;
            font-size: 12px;
        }
        p{
            margin: 0;
            padding: 0;
            font-size: 12px;
        }
      }
      .end_Div {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        .toast-close {
          width: 10%;
          background-color: transparent;
          border: none;
          height: 25px;
          width: 25px;
          color: #000;
          cursor: pointer;
        }
      }
    }
  }
}

@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
