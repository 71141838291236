//Delete Facility Popup
.deleteFacilityHeaderCont {
  .deleteFacilityHeader {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin: 0;
  }

  
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .deleteFacilityCloseIcon {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.deleteFacilityBodyCont {
  padding: 5px !important;
}
.deleteFacilityBody {
  display: flex;
  flex-direction: column;
  margin: 5px;
  .deleteFacilityTxtCont {
    padding: 10px;
    margin: 0 0 10px 0;
    p {
      font-size: 15px;
      margin: 0;
      font-weight: 500;
    }
  }
  .deleteFacilityWarningCont {
    border-left: 5px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f4433659;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    .warningImgTxtCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      .warningIcon {
        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
      p {
        margin: 0;
        font-size: 15px;
        font-weight: 600;
      }
    }
    .warningTxt {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
.shiftAssetsBtn {
  background-color: #ffffff;
  border: 1px solid #6d7073;
  color: #6d7073;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-size: 15px;
  font-weight: 400;
}
.deleteButton {
  background-color: #ea5037;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-size: 15px;
  font-weight: 400;
}

.deletePopModal {
  //margin-top: 6%;
}
