.lastUpdated {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  h3 {
    font-size: 8px;
    margin: 0px;
    color: #0b0b0c;
    margin-left: 55px;
    margin-bottom: 7.5px;
    margin-top: -10px;
    z-index: 0;
  }
}
.lastUpdatedTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  .showTime {
    h3 {
      font-size: 8px;
      margin-left: 12%;
      color: #0b0b0c;
    }
  }
}
.assetCard {
  border: 1px solid #dae2ff;
  border-bottom: 4px solid #4caf50;
  border-radius: 6px;
  margin: 6px 4.5px;
  &.Excellent {
    border-radius: 5px;
    border-bottom: 3px solid #4caf50;
  }
  &.Good {
    border-bottom: 3px solid #ffc107;
  }
  &.Poor {
    border-bottom: 3px solid #f44336;
  }
  .assetCard-col-badge {
    &.Excellent {
      background-color: rgba(54, 215, 183, 0.13);
      color: #3dac85;
    }
  }
  .assetCard-col-badge {
    &.Good {
      background-color: #ffecb3;
      color: #f39c12;
    }
  }
  .assetCard-col-badge {
    &.Poor {
      background-color: rgba(255, 76, 48, 0.13);
      color: #ff4c30;
    }
  }
  .assetCard-info-extend {
    position: absolute;
    top: 10px;
    right: 8px;
  }
  ul {
    list-style-type: none;
    display: flex;
    position: relative;
  }
  li {
    background-color: #ffc107;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 2px;
    transition: all 5s linear;
    padding: 5px;
    &:first-child,
    &:last-child {
      background-color: #f44336;
    }
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(3%)
        saturate(7373%) hue-rotate(205deg) brightness(118%) contrast(101%);
      width: 12px;
      height: 12px;
      margin-right: 2px;
    }
    p {
      color: #fff;
      display: flex;
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 0px;
      text-transform: capitalize;
      transition: all 2s !important;
      span {
        display: none;
        margin-left: 3px;
      }
    }
    &:hover {
      p {
        span {
          transition-delay: 5s;
          display: inline-block;
          transition-delay: 5s;
        }
      }
    }
  }
  section {
    display: flex;
  }
  &-header {
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px 8px;
    section {
      img {
        width: 30px;
        margin-right: 10px;
        margin-left: 7.5px;
        object-fit: contain;
      }
      .header_styleProp {
        width: 9vw;
      }
    }
    h6 {
      color: #9f9f9f;
      font-size: 10px;
      font-weight: 700;
      margin-bottom: 0px;
    }
    p {
      color: #131523;
      font-size: 10px;
      line-height: 12px;
      margin-top: 2.5px;
      margin-bottom: 5px;
    }
  }
  &-colum {
    background-color: #f5f6fa;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    padding: 10px;
     height: 13vw;
    //height: 33vh;
    &:after {
      background-color: #d7dbec;
      content: "";
      position: absolute;
      width: 95%;
      height: 1px;
      top: 54%;
      left: 8px;
    }
  }
  &-col-badge {
    padding: 2px 8px;
    border-radius: 10px;
    position: relative;
    top: -5px;
    right: -5px;
  }
  &-col {
    flex: 0 0 auto;
    width: 50% !important;
    // padding: 8px 0px;
    position: relative;
    min-height: 65px;
    padding: 10px 3px 10px 3px;
    img {
      width: 12px;
    }
    &:nth-child(even) {
      &:before {
        background-color: #d7dbec;
        content: "";
        position: absolute;
        width: 1px;
        height: 75%;
        top: 8px;
        left: -4px;
      }
    }
    p {
      color: #707070;
      font-size: 10px;
      margin-bottom: 0px;
      margin-top: 0px;
      width: 7vw;
    }
    h2 {
      color: #0b0b0c;
      font-weight: 600 !important;
      font-size: 13px !important;
      line-height: 26px !important;
      margin-bottom: 0px;
      margin-right: 8px;
      span {
        font-size: 13px;

        &.assetCard-col-badge {
          font-size: 9px;
          line-height: 15px;
        }
      }
      .fontSpan {
        color: #707070;
        font-size: 10px;
        margin-bottom: 0px;
        margin-left: 8px;
      }
    }
    h6 {
      color: #131523;
      font-size: 8px;
      margin-bottom: 0px;
    }
  }
  &-footer {
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    p {
      margin-bottom: 0px;
      padding: 8px 10px;
      border-bottom: 1px solid #d7dbec;
      font-size: 12px;
      color: #131523;

      b {
        margin-left: 5px;
        font-size: 10px;
      }
    }
    div {
      border-radius: 3px;
      display: inline-block;
      padding: 2px 8px 2px 5px;
      margin: 1px 1px;
      &.inActiveBadge {
        background-color: #f44747;
      }
      &.activeBadge {
        background-color: #5aa15d;
      }
      &.offlineBadge {
        background-color: #f44336;
      }
      &.onlineBadge {
        background-color: #4caf50;
      }
      &.slowBadge {
        background-color: #ffc107;
      }
      &.lostBadge {
        background-color: #df4320;
      }
      &.maintenanceBadge {
        background-color: #e9be03;
      }
      span {
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        display: block;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

.disable {
  .assetCard {
    border: 1px solid #dae2ff;
    border-bottom: 4px solid #707070;
    opacity: 0.8;
    cursor: default;
    filter: grayscale(1);
    &.Excellent {
      border-bottom: 3px solid #707070;
      .assetCard-col-badge {
        background-color: #f0f0f0;
        color: #707070;
      }
    }
    &.Good {
      border-bottom: 3px solid #707070;
      .assetCard-col-badge {
        background-color: #f0f0f0;
        color: #707070;
      }
    }
    &.Poor {
      border-bottom: 3px solid #707070;
      .assetCard-col-badge {
        background-color: #f0f0f0;
        color: #707070;
      }
    }
    li {
      background-color: #707070;
      &:first-child,
      &:last-child {
        background-color: #707070;
      }
    }
    &-header {
      background-color: #f0f0f0;
      section {
        img {
          filter: grayscale(100%);
        }
      }
    }
    &-footer {
      background-color: #f0f0f0;
      p {
        border-bottom: 1px solid #dae2ff;
      }
      div {
        &.in-activeBadge {
          background-color: #707070;
        }
        &.activeBadge {
          background-color: #4caf50;
        }
        &.slow-badge {
          background-color: #ffc107;
        }
      }
    }
  }
}

.imgInfoAssetCard {
  display: flex;

  img {
    width: 1vw;
    margin-right: 4px;
    margin-top: -3px;
  }
}
// media query

@media only screen and (max-width: 600px) {
  .assetCard-header section .header_styleProp {
    width: 50vw;
  }
}



