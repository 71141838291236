.airQualityLiveDataCard {
  display: flex;
  width: 100%;

  .cardsRowCont {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .rowOne {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .airQualityContainer {
        margin: 0;
        padding: 0;
        text-align: center;

        .cardHeading {
          margin: 0;
          padding: 0;
          font-size: 0.7rem;
          color: #000000;
          font-weight: 500;
          margin-left: 0.5rem;
          text-align: center;
        }

        .cardValuesCont {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 4px;
          padding: 5px;

          .cardIcon {
            margin: 0;
            padding: 0;
            margin-right: 3px;
            cursor: default;
          }

          .card1Values {
            margin: 0;
            padding: 0;
            font-size: 0.7rem;
          }
        }

        .cardCelcius {
          margin: 0;
          padding: 0;
          text-align: center;
          font-size: 0.5rem;
        }
      }
    }

    .rowTwo {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 4%;

      .airQualityContainer {
        margin: 0;
        padding: 0;
        text-align: center;

        .cardHeading {
          margin: 0;
          padding: 0;
          font-size: 0.7rem;
          color: #000000;
          font-weight: 500;
          margin-left: 0.5rem;
          text-align: center;
        }

        .cardValuesCont {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 4px;
          padding: 5px;

          .cardIcon {
            margin: 0;
            padding: 0;
            margin-right: 3px;
            cursor: default;
          }

          .card1Values {
            margin: 0;
            padding: 0;
            font-size: 0.7rem;
          }
        }

        .cardCelcius {
          margin: 0;
          padding: 0;
          text-align: center;
          font-size: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .airQualityLiveDataCard {
    .cardsRowCont {
      .rowOne {
        .airQualityContainer {
          .cardHeading {
            font-size: 0.5rem;
          }

          .cardValuesCont {
            margin-top: 3px;
            padding: 3px;

            .cardIcon {
              height: 10px;
              width: 10px;
              cursor: default;
            }

            .card1Values {
              font-size: 0.4rem;
            }
          }

          .cardCelcius {
            font-size: 0.3rem;
          }
        }
      }

      .rowTwo {
        .airQualityContainer {
          .cardHeading {
            font-size: 0.5rem;
          }

          .cardValuesCont {
            margin-top: 3px;
            padding: 3px;

            .cardIcon {
              height: 10px;
              width: 10px;
              cursor: default;
            }

            .card1Values {
              font-size: 0.4rem;
            }
          }

          .cardCelcius {
            font-size: 0.3rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .airQualityLiveDataCard {
    .cardsRowCont {
      .rowOne {
        .airQualityContainer {
          .cardHeading {
            font-size: 0.8rem;
          }

          .cardValuesCont {
            margin-top: 5px;
            padding: 4px;

            .cardIcon {
            }

            .card1Values {
              font-size: 0.7rem;
            }
          }

          .cardCelcius {
            font-size: 0.5rem;
          }
        }
      }

      .rowTwo {
        .airQualityContainer {
          .cardHeading {
            font-size: 0.8rem;
          }

          .cardValuesCont {
            margin-top: 5px;
            padding: 4px;

            .cardIcon {
            }

            .card1Values {
              font-size: 0.7rem;
            }
          }

          .cardCelcius {
            font-size: 0.5rem;
          }
        }
      }
    }
  }
}
