.ButtonContainer {
  background-color: #0458c3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  .subContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    align-items: center;
    .down-arrow-image {
      height: 14px;
      margin-right: 6px;
      margin-left: -4px;
    }
    p {
      margin-top: 17px;
      font-size: 12px;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ButtonContainer .subContainer .down-arrow-image {
    height: 10px;
  }
  .ButtonContainer .subContainer p {
    margin-top: 15px;
    font-size: 8px;
    color: #ffffff;
  }
}
