.faultDetailsContainer {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .faultDetailsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 5px;
    height: 5vh;
    border-bottom: 1px solid #e7e7e7;
    .faultDetailsAssetId {
      font-size: 13px;
      color: #727377;
      font-weight: 500;
    }
    .faultDetailsFaultType {
      font-size: 13px;
      color: #727377;
      font-weight: 500;
    }
    .faultDetailsCondition {
      font-size: 13px;
      color: #727377;
      font-weight: 500;
    }
    .faultDetailsLocation {
      font-size: 13px;
      color: #727377;
      font-weight: 500;
    }
    .faultDetailsRecommendations {
      font-size: 13px;
      color: #727377;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 600px) {
  .faultDetailsContainer {
    .faultDetailsHeader {
      height: 3vh;
      .faultDetailsAssetId {
        font-size: 9px;
      }
      .faultDetailsFaultType {
        font-size: 9px;
      }
      .faultDetailsCondition {
        font-size: 9px;
      }
      .faultDetailsLocation {
        font-size: 9px;
      }
      .faultDetailsRecommendations {
        font-size: 9px;
      }
    }
  }

  .faultDetailsCardContainer {
    .motorTxt {
      font-size: 8px;
    }
    .faultTxt {
      font-size: 8px;
    }
    .conditionTxt {
      font-size: 8px;
      padding: 2px;
    }
    .locationContainer {
      .areaTxt {
        font-size: 8px;
      }
      .addressTxt {
        font-size: 8px;
      }
    }
    .checkTxt {
      font-size: 8px;
    }
    .moreImg {
      height: 12px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .faultDetailsContainer .faultDetailsHeader {
    height: 3vh;
  }

  .faultDetailsCardContainer {
    .motorTxt {
      font-size: 11px;
    }
    .faultTxt {
      font-size: 11px;
      position: relative;
      left: 10px;
    }
    .conditionTxt {
      font-size: 11px;
      padding: 4px;
      position: relative;
      left: 22px;
    }
    .locationContainer {
      position: relative;
      left: 22px;
    }
    .checkTxt {
      font-size: 11px;
      position: relative;
      left: 50px;
    }
    .moreImg {
      height: 18px;
      position: relative;
      left: 30px;
    }
  }
}
