.toggleButtonContainer {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 18px;
    margin-left: 4%;
    margin-top: 2%;        
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #F5F6FA;
        transition: 0.3s;
        border-radius: 30px;
        border: 1px solid #5038F5;
    }
    span:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 3px;
        bottom: 1.5px;
        background-color: #5038F5;
        border-radius: 50%;
        transition: 0.3s;
    }
    strong {
        position: absolute;
        left: 100%;
        width: -webkit-max-content;
        width: max-content;
        line-height: 17px;
        margin-left: 10px;
        cursor: pointer;
        bottom: 0px;
    }
}





input:checked+span {
    background-color: #5038F5;
}

input:checked+span:before {
    transform: translateX(29px);
    background-color: #FFFFFF;
}


