.bulkDisableFacilityPopup {
  div {
    border-radius: 15px;
    border: none;
  }
}
.bulkDisableFacilityHeader {
  padding: 30px 30px 10px 30px !important;
  .bulkDisableHeaderTxt {
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.bulkDisableFacilityBody {
  padding: 5px 30px 5px 30px !important;
  .bulkDisableTxtCont {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    li {
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.bulkDisableFooter {
  padding: 15px 30px 30px 0px !important;
  .bulkCancelBtn {
    border: 1px solid #cfc8b8 !important;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: black;
    font-weight: 500;
  }
  .bulkDisableBtn {
    border: 1px solid #ea5037 !important;
    background-color: #ff5539;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 300;
  }
}
