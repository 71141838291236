.image-container {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 767px) and (max-width: 1023px) {
    flex-direction: column;
    gap: 0;
  }

  .image-heading {
    font-family: "Inter", serif;
    font-style: normal;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 0.907rem;
    color: #000000;
  }

  .image-instructions {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.778rem;
    color: #707070;
    margin-bottom: 0;
  }
}
