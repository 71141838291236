 .TempInputCardContainer {
     //  display: flex;
     //  justify-content: center;
     //  align-items: center;
     //  flex-direction: column;
     min-width: 10vw;
     margin-left: 10px;
     .tempInputTitleText {
         font-size: 0.8rem;
         font-weight: 600;
         margin: 0;
         padding: 0;
     }

     .styleInp {
         border: 2px solid #a7b8f4;
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
         height: 4.5vh;
         border-radius: 3px;

         .inputTempTextValue {
             width: 85%;
             border-left: 0;
             border-top: 0;
             border-bottom: 0;
             border-right: 2px solid #a7b8f4;
             font-size: 0.8rem;
             color: #000000;
             outline: none;
             padding: 0px 0px 0px 5px;
         }

         ::-moz-focus-inner {
             outline: none;
         }

         p {
             font-size: 14px;
             width: 20%;
             margin: 0;
             padding: 0;
             display: flex;
             justify-content: center;
             align-items: center;
         }
     }
 }
.tooltip {
    font-size: 8px !important;
    width: 16% !important;
}