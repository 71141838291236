$padding-left-card: 1.3rem;
$padding-right-card: 1.3rem;

.ahuCardParent {
  margin-top: 1.8rem;
  margin-left: 1rem;
  margin-bottom: 1.8rem;

  .ahuDetailsCard {
    display: flex;
    width: 19.5rem;
    flex-direction: column;
    border-radius: 0.6rem;
    border: 1.4px solid #DAE2FF;
    background: #FFF;

    &:hover {
      border: 1.4px solid #2680EB;
      background: #FAFBFD;
    }

    .ahuRowOne {
      display: flex;
      justify-content: end;
      height: 19px;
      .ahuTripped {
        display: flex;
        color: #FFF;
        font-family: Inter, serif;
        font-size: 0.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0.25rem 0.75rem;
        justify-content: center;
        align-items: center;
        margin-top: -1px;
        margin-right: -1px;
        border-radius: 0 0.5rem 0 0.3rem;
        background: #FF4E36;
        position: relative;
      }
    }

    .ahuRowTwo {
      display: flex;
      justify-content: space-between;
      padding-left: $padding-left-card;
      padding-right: $padding-right-card;
      padding-top: 0.75rem;

      .ahuId {
        display: flex;
        padding: 0.132rem 0.264rem;
        align-items: center;
        border-radius: 0.132rem;
        background: #FFC4AB;
        color: #000;
        font-family: Inter, serif;
        font-size: 0.704rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

    }

    .ahuRowThree {
      display: flex;
      justify-content: space-between;
      padding-left: $padding-left-card;
      padding-right: $padding-right-card;
      padding-top: 1rem;

      .ahuName {
        color: #000;
        font-family: Inter, serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .ahuType {
        display: flex;
        padding: 0.1rem 0.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.15rem;
        background: #3C3B3B;
        color: #FCFCFC;
        font-family: Inter, serif;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .ahuRowFour {
      display: flex;
      justify-content: space-between;
      padding-left: $padding-left-card;
      padding-right: $padding-right-card;
      padding-top: 1rem;
      align-items: flex-start;

      .temperatureContent {
        display: flex;
        align-items: flex-start;
        .redText{
          color: #F82828;
        }
      }

      .temperatureIcon {
        width: 0.96035rem;
        height: 0.96035rem;
        flex-shrink: 0;
        margin-top: 0.2rem;
        margin-right: 0.3rem;
      }

      .ahuTemperatureText {
        color: #0C0B0B;
        font-family: Inter, serif;
        font-size: 1.38295rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .temperateMinMax {
        display: flex;
        gap: 0.2rem;

        .ahuMinMaxTemperatureTitle {
          color: #131523;
          font-family: Inter, serif;
          font-size: 0.6rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .ahuMinMaxTemperatureText {
          color: #131523;
          font-family: Inter, serif;
          font-size: 0.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .ahuRowFive {
      display: flex;
      justify-content: space-between;
      padding-left: $padding-left-card;
      padding-right: $padding-right-card;
      padding-top: 1rem;
      padding-bottom: 1rem;
      align-items: flex-start;

      .floorContent {
        display: flex;
        align-items: flex-start;

        .floorIcon {
          width: 0.675rem;
          height: 0.9643rem;
          flex-shrink: 0;
        }
        .floorText{
          color: #131523;
          font-family: Inter, serif;
          font-size: 0.81495rem;
          font-style: normal;
          font-weight: 400;
          margin-left: 0.3rem;
          line-height: normal;
        }
      }

      .updatedTime{
        color: #707070;
        text-align: right;
        font-family: Inter, serif;
        font-size: 0.72805rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }


    }
  }
}