.eventListDashboardMainDiv {
    background-color: #ffffff;
    height: 75vh;
    padding: 15px 25px 30px 25px;
    border-radius: 6px;
    position: -webkit-sticky;
    
    .eventListHeaderDiv {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .goBackDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            width: 7vw;

            .goBackDivTitle {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                margin: 0;
            }
        }

        .componentHeading {
            font-size: 20px;
            font-weight: 500;
            color: rgba(0, 0, 0, 1);
            margin: 0;
        }
    }

    .eventListFilterMainDiv {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 0px 10px;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
      
        .eventFilterRow {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: baseline;
      
          .eventFilterHeading {
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 1);
            margin: 0;
          }
      
          .filterApplyButton {
            width: fit-content;
            font-weight: 500;
            border-radius: 25px;
            padding: 4px 16px 4px 16px;
            border: 1px solid;
            margin: 0px 0px 0px 8px;
          }
      
          .enableFilterApplyButton {
            background-color: rgba(15, 67, 249, 1);
            border-color: rgba(15, 67, 249, 1);
            color: rgba(255, 255, 255, 1);
            cursor: pointer;
            font-size: 12px;
          }
      
          .disableFilterApplyButton {
            border-color: #999;
            background-color: #ccc;
            color: #666;
            cursor: default;
            font-size: 12px;
          }
      
          .clearAllButton {
            color: rgba(220, 53, 69, 1);
            font-size: 14px;
            cursor: pointer;
            width: 100px !important;
            padding: 0 !important;
            text-decoration-color: rgba(220, 53, 69, 1);
            margin: 0px 0px 0px 16px;
          }
      
        }
      
        .eventCountStatusDiv {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
          margin: 0;
          white-space: nowrap;
          text-align: right;
        }
      
      }

      .eventListDashboardMainContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 16px 20px 16px 20px;
    
        .eventListDashboardMainCont {
            display: flex;
            flex-direction: column;
            gap: 8px;
            height: 50vh;
            overflow-y: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar{
              display: none;
            }

        }

      }

}