.alertComp {
    margin: 16px 0 16px 16px;

    .alertComp_Header {
        padding: 15px;

        .dataDiv {
            .title {
                margin-left: 0;
                h3 {
                    font-size: 17px;
                    font-weight: 300;
                    font-family: "Inter", sans-serif !important;
                }

            }

            .count {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                h1 {
                    margin-right: 12px;
                    font-size: 32px;
                    font-weight: 600;
                    color: red;
                    font-family: "Inter", sans-serif !important;

                }

                h5 {
                    font-size: 12px;
                    font-family: "Inter", sans-serif !important;
                    .percentageIcon {
                        height: 10px;
                        width: 10px;
                    }
                }
            }

            .status {
                display: flex;
                align-items: center;

                .circle:first-child {
                    margin-right: 8px;
                    border-right: 3px solid #ccc;
                    padding-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div {
                        img{
                        // height: 15px;
                        // width: 15px;
                        // border: 2px solid #f00;
                        // background-color: red;
                        // border-radius: 3px;
                       
                    }}
                    h3 {
                        font-size: 16px;
                        color: red;
                        margin: 0;
                        margin-left: 10px;
                        span {
                            color:#707070;
                        }

                    }
                }

                .circle {
                    margin-right: 8px;
                    
                    padding-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div {
                        img{

                        }
                
                       
                    }
                    h3 {
                        font-size: 16px;
                        color: #008000;
                        margin: 0;
                        margin-left: 10px;
                        span {
                            color:#707070;
                        }

                    }
                }
               
            }
          

        }
    }

    .alertComp_chart {}

    .time{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DAE2FF;
        padding: 14px;
        margin-left: 16px;
        margin-right: 32px;
        div:first-child{
            img{

            }
           
        }
        div{
            
            .divStyle{
                margin-left: 13px;

                h3{
                    font-size: 13px;
                    font-weight: 400;
                    font-family: "Inter", sans-serif !important;
                    margin: 0;
                }
                h1{
                    font-size: 25px;
                    font-weight: 700;
                    margin: 0;
                    span{
                        font-size: 12px;
                        font-weight: 500;
                        margin-left: 16px;
                        color: #000;
                        
                    }
                }
            }
        }
    }
}