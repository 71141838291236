.filterChip {
  display: inline-block;
  background-color: rgba(255, 76, 48, 0.13);
  border-radius: 11px;
  padding: 3px 7px;
  color: #ff4c30;
  text-transform: capitalize;
  margin: 0 0 0px 8px;
  font-size: 9px;
  height: 3vh;
  &:hover {
    background-color: #ff4c30;
    color: #ffffff;
  }
}
