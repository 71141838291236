.loadingBox1 {
    width: 100%;
    display: flex;
    height: 15rem;
    justify-content: center;
}

.eventListMainContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 48px 20px;

    .eventListMainCont {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .paginationMainDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 8px;

        .paginationText {
            font-size: 13px;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
            margin: 0;
        }
    }

    .downloadEventLog {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(0, 117, 255, 0.2);
        gap: 8px;
        border-radius: 6px;
        padding: 4px 8px 4px 8px;
        margin-top: 8px;
        cursor: pointer;

        .downloadButtonText {
            font-size: 14px;
            font-weight: 500;
            color: rgba(82, 48, 212, 1);
            margin: 0;
        }
    }

}