.facilitySettingsCardsCont {
  height: 100%;
  // box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .facilitySettingsCardsNav {
    display: flex;
    flex-direction: row;
    height: 15%;
    border: 1px solid #bdcbfb;
    width: 94%;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
    position: relative;
    left: 15px;
    border-radius: 5px;
    p {
      font-size: 12px;
      margin: 0px;
      font-weight: 500;
    }
    .facilitySettingsNavBtnsCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .selectAllBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
        color: #000000;
      }
      .deselectAllBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
        color: #000000;
      }
      .addUserBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #017aff;
        color: #ffffff;
        border-radius: 3px;
        font-size: 12px;
      }
      .disableBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #fbe6b5;
        border: 1px solid #cfc8b8;
        border-radius: 3px;
        font-size: 12px;
        color: #000000;
        img {
          margin-top: -4px;
          height: 13px;
          width: 13px;
        }
      }
      .deleteBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #ed381b;
        border: 1px solid #ea5037;
        border-radius: 3px;
        font-size: 12px;
        color: #ffffff;
        img {
          margin-top: -4px;
          height: 15px;
          width: 13px;
        }
      }
    }
  }
  .facilityCardsCont {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 10px 0px 0px 0px;
    gap: 10px;
    position: relative;
    flex-wrap: wrap;
    overflow-y: scroll;
    // margin-bottom: 4%;
  }
  .facilityCardsCont::-webkit-scrollbar {
    display: none;
  }
}

// Add to facility Popup
.assignUserFacilityPopupModal {
  div {
    border-radius: 15px;
    border: none;
    width: 43vw;
  }
}
.assignUserFacilityPopHeaderCont {
  padding: 30px 35px 10px 35px !important;
  .assignUserFacilityPopHeader {
    margin: 0px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.assignUserFacilityPopBody {
  padding: 15px 35px 5px 35px !important;
  .assignUserFacilityPopBodyCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    .assignUserBodyHeaderCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 30px;
      gap: 30px;
      margin-bottom: 20px;
      width: 100%;
      p {
        margin: 0px;
        font-size: 15px;
        font-weight: 500;
      }
      select {
        width: 45%;
        outline: none;
        border: none;
        border: 1px solid #bdcbfb;
        padding: 5px;
        border-radius: 3px;
        font-size: 13px;
        font-weight: 400;
        color: #707070;
      }
    }
    .assignUserFacilityDetailsCont {
      display: flex;
      flex-direction: column;
      width: 100%;
      .detailsHeaderCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
          width: 43%;
          margin: 0px;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
      .selectorContDiv {
        height: 15vh;
        overflow-y: scroll;
        width: 100%;
        border-radius: 0px !important;
        .detailsSelectorCont {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          margin-bottom: 10px;
          width: 100%;
          .mapFacilitySelector {
            width: 45%;
            outline: none;
            border: 1px solid #bdcbfb;
            border-radius: 3px;
            padding: 0px;
            font-size: 13px;
            font-weight: 400;
          }

          select {
            width: 45%;
            outline: none;
            border: none;
            border: 1px solid #bdcbfb;
            padding: 10px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 500;
            color: black;
          }
          img {
            height: 20px;
            width: 14px;
            cursor: pointer;
          }
        }
      }
      .selectorContDiv::-webkit-scrollbar {
        display: none;
      }
    }
    .assignUserFacilityDetailsBtnCont {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 0px;
      width: 100%;
      margin-top: 6px;
      .assignUserFacilityDetailsBtn {
        background-color: #0f43f9;
        margin: 0px;
        color: #ffffff;
        border: none;
        padding: 3px 10px 3px 10px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 300;
        img {
          height: 11px;
          width: 13px;
          margin-right: 5px;
        }
      }
    }
  }
}
.assignUserFacilityPopFooterCont {
  padding: 0px 35px 30px 0px !important;
  .assignUserFacilityCancelBtn {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 15px;
    padding: 5px 12px 5px 12px;
    border-radius: 3px;
    font-weight: 500;
  }
  .assignUserFacilityMappingBtn {
    border: 1px solid #5711e2;
    background-color: #0075ff;
    font-size: 15px;
    color: #ffffff;
    padding: 5px 12px 5px 12px;
    border-radius: 3px;
    font-weight: 300;
  }
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: none !important;
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .facilitySettingsCardsCont {
    .facilitySettingsCardsNav {
      height: 8%;
      width: 91%;
    }
    .facilityCardsCont {
      flex-wrap: wrap;
      overflow-y: scroll;
      justify-content: center;
      padding: 10px 10px 0px 10px;
    }
    .facilityCardsCont::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .facilitySettingsCardsCont {
    .facilitySettingsCardsNav {
      height: 10%;
      width: 95%;
      align-items: center;
      grid-gap: 5px;
      gap: 5px;
      left: 10px;
      padding: 0px 5px 0px 5px;
      p {
        font-size: 8px;
      }
      .facilitySettingsNavBtnsCont {
        gap: 5px;
        .selectAllBtn {
          padding: 2px 8px 2px 8px;
          font-size: 8px;
        }
        .deselectAllBtn {
          padding: 2px 8px 2px 8px;
          font-size: 8px;
        }
        .addUserBtn {
          padding: 2px 8px 2px 8px;
          font-size: 8px;
        }
        .disableBtn {
          padding: 2px 8px 2px 8px;
          font-size: 8px;
          img {
            height: 10px;
            width: 10px;
          }
        }
        .deleteBtn {
          padding: 2px 8px 2px 8px;
          font-size: 8px;
          img {
            height: 12px;
            width: 10px;
          }
        }
      }
    }
    .facilityCardsCont {
      justify-content: center;
    }
  }

  // Add to facility Popup
  .assignUserFacilityPopupModal {
    div {
      width: 96vw;
    }
  }
  .assignUserFacilityPopHeaderCont {
    padding: 20px 20px 10px 20px !important;
    .assignUserFacilityPopHeader {
      font-size: 16px !important;
    }
    .closeIconDiv {
      height: 30px;
      width: 30px;
      img {
        cursor: pointer;
        height: 12px;
        width: 12px;
      }
    }
    .closeIconDiv:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
  }
  .assignUserFacilityPopBody {
    padding: 15px 20px 5px 20px !important;
    .assignUserFacilityPopBodyCont {
      .assignUserBodyHeaderCont {
        p {
          font-size: 13px;
        }
        select {
          width: 50%;
          padding: 4px;
          font-size: 10px;
        }
      }
      .assignUserFacilityDetailsCont {
        .detailsHeaderCont {
          p {
            font-size: 13px;
          }
        }
        .selectorContDiv {
          height: 11vh;
          .detailsSelectorCont {
            .mapFacilitySelector {
              width: 45%;
              outline: none;
              border: 1px solid #bdcbfb;
              border-radius: 3px;
              padding: 0px;
              font-size: 13px;
              font-weight: 400;
            }

            select {
              padding: 4px;
              border-radius: 3px;
              font-size: 10px;
            }
            img {
              height: 15px;
              width: 12px;
              cursor: pointer;
            }
          }
        }
        .selectorContDiv::-webkit-scrollbar {
          display: none;
        }
      }
      .assignUserFacilityDetailsBtnCont {
        .assignUserFacilityDetailsBtn {
          padding: 2px 8px 2px 8px;
          font-size: 10px;
          img {
            height: 11px;
            width: 13px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .assignUserFacilityPopFooterCont {
    padding: 0px 20px 15px 0px !important;
    .assignUserFacilityCancelBtn {
      font-size: 13px;
      padding: 3px 8px 3px 8px;
    }
    .assignUserFacilityMappingBtn {
      font-size: 13px;
      padding: 3px 8px 3px 8px;
    }
  }
}

.loadMore-btn {
  width: 10%;
  align-self: center;
  border: none;
  background-color: skyblue;
  border-radius: 20px;
  // margin-top: 5%;
}
