.cardMeterData {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;

    .meterDataHeading {
        font-size: 0.7017rem;
        font-weight: 400;
        color: rgba(112, 112, 112, 1);
        margin: 0;
    }

    .meterDataValueView {
        display: flex;
        flex-direction: column;

        .meterDataValue {
            font-size: 0.95rem;
            font-weight: 600;
            margin: 0;

            .unitDescription {
                font-size: 0.935rem;
                font-weight: 500;

            }
        }
    }
}

.limitValueClass{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.limitText {
    font-size: 0.7rem;
    font-weight: 600;
    color: rgba(27, 27, 27, 1);
    margin: 0;
    padding-right: 2px;
}

.limitValue {
    font-size: 0.7rem;
    font-weight: 400;
    color: rgba(27, 27, 27, 1);
    margin: 0;
}

.custom-tooltip {
    background-color: rgba(255, 255, 255, 1) !important;
    border-radius: 4px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 5px 5px 6px 5px !important;
}

/* Remove undesired shapes */
.custom-tooltip.tooltip::before,
.custom-tooltip.tooltip::after {
    content: none !important;
}


.cardMeterData {
    .__react_component_tooltip {
        margin-top: 1vh !important;
        opacity: 0.9 !important;
    }

    .__react_component_tooltip::before {
        display: none !important;
    }

    .__react_component_tooltip::after {
        display: none !important;
    }
}