.dateTimeInputCardContainer {
    min-width: 10vw;
    margin-left: 10px;

    .tempInputTitleText {
        font-size: 0.8rem;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }

    .styleInp {
        border: 2px solid #a7b8f4;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 4.5vh;
        border-radius: 3px;

        .inputTempTextValue {
            width: 85%;
            border-left: 0;
            border-top: 0;
            border-bottom: 0;
            border-right: 2px solid #a7b8f4;
            font-size: 0.8rem;
            color: #000000;
            outline: none;
            padding: 0px 0px 0px 5px;
        }

        ::-moz-focus-inner {
            outline: none;
        }.calenderIcon {
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .slectDateTimeCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 7px;
        margin-top: 3px;
        position: absolute;
        background-color: #FFFFFF;
        border-radius: 6px;
        z-index: 99;
        border : 2px solid rgb(173, 172, 172); 

        .bottonButtonsDiv {
            .applyButton {
                background-color: transparent;
                border-width: 2px;
                border: 1px solid #038aff;
                border-radius: 30px;
                font-size: 12px;
                padding: 3px;
                padding-left: 15px;
                padding-right: 15px;
                font-weight: 550;
                outline: none;
                z-index: 9999;
              }
              .cancelButton {
                margin-right: 2vw;
                font-weight: 550;
                font-size: 12px;
                border: none;
                outline: none;
                background-color: transparent;
                padding: 5px;
                z-index: 9999;
              }
              .applyButtonOpacity {
                opacity: 0.3;
                cursor: not-allowed;
                background-color: transparent;
                border-width: 2px;
                border: 1px solid #038aff;
                border-radius: 30px;
                font-size: 12px;
                padding: 3px;
                padding-left: 15px;
                padding-right: 15px;
                font-weight: 550;
                outline: none;
                z-index: 9999;
            }
        }
    }
}