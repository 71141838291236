.peakTimeAndFootfallContainer {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-around;
  .peakTimeAndFootfallTxtCont {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    .peakTimeAndFootfallTxtHead {
      font-size: 15px;
      font-weight: 600;
      color: #000000;
    }
    .peakTimeAndFootfallTxt {
      font-size: 12px;
      color: #707070;
      font-weight: 400;
    }
    .peakTimeAndFootfallTime {
      font-size: 25px;
      font-weight: 700;
      color: #000000;
    }
  }

  .footfallImageCountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    padding-top: 15px;
    .footfallImage {
      height: 40px;
      padding-bottom: 10px;
    }
    .footfallCountTextCont {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: -20px;
      .footfallCount {
        font-size: 25px;
        font-weight: 700;
        color: #000000;
      }
      .footfallCountText {
        font-size: 12px;
        font-weight: 700;
        color: #000000;
        margin-top: -5px;
      }
    }
  }
}
