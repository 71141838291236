.dropdownReportsReactSelect{
  display: flex;
  flex-direction: column;
  align-items: first;
  margin-left: 10px;
}

.reportsDropDownHeader{
  margin: 0;
  padding: 0;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
  line-height: 1.5rem !important;
  align-content: center !important;
}