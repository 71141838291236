.barContainer {
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    height: 40px;
    flex-direction: row;
    justify-content: "space-between";
    align-items: "center";
    padding: 5px;

    .subDivOne {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h5 {
            font-size: 14px;
            margin-top: 20px;
            font-family: sans-serif;
        }

        p {
            font-size: 12px;
            font-family: sans-serif;
            margin: 0;
            padding: 0;
        }
    }

    .subDivTwo {
        flex-grow: 1;
        margin: 0;
        padding: 0;
        width: 60%
    }
}