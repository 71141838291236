//Delete Facility Popup
.bulkDeletePopModal {
  div {
    border: none;
    border-radius: 15px;
  }
}
.bulkDeleteFacilityHeader {
  padding: 30px 30px 10px 30px !important;
  .bulkDeleteHeaderTxt {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin: 0;
  }
  .closeIconDiv {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .closeIconDiv:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}
.bulkDeleteBodyCont {
  padding: 5px 30px 5px 30px !important;
  .bulkDeleteBody {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    padding: 0px;
    li {
      margin: 0px;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.bulkDeleteFacilityFooterCont {
  position: relative;
  padding: 10px 30px 30px 0px !important;
  .deleteFacilitiesBtn {
    background-color: #ff5539;
    border: 1px solid #ea5037;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 400;
    border: none;
    img {
      height: 12px;
      width: 12px;
      margin-left: 10px;
    }
  }
  .cancelButton {
    border: 1px solid #cfc8b8;
    background-color: #fcf2da;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: black;
    font-weight: 500;
  }
  .deleteFacilityFeaturesCont {
    position: absolute;
    background-color: white;
    top: 50px;
    left: 120px;
    box-shadow: 2px 2px 4px gray;
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    width: 20vw;
    .deleteFacilityFeatures {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 5px 10px 5px 10px;
      cursor: pointer;

      .deleteFacilityFeaturesHeader {
        margin: 0px;
        font-size: 13px;
        font-weight: 600;
      }
      .deleteFacilityFeatureTxt {
        margin: 0px;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .deleteFacilityFeatures:hover {
      background-color: #d4ecff;
      border: 1px solid #9ad2ff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 5px 10px 5px 10px;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}
