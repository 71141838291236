.incidentSummaryContainer {
  padding: 20px;
}
.incidentSummaryBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .incidentSummaryBtnCompCont {
    border-bottom: 1px solid #cccccc;
    width: 50%;
    display: flex;
    flex-direction: row;
  }
  .incidentSummarySelector {
    padding-top: 15px;
    padding-right: 20px;
    width: 15%;
    .incidentSelector {
      border-color: #bdcbfb;
      outline: none;
      width: 150px;
      font-size: 13px;
      padding: 5px;
      border-radius: 5px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 600px) {
  .incidentSummaryBtnContainer .incidentSummarySelector {
    position: relative;
    right: 50px;
    .incidentSelector {
      width: 100px;
      font-size: 11px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .incidentSummaryBtnContainer .incidentSummarySelector .incidentSelector {
    width: 110px;
  }
}
