.dateTimeCard {
    border-bottom: 1px solid rgb(99, 95, 95);
    padding-left: 5px;
    margin-left: 5px;
    margin-bottom: 8px;
    p{
        margin: 0;
        padding: 0;
        font-size: 0.9rem;
        color: #000000;
        margin-bottom: 3px;
    }
    input {
        border: none;
        outline: none;
        font-size: 0.9rem;
        color: #000000;
    }
}