.usersCardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .usersCardsNav {
    display: flex;
    flex-direction: row;
    height: 13%;
    border: 1px solid #bdcbfb;
    width: 95%;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
    position: relative;
    border-radius: 5px;
    p {
      font-size: 12px;
      margin: 0px;
      font-weight: 500;
    }
    .usersNavBtnsCont {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .usersSelectAllBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
      }
      .usersDeselectAllBtn {
        border: none;
        padding: 3px 10px 3px 10px;
        background-color: #e1efff;
        border: 1px solid #bdcbfb;
        border-radius: 3px;
        font-size: 12px;
      }
      .usersDisableBtn {
        padding: 3px 10px 3px 10px;
        background-color: #fbe6b5;
        border: 1px solid #cfc8b8;
        border-radius: 3px;
        font-size: 12px;
        img {
          height: 10px;
          width: 10px;
          margin-right: 5px;
        }
      }
      .usersDeleteBtn {
        padding: 3px 10px 3px 10px;
        background-color: #ed381b;
        border: 1px solid #ea5037;
        color: #ffffff;
        border-radius: 3px;
        font-size: 12px;
        img {
          height: 13px;
          width: 13px;
          margin-right: 5px;
        }
      }
    }
  }
  .usersCardsCont {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 10px 0px 0px 0px;
    gap: 10px;
    position: relative;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
  .usersCardsCont::-webkit-scrollbar {
    display: none;
  }
}
