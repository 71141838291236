.summaryParentCard {
  padding: 13px;
  min-height: 40vh;

  .loderInCards {
    padding: 5%;
  }
}
.colOne {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(80px, auto);
  grid-column-start: 2;
  grid-column-end: 1;
  display: contents;
}
.summary-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-column-start: 2;
  grid-column-end: 1;
  display: contents;
}
.colOne {
  padding-left: 10px;
}
.datatoShow {
  text-align: center;
  margin: 0;
  padding: 1px;
  font-size: 12px !important;
  font-weight: 500 !important;
}
@media only screen and (max-width: 600px) {
  .colOne {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(80px, auto);
    grid-column-start: 2;
    grid-column-end: 1;
    display: contents;
  }
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-column-start: 2;
    grid-column-end: 1;
    display: contents;
  }
  .colOne {
    padding-left: 10px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .summaryParentCard {
    padding: 13px;
    margin-right: 10px;
  }
}
