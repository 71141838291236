.TempComplianceOpps {
  margin-top: 56px;
}

.tempcompliance-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #dae2ff;
  border-radius: 5px;
  margin: 4px;
  align-items: center;
  padding: 10px;

  .areas-details {
    display: flex;
    div {
      display: flex;
      flex-direction: column;
    }

    .atsuya-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      height: 2rem;
      width: 2rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      margin-top: 0px;
      margin-right: 7px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
      font-weight: 700;
    }

    p {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
    }
  }

  .batch-ids {
    display: flex;
    flex-direction: column;

    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 5px !important;
    }
  }

  .percentage-number {
    h6 {
      color: #182f43;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
      font-weight: 700;
    }
  }

  .temperature-values {
    h6 {
      color: #182f43;
      background-color: #fcf2da;
      padding: 5px;
      font-family: "Roboto";
      font-size: 12px;
      margin-bottom: 0px !important;
      font-weight: 700;
    }
  }
}

@media only screen and (max-width: 600px) {
  .tempcompliance-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #dae2ff;
    border-radius: 5px;
    margin: 4px;
    align-items: center;
    padding: 10px;
    height: 85px;
    position: relative;
  }

  .tempcompliance-container {
    .areas-details {
      .atsuya-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 2rem;
        width: 2rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        margin-top: 0px;
        margin-right: 7px;
        position: relative;
        bottom: 17px;
      }

      h6 {
        color: #182f43;
        font-family: "Roboto";
        font-size: 12px;
        margin-bottom: 0px !important;
        font-weight: 700;
        position: relative;
        bottom: 16px;
      }

      p {
        color: #182f43;
        font-family: "Roboto";
        font-size: 12px;
        margin-bottom: 0px !important;
        position: relative;
        right: 38px;
        top: 20px;
      }
    }
  }

  .tempcompliance-container .batch-ids h6 {
    color: #182f43;
    font-family: "Roboto";
    font-size: 12px;
    margin-bottom: 5px !important;
    position: absolute;
    top: 46px;
    left: 11px;
  }

  .tempcompliance-container .temperature-values h6 {
    color: #182f43;
    background-color: #fcf2da;
    padding: 5px;
    font-family: "Roboto";
    font-size: 12px;
    margin-bottom: 0px !important;
    font-weight: 700;
    position: relative;
    top: 17px;
    right: 38px;
  }
}
