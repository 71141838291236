.bar-chart {
  display: flex;
  flex-direction: column;
  margin: 15px 20px 20px 30px;
  min-height: 30vh;
  // overflow-x: scroll;
  // transition: all 0.5s;
  width: '100%';
  .labels{
    padding: 16px;
    display: flex;
    flex-direction: row;
    label{
      font-size: 14px;
      margin-right: 20px;
      input{
        margin-right: 10px;
      }
    }
  }
}
  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0.3rem;
    width: 55%;
    font-weight: 400;
    margin-right: 10px;
    margin-left: 10px;
    //align-items: center;
    p{
      font-size: 13px;
      margin: 0;
      padding: 0;
    // span{
    //   font-size: 13px;
    //   font-weight: 500;
    // }
  }
  }
  
  .bar-wrapper {
    //border-radius: 6px;
    //height: 25px;
    //background-color: #c4cdf0;
    flex-grow: 1;
    width: 45%;
    //margin-right: 10px;
  }

  .bar-wrapper-tickets {
   
      border-radius: 6px;
      height: 25px;
      background-color: #c4cdf0;
      flex-grow: 1;
      margin-right: 10px;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
  
  }
  
  .bar-value {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #ff7723;
    color: #fff;
    cursor: pointer;
    margin-right: 15px;
    span{
      margin-left: 4vw;
    }
  }
  .backButtonStock{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button{
      background-color: #00ffff00;
    border: 0;
    img{
      width: 1.2vw;
     }
    }
  }
  .showMoreButton{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    margin-top: 15px;
    img{
      width: 1.2vw;
      margin-left: 10px; 
    }
  }
  .title_Main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    .title_bar {
      padding: 5px;

  
      h3 {
        margin: 0;
        font-size: 15px;
      }
    }
  }

  
@media only screen and (max-width: 600px) {
  .bar-chart {
    display: flex;
    flex-direction: column;
    margin: 15px 0px 20px 0px;
    //max-height: 31vh;
   // overflow-x: scroll;
   // transition: all 0.5s;
    .labels {
      padding: 0px;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      label {
        font-size: 10px;
        margin-right: 20px;
        input {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 38vw!important;
    font-weight: 400;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 13px;
    //align-items: center;
    span {
      font-size: 11px;
      font-weight: 500;
    }
  }
}

 
@media only screen and (max-width: 768px) and (max-width:1024px) {

  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25vw;
    font-weight: 400;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 13px;
    //align-items: center;
    span {
      font-size: 11px;
      font-weight: 500;
    }
  }
}
