
.dateTimedivContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .cardContainer{
    box-shadow: 0 15px 40px rgba(49, 48, 48, 0.12);
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
    width: 350px;
    padding: 30px 30px 20px 5px;
    .iconsDivContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0;
      div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        p{
          margin: 0;
          padding: 0;
          font-size: 0.9rem;
        }
      button {
        border: none;
        background: none;
       // font-size: 0.9rem;
      }
      .disable {
        opacity: 0.3;
        font-size: 1rem;
      }.enable{
        font-size: 1rem;
      }
     }.selected {
        p{
          color: #1c03f5;
        }
      button {
        color: #1c03f5;
      }
      .disable {
        color: #1c03f5;
      }.enable{
        color: #1c03f5;
      }
     }.notSelected{
      p{
        color: #000000;
      }
    button {
      color: #000000;;
    }
    .disable {
      color: #000000;;
    }.enable{
      color: #000000;;
    }
     }
    
    }
    .calenderContainer{
      display: flex;
      flex-direction: row;
      width: 100%;
      max-height: 50vh;
      min-height: 30vh;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #FFFFFF;
      .timeSelector {
        width: 100%;
        .timeHeader {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          position: sticky;
          h5{
            font-size: 0.8rem;
            text-align: center;
            margin: 0;
            width: 20%;
            padding: 0;
          }.selected {
            color: #1c03f5;
          }.notSelected {
            color: #000000;
          }
        }
        .timeRow {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
          align-items: center;
          div{
            width: 20%;
            text-align: center;
            max-height: 28vh;
            overflow: scroll;
            p{
              margin: 0;
              padding: 0;
              font-size: 0.8rem;
              color: #000000;
              margin-top: 10px;
              cursor: pointer;
            }.selected{
              background-color: #1c03f5;
              border-radius: 2px;
              color: #FFFFFF;
            }
            p:not(.selected):hover{
              background-color: #f2f2f2;
            }
          }::-webkit-scrollbar {
            width: 0; /* Hide the scrollbar */
            background-color: transparent; /* Optional: Set a transparent background */
          }
        }
      }
    }
    .buttonRowDiv {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 10px;
      button { 
        font-size: 0.8rem;
        color: #000000;
        border: none;
        padding: 5px;
        margin-right: 10px;
        width: 20%;
        border-radius: 3px;
      }.applyButton{
        background-color: #1c03f5;
        color: #FFFFFF;
        border: 2px solid #1c03f5;
      }.cancelButton {
        background-color: #FFFFFF;
        color: #030bff;
        border: 2px solid #030bff;
      }
    }
  }
}


.dateTimeInputContainer {
  display: flex;
  width: 80%;
  flex-direction: row;
  border: 1px solid #000000;
  margin: 0;
  padding: 2px;
  border-radius: 3px;
  background-color: #FFFFFF;
  overflow-x: scroll;
  
  div{
    // width: 48%;
    input {
      color: #FFFFFF;
      color: #000000;
      font-weight: 500;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }
  //align-items: center;
}
.dateTimeInputContainer::-webkit-scrollbar{
  display: none;
}
.current-date {
  border: none;
  font-size: 0.8rem;
  outline: none;
  background: transparent;
  color: #000000;
}:focus{
  border: none;
  outline: none;
}

.dateSpecific{
  .modal-content {
    background-color: transparent !important;
    border: none !important;
    background: none !important;
  }
}
 
  


// .cardContainer header{
//   display: flex;
//   align-items: center;
//   padding: 25px 30px 10px;
//   justify-content: space-between;
//   //width: 30vw;
// }
// header .icons{
//   display: flex;
// }
// header .icons span{
//   height: 38px;
//   width: 38px;
//   margin: 0 1px;
//   cursor: pointer;
//   color: #878787;
//   text-align: center;
//   line-height: 38px;
//   font-size: 1.9rem;
//   user-select: none;
//   border-radius: 50%;
// }
// .icons span:last-child{
//   margin-right: -10px;
// }
// header .icons span:hover{
//   background: #f2f2f2;
// }
// header .current-date{
//   font-size: 1.45rem;
//   font-weight: 500;
// }



// .calendar{
//   padding: 5px;
//  // width: 100%;
// }



.calendar ul{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: center;
}
.calendar .days{
  margin-bottom: 20px;
}
.calendar li{
  color: #333;
  width: calc(100% / 7);
  font-size: 0.8rem;
  border: none;
}
.calendar .weeks li{
  font-weight: 500;
  width: calc(100% / 7);
  background-color: transparent;
}
.calendar .days li{
  z-index: 1;
  position: relative;
  margin-top: 0.5rem;
  width: calc(100% / 7);
  background-color: transparent;
}
.days li.active{
  color: #fff;
  opacity: 1;
  background-color: transparent;
  //border-radius: 50%;
}
.days li::before{
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 30px;
  width: 30px;
  z-index: -1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.days li.active::before{
  background-color: #1c03f5;
  color: #FFFFFF;
  font-weight: 600;
}
.days li:not(.active):hover::before{
 background-color: #f2f2f2;
 //border-radius: 50%;
}

.clickAble {
  opacity: 1;
  background-color: #000000;
  cursor: pointer;
}.notClickable  {
  opacity: 0.3;
  background-color: #767575;
  pointer-events: none;
}

.disableButton{
  opacity: 0.3;
  cursor: not-allowed;
}

.warningText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  p {
    color: #f01919;
    font-size: 0.7rem;
    margin: 0;
    padding: 0;
  }
}

.fullWidthInput {
  width: 100%;
}