.companyInformationContainer {
  margin-top: 15px;
  background-color: #fff;
  min-height: 70vh;
  width: 100%;
  border-radius: 5px;
  flex-direction: column;
  padding: 32px;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;

    @media only screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .companyInformationDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 64px;
    padding: 32px;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 8px;
      padding: 16px;
    }

    .companyDetails-left {
      width: 50%;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
      }

      .companyDetails-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding: 8px;

        @media only screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          padding: 8px 0px;
        }

        p {
          width: 40%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          margin: 0;
          padding: 0;

          @media only screen and (max-width: 767px) {
            width: max-content;
          }
        }

        input {
          width: 60%;
          height: 36px;
          border: 1px solid #bdcbfb;
          border-radius: 6px;
          padding: 8px;

          &::-webkit-inner-spin-button {
            display: none;
          }

          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }

    .companyDetails-right {
      width: 40%;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
      }

      .weightage-div {
        @media only screen and (max-width: 767px) {
          flex-wrap: nowrap;
          display: none;
        }

        @media only screen and (min-width: 767px) and (max-width: 1025px) {
          flex-wrap: nowrap;
          display: none;
        }
      }

      .weightage {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #787878;
        text-align: end;
      }

      .margin {
        margin-top: 16px;
      }

      .slider-container {
        display: flex;
        flex-direction: row;
        gap: 16px;

        @media only screen and (max-width: 767px) {
          margin-top: 16px;
          flex-direction: column;
          gap: 0;
        }

        @media only screen and (min-width: 767px) and (max-width: 1024px) {
          margin-top: 16px;
          flex-direction: column;
          gap: 0;
        }

        .category {
          width: 30%;
          margin: 0;
          padding: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000000;

          @media only screen and (max-width: 767px) {
            width: 100%;
          }

          @media only screen and (min-width: 767px) and (max-width: 1024px) {
            width: 100%;
          }
        }

        .slider {
          width: 100%;
          display: flex;
          flex-direction: row;

          input {
            width: 100%;
          }

          .category-result {
            width: 20%;
            text-align: center;
            margin: 0;
            padding: 0;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;

            @media only screen and (min-width: 767px) and (max-width: 1023px) {
              width: 30%;
            }
          }
        }
      }
    }
  }

  .horizontal-line {
    width: 100%;
    height: 1px;
    border: 1px solid #d7dbec;
  }

  .action-area {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px;
    gap: 8px;

    .cancel-button {
      background: #fcf2da;
      border: 1px solid #cfc8b8;
      border-radius: 4px;
      padding: 3px 12px;
    }

    .update-button {
      background: #0075ff;
      border: 1px solid #5711e2;
      border-radius: 4px;
      color: #ffffff;
      padding: 3px 12px;
    }
  }
}
