.downloadEventPopUpHeader {
    border: none;

    .downloadEventHeaderMainDiv {
        width: 73%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .eventHeaderTitle {
            font-size: 16px;
            font-weight: 600;
            color: rgba(30, 30, 30, 1);
            margin: 0;
        }

        .closeIconDiv {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .closeIcon {
                width: 15px;
                height: 15px;
            }

            &:hover {
                background-color: #d3d3d3;
            }

        }

    }

}

.downloadEventPopUpBody {
    border: none;
    min-width:65px;
    overflow: hidden;

    .downloadEventBodyMainDiv {
        display: flex;
        flex-direction: row;
        margin: 16px 0px 0px 0px;
        justify-content: space-between;

        .eventDateTimeRangePickerDiv {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .dateTimeRangePickerTitle {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                margin: 0;
            }

            .dateRangeWarningText {
                font-size: 11px;
                font-weight: 400;
                color: rgba(215, 21, 21, 1);
                margin: 0;
            }
        }

        .eventFileFormatDiv {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 0px 16px 0px 0px;

            .eventFormatHeading {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                margin: 0;
            }

            .fileFormatDiv {
                display: flex;
                flex-direction: row;
                gap: 16px;

                .fileFormatCard {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;

                    .radioButtonOuterDiv {
                        width: 12px;
                        height: 12px;
                        border: 1.7px solid rgba(0, 117, 255, 1);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        cursor: pointer;

                        .radioButtonInnerDiv {
                            width: 4px;
                            height: 4px;
                            background-color: rgba(0, 117, 255, 1);
                            border-radius: 50%;
                        }
                    }

                    .fileFormatText {
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(22, 22, 22, 1);
                        margin: 0;
                    }
                }
            }
        }
    }

}

.downloadEventPopUpFooter {
    border: none;

    .downloadEventFooterMainDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .cancelButton {
            border: 1px solid rgba(207, 200, 184, 1);
            background-color: rgba(252, 242, 218, 1);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 8px 4px 8px;
            cursor: pointer;

            .cancelButtonText {
                font-size: 14px;
                font-weight: 500;
                color: rgba(6, 6, 6, 1);
                margin: 0;
            }
        }

        .downloadButtonDiv {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            background-color: rgba(0, 117, 255, 1);
            border: 1px solid rgba(87, 17, 226, 1);
            border-radius: 4px;
            padding: 4px 8px 4px 8px;
            cursor: pointer;

            .downloadButtonImage {
                width: 15px;
                height: 15px;
            }

            .downloadButtonText {
                font-size: 12px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                margin: 0;
            }
        }

    }

}


.downloadEventPopUp{
    .modal-dialog {
        .modal-content{
            min-width: 37vw !important;      
        }
    }
}