.faultsSummaryContainer {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  .chartBtnsCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    border-bottom: 1px solid #cccccc;
  }
  .selectorContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    .faultSelector {
      border-color: #bdcbfb;
      outline: none;
      font-size: 13px;
      padding: 5px;
      border-radius: 5px;
      font-weight: 500;
      width: 130px;
    }
  }
}
