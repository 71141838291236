.addEditOrgBody {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 15px;

  .addEditOrgParentRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .addEditOrgRow {
      width: 50%;
      position: relative;
    }
  }
}

.page-Layout {
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  padding: 0px 15px 7% !important;
  height: calc(100vh - 90px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    background: #e4e4e4;
  }

  &::-webkit-scrollbar-track-piece {
    background: #e4e4e4;
  }

  &::-webkit-scrollbar-button {
    background: #e4e4e4;
    height: 1%;
  }

  &::-webkit-scrollbar {
    //width: 0.5em;
    height: 0.5em;
  }

  &.pl-40 {
    padding: 20px 20px 5% 40px !important;
  }
}


::-webkit-scrollbar-thumb {
  background: #ffffff;
}

::-webkit-scrollbar-track-piece {
  background: #ffffff;
}

::-webkit-scrollbar-button {
  background: #ffffff;
  height: 1%;
}

::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
}

.pl-40 {
  padding: 20px 20px 5% 40px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(229, 229, 229);
  border-radius: 10px;
}

.page-Layout {
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  padding: 0px 15px 3% !important;
    height: calc(100vh - 70px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    background: #e4e4e4;
  }

  &::-webkit-scrollbar-track-piece {
    background: #e4e4e4;
  }

  &::-webkit-scrollbar-button {
    background: #e4e4e4;
    height: 1%;
  }

  &::-webkit-scrollbar {
    //width: 0.5em;
    height: 0.5em;
  }

  &.pl-40 {
    padding: 20px 20px 5% 40px !important;
  }
}

.marginBottom0px {
  margin-bottom: 0 !important;
}

.paddingTop30px{
  padding-top: 30px !important;
}


.dividerLineBlack {
  height: 0.074rem;
  background-color: #000000;
}


.dialog-width {
  width: 34.708rem !important;
}


.buttonPrimary {
  border: none;
  background-color: #0075FF;
  color: #ffffff;
  font-size: 0.828rem;
  height: 1.785rem;
  border-radius: 3px;
  border: 1px solid #5711E2;
}

.secondaryButton {
  border: 1px solid #CFC8B8;
  background-color: #FCF2DA;
  color: black;
  height: 1.785rem;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 12px 5px 12px;
  border-radius: 3px;
}

.addEditFooter {
  padding-right: 25px !important;
  padding-bottom: 20px !important;
  padding-left: 27px !important;
}

.createOrganisationPopFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .editSwitchCont {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin: 0;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .createOrganisationFooterBtns {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: auto;
  }
}

//.addEditModal {
//  //width: 60vw !important;
//  height: 50% !important;
//  //min-width: 50vw !important;
//  //max-width: 80vw !important;
//  min-height: 30vw !important;
//  margin-top: 150px !important;
//  margin-bottom: 100px !important;
//}

.addEditOrgRowLeft {
  padding-right: 15px;
}

.width100 {
  width: 100%;
}

.addEditOrgKey {
  font-size: 0.833rem;
  font-weight: 400;
  color: black;
}

.width89 {
  width: 89%;
}

.width84 {
  width: 84%;
}

.width86 {
  width: 86%;
}

.marginLeft4 {
  margin-left: 4px;
}

.addEditOrgInput {
  outline: none;
  border: 1px solid #bdcbfb;
  border-radius: 5px;
  font-size: 0.75rem !important;
  height: 1.792rem;
  width: 100%;
  padding: 4px !important;

  img {
    width: 1.292rem
  }
}

.greyedOut {
  color: #cccccc;
}

.adminInfoDivider {
  width: 3.379rem;
}

.inputFieldError{
  display: block !important;
  font-size: 0.6rem !important;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 0 !important;
  margin-bottom: 0;
}
