.selectedEventCategory {
    display: flex;
    flex-direction: column;

    .selectedEventHeader {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        .eventCloseIcon {
            width: 20px;
            height: 20px;
        }

        .goBackText {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: rgba(0, 0, 0, 1);
            margin: 0;
        }
    }

    .eventDetailsChart {
        display: flex;
        flex-direction: column;
        padding: 16px 25px 0px 25px;
        gap: 8px;

    }
}